import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Nav, Button, Modal, InputGroup, FormGroup, FormControl } from 'react-bootstrap'
import CurrentSongDisplay from './CurrentSongDisplay'
import SuggestionSearch from './SuggestionSearch'
import SpotifySearch from './SpotifySearch'
import AppleMusicSearch from './AppleMusicSearch'
import TidalSearch from './TidalSearch'
import CustomSong from './CustomSong'
import client from '../../../helpers/client'


const ALL_PROVIDERS = [
  { value: "current", label: "Current Song"},
  { value: "suggestions", label: "Suggestions" },
  { value: "spotify", label: "Spotify" },
  { value: "apple", label: "Apple Music" },
  { value: "tidal", label: "Tidal" },
  { value: "custom", label: "Add Custom Song" },
]

function availableProvidersFor({ currentSong, suggestionListId, supportsSpotify, supportsAppleMusic, supportsTidal }) {
  return ALL_PROVIDERS.filter(provider => {
    if (provider.value == "current") {
      return !!currentSong
    } else if (provider.value == "suggestions") {
      return !!suggestionListId
    } else if (provider.value == "spotify") {
      return supportsSpotify
    } else if (provider.value == "apple") {
      return supportsAppleMusic
    } else if (provider.value == "tidal") {
      return supportsTidal
    } else {
      return true
    }
  })
}

const MusicBrowser = ({ label, disabled, onChange, currentSong, suggestionListId, supportsSpotify, supportsAppleMusic, supportsTidal }) => {
  const availableProviders = useMemo(() => availableProvidersFor({ currentSong, suggestionListId, supportsSpotify, supportsAppleMusic, supportsTidal }), [currentSong, suggestionListId])

  const [ provider, setProvider ] = useState(availableProviders[0].value)
  const [ showSongPicker, setShowSongPicker ] = useState(false)
  const [ searchText, setSearchText ] = useState("")

  function handleSongSelected(song) {
    // Only send the song if it is not blank.  If it is blank, we just close
    // the modal.
    if(song && (song.name || song.artistName || song.albumName || song.songNotes)) {
      onChange(song)
    }
    setShowSongPicker(false)
  }

  function handleSongRemoved(e) {
    e.preventDefault()
    onChange({ id: null, name: null, artistName: null, albumName: null, songNotes: null })
    setShowSongPicker(false)
  }


  return (
    <>
      <Button onClick={() => setShowSongPicker(true)} disabled={disabled}>{ label }</Button>
      <Modal show={showSongPicker} onHide={() => setShowSongPicker(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Search Songs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={styles.navWrapper}>
            <Nav variant="pills" activeKey={provider} onSelect={provider => setProvider(provider)}>
              { availableProviders.map(provider => (
                <Nav.Item key={provider.value}>
                  <Nav.Link eventKey={provider.value}>
                    {provider.label}
                  </Nav.Link>
                </Nav.Item>
              )) }
            </Nav>
          </div>
          { provider == "current" &&
          <div>
            <CurrentSongDisplay
              currentSong={currentSong}
              onSelect={handleSongSelected}
            />
            <div className="text-end">
              <a href="#" onClick={handleSongRemoved} className="text-danger">Remove Song</a>
            </div>
          </div>
          }
          { provider == "suggestions" &&
            <SuggestionSearch
              searchText={searchText}
              setSearchText={setSearchText}
              onSelect={handleSongSelected}
              suggestionListId={suggestionListId}
            />
          }
          { provider == "spotify" &&
            <SpotifySearch
              searchText={searchText}
              setSearchText={setSearchText}
              onSelect={handleSongSelected}
            />
          }
          { provider == "apple" &&
            <AppleMusicSearch
              searchText={searchText}
              setSearchText={setSearchText}
              onSelect={handleSongSelected}
            />
          }
          { provider == "tidal" &&
            <TidalSearch
              searchText={searchText}
              setSearchText={setSearchText}
              onSelect={handleSongSelected}
            />
          }
          { provider == "custom" &&
            <CustomSong onSelect={handleSongSelected} />
          }
        </Modal.Body>
      </Modal>
    </>

  )
}

const styles = {
  navWrapper: {
    marginBottom: 20
  }
}

export default MusicBrowser
