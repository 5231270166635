import React, { useState } from 'react'
import { Form, FormGroup } from 'react-bootstrap'
import ErrorBlock from './ErrorBlock'
import MINIMAL_IMG from '../images/preview-minimal.png'
import AUTOMATIC_IMG from '../images/preview-automatic_slots.png'
import SLOT_PICKER_IMG from '../images/preview-slot_picker.png'
import CALENDAR_PICKER_IMG from '../images/preview-calendar_picker.png'

const FancyRadioElement = ({ selected, value, title, description, preview, onChange }) => (
  <Form.Check
    type="radio"
    className="radio-element"
    id={`calendarMode-${value}`}
    inline
  >
    <Form.Check.Label>
      <div><img src={ preview } /></div>
      <div className="title">{ title }</div>
    </Form.Check.Label>
    <Form.Check.Input
      type="radio"
      checked={selected === value}
      onChange={() => onChange(value)}
    />
    <Form.Check.Label>
      <div className="info">{ description }</div>
    </Form.Check.Label>
  </Form.Check>
)

const CalendarModeOptions = ({ formValues, setFormValues }) => {
  switch(formValues.dateMode) {
    case "date_only":
      return (
        <>
          <FancyRadioElement
            selected={formValues.calendarMode}
            value="automatic_slots"
            title="Calendar Picker"
            description="Show dates and allow customer to choose from available future dates"
            preview={CALENDAR_PICKER_IMG}
            onChange={value => setFormValues({ calendarMode: value })}
          />
        </>
      )
    case "date_time":
      return (
        <>
          <FancyRadioElement
            selected={formValues.calendarMode}
            value="minimal"
            title="Minimal"
            description="Display a simple date and timepicker and allow customers to choose from available future dates and times"
            preview={MINIMAL_IMG}
            onChange={value => setFormValues({ calendarMode: value })}
          />
          <FancyRadioElement
            selected={formValues.calendarMode}
            value="automatic_slots"
            title="Automatic Slots"
            description="Automatically generate and display available slots for the day and allow customers to choose from them"
            preview={AUTOMATIC_IMG}
            onChange={value => setFormValues({ calendarMode: value })}
          />
          <FancyRadioElement
            selected={formValues.calendarMode}
            value="predefined_slots"
            title="Predefined Slots"
            description="Define your own custom times for this package and display only the available slots to the customer"
            preview={SLOT_PICKER_IMG}
            onChange={value => setFormValues({ calendarMode: value })}
          />
        </>
      )
    default:
      throw new Error(`Invalid date_mode: ${formValues.dateMode}`)
  }
}

const CalendarModeSelector = (props) => {
  const { errors } = props

  return (
    <FormGroup>
      <div className="calendar-selector-wrapper">
        <CalendarModeOptions {...props} />
      </div>
      <ErrorBlock error={errors.calendarMode} />
    </FormGroup>
  )
}

export default CalendarModeSelector
