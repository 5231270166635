export default function extractAvailablePackagesFrom({ formSettings, serviceId }) {
  if(!serviceId) {
    return formSettings.availablePackages.slice()
  }

  // Remove packages and package groups that don't belong to the selected service
  return formSettings.availablePackages
    .filter(([groupName, packages]) => {
      return packages.some(([packageId, packageName, meta]) => {
        return meta.serviceId == serviceId
      })
    })
    .map(([groupName, packages]) => {
      return [groupName, packages]
    })
}
