import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import TreeView from 'react-treeview'
import PackageGroup from './PackageGroup.js'
import store from '../stores/store.js'

@inject("store")
@observer
export default class EventCategory extends Component {
  static propTypes = {
    eventCategory: PropTypes.object.isRequired,
    onClickPackage: PropTypes.func,
    showCheckboxes: PropTypes.bool
  }

  handleTreeClick = (id) => {
    const { store } = this.props
    store.toggleEventCategoryCollapsed(id)
  }

  handleSelection = (id) => {
    const { store } = this.props
    store.toggleEventCategorySelection(id)
  }

  renderEventCategory() {
    const { eventCategory, onClickPackage } = this.props

    if(onClickPackage) {
      return (
        <span
          onClick={() => onClickPackage({eventCategory})}
          style={{...styles.package, ...styles.clickableEventCategory}}
        >
          { eventCategory.name }
        </span>
      )
    }
    else {
      return <span style={styles.package}>{ eventCategory.name }</span>
    }
  }

  renderEventCategoryCheckbox() {
    const { eventCategory, showCheckboxes } = this.props

    if(showCheckboxes) {
      return (
        <Form.Check
          type="checkbox"
          style={styles.checkbox}
          id={`eventCategory-${eventCategory.id}`}
          >
          <Form.Check.Input
            onChange={() => this.handleSelection(eventCategory.id)}
            checked={!!eventCategory.selected}
          />
          <Form.Check.Label>
            { this.renderEventCategory() }
          </Form.Check.Label>
        </Form.Check>
      )
    }
    else {
      return this.renderEventCategory()
    }
  }

  render() {
    const { eventCategory, showCheckboxes, onClickPackage } = this.props

    return (
      <TreeView
        onClick={() => this.handleTreeClick(eventCategory.id)}
        collapsed={eventCategory.collapsed}
        nodeLabel={this.renderEventCategoryCheckbox()}
        >
        <div style={styles.offset}>
          {eventCategory.packageGroups.map(packageGroup =>
            <PackageGroup
              key={packageGroup.id}
              showCheckboxes={showCheckboxes}
              packageGroup={packageGroup}
              onClickPackage={onClickPackage}
            />
          )}
        </div>
      </TreeView>
    )
  }
}

const styles = {
  checkbox: {
    display: "inline-block",
    marginTop: 0,
    marginBottom: 5
  },
  offset: {
    marginLeft: 10
  },
  clickableEventCategory: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  package: {},
}
