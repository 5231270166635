import React from 'react';
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/lib/Async'
import CustomOption from './CustomOption'
import QuantityToken from './QuantityToken'
import BasicToken from './BasicToken'
// https://react-select.com/async#loading-asynchronously
import client from '../../helpers/client'
import './index.scss'

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    fontSize: 18,
    paddingLeft: 10
  })
}

const deserializeAddOn = (add_on) => {
  if(add_on && add_on.id && add_on.attributes) {
    return {
      quantity: add_on.quantity ? add_on.quantity : 1,
      value: add_on.id,
      label: add_on.attributes.name,
      attributes: add_on.attributes
    }
  }
  else {
    return null
  }
}

const deserializeAddOns = (serializedAddOns) => {
  // If serializedAddOns is an array
  if(Array.isArray(serializedAddOns)) {
    return serializedAddOns.map(add_on => deserializeAddOn(add_on)).filter(add_on => add_on)
  }
  else {
    return deserializeAddOn(serializedAddOns)
  }
}



class AddOnDropdown extends React.Component {
  static propTypes = {
    addOns: PropTypes.array,
    inputId: PropTypes.string,
    selected: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    apiKey: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    showQuantities: PropTypes.bool
  }

  static defaultProps = {
    addOns: [],
    multiple: true,
    showQuantities: true
  }

  state = {
    selectedOptions: deserializeAddOns(this.props.selected),
    currentValue: ""
  }

  componentDidMount() {
    const { inputId } = this.props

    if(inputId) {
      this.input = document.getElementById(inputId)
      this.syncHtmlInput()
    }
  }

  handleInputChange = (currentValue) => {
    this.setState({ currentValue })
  }

  handleChange = (selectedOptions) => {
    this.setState({ selectedOptions }, () => this.syncHtmlInput())

  }

  syncHtmlInput() {
    const { showQuantities } = this.props
    const { selectedOptions } = this.state

    if(this.input) {
      if(selectedOptions) {
        if(Array.isArray(selectedOptions) && showQuantities) {
          this.input.value = selectedOptions.map(option => `${option.quantity}x${option.value}`).join(",")
        }
        else if(Array.isArray(selectedOptions)) {
          this.input.value = selectedOptions.map(option => option.value).join(",")
        }
        else {
          this.input.value = selectedOptions.value
        }
      }
      else {
        this.input.value = ""
      }
    }
  }

  handleLoadOptions = (inputValue, callback) => {
    const { apiKey } = this.props
    const { currentValue } = this.state

    client.get(`/api/v1/add_ons.json?api_key=${apiKey}&text=${currentValue}&order=name&only_widget_visible=0`)
      .then(response => {
        const options = deserializeAddOns(response.data.data)
        callback(options)
      })
      .catch(error => {
        const { data, status } = error.response
        const { errors } = data
        const errorMessage = status === 422 ? errors.join(", ") : "Error contacting server"
        alert(errorMessage)
      })
  }



  render() {
    const { addOns, multiple, showQuantities } = this.props
    const { selectedOptions, currentValue } = this.state;

    return (
      <AsyncSelect
        cacheOptions
        name={"add_on_id"}
        loadOptions={this.handleLoadOptions}
        onInputChange={this.handleInputChange}
        onChange={this.handleChange}
        components={{
          Option: CustomOption,
          MultiValueLabel: showQuantities ? QuantityToken : BasicToken,
        }}
        styles={customStyles}
        placeholder="Start typing..."
        noOptionsMessage={() => currentValue ? "Unable to find any matching add ons" : "Start typing to search for add ons"}
        defaultOptions={deserializeAddOns(addOns)}
        value={selectedOptions}
        isClearable={true}
        isMulti={multiple}
      />
    );
  }
}

export default AddOnDropdown
