import React from 'react'
import { Button, InputGroup, FormGroup, FormControl } from 'react-bootstrap'

const SearchBar = ({ searchText, setSearchText, loading, onSearch }) => (
  <form onSubmit={onSearch}>
    <FormGroup>
      <InputGroup>
        <FormControl
          type="text"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          placeholder="Search for songs by name, artist, or album"
          autoFocus
        />
        <Button type="submit" variant="primary" disabled={loading}>
          { loading ? "Searching..." : "Search" }
        </Button>
      </InputGroup>
    </FormGroup>
  </form>
)

export default SearchBar
