import { autorun, observable, action, computed, toJS } from 'mobx'
import client from '../../../helpers/client'
import { asNormalizedMaps, asNormalizedArrays } from '../../../helpers/json-normalizer'
import { format, addDays } from 'date-fns'
import flatMap from 'lodash/flatMap'


class Store {
  @observable ready = false
  @observable loadingSlots = true
  @observable franchise
  @observable eventCategories
  @observable packageGroups
  @observable eventTypes
  @observable selectedPackage = null
  @observable selectedDate = new Date
  @observable selectedTime = null
  @observable lengthInMinutes = ''
  @observable lengthInDays = ''
  @observable units = ''
  @observable packageUnitRangeId = ''
  @observable slots = []
  @observable unrecoverableError
  @observable cartHtml = ''
  @observable chosenDateOrTimeAvailable

  constructor({ host, apiKey, selectedPackage, franchise, cartHtml, unavailableHtml }) {
    this.host = host
    this.apiKey = apiKey
    this.selectedPackage = selectedPackage
    this.unavailableHtml = unavailableHtml
    if(franchise) {
      this.franchise = franchise
    }
    if(cartHtml) {
      this.cartHtml = cartHtml
    }
    this.slotsLoadedCallback = null
  }

  async setup({
    lengthInMinutes,
    lengthInDays,
    units,
    packageUnitRangeId,
    selectedDate,
    selectedTime,
    onSetDate,
    onSetTime,
    onSetLengthInMinutes,
    onSetLengthInDays,
    onSetUnits,
    onSetPackageUnitRangeId,
    onSubmit
  }) {
    if(lengthInMinutes) {
      this.lengthInMinutes = lengthInMinutes
    }
    if(lengthInDays) {
      this.lengthInDays = lengthInDays
    }
    if(units) {
      this.units = units
    }
    if(packageUnitRangeId) {
      this.packageUnitRangeId = packageUnitRangeId
    }
    if(selectedDate) {
      this.selectedDate = selectedDate
    }
    if(selectedTime) {
      this.selectedTime = selectedTime
    }
    if(onSetLengthInMinutes) {
      this.onSetLengthInMinutes = onSetLengthInMinutes
    }
    if(onSetLengthInDays) {
      this.onSetLengthInDays = onSetLengthInDays
    }
    if(onSetUnits) {
      this.onSetUnits = onSetUnits
    }
    if(onSetPackageUnitRangeId) {
      this.onSetPackageUnitRangeId = onSetPackageUnitRangeId
    }
    if(onSetDate) {
      this.onSetDate = onSetDate
    }
    if(onSetTime) {
      this.onSetTime = onSetTime
    }
    if(onSubmit) {
      this.onSubmit = onSubmit
    }

    if(!this.franchise) {
      await this.loadFranchise()
    }
    if(this.selectedPackage && this.dateMode == "date_time") {
      await this.loadSlots()
    }
    else if(this.selectedPackage && this.dateMode == "date_only") {
      await this.checkAvailabilityForDateRange()
    }

    this.ready = true
  }

  @action setDate(date) {
    if(this.onSetDate) {
      this.onSetDate(date)
    }
    this.selectedDate = date
    this.selectedTime = null
    this.slots = []

    if(!this.selectedPackage) {
      return
    }

    switch(this.dateMode) {
      case "date_only":
        this.checkAvailabilityForDateRange()
        this.loadCart()
        break;
      case "date_time":
        this.loadSlots()
        break;
      default:
        throw new Error(`Invalid datemode: ${this.dateMode}`)
    }
  }

  @action setTime(localizedTime) {
    if(this.onSetTime) {
      this.onSetTime(localizedTime)
    }
    this.selectedTime = localizedTime
    this.loadCart()
  }

  @action setLengthInMinutes(lengthInMinutes) {
    if(this.onSetLengthInMinutes) {
      this.onSetLengthInMinutes(lengthInMinutes)
    }
    this.lengthInMinutes = lengthInMinutes
    this.loadSlots()
    this.loadCart()
  }

  @action setLengthInDays(lengthInDays) {
    if(this.onSetLengthInDays) {
      this.onSetLengthInDays(lengthInDays)
    }
    this.lengthInDays = lengthInDays
    this.loadSlots()
    this.loadCart()
  }

  @action setUnits(units) {
    if(this.onSetUnits) {
      this.onSetUnits(units)
    }
    this.units = units
    this.loadCart()
  }

  @action setPackageUnitRangeId(packageUnitRangeId) {
    if(this.onSetPackageUnitRangeId) {
      this.onSetPackageUnitRangeId(packageUnitRangeId)
    }
    this.packageUnitRangeId = packageUnitRangeId
    this.loadCart()
  }

  @action submit() {
    if(this.onSubmit) {
      this.onSubmit()
    }
  }

  @computed get groupedPackageGroups() {
    const sortedEventCategories = Array.from(this.eventCategories)
      .map(([id, eventCategory]) => eventCategory)
      .sort((a, b) => a.attributes.position - b.attributes.position)

    return flatMap(sortedEventCategories, (eventCategory) => {
      return eventCategory.relationships.packageGroups.data
        .map(({ id }) => this.packageGroups.get(id))
        .filter(packageGroup => packageGroup)
        .sort((a, b) => a.attributes.position - b.attributes.position)
        .map(packageGroup => {
          return { eventCategory, packageGroup }
        })
    })
  }

  @computed get availableLengthsInMinutes() {
    if(!this.selectedPackage) {
      return []
    }
   
    const { minLength, maxLength, stepInMinutes, enableCustomerCanBookExtraHours } = this.selectedPackage.attributes

    if(!enableCustomerCanBookExtraHours) {
      return []
    }

    let availableLengths = []
    for(let i = minLength; i <= maxLength; i += stepInMinutes) {
      availableLengths.push(i)
    }
    return availableLengths
  }

  @computed get availableLengthsInDays() {
    if(!this.selectedPackage) {
      return []
    }
   
    const { minLengthInDays, maxLengthInDays, enableCustomerCanBookExtraDays } = this.selectedPackage.attributes
    if(!enableCustomerCanBookExtraDays) {
      return []
    }

    let availableLengths = []
    for(let i = minLengthInDays; i <= maxLengthInDays; i += 1) {
      availableLengths.push(i)
    }
    return availableLengths
  }

  @computed get endDate() {
    if(!this.selectedPackage) {
      return null
    }

    switch(this.dateMode) {
      case "date_only":
        return addDays(this.selectedDate, this.lengthInDays - 1)
      case "date_time":
        return this.selectedDate
      default:
        throw new Error(`Invalid value for datemode: ${this.dateMode}`)
    }
  }

  @computed get dateMode() {
    if(this.selectedPackage) {
      return this.selectedPackage.attributes.dateMode
    }
    else {
      return null
    }
  }

  @computed get showPackageSelector() {
    return !!this.selectedPackage
  }

  @computed get showDateSelector() {
    return !!this.selectedPackage
  }

  @computed get showHourlySelector() {
    return this.availableLengthsInMinutes.length > 0
  }

  @computed get showDailySelector() {
    return this.availableLengthsInDays.length > 0
  }

  @computed get showUnitSelector() {
    if(!this.selectedPackage) {
      return false
    }

    if(!this.selectedPackage.attributes.enableCustomerCanBookPerUnit) {
      return false
    }

    if(this.selectedPackage.attributes.dateMode == "date_time" && !this.selectedTime) {
      return false
    }

    return true
  }

  @computed get showUnitRangeSelector() {
    if(!this.selectedPackage) {
      return false
    }

    if(!this.selectedPackage.attributes.enableCustomerCanBookPerUnitRange) {
      return false
    }

    if(this.selectedPackage.attributes.dateMode == "date_time" && !this.selectedTime) {
      return false
    }

    return true
  }

  @computed get showTimeSlotSelector() {
    return this.selectedPackage && this.selectedDate && !this.selectedTime && this.slots.length > 0
  }

  @computed get showLoader() {
    return this.loadingSlots
  }

  @computed get showUnavailable() {
    if(!this.selectedPackage) {
      return false
    }

    return this.selectedDate && !this.chosenDateOrTimeAvailable
  }

  @computed get showCartPreview() {
    if(!this.selectedPackage) {
      return false
    }

    if(this.dateMode == "date_time" && this.selectedDate && this.selectedTime) {
      return true
    }
    else if(this.dateMode == "date_only" && this.selectedDate && this.chosenDateOrTimeAvailable) {
      return true
    }
    else {
      return false
    }
  }

  @computed get showContinue() {
    if(!this.selectedPackage) {
      return false
    }

    if(!this.selectedDate) {
      return false
    }

    if(this.dateMode == "date_time" && !this.selectedTime) {
      return false
    }

    if(this.dateMode == "date_only" && !this.chosenDateOrTimeAvailable) {
      return false
    }

//     const { enableCustomerCanBookPerUnit, enableCustomerCanBookPerUnitRange } = this.selectedPackage.attributes
//     if(enableCustomerCanBookPerUnit && !this.units) {
//       return false
//     }

//     if(enableCustomerCanBookPerUnitRange && !this.packageUnitRangeId) {
//       return false
//     }

    return true
  }

  loadFranchise() {
    return client.get(`${this.host}/api/v1/franchise`)
      .then(response => {
        const data = asNormalizedArrays(response.data)
        this.franchise = Object.values(data.franchise)[0]
      })
      .catch(error => (this.unrecoverableError = error))
  }
  

  loadSlots() {
    if(this.selectedDate && this.selectedPackage) {
      const formattedDate = format(this.selectedDate, "yyyy-MM-dd")

      this.loadingSlots = true
      return client.get(`${this.host}/api/v1/available_slots?package_id=${this.selectedPackage.id}&event_date=${formattedDate}&length_in_minutes=${this.lengthInMinutes}&length_in_days=${this.lengthInDays}`)
        .then(response => {
          const data = asNormalizedArrays(response.data)
          this.loadingSlots = false
          if(data.availabilitySlot) {
            this.slots = Object.values(data.availabilitySlot)
            this.chosenDateOrTimeAvailable = true
          }
          else {
            this.slots = []
            this.chosenDateOrTimeAvailable = false
          }
          if(this.slotsLoadedCallback) {
            this.slotsLoadedCallback()
          }
        })
        .catch(error => (this.unrecoverableError = error))
    }
    else {
      this.slots = []
    }
  }

  checkAvailabilityForDateRange() {
    if(this.selectedDate && this.selectedPackage) {
      const formattedStartDate = format(this.selectedDate, "yyyy-MM-dd")
      const formattedEndDate = format(this.endDate, "yyyy-MM-dd")

      this.loadingSlots = true
      return client.get(`${this.host}/api/v1/availability?package_id=${this.selectedPackage.id}&starts_at=${formattedStartDate}&ends_at=${formattedEndDate}`)
        .then(response => {
          const available = response.data.data.attributes.available
          this.loadingSlots = false
          this.chosenDateOrTimeAvailable = available

          if(this.slotsLoadedCallback) {
            this.slotsLoadedCallback()
          }
        })
        .catch(error => (this.unrecoverableError = error))
    }
    else {
      this.slots = []
    }
    
  }

  loadCart() {
    const formattedDate = format(this.selectedDate, "yyyy-MM-dd")

    client.post(`/reservation/minimal_cart`, {
      lengthInMinutes: this.lengthInMinutes,
      lengthInDays: this.lengthInDays,
      startsAtDate: formattedDate,
      startsAtTime: this.selectedTime,
      units: this.units,
      packageUnitRangeId: this.packageUnitRangeId,
    })
      .then(response => (this.cartHtml = response.data))
      .catch(error => (this.unrecoverableError = error))

  }
}

export default Store
