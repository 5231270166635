import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import EventCategory from './EventCategory'
import TreeView from 'react-treeview'

@inject("store")
@observer
export default class PackageSelector extends Component {
  renderAllPackagesElement() {
    const { allowSelectAll, showCheckboxes, onClickPackage, store } = this.props
    const { showAllPackages, showCustom, viewableEventCategories } = store

    if(allowSelectAll) {
      return (
        <TreeView
          onClick={() => store.toggleAllPackagesCollapsed()}
          collapsed={store.allCategoriesCollapsed}
          nodeLabel={this.renderAllPackagesCheckbox()}
        >
          <div style={styles.offset}>
            {viewableEventCategories.map((eventCategory, i) =>
              <EventCategory
                key={eventCategory.id}
                eventCategory={eventCategory}
                showCheckboxes={showCheckboxes}
                onClickPackage={onClickPackage}
              />
            )}
            <Form.Check
              type="checkbox"
              checked={!!showCustom}
              style={styles.checkbox}
              onChange={() => store.toggleCustom()}
              label="Custom / One-off Packages"
              id="package-selector-custom"
            />
          </div>
        </TreeView>
      )
    }
    else {
      return (
        <div style={styles.offset}>
          {viewableEventCategories.map((eventCategory, i) =>
            <EventCategory
              key={eventCategory.id}
              eventCategory={eventCategory}
              showCheckboxes={showCheckboxes}
              onClickPackage={onClickPackage}
            />
          )}
        </div>
      )
    }
  }


  renderAllPackagesCheckbox() {
    const { store } = this.props

    return (
      <Form.Check
        type="checkbox"
        onChange={() => store.toggleAllPackages()}
        checked={store.allCategories || false}
        style={styles.checkbox}
        label="All Packages"
        id="package-selector-all"
      />
    )
  }

  render() {
    const { showCheckboxes, showActiveInactiveSelector, store } = this.props
    const { showAllPackages, showCustom } = store

    return (
      <div>
        { this.renderAllPackagesElement() }
        { showActiveInactiveSelector &&
          <div
            style={styles.toggleAllPackagesWrapper}
          >
            { showAllPackages ?  "* Showing All Packages" : "* Showing Public Packages Only" }
            <span
              style={styles.toggleAllPackages}
              onClick={() => store.showAllPackages = !store.showAllPackages}
            >
              { showAllPackages ?  "(Show public packages only)" : "(Show all packages)" }
            </span>
          </div>
        }
      </div>
    )
  }
}

const styles = {
  checkbox: {
    display: "inline-block",
    marginTop: 0,
    marginBottom: 5
  },
  toggleAllPackagesWrapper: {
    fontSize: 11,
    fontStyle: 'italic',
    marginTop: 10
  },
  toggleAllPackages: {
    cursor: 'pointer',
    textDecoration: 'underline',
    paddingLeft: 4
  }
}

