import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Button } from 'react-bootstrap'
import ChecklistItemEditor from './ChecklistItemEditor'
import extractErrors from '../util/extractErrors'


@inject("checklistStore")
@observer
export default class NewChecklistItem extends React.Component {
  static propTypes = {
    checklistStore: PropTypes.object.isRequired,
  }

  state = {
    show: false,
    loading: false,
    errors: {}
  }

  handleOpen = (e) => {
    e.preventDefault()
    this.setState({ show: true })
  }

  handleClose = () => {
    this.setState({ show: false })
  }

  handleSubmit = async ({ title, notes, dueOn, users }) => {
    const { checklistStore } = this.props

    await this.setState({ loading: true })

    try {
      await checklistStore.createItem({ title, notes, dueOn, users })
      this.setState({ loading: false, show: false, errors: {} })
      return true
    } catch(e) {
      const errors = extractErrors(e)
      this.setState({ loading: false, errors })
      return false
    }
  }



  render() {
    const { checklistStore, buttonProps, buttonContent } = this.props
    const { show, loading, errors } = this.state
    const { editChecklist } = checklistStore

    if(!editChecklist) {
      return false
    }

    return (
      <div className={`new-item`}>
        <ChecklistItemEditor
          modalTitle="New Item"
          show={show}
          onHide={this.handleClose}
          onSubmit={this.handleSubmit}
          loading={loading}
          errors={errors}
        />

        <Button
          onClick={this.handleOpen}
          variant="secondary"
          {...buttonProps}
        >
          { buttonContent ?
              buttonContent :
              <>
                <i className="fa fa-plus" />
                { " " }
                New Item
              </>
          }
        </Button>
      </div>
    )
  }
}

