function elementsForField(field) {
  switch(field.type) {
    case "text_field":
      return ["text"]
    case "double_text_field":
      return ["text", "text2"]
    case "triple_text_field":
      return ["text", "text2", "text3"]
    case "quadruple_text_field":
      return ["text", "text2", "text3", "text4"]
    case "text_box":
      return ["text"]
    case "radio_group":
      return ["radio"]
    case "dropdown":
      return ["dropdown"]
    case "checkbox":
      return ["checkbox"]
    case "checkbox_group":
      return ["checkbox"]
    case "file_uploader_field":
      return ["file"]
    case "image_uploader_field":
      return ["file"]
    case "e_sign_field":
      return ["signer"]
    default:
      return ["text"]
  }
}

export default elementsForField
