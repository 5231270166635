import React from 'react'
import fieldTypes from '../data/fieldTypes.json'

const FieldShadowEffect = ({ field, children }) => {
  const meta = fieldTypes[field.type]
  const classNames = meta.shadow ? `field-instance shadow-sm` : `field-instance border-0`

  return (
    <div className={classNames}>
    { children }
    </div>
  )
}

export default FieldShadowEffect
