import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Form, FormGroup } from 'react-bootstrap'

const BrandSelector = ({ store }) => {
  const { showBrands, brands, brandId } = store

  if(!showBrands) return null

  return (
    <div>
      <FormGroup>
        <div className="brand-selector-control">
          <Form.Select
            name="group-by"
            value={brandId}
            onChange={e => (store.brandId = e.target.value)}
            size="sm"
          >
            <option value="">All Brands</option>
            { brands.map(brand =>
            <option key={brand.id} value={brand.id} >{ brand.name }</option>
            )}
          </Form.Select>
        </div>
      </FormGroup>
    </div>
  )
}

export default inject("store")(observer(BrandSelector))
