import React from 'react'
import { observer, inject } from 'mobx-react'
import { inGroupsOf } from '../util'

@inject("store")
@observer
class ServiceSelector extends React.Component {
  render() {
    const { store } = this.props
    const { visibleEventCategories } = store
    const perRow = 3

    return (
      <div className="service-selector">
          { inGroupsOf(visibleEventCategories, perRow).map((row, index) =>
            <div key={index} className="service-selector-row">
              { row.map(eventCategory =>
                <div
                  onClick={() => (store.activeEventCategoryId = eventCategory.id)}
                  key={eventCategory.id}
                  className="service-selector-item"
                >
                  <div className="service-selector-service-image" style={{backgroundImage: `url(${eventCategory.primaryImage.panelVariant})`}} />
                  <div className="service-selector-service-title">{ eventCategory.name } </div>
                </div>
              )}
            </div>
          )}
      </div>
    )
  }
}

export default ServiceSelector
