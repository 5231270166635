import React, { useState, useCallback, useRef } from 'react'
import store from '../stores/store.js'
import { observer } from "mobx-react"
import { Dropdown, NavItem, NavLink } from 'react-bootstrap'
import FaIcon from '../../../helpers/fa_icon.js'
import OutsideClicker from '../../OutsideClicker'

const removeConfirmationMessage = 'Are you sure you want to remove this tab? This will remove all fields on this page and can not be undone!'
const duplicateTabMessage = "Are you sure you want to duplicate this tab?"

const TabSettingsEditor = observer(({ fieldGroupIndex }) => {
  const [ editingTabName, setEditingTabName ] = useState(false)
  const [ editingSort, setEditingSort ] = useState(false)
  const { name, allowSort } = store.fieldGroups[fieldGroupIndex]
  const inputRef = useRef(null)

  async function handleEnableEditing(e) {
    e.preventDefault()
    await setEditingTabName(true)
    inputRef.current.focus()
  }

  async function handleEnableSort(e) {
    e.preventDefault()
    await setEditingSort(true)
  }

  function handleSubmit(e) {
    e.preventDefault()
    store.persistFormTemplate()
    setEditingTabName(false)
    setEditingSort(false)
  }

  if(!store.buildable) return null

  return (
    <ul className="tab-settings-editor">
      <li className="tab-settings-item">
        <OutsideClicker onClick={() => setEditingTabName(false)}>
          <form style={editingTabName ? style.visible : style.notVisible} onSubmit={handleSubmit}>
            <input
              type="text"
              value={ name }
              ref={inputRef}
              onChange={e => store.fieldGroups[fieldGroupIndex].name = e.target.value}
            />
          </form>
        </OutsideClicker>
        { store.fieldGroups.length > 1 &&
          <a href="#" onClick={handleEnableEditing} style={editingTabName ? style.notVisible : style.visible}>
            Tab Name: { name }
            <FaIcon icon="pencil" />
          </a>
        }
      </li>
      <li className="tab-settings-item">
        <OutsideClicker onClick={() => setEditingSort(false)}>
          <form style={editingSort ? style.visible : style.notVisible} onSubmit={handleSubmit}>
            <select
              value={allowSort}
              onChange={e => store.fieldGroups[fieldGroupIndex].allowSort = (e.target.value == 'true')}
            >
              <option value="true">Allow User to Drag & Drop</option>
              <option value="false">Do Not Allow User to Reorder</option>
            </select>
          </form>
        </OutsideClicker>
        <a href="#" onClick={handleEnableSort} style={editingSort ? style.notVisible : style.visible}>
          Sort: {allowSort ? 'Allow User to Drag & Drop' : 'Do Not Allow User to Reorder'}
          <FaIcon icon="sort" />
        </a>
      </li>
      <Dropdown
        as={NavItem}
        align="end"
        id={`manage-tab-dropdown-${fieldGroupIndex}`}
        className="tab-settings-item"
      >
        <Dropdown.Toggle as={NavLink}>Additional Tab Settings</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => confirm(duplicateTabMessage) && store.duplicateFieldGroup(fieldGroupIndex)}>
            <FaIcon icon="copy" text="Duplicate Tab" />
          </Dropdown.Item>
          <Dropdown.Item onClick={() => confirm(removeConfirmationMessage) && store.removeFieldGroup(fieldGroupIndex)}>
            <FaIcon icon="trash" text="Remove Tab" />
          </Dropdown.Item>
          { (fieldGroupIndex > 0) &&
          <Dropdown.Item onClick={() => store.moveFieldGroupLeft(fieldGroupIndex)}>
            <FaIcon icon="arrow-left" text="Move Tab Left" />
          </Dropdown.Item>
          }
          { (fieldGroupIndex < store.fieldGroups.length - 1) &&
            <Dropdown.Item onClick={() => store.moveFieldGroupRight(fieldGroupIndex)}>
              <FaIcon icon="arrow-right" text="Move Tab Right" />
            </Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
    </ul>
  )
})

const style = {
  visible: {
    display: 'block'
  },
  notVisible: {
    display: 'none'
  }
}

export default TabSettingsEditor
