import React from 'react'
import { observer, inject } from 'mobx-react'
import { inGroupsOf } from '../util'

@inject("store")
@observer
class PackageGroupSelector extends React.Component {
  render() {
    const { store } = this.props
    const { visiblePackageGroups } = store
    const perRow = 3

    return (
      <div className="service-selector">
          { inGroupsOf(visiblePackageGroups, perRow).map((row, index) =>
            <div key={index} className="service-selector-row">
              { row.map(packageGroup =>
                <div
                  key={packageGroup.id}
                  className="service-selector-item"
                  onClick={() => (store.activePackageGroupId = packageGroup.id)}
                >
                  <div className="service-selector-service-image" style={{backgroundImage: `url(${packageGroup.primaryImage.panelVariant})`}} />
                  <div className="service-selector-service-title">{ packageGroup.name } </div>
                </div>
              )}
            </div>
          )}
      </div>
    )
  }
}

export default PackageGroupSelector
