import React from 'react'
import client from '../../../helpers/client'

const SlotCheckbox = ({ value }) => {
  return value ? "✅" : "❌"
}

const SlotRow = ({ eventType, packageSlot, onEdit, onRemove, apiKey }) => {
  const handleEdit = (e) => {
    e.preventDefault()
    onEdit(packageSlot)
  }

  const handleRemove = (e) => {
    e.preventDefault()
    if(confirm("Are you sure?")) {
      client.delete(`/api/v1/event_types/${eventType.id}/package_slots/${packageSlot.id}?api_key=${apiKey}`)
        .then(() => onRemove(packageSlot))
        .catch(e => {
          console.warn(e)
          alert(e.message)
      })
    }
  }

  return (
    <tr className="slot-row">
      <th>
        <a href="#" onClick={handleEdit}>
          { packageSlot.label ? `${packageSlot.label}` : `${ packageSlot.startsAtTime } —  ${ packageSlot.endsAtTime }` }
        </a>
      </th>
      <td>
        <a href="#" onClick={handleEdit}>
          <SlotCheckbox value={packageSlot.sunday} />
        </a>
      </td>
      <td>
        <a href="#" onClick={handleEdit}>
          <SlotCheckbox value={packageSlot.monday} />
        </a>
      </td>
      <td>
        <a href="#" onClick={handleEdit}>
          <SlotCheckbox value={packageSlot.tuesday} />
        </a>
      </td>
      <td>
        <a href="#" onClick={handleEdit}>
          <SlotCheckbox value={packageSlot.wednesday} />
        </a>
      </td>
      <td>
        <a href="#" onClick={handleEdit}>
          <SlotCheckbox value={packageSlot.thursday} />
        </a>
      </td>
      <td>
        <a href="#" onClick={handleEdit}>
          <SlotCheckbox value={packageSlot.friday} />
        </a>
      </td>
      <td>
        <a href="#" onClick={handleEdit}>
          <SlotCheckbox value={packageSlot.saturday} />
        </a>
      </td>
      <td>
        <a href="#" onClick={handleEdit}>
          <i className="fa fa-pencil" />
        </a>
        { " " }
        <a href="#" onClick={handleRemove} className="remove" >
          <i className="fa fa-trash" />
        </a>
      </td>
    </tr>
  )
}

export default SlotRow
