import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Modal, Row, Col, FormGroup, FormControl } from 'react-bootstrap'
import ColorPicker from '../ColorPicker'
import EventTypeSelector from '../EventTypeSelector'
import CopyableTextBlock from '../CopyableTextBlock'
import stringifyProps from './stringifyProps'

export default class ContactWidgetButton extends Component {
  state = {
    contactFormId: this.props.contactForms[0].id,
    showModal: false,
    style: "embed",
    showAdvancedStylingOptions: false,
    labelsAsPlaceholders: false,
    wideSubmitButtons: false,
    maxWidth: "",
    buttonBackgroundColor: "",
    buttonForegroundColor: "",
    labelColor: "",
    fontFamily: "",
    labelFontWeight: "",
    labelFontSize: "",
    borderWidth: "",
    borderColor: "",
    borderRadius: "",
    inputBackgroundColor: "",
    iframe: false
  }

  static propTypes = {
    type: PropTypes.string.isRequired,
    host: PropTypes.string.isRequired,
    contactForms: PropTypes.array.isRequired,
    apiKey: PropTypes.string.isRequired
  }

  showModal = () => {
    this.setState({showModal: true})
  }

  hideModal = () => {
    this.setState({showModal: false})
  }

  showAdvancedStylingOptions = (e) => {
    e.preventDefault()
    this.setState({ showAdvancedStylingOptions: true })
  }

  hideAdvancedStylingOptions = (e) => {
    e.preventDefault()
    this.setState({ showAdvancedStylingOptions: false })
  }

  get html() {
    const { host, apiKey } = this.props
    const { style, contactFormId, showAdvancedStylingOptions,
      labelsAsPlaceholders, wideSubmitButtons, buttonBackgroundColor,
      buttonForegroundColor, maxWidth, labelColor, fontFamily,
      labelFontWeight, labelFontSize, borderWidth, borderColor, borderRadius, inputBackgroundColor, iframe} = this.state

    const baseProps = { apiKey, contactFormId, iframe, host: `https://${host}` }
    let dataProps = {}
    if(showAdvancedStylingOptions) {
      dataProps = { labelsAsPlaceholders, wideSubmitButtons, buttonBackgroundColor, buttonForegroundColor, maxWidth }

      if(buttonBackgroundColor) {
        dataProps.buttonBackgroundColor = buttonBackgroundColor
      }
      if(buttonForegroundColor) {
        dataProps.buttonForegroundColor = buttonForegroundColor
      }
      if(maxWidth) {
        dataProps.maxWidth = maxWidth
      }
      if(labelColor) {
        dataProps.labelColor = labelColor
      }
      if(fontFamily) {
        dataProps.fontFamily = fontFamily
      }
      if(labelFontWeight) {
        dataProps.labelFontWeight = labelFontWeight
      }
      if(labelFontSize) {
        dataProps.labelFontSize = labelFontSize
      }
      if(borderRadius) {
        dataProps.borderRadius = borderRadius
      }
      if(borderWidth) {
        dataProps.borderWidth = borderWidth
      }
      if(borderColor) {
        dataProps.borderColor = borderColor
      }
      if(inputBackgroundColor) {
        dataProps.inputBackgroundColor = inputBackgroundColor
      }
    }

    const combinedProps = {...baseProps, ...dataProps}

    if(style === "basicLink") {
      let url = `https://${host}/contact/${contactFormId}`
      if(Object.keys(dataProps).length > 0) {
        url = `${url}?props=${encodeURIComponent(stringifyProps(dataProps))}`
      }
      return url
    }
    else if(style === "embed" && !iframe) {
      return `<script src="https://${host}/api/checkcherry_widgets" type="text/javascript" charset="utf-8"></script><div class="checkcherry__widget__contact-form" data-props='${stringifyProps(combinedProps)}'></div>`
    }
    else if(style === "embed" && iframe) {
      return `<iframe class="checkcherry-autoresize-frame" src="https://${host}/contact/${contactFormId}?iframe=true&props=${encodeURIComponent(stringifyProps(dataProps))}" style="margin: 0; padding: 0; border: none; max-width: ${maxWidth || "500px"}; width: 100%; height: 400px" scrolling="auto" allowtransparency="true"></iframe><script src="https://${host}/api/checkcherry_widgets/iframe" type="text/javascript" charset="utf-8"></script>`
    }
    else {
      return ""
    }
  }

  render() {
    const { type, contactForms } = this.props
    const { showModal, style, contactFormId, showAdvancedStylingOptions,
      labelsAsPlaceholders, wideSubmitButtons, buttonBackgroundColor,
      buttonForegroundColor, labelColor, maxWidth, fontFamily,
      labelFontSize, labelFontWeight, borderWidth, borderColor, borderRadius, inputBackgroundColor, iframe} = this.state

    return (
      <div>
        <div className="btn-group">
          <Button onClick={this.showModal} variant="primary">
            <i className="fa fa-code" />
            { " " }
            Get Embed Code
          </Button>
        </div>
        <Modal show={showModal} onHide={this.hideModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Lead Form Widget</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={7}>
                <h3>Customize</h3>
                <FormGroup className="mb-2">
                  <Form.Label>Choose Style</Form.Label>
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "basicLink"}
                    onChange={() => this.setState({style: "basicLink"})}
                    label="Link Only"
                    id="contact-form-style-link"
                  />
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "embed"}
                    onChange={() => this.setState({style: "embed"})}
                    label="Embed on my site"
                    id="contact-form-style-embed"
                  />
                </FormGroup>
                <FormGroup className="mb-2">
                  <Form.Label>Choose Contact Form</Form.Label>
                  { contactForms.map(contactForm =>
                    <Form.Check
                      type="radio"
                      key={contactForm.id}
                      name="contactForm"
                      checked={contactForm.id === contactFormId }
                      onChange={() => this.setState({contactFormId: contactForm.id})}
                      label={contactForm.form_name}
                      id={`contact-form-${contactForm.id}`}
                    />
                  )}
                  <div>
                    <a href="/admin/contact_forms">
                      Click here to customize your contact forms
                      { " " }
                      <i className="fa fa-external-link" />
                    </a>
                  </div>
                </FormGroup>
                { style === "embed" &&
                <FormGroup className="mb-2">
                  <Form.Label>Enable Compatibility Mode?</Form.Label>
                  <Form.Check
                    type="radio"
                    name="iframe"
                    checked={iframe === true}
                    onChange={() => this.setState({iframe: true})}
                    label="Yes"
                    id="contact-form-iframe-yes"
                  />
                  <Form.Check
                    type="radio"
                    name="iframe"
                    checked={iframe === false}
                    onChange={() => this.setState({iframe: false})}
                    label="No"
                    id="contact-form-iframe-no"
                  />
                  <Form.Text muted>Compatibility mode embeds widget within an iFrame for greater compatibility with some website builders</Form.Text>
                </FormGroup>
                }
                { !showAdvancedStylingOptions &&
                  <div>
                    <a href="#" onClick={this.showAdvancedStylingOptions}>
                      Show Advanced Options
                    </a>
                  </div>
                }
                { showAdvancedStylingOptions &&
                  <div>
                    <h3>Advanced</h3>
                    <Row>
                      <Col sm={6}>
                        <FormGroup className="mb-2">
                          <Form.Check
                            type="checkbox"
                            checked={wideSubmitButtons}
                            onChange={() => this.setState({ wideSubmitButtons: !wideSubmitButtons })}
                            label="Use wide buttons"
                            id="contact-form-wide-buttons"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup className="mb-2">
                          <Form.Check
                            type="checkbox"
                            checked={labelsAsPlaceholders}
                            onChange={() => this.setState({ labelsAsPlaceholders: !labelsAsPlaceholders })}
                            label="Show labels as placeholders"
                            id="contact-form-labels-as-placeholders"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col sm={6}>
                        <Form.Label>Override Label Color</Form.Label>
                        <ColorPicker
                          value={labelColor}
                          onChange={value => this.setState({ labelColor: value })}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Label>Override Button Background Color</Form.Label>
                        <ColorPicker
                          value={buttonBackgroundColor}
                          onChange={value => this.setState({ buttonBackgroundColor: value })}
                        />
                      </Col>
                      <Col sm={6}>
                        <label>Override Button Text Color</label>
                        <ColorPicker
                          value={buttonForegroundColor}
                          onChange={value => this.setState({ buttonForegroundColor: value })}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col sm={6}>
                        <Form.Label>Override Font Family</Form.Label>
                        <FormControl
                          type="text"
                          placeholder="Example: Helvetica, Arial, sans-serif"
                          value={fontFamily}
                          onChange={e => this.setState({ fontFamily: e.target.value })}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Label>Maximum Form Width</Form.Label>
                        <FormControl
                          type="text"
                          placeholder="Width in pixels or percentage"
                          value={maxWidth}
                          onChange={e => this.setState({ maxWidth: e.target.value })}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Label>Override Label Font Weight</Form.Label>
                        <FormControl
                          type="text"
                          placeholder="Example: bold"
                          value={labelFontWeight}
                          onChange={e => this.setState({ labelFontWeight: e.target.value })}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Label>Override Label Font Size</Form.Label>
                        <FormControl
                          type="text"
                          placeholder="Example: 12px"
                          value={labelFontSize}
                          onChange={e => this.setState({ labelFontSize: e.target.value })}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col sm={6}>
                        <Form.Label>Border Width</Form.Label>
                        <FormControl
                          type="text"
                          placeholder="Example: 1px"
                          value={borderWidth}
                          onChange={e => this.setState({ borderWidth: e.target.value })}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Label>Border Color</Form.Label>
                        <ColorPicker
                          value={borderColor}
                          onChange={value => this.setState({ borderColor: value })}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Label>Border Radius</Form.Label>
                        <FormControl
                          type="text"
                          placeholder="Example: 4px"
                          value={borderRadius}
                          onChange={e => this.setState({ borderRadius: e.target.value })}
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Label>Textbox Background Color</Form.Label>
                        <FormControl
                          type="text"
                          placeholder="Example: white"
                          value={inputBackgroundColor}
                          onChange={e => this.setState({ inputBackgroundColor: e.target.value })}
                        />
                      </Col>
                    </Row>
                  </div>
                }
              </Col>
              <Col sm={5}>
                <h3>HTML</h3>

                <p>Copy and paste the HTML below into your website to add your contact form</p>

                <FormGroup>
                  <CopyableTextBlock value={this.html} />
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const styles = {
  previewStyle: {
    wordWrap: 'break-word'
  },
  embedStyle: {
    height: 300
  },
  hideAdvancedStylingOptions: {
    paddingLeft: 5,
    labelFontWeight: "normal"
  }
}
