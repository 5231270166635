import React from 'react'
import { toJS } from 'mobx'

function getFileNameFromUrl(url) {
  try {
    const parsedUrl = new URL(url, window.location.href)
    const path = parsedUrl.pathname
    return decodeURIComponent(path.substring(path.lastIndexOf('/') + 1))
  }
  catch(e) {
    return null
  }
}

const FilePreview = ({ file }) => {
  if(!file) {
    return null
  }

  const { url, filename } = file
  const displayFilename = filename || getFileNameFromUrl(file.url)

  return (
    <div style={styles.wrapper}>
      { displayFilename &&
        <span style={styles.filename}>
          { file.url ?  <a href={file.url}>{ displayFilename }</a> : <>{ displayFilename }</>
          }
        </span>
      }
      { file.url &&
        <a href={file.url} className="btn btn-primary">
          <i className="fa fa-download" />
          { " " }
          Download File
        </a>
      }
    </div>
  )
}

const styles = {
  wrapper: {
    marginBottom: 10,
    marginTop: 10
  },
  filename: {
    marginRight: 10
  }
}

export default FilePreview
