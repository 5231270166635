import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { inject, observer } from 'mobx-react'
import { format, parse, differenceInYears } from 'date-fns'
import DatePicker from 'react-datepicker'
import { Form, FormControl, FormGroup } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css';

const MAX_YEARS = 5

@inject("store")
@inject("dateFormat")
@observer
export default class DateControl extends React.Component {
  static propTypes = {
    dateFormat: PropTypes.string.isRequired,
  }

  state = {
    startDateError: "",
    endDateError: ""
  }

  handleStartDateChange = (startDate) => {
    const { store } = this.props
    store.startDate = startDate
  }

  handleEndDateChange = (endDate) => {
    const { store } = this.props
    store.endDate = endDate
  }

  validateStartDate(startDate, callback) {
    const { store } = this.props

    if(startDate > store.endDate) {
      this.setState({ startDateError: "must be less than end" })
    }
    else if(differenceInYears(store.endDate, startDate) > MAX_YEARS) {
      this.setState({ startDateError: `max ${MAX_YEARS} years can be selected` })
    }
    else {
      this.setState({ startDateError: "" }, callback)
    }
  }

  validateEndDate(endDate, callback) {
    const { store } = this.props

    if(endDate < store.startDate) {
      this.setState({ endDateError: "must be greater than start" })
    }
    else if(differenceInYears(endDate, store.startDate) > MAX_YEARS) {
      this.setState({ endDateError: `max ${MAX_YEARS} years can be selected` })
    }
    else {
      this.setState({ endDateError: "" }, callback)
    }
  }

  render()  {
    const { store, dateFormat } = this.props
    const { startDateError, endDateError } = this.state
    const { startDate, endDate, datePresets, datePreset } = store

    return (
      <div className="mb-2">
        <FormGroup>
          <div className="group-by-control">
            <Form.Label>Date Range</Form.Label>
            <Form.Select
              name="group-by"
              value={datePreset}
              onChange={e => (store.datePreset = e.target.value)}
              size="sm"
            >
              { datePresets.map((currentDatePreset) => 
                <option key={currentDatePreset.value} value={currentDatePreset.value}>{currentDatePreset.label}</option>
              )}
            </Form.Select>
          </div>
        </FormGroup>
        { datePreset === "custom" &&
        <div className="custom-date-range-selector">
          <Form inline onSubmit={this.handleSubmit}>
            <FormGroup controlId="formInlineName" validationState={startDateError ? "error" : null}>
              <DatePicker
                name="start-date"
                className="input-sm"
                onChange={startDate => this.validateStartDate(startDate, () => store.startDate = startDate)}
                selected={startDate}
                dateFormat={dateFormat}
                isInvalid={!!startDateError}
              />
              { startDateError && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{startDateError}</Form.Control.Feedback> }

            </FormGroup>
            <span> to </span>
            <FormGroup controlId="formInlineName" validationState={endDateError ? "error" : null}>
              <DatePicker
                name="end-date"
                className="input-sm"
                onChange={endDate => this.validateEndDate(endDate, () => store.endDate = endDate )}
                selected={endDate}
                dateFormat={dateFormat}
                isInvalid={!!endDateError}
              />
              { endDateError && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{endDateError}</Form.Control.Feedback> }
            </FormGroup>
          </Form>
          { store.customDatePresetError &&
            <div className="custom-date-range-error">{ store.customDatePresetError }</div>
          }
        </div>
        }
      </div>
    )
  }
}
