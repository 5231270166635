import React, { useState } from 'react'
import { observer, inject } from 'mobx-react'

const MIN_ITEMS_FOR_BULK_EDITING = 3

const BulkEditorToolbar = ({ checklistStore }) => {
  const [updating, setUpdating] = useState(false)

  const anySelected = checklistStore.bulkSelectedItemIds.length > 0
  const allSelected = checklistStore.bulkSelectedItemIds.length === checklistStore.sortedItemIds.length
  const numberSelected = checklistStore.bulkSelectedItemIds.length
  const anyChecked = checklistStore.bulkSelectedItems.some(item => item.attributes.checked)
  const anyUnchecked = checklistStore.bulkSelectedItems.some(item => !item.attributes.checked)

  function handleStartBulkEditing(e) {
    e.preventDefault()
    checklistStore.clearBulkSelectedItems()
    checklistStore.bulkEditing = true
  }

  function handleEndBulkEditing(e) {
    e.preventDefault()
    checklistStore.bulkEditing = false
    checklistStore.clearBulkSelectedItems()
  }

  function handleSelectAll(e) {
    e.preventDefault()
    checklistStore.selectAllBulkSelectedItems()
  }

  async function handleBulkUpdate(e, action) {
    e.preventDefault()
    setUpdating(true)

    try {
      await checklistStore.bulkUpdateSelectedItems(action)
    }
    catch(error) {
      alert('Error updating selected items')
    }

    setUpdating(false)
    checklistStore.bulkEditing = false
    checklistStore.clearBulkSelectedItems()
  }


  if(checklistStore.sortedItemIds.length <= MIN_ITEMS_FOR_BULK_EDITING) {
    return null
  }
  else if(updating) {
    return (
      <div>
        <i className="fa fa-spinner fa-spin"></i>
        { " " }
        Applying Changes...
      </div>
    )
  }
  else if(checklistStore.bulkEditing) {
    return (
      <div>
        { !allSelected && (
          <>
            <a href="#" onClick={handleSelectAll}>Select All</a>
            { " | " }
          </>
        )}
        { (anySelected && anyUnchecked) && (
          <>
            <a href="#" onClick={e => handleBulkUpdate(e, "mark_as_checked")}>Mark { numberSelected } Selected as Checked</a>
            { " | " }
          </>
        )}
        { (anySelected && anyChecked) && (
          <>
            <a href="#" onClick={e => handleBulkUpdate(e, "mark_as_unchecked")}>Mark { numberSelected } Selected as Unchecked</a>
            { " | " }
          </>
        )}
        { anySelected && (
          <>
            <a href="#" onClick={e => handleBulkUpdate(e, "delete")}>Delete { numberSelected } Selected</a>
            { " | " }
          </>
        )}
        <a href="#" onClick={handleEndBulkEditing}>Cancel</a>
      </div>
    )
  }
  else {
    return (
      <div>
        <a href="#" onClick={handleStartBulkEditing}>Bulk Update</a>
      </div>
    )
  }
}

export default inject("checklistStore")(observer(BulkEditorToolbar))
