import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("store")
@observer
export default class EventStatusSelector extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  render() {
    const { store } = this.props
    const {
      hideStatusSelector,
      enableConfirmed, showConfirmed,
      enableProposals, showProposals,
      enableCancelled, showCanceled
    } = store

    if(hideStatusSelector) {
      return null
    }

    if(!enableConfirmed && !enableProposals && !enableCancelled) {
      return null
    }

    return (
      <div className="rbc-btn-group">
        { enableConfirmed &&
          <button
            type="button"
            className={showConfirmed ? 'rbc-active' : null}
            onClick={() => (store.showConfirmed = !showConfirmed) }
          >
            Confirmed
            { showConfirmed && <div className="label-box confirmed" /> }
          </button>
        }
        { enableProposals &&
          <button
            type="button"
            className={showProposals ? 'rbc-active' : null}
            onClick={() => (store.showProposals = !showProposals) }
          >
            Proposal
            { showProposals && <div className="label-box proposal" /> }
          </button>
        }
        { enableCancelled &&
          <button
            type="button"
            className={showCanceled ? 'rbc-active' : null}
            onClick={() => (store.showCanceled = !showCanceled) }
          >
            Canceled/Postponed/Expired
            { showCanceled && <div className="label-box canceled" /> }
          </button>
        }
      </div>
    )
  }
}

