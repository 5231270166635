import cookies from 'js-cookie'

const paramsToRetain = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "gclid", "ref", "fbclid", "_gl"]

function fetchUrlParam(param) {
  if(window.location.search) {
    const params = new URLSearchParams(window.location.search)
    return params.get(param)
  }
  return null
}

function fetchCookieParam(param) {
  return cookies.get(param)
}

function fetchParam(param) {
  return fetchUrlParam(param) || fetchCookieParam(param)
}

function retainTrackingParams(inputUrl) {
  // Fetch values for any tracking params in our window's query string or
  // in our cookies, and append them to the input URL.
  const queryString = window.location.search
  const windowUrlParams = new URLSearchParams(queryString);
  const trackingParams = {}
  paramsToRetain.forEach(param => {
    const value = fetchParam(param)
    if(value) {
      if(inputUrl.includes("?")) {
        inputUrl += `&${param}=${value}`
      }
      else {
        inputUrl += `&${param}=${value}`
      }
    }
  })

  return inputUrl
}

export default retainTrackingParams
