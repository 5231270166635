import React, { useState } from 'react'
import SearchBar from './SearchBar'
import SongList from './SongList'
import client from '../../../helpers/client'

async function searchSpotifySongs(text) {
  const url = `/api/v1/spotify/songs?text=${text}`
  const response = await client.get(url)
  const songs = deserializeSpotifySongs(response.data.data)
  return songs
}
function deserializeSpotifySongs(songs) {
  return songs.map(song => {
    return {
      id: song.id,
      attributes: {
        ...song.attributes,
        provider: "spotify"
      }
    }
  })
}

const SpotifySearch = ({ searchText, setSearchText, onSelect }) => {
  const [ songs, setSongs ] = useState([])
  const [ loading, setLoading ] = useState(false)

  async function handleSearch(e) {
    e.preventDefault()
    setLoading(true)
    try {
      const songs = await searchSpotifySongs(searchText)
      setSongs(songs)
    }
    catch(e) {
      console.log(e)
      alert("There was an error searching for songs. Please try again.")
    }
    setLoading(false)
  }

  return (
    <>
      <SearchBar
        loading={loading}
        onSearch={handleSearch}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <SongList
        songs={songs}
        onSelect={onSelect}
      />
    </>
  )
}

export default SpotifySearch
