import React from 'react'
import { toJS } from 'mobx'
import PropTypes from 'prop-types'
import Currency from '../../../../helpers/currency.js'

export default class TooltipDisplay extends React.Component {
  static propTypes = {
    payload: PropTypes.array,
    label: PropTypes.string,
    currency: PropTypes.object.isRequired
  }

  render() {
    const { payload, label, currency } = this.props

    if(payload && payload.length > 0) {
      const currentName = payload[0].payload.currentName
      const currentCount = payload[0].payload.currentCount
      const currentTotalCents = payload[0].payload.currentTotalCents

      const previousName = payload[0].payload.previousName
      const previousCount = payload[0].payload.previousCount
      const previousTotalCents = payload[0].payload.previousTotalCents

      const differenceCount = currentCount - previousCount
      const differenceCountPercent = (differenceCount / previousCount) * 100
      const differenceTotalCents = currentTotalCents - previousTotalCents
      const differenceTotalCentsPercent = (differenceTotalCents / previousTotalCents) * 100

      return (
        <div className="tooltip-display">
          <div className="tooltip-display-total">
            <div className="tooltip-display-label">Current Period: { currentName }</div>
            <div>Revenue Collected: { Currency.formatted(currentTotalCents, currency) }</div>
            <div>{ currentCount } { currentCount === 1 ? "Payment" : "Payments" }</div>
          </div>
          <div className="tooltip-display-total">
            <div className="tooltip-display-label">Previous Period: { previousName }</div>
            <div>Revenue Collected: { Currency.formatted(previousTotalCents, currency) }</div>
            <div>{ previousCount } { previousCount === 1 ? "Payment" : "Payments" }</div>
          </div>
          <div className="tooltip-display-total">
            <div className="tooltip-display-label">Change</div>
            <div className={differenceTotalCents >= 0 ? "text-success" : "text-danger" }>
              Revenue Collected: { Currency.formatted(differenceTotalCents, currency) } ({ differenceTotalCentsPercent.toFixed(2) }%)
            </div>
            <div className={differenceCount >= 0 ? "text-success" : "text-danger" }>
              { differenceCount } { differenceCount === 1 ? "Payment" : "Payments" } ({ differenceCountPercent.toFixed(2) }%)
            </div>
          </div>
        </div>
      )
    }
    else {
      return null
    }
  }
}
