import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("store")
@inject("showPackageSelector")
@observer
export default class PackageSelector extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    showPackageSelector: PropTypes.bool.isRequired
  }

  handleChange = (e) => {
    const { store } = this.props
    store.setPackageId(e.target.value)
  }

  render() {
    const { store } = this.props
    const { selectedPackage } = store
    const { name, descriptionHtml, primaryImage } = selectedPackage.attributes

    return (
      <div className="pricing-preview">
        <div className="pricing-preview-name">{ name }</div>
        <div>
          { (primaryImage && primaryImage.largeVariant) && 
            <img src={primaryImage.largeVariant} className="pricing-preview-image" alt={name} />
          }
        </div>
        <div className="pricing-preview-html ql-content-wrapper">
          <div dangerouslySetInnerHTML={{__html: descriptionHtml}} />
        </div>
      </div>
    )
  }
}
