import React, { useCallback, useRef } from 'react'
import { observer } from 'mobx-react'
import EventTypeSelector from '../../EventTypeSelector'
import store from '../stores/store.js'
import { toJS } from 'mobx'

const defaultSettings = {
  allCategories: true
}

function mergedEventTypeSelectorSettings(baseSettings, scopedSettings) {
  const safeScopedSettings = scopedSettings || defaultSettings

  const mergedEventCategories = baseSettings.eventCategories.map(eventCategory => {
    const selected = safeScopedSettings.allCategories ||
      safeScopedSettings.eventCategoryIds?.includes(eventCategory.id)

    return {
      ...eventCategory,
      selected,
      packageGroups: eventCategory.packageGroups.map(packageGroup => {
        const selected = safeScopedSettings.allCategories ||
          safeScopedSettings.eventCategoryIds?.includes(eventCategory.id) ||
          safeScopedSettings.packageGroupIds?.includes(packageGroup.id)


        return {
          ...packageGroup,
          selected,
          eventTypes: packageGroup.eventTypes.map(eventType => {
            const selected = safeScopedSettings.allCategories ||
              safeScopedSettings.eventCategoryIds?.includes(eventCategory.id) ||
              safeScopedSettings.packageGroupIds?.includes(packageGroup.id) ||
              safeScopedSettings.eventTypeIds?.includes(eventType.id)

            return {
              ...eventType,
              selected,
            }
          })
        }
      })
    }
  })

  const mergedAddOnCategories = baseSettings.addOnCategories.map(addOnCategory => {
    const selected = safeScopedSettings.allAddOnCategories ||
      safeScopedSettings.addOnCategoryIds?.includes(addOnCategory.id)

    return {
      ...addOnCategory,
      selected,
      addOns: addOnCategory.addOns.map(addOn => {
        const selected = safeScopedSettings.allAddOnCategories ||
          safeScopedSettings.addOnCategoryIds?.includes(addOnCategory.id) ||
          safeScopedSettings.addOnIds?.includes(addOn.id)

        return {
          ...addOn,
          selected,
        }
      })
    }
  })

  return {
    ...baseSettings,
    ...safeScopedSettings,
    eventCategories: mergedEventCategories,
    addOnCategories: mergedAddOnCategories
  }
}

const ConditionalFieldEventTypeSelector = observer(({ value, onChange }) => {
  const valueRef = useRef(JSON.stringify(value))

  const handleChange = useCallback(value => {
    const serializedValue = JSON.stringify(value)
    if(serializedValue === valueRef.current) return
    valueRef.current = serializedValue
    onChange(value)
  }, [ onChange ])

  const merged = mergedEventTypeSelectorSettings(store.eventTypeSelectorSettings, value)

  return (
    <EventTypeSelector
      {...merged}
      supportsPackages={true}
      supportsAddOns={true}
      supportsBackdrops={false}
      supportsMiniSessions={false}
      onChange={handleChange}
    />
  )
})

export default ConditionalFieldEventTypeSelector
