import React from 'react'
import FieldCopier from './FieldCopier'
import FieldEditor from './FieldEditor'
import FieldDeletor from './FieldDeletor'
import fieldTypes from '../data/fieldTypes.json'

const TopControls = ({ buildable, field, fieldIndex, fieldGroupIndex }) => {
  if(!buildable) return null

  return (
    <div className="top-controls">
      <span className="control">
        <FieldEditor fieldTypes={fieldTypes} field={field} fieldIndex={fieldIndex} fieldGroupIndex={fieldGroupIndex} />
      </span>
      <span className="control">
        <FieldCopier fieldTypes={fieldTypes} field={field} fieldIndex={fieldIndex} fieldGroupIndex={fieldGroupIndex} />
      </span>
      <span className="control">
        <FieldDeletor fieldTypes={fieldTypes} field={field} fieldIndex={fieldIndex} fieldGroupIndex={fieldGroupIndex} />
      </span>
    </div>
  )
}

export default TopControls
