import React from 'react'
import SongPreview from '../SongPreview'
import CustomSong from './CustomSong'

const CurrentSongDisplay = ({ currentSong, onSelect }) => {
  if(!currentSong) {
    return null
  }
  else if(currentSong.provider === 'custom') {
    return (
      <CustomSong
        currentSong={currentSong}
        onSelect={onSelect}
      />
    )
  }
  else {
    return (
      <SongPreview
        key={currentSong.id}
        id={currentSong.id}
        name={currentSong.name}
        artistName={currentSong.artistName}
        albumName={currentSong.albumName}
        albumImageUrl={currentSong.albumImageUrl}
        previewUrl={currentSong.previewUrl}
        provider={currentSong.provider}
        songNotes={currentSong.songNotes}
        onAdd={onSelect}
        showConfirmAdd
      />
    )
  }
}

export default CurrentSongDisplay
