import React from 'react'
import { Col } from 'react-bootstrap'

class FormSidebar extends React.Component {
  render() {
    const { showSidebar, children } = this.props

    if(showSidebar) {
      return (
        <Col sm={4}>
          { children }
        </Col>
      )
    }
    else {
      return children
    }
  }
}

export default FormSidebar
