import React, { useRef, useContext } from 'react'
import useSize from '@react-hook/size'
import ResponsiveContext from './ResponsiveContext'

const fallbackBreakpoint = 500

const ResponsiveRow = ({ children, breakpoint }) => {
  const target = useRef(null)
  const [width, height] = useSize(target)
  const isMobile = width < (breakpoint || fallbackBreakpoint)

  return (
    <ResponsiveContext.Provider value={{ isMobile }}>
      <div className="row" ref={target}>
        {children}
      </div>
    </ResponsiveContext.Provider>
  )
}

export default ResponsiveRow
