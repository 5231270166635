function groupPaymentMethodsByFee(paymentMethodOptions, totalFormatted, authorizeOnly) {
  const items = []

  paymentMethodOptions.forEach(paymentMethodOption => {
    const { feeAmountFormatted, totalWithFeesFormatted, feeDescription } = paymentMethodOption
    const item = items.find(item => item.feeAmountFormatted === feeAmountFormatted)

    if(item) {
      item.paymentMethodOptions.push(paymentMethodOption)
    }
    else {
      items.push({
        feeDescription,
        feeAmountFormatted,
        totalWithFeesFormatted,
        totalFormatted,
        paymentMethodOptions: [paymentMethodOption]
      })
    }
  })

  return items
}

export default groupPaymentMethodsByFee
