import React from 'react'
import { FormControl } from 'react-bootstrap'
import { TempusDominus, Namespace } from '@eonasdan/tempus-dominus';
import { useRef, useEffect } from 'react'
import dateToTime from './dateToTime'

const icons = {
  type: 'icons',
  time: 'fa fa-clock-o',
  date: 'fa fa-calendar',
  up: 'fa fa-chevron-up',
  down: 'fa fa-chevron-down',
  previous: 'fa fa-chevron-left',
  next: 'fa fa-chevron-right',
  today: 'fa fa-calendar-check-o',
  clear: 'fa fa-trash',
  close: 'fa fa-times'
}

const TimeControl = ({ timepickerFormat, step, value, onChange }) => {
  const ref = useRef(null)

  useEffect(() => {
    const picker = new TempusDominus(ref.current, {
      display: {
        viewMode: 'clock',
        icons,
        components: {
          decades: false,
          year: false,
          month: false,
          date: false,
          hours: true,
          minutes: true,
          seconds: false
        },
      },
      localization: {
        format: timepickerFormat
      },
      stepping: step || 1
    })

    const subscription = picker.subscribe(Namespace.events.change, (e) => {
      const newTimestamp = dateToTime(e.date)
      onChange(newTimestamp)
    });

    return () => {
      subscription.unsubscribe()
    }
  }, [ref.current])

  return (
    <FormControl
      ref={ref}
      type="text"
      value={value || ""}
      onChange={e => onChange(e.target.value)}
    />
  )
}

export default TimeControl
