import React from 'react';
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/lib/Async'
// https://react-select.com/async#loading-asynchronously
import client from '../../helpers/client'
import CustomOption from './CustomOption'
import './index.scss'

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    fontSize: 18,
    paddingLeft: 10
  })
}

const deserializeVenue = (venue) => {
  if(venue && venue.id && venue.attributes) {
    return {
      value: venue.id,
      label: venue.attributes.name,
      attributes: venue.attributes
    }
  }
  else {
    return null
  }
}

const deserializeVenues = (serializedVenues) => {
  return serializedVenues.map(venue => deserializeVenue(venue)).filter(venue => venue)
}



class VenueDropdown extends React.Component {
  static propTypes = {
    venues: PropTypes.array,
    inputId: PropTypes.string,
    selected: PropTypes.object,
    apiKey: PropTypes.string.isRequired,
    connectionId: PropTypes.string
  }

  static defaultProps = {
    venues: []
  }

  state = {
    selectedOption: deserializeVenue(this.props.selected),
    currentValue: ""
  }

  componentDidMount() {
    const { inputId } = this.props

    if(inputId) {
      this.input = document.getElementById(inputId)
      this.syncHtmlInput()
    }
  }

  handleInputChange = (currentValue) => {
    this.setState({ currentValue })
  }

  handleChange = (selectedOption) => {
    console.log({ selectedOption })
    this.setState({ selectedOption }, () => this.syncHtmlInput())

  }

  syncHtmlInput() {
    const { selectedOption } = this.state

    if(this.input) {
      if(selectedOption) {
        this.input.value = selectedOption.value
      }
      else {
        this.input.value = ""
      }
    }
  }

  handleLoadOptions = (inputValue, callback) => {
    const { apiKey, connectionId } = this.props
    const { currentValue } = this.state

    client.get(`/api/v1/venues/autocomplete.json?api_key=${apiKey}&text=${currentValue}`)
      .then(response => {
        const options = deserializeVenues(response.data.data)
        callback(options)
      })
      .catch(error => {
        const { data, status } = error.response
        const { errors } = data
        const errorMessage = status === 422 ? errors.join(", ") : "Error contacting server"
        alert(errorMessage)
      })
  }



  render() {
    const { venues } = this.props
    const { selectedOption, currentValue } = this.state;

    return (
      <AsyncSelect
        cacheOptions
        name={"venue_id"}
        loadOptions={this.handleLoadOptions}
        components={{ Option: CustomOption }}
        onInputChange={this.handleInputChange}
        onChange={this.handleChange}
        styles={customStyles}
        placeholder="Start typing..."
        noOptionsMessage={() => currentValue ? "Unable to find any matching places. Visit Manage -> Business Settings -> Places to add more saved places" : "Start typing to search your saved places"}
        defaultOptions={deserializeVenues(venues)}
        value={selectedOption}
        isClearable={true}
      />
    );
  }
}

export default VenueDropdown
