import React, { useState, useEffect } from 'react'
import Backdrop from './Backdrop'
import Pager from './Pager'
import { backdropsForCategory, paginatedResults, sortByPosition } from '../util'

const Category = ({ category, backdrops, search, chooseUrl, perPage, csrfToken }) => {
  const [ currentStartPage, setCurrentStartPage ] = useState(1)
  const [ currentEndPage, setCurrentEndPage ] = useState(1)

  useEffect(() => {
    if(currentStartPage > 1) {
      setCurrentStartPage(1)
      setCurrentEndPage(1)
    }
  }, [search])

  function handlePageChange(page) {
    setCurrentStartPage(page)
    setCurrentEndPage(page)
  }

  function handleLoadMore() {
    setCurrentEndPage(currentEndPage + 1)
  }

  const sortedResults = sortByPosition(backdrops)
  const totalPages = Math.ceil(sortedResults.length / perPage)
  const activeResults = paginatedResults(sortedResults, currentStartPage, currentEndPage, perPage)

  // Hide empty categories
  if(!backdrops.length) {
    return null
  }

  return (
    <div className="categorized-group">
      <div className="categorized-group-header">
        <div className="title">{ category.name || "Uncategorized" }
          { category.subHeader && 
            <div className="categorized-group-sub-header"> { category.subHeader} </div>
          }
        </div>
      </div>
      <div className="categorized-group-body">
        <div className="categorized-item-container">
          { activeResults.map(backdrop => 
          <Backdrop
            key={backdrop.id}
            backdrop={backdrop}
            chooseUrl={chooseUrl}
            csrfToken={csrfToken}
            />
          )}
        </div>
        <Pager currentPage={currentEndPage} totalPages={totalPages} onChangePage={handlePageChange} onLoadMore={handleLoadMore} />
        { !activeResults.length && <p className="text-center">No backdrops matched your search</p> }
      </div>
    </div>
  )
}

export default Category
