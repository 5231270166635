import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import Currency from '../../../../helpers/currency.js'
import { ResponsiveRow, ResponsiveColumn } from '../../../ui/CustomResponsiveLayout'

@inject("currency")
@inject("store")
@observer
export default class Statistics extends React.Component {
  static propTypes = {
    currency: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
  }

  get totalAmountPaid() {
    const { store } = this.props
    const { data } = store

    return data
      .map(period => period.amountPaidCents)
      .filter(amountPaidCents => amountPaidCents)
      .reduce((acc, value) => acc + value, 0)
  }


  get paymentCount() {
    const { store } = this.props
    const { data } = store

    return data
      .map(period => period.paymentCount)
      .filter(paymentCount => paymentCount)
      .reduce((acc, value) => acc + value, 0)
  }

  render() {
    const { currency, store } = this.props
    const { data } = store
    
    const { totalAmountPaid, paymentCount } = this

    if(!data || !totalAmountPaid) {
      return null
    }

    return (
      <div className="statistics">
        <ResponsiveRow breakpoint={500}>
          <ResponsiveColumn className="text-center">
            Amount Paid: <span className="value">{ Currency.formatted(totalAmountPaid, currency)}</span>
          </ResponsiveColumn>
          <ResponsiveColumn className="text-center">
            Payment Count: <span className="value">{ paymentCount }</span>
          </ResponsiveColumn>
        </ResponsiveRow>
      </div>
    )
  }
}
