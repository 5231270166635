import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("store")
@observer
export default class LeadSessionSelector extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  render() {
    const { store } = this.props
    const { hideStatusSelector, showLeads, enableLeads, showMiniSessions, enableMiniSessions } = store

    if(hideStatusSelector) {
      return null
    }

    return (
    <div className="rbc-btn-group">
      { enableLeads &&
        <button
          type="button"
          className={showLeads ? 'rbc-active' : null}
          onClick={() => (store.toggleShowLeads()) }
        >
          Leads
          { showLeads && <div className="label-box leads" /> }
        </button>
      }
      { enableMiniSessions &&
        <button
          type="button"
          className={showMiniSessions ? 'rbc-active' : null}
          onClick={() => (store.toggleShowMiniSessions()) }
        >
          Mini Sessions
          { showMiniSessions && <div className="label-box session" /> }
        </button>
      }
    </div>
    )
  }
}
