import React from 'react'
import PropTypes from 'prop-types'

export default class FranchiseBlockoutDateRange extends React.Component {
  static propTypes = {
    event: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired
  }

  render() {
    const { event } = this.props
    const { title } = event
    const { id } = event.attributes

    return (
      <a
        href={`/admin/availability/franchise_blockout_date_ranges/${id}/edit`}
        className="calendar-event">
        { title }
      </a>
    )
  }
}

