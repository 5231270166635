import React from 'react'
import conditionalMatchers from '../data/conditionalMatchers.json'
import store from '../stores/store.js'
import { toJS } from 'mobx'

function conditionalMessagesFor({ field }) {
  try {
    const messages = field.conditions.map(condition => {
      const { conditionalFieldUuid, conditionalMatcher, conditionalText } = condition
      const matcher = conditionalMatchers.find(currentMatcher => currentMatcher.value === conditionalMatcher)

      if(!conditionalFieldUuid) {
        throw new Error("This conditional field is not valid")
      }

      if(!matcher) {
        throw new Error("This conditional field is not valid")
      }

      if(conditionalFieldUuid === "_package_matcher") {
        return `Conditional Field: Only displays if the booking contains certain packages`
      }
      else {
        const relatedField = store.fieldGroupFor(conditionalFieldUuid)
        if(!relatedField) {
          throw new Error("This conditional field is not valid")
        }
        if(matcher.usesValue) {
          return `Conditional Field: Only displays if "${relatedField.name}" ${matcher.inSentence} "${conditionalText}"`
        }
        else {
          return `Conditional Field: Only displays if "${relatedField.name}" ${matcher.inSentence}`
        }
      }
    })

    return messages.join(" AND ")
  } catch(e) {
    return e.message
  }
}

const ConditionalWrapperBuilderPreview = ({ field, children }) => {
  const message = conditionalMessagesFor({ field })

  if(!message) return children

  return (
    <div className="conditional-field-preview">
      { children }
      <div className="conditional-field-preview__label"> { message } </div>
    </div>
  )
}

export default ConditionalWrapperBuilderPreview
