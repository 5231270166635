import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("checklistStore")
@observer
export default class ErrorHandler extends React.Component {
  handleRefresh = (e) => {
    e.preventDefault()
    window.location.reload()
  }

  render() {
    const { checklistStore, children } = this.props
    const { errorMessage } = checklistStore

    if(errorMessage) {
      return (
        <div className="error">
          <i className="fa fa-exclamation-triangle fa-2x" />
          <div>
            <div>An error occurred in this component</div>
            <div>{ errorMessage }</div>
            <div>
              <a href="#" onClick={this.handleRefresh}>
                Refresh Page
              </a>
            </div>
          </div>
        </div>
      )
    }
    else {
      return children
    }
  }
}

