import React from 'react'
import { parseDate, formatCompact, pastDue, dueSoon } from '../util'

const DueDateLabel = ({ dueOn }) => {
  if(dueOn) {
    const parsedDate = parseDate(dueOn)

    let label_type = ""
    if(pastDue(parsedDate)) {
      label_type = "text-bg-danger"
    }
    else if(dueSoon(parsedDate)) {
      label_type = "text-bg-warning"
    }
    else {
      label_type = "text-bg-success"
    }

    return (
      <span className={`due-date-label badge ${label_type}`}>
        Due: { formatCompact(parsedDate) }
      </span>
    )
  }
  else {
    return null
  }
}

export default DueDateLabel
