import React from 'react'
import PropTypes from 'prop-types'
import MonthWeekDaySelector from './MonthWeekDaySelector'
import DateDropdowns from './DateDropdowns'
import BrandSelector from './BrandSelector'
import EventStatusSelector from './EventStatusSelector'
import LeadSessionSelector from './LeadSessionSelector'
import SpecialDatesSelector from './SpecialDatesSelector'
import TodayButton from './TodayButton'

const Toolbar = () => (
  <div className="rbc-toolbar top-toolbar">
    <div className="toolbar-column">
      <div className="toolbar-cell">
        <BrandSelector />
      </div>
      <div className="toolbar-cell">
        <DateDropdowns />
      </div>
      <div className="toolbar-cell">
        <TodayButton />
        <MonthWeekDaySelector />
      </div>
      <div className="toolbar-cell">
      </div>
    </div>

    <div className="toolbar-column">
      <div className="toolbar-cell">
        <EventStatusSelector />
      </div>
      <div className="toolbar-cell">
        <LeadSessionSelector />
        <SpecialDatesSelector />
      </div>
    </div>
  </div>
)

export default Toolbar
