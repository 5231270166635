import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { parseTimeJSON } from '../util'

@inject("store")
@observer
export default class TimeSlot extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    slot: PropTypes.object.isRequired
  }

  handleClick = () => {
    const { store, slot } = this.props
    const startsAt = slot.attributes.localizedTime
    store.setTime(startsAt)
    store.setLengthInMinutes(slot.attributes.lengthInMinutes)
  }

  render() {
    const { slot, store } = this.props
    const { formattedSlotName } = slot.attributes

    return (
      <a className="time-slot" key={slot.id} onClick={this.handleClick}>
        { formattedSlotName }
      </a>
    )
  }
}
