import { parse, format } from 'date-fns'
import client from '../../../helpers/client'

const minutesToString = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60)
  const hourString = (hours === 1 ? "1 hour" : `${hours} hours`)

  const minutes = totalMinutes % 60
  let minuteString = ""
  if(minutes === 1) {
    minuteString = "1 minute"
  }
  else if(minutes > 1) {
    minuteString = `${minutes} minutes`
  }

  return `${hourString} ${minuteString}`
}

const getFormValue = (target) => {
  const input = document.querySelector(target)
  if(input) {
    return input.value
  }
  else {
    return null
  }
}

const setFormValue = (target, value) => {
  const input = document.querySelector(target)
  if(input) {
    input.value = value
  }
}

const parseLength = (lengthInMinutes) => {
  if(lengthInMinutes && lengthInMinutes > 0) {
    return parseInt(lengthInMinutes)
  }
  else {
    return ''
  }
}

const parseDate = (dateString, dateFormat) => {
  if(dateString) {
    return parse(dateString, dateFormat, new Date())
  }
  else {
    return null
  }
}

const parseTime = (timeString, date) => {
  if(!date || !timeString) {
    return null
  }
  return parse(timeString, 'p', date)
}

const parseTimeJSON = (date) => {
  if(!date) {
    return null
  }
  return new Date(date.replace(/[+-][0-9]{2}:[0-9]{2}$/, ''))
}

const formatDate = (date, dateFormat) => {
  if(date) {
    return format(date, dateFormat)
  }
  else {
    return null
  }
}

const formatTime = (time) => {
  if(time) {
    return format(time, 'p')
  }
  else {
    return null
  }
}

async function persistFormValue(target, value) {
  const input = document.querySelector(target)
  const form = input.closest('form')
  const formData = new FormData()
  formData.append(input.name, input.value)
  await client.post(form.action, formData, { withCredentials: true, headers: { 'X-Requested-With': 'XMLHttpRequest'} })
}

export { minutesToString, getFormValue, setFormValue, parseLength, parseDate, parseTime, parseTimeJSON, formatDate, formatTime, persistFormValue }
