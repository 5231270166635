import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'react-bootstrap'
import {observer} from "mobx-react"
import store from '../stores/store.js'

@observer
export default class Checkbox extends React.Component {
  render() {
    const { field } = this.props
    const { name, uuid, hint, required, label } = field
    const instance = this.props.instance || 0
    const value = store.valueFor(uuid, "checkbox", instance)
    const errorMessage = store.errorFor(uuid, "checkbox", instance)

    return (
      <FormGroup>
        <Form.Label>{ required ? "*" : "" } {name}</Form.Label>
        <Form.Check
          type="checkbox"
          checked={value}
          onChange={e => store.setValueFor(uuid, "checkbox", instance, e.target.checked)}
          disabled={!store.editable}
          isInvalid={!!errorMessage}
          label={label}
          id={`checkbox-${uuid}-${instance}`}
        />
        { errorMessage && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{errorMessage}</Form.Control.Feedback> }
        { hint && <Form.Text muted>{hint}</Form.Text > }
      </FormGroup>
    )
  }
}
