import client from '../../../helpers/client'

async function searchAppleMusicPlaylists(playlistUrl) {
  const url = `/api/v1/apple_music/songs/playlist?playlist_url=${encodeURIComponent(playlistUrl)}`
  const response = await client.get(url)
  const songs = deserializeAppleMusicSongs(response.data.data)
  const { playlistName, playlistDescription, playlistArtworkUrl } = response.data.meta
  return {
    playlistName,
    playlistUrl,
    playlistDescription,
    playlistArtworkUrl,
    songs,
    playlistProvider: "apple"
  }
}

function deserializeAppleMusicSongs(songs) {
  return songs.map(song => {
    return {
      id: song.id,
      attributes: {
        ...song.attributes,
        provider: "apple"
      }
    }
  })
}

export default searchAppleMusicPlaylists
