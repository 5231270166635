import React, { useState, useEffect } from 'react'
import Backdrop from './Backdrop'
import NewBackdrop from './NewBackdrop'
import Pager from './Pager'
import { backdropsForCategory, paginatedResults, sortByPosition, resultsForSearch } from '../util'
import { CATEGORIES_ACTIVE } from '../options'

const Category = ({ category, backdrops, allCategories, showCategories, count, search, perPage, csrfToken }) => {
  const [ currentPage, setCurrentPage ] = useState(1)

  useEffect(() => {
    if(currentPage > 1) {
      setCurrentPage(1)
    }
  }, [search])

  function handlePageClick(page) {
    setCurrentPage(page)
  }

  // If search results don't match anything, hide the category
  if(search && !backdrops.length) {
    return null
  }

  // Optionally hide categories with no backdrops
  if(showCategories === CATEGORIES_ACTIVE && !backdrops.length && !category.recentlyCreated) {
    return null
  }

  const sortedResults = sortByPosition(backdrops, search)
  const totalPages = Math.ceil(sortedResults.length / perPage)
  const activeResults = paginatedResults(sortedResults, currentPage, perPage)

  return (
    <div className="categorized-group">
      <div className="categorized-group-header">
        <div className="title">
          <a href={`/admin/backdrop_categories/${category.id}/edit`} style={{color: "#fff", textDecoration: "none"}}>
            { category.name || "Uncategorized" }
          </a>
          { category.subHeader && 
            <div className="categorized-group-sub-header"> { category.subHeader} </div>
          }
        </div>
        <div className="control-wrapper">
          { category.position > 1 &&
            <a href={`/admin/backdrop_categories/${category.id}/move_to?position=${category.position - 1}`} className="control" title="Move Up" data-method="put">
              <i className="fa fa-arrow-up" />
            </a>
          }
          { category.position < count &&
            <a href={`/admin/backdrop_categories/${category.id}/move_to?position=${category.position + 1}`} className="control" title="Move Down" data-method="put">
              <i className="fa fa-arrow-down" />
            </a>
          }
          <a href={`/admin/backdrop_categories/${category.id}/edit`} className="control" title="Settings">
            <i className="fa fa-cog" />
          </a>
          { !category.syncWithPbBackdrops &&
            <a href={`/admin/backdrop_categories/${category.id}/duplicate`} className="control" title="Settings" data-method="put" data-confirm="Do you want to duplicate this category?" id={`photobooth-background-category-clone-${category.id}`}>
              <i className="fa fa-clone" />
            </a>
          }
        </div>
      </div>
      <div className="categorized-group-body">
        <div className="categorized-item-container">
          { activeResults.map(backdrop => 
          <Backdrop
            key={backdrop.id}
            backdrop={backdrop}
            count={activeResults.length}
            category={category}
            allCategories={allCategories}
            csrfToken={csrfToken}
            />
          )}
          <NewBackdrop category={category} />
        </div>
        <Pager currentPage={currentPage} totalPages={totalPages} onChangePage={handlePageClick} />
      </div>
    </div>
  )
}

export default Category
