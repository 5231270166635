import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import SongPreview from '../ui/SongPreview'
import AddSongButton from './AddSongButton'
import FaIcon from '../../helpers/fa_icon'
import client from '../../helpers/client'
import './style.scss'

const SuggestionListEditor = ({ apiKey, suggestionListId, title: initialTitle, data }) => {
  const [ loading, setLoading ] = useState(false)
  const [ notification, setNotification ] = useState("")
  const [ showTitleEditor, setShowTitleEditor ] = useState(false)
  const [ title, setTitle ] = useState(initialTitle || '')
  const [ list, setList ] = useState(data || [])

  function handleAddSong (song, position = "bottom") {
    const newList = list.filter(s => s.id != song.id) // don't add duplicates
    if(position == "top") {
      newList.unshift(song)
    }
    else {
      newList.push(song)
    }
    setList(newList)
  }

  function handleUpdateSong(song) {
    const newList = list.map(s => {
      if(s.id == song.id) {
        return song
      }
      else {
        return s
      }
    })
    setList(newList)
  }


  function removeSong (index) {
    const newList = [...list]
    newList.splice(index, 1)
    setList(newList)
  }

  async function persistList (e) {
    e.preventDefault()

    setLoading(true)
    setShowTitleEditor(false)
    try {
      const url = `/admin/suggestion_lists/${suggestionListId}.json`
      const response = await client.put(url, { title, data: JSON.stringify(list) })
      window.scrollTo(0, 0)
      setNotification("Your changes have been saved")
    }
    catch(e) {
      console.log(e)
      alert("There was an error saving your changes")
    }
    setLoading(false)
  }

  const handleDragEnd = ({ draggableId, source, destination }) => {
    if (!destination) return
    const newList = [...list]
    const [removed] = newList.splice(source.index, 1)
    newList.splice(destination.index, 0, removed)
    setList(newList)
  }

  function handleShowTitleEditor (e) {
    e.preventDefault()
    setShowTitleEditor(true)
  }

  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(() => setNotification(""), 3000)
      return () => clearTimeout(timeout)
    }
  }, [notification])

  return (
    <div className="suggestion-list-editor">
      { notification && <div className="text-center alert fade show alert-success">{ notification }</div> }
      <h1>
        { showTitleEditor ?
          <form onSubmit={persistList}>
            <input type="text" value={title} onChange={e => setTitle(e.target.value)} />
          </form>
        :
          <>
            { title }
            <a href="#" onClick={handleShowTitleEditor} className="edit-title"><FaIcon icon="pencil-square-o" /></a>
          </>
        }
      </h1>
      <AddSongButton onChange={handleAddSong} position="top" />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="suggestion-list-droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              { list.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={`draggable-${item.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      key={index}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div key={index}>
                        <div className="song-row">
                          <div className="song-preview">
                            <SongPreview
                              {...item}
                              onUpdate={handleUpdateSong}

                            />
                          </div>
                          <div className="controls">
                            <a href="#" onClick={e => {
                              e.preventDefault()
                              removeSong(index)}
                              }>
                              <FaIcon icon="times" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
                ))}
                { provided.placeholder }
            </div>
          )}
        </Droppable>
      </DragDropContext>
      { list.length > 0 && <AddSongButton onChange={handleAddSong} position="bottom" /> }
      <div className="submit-button-wrapper">
        <Button onClick={persistList} variant='primary' size='lg' disabled={loading} className="w-100">
          { loading ? "Saving..." : "Save" }
        </Button>
      </div>
    </div>
  )
}

export default SuggestionListEditor
