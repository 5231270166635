import React, { useContext } from 'react'
import BrandContext from '../contexts/BrandContext'

const BrandBadge = ({ brandId }) => {
  const { brands } = useContext(BrandContext)

  // Only show brands if multiple brand features are enabled
  if(brands.length <= 1) return null

  const currentBrand = brands.find(brand => brand.id === brandId)
  if(!currentBrand) return null

  return (
    <div className="ms-1 badge badge-pill" style={{ backgroundColor: currentBrand.primaryColor, textColor: currentBrand.contrastColor }}>
      { currentBrand.name }
    </div>
  )
}

export default BrandBadge
