import React from 'react'
import PropTypes from 'prop-types'
import {observer} from "mobx-react"

import store from '../stores/store.js'
import fieldTypes from '../data/fieldTypes.json'

@observer
export default class FieldCopier extends React.Component {
  render() {
    return (
      <i className="fa fa-clone"
        title="Duplicate Field"
        style={{cursor: 'pointer'}}
        onClick={() => store.duplicateField(this.props.fieldGroupIndex, this.props.fieldIndex)}
        />
    )
  }
}

React.propTypes = {
  field: PropTypes.object.required,
  fieldIndex: PropTypes.number.required,
  fieldGroupIndex: PropTypes.number.required
}
