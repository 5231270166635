import React from 'react'
import { ListGroup } from 'react-bootstrap'
import SearchResultLead from './SearchResultLead'

const SearchResultListLeads = ({ leads, searchValue }) => {
  if(leads.data.length === 0) {
    return null
  }

  const params = new URLSearchParams({
    "query[query]" : searchValue,
    "query[show_past]": "true",
    "query[show_future]": "true",
  })
  const moreResultsPath = `/admin/leads?${params.toString()}`

  return (
    <div className="mb-3">
      <h5>
        <a href={moreResultsPath} className="text-dark"> 
          Leads &raquo;
        </a>
      </h5>
      <ListGroup>
        {leads.data.map(result => (
          <SearchResultLead key={result.id} result={result} />
        ))}
      </ListGroup>
      <div className="text-end mt-2">
        <a className="text-muted" href={moreResultsPath}>Search all leads &raquo;</a>
      </div>
    </div>
  )
}

export default SearchResultListLeads
