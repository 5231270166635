import React, { useState } from 'react'
import ShowAllPaymentMethods from './ShowAllPaymentMethods'
import client from '../../../helpers/client'

const CustomButton = ({ onClick, paymentMethodOption, paymentMethod, selected }) => (
  <button
    className={`custom-button ${selected ? 'selected' : 'unselected'}`}
    id={`pay-with-custom-btn-${paymentMethodOption.id}`}
    onClick={onClick}
    style={{ backgroundColor: paymentMethodOption.buttonBackgroundColor, color: paymentMethodOption.buttonForegroundColor }}
  >
    { paymentMethodOption.name }
  </button>
)

const CustomRedemptionForm = ({ paymentMethod, paymentMethodOption, setLoading, onGoBack, paymentIntentId, allowPayLater, showBackToAllPaymentMethodOptions }) => {
  const { customDescriptionHtml } = paymentMethodOption
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true)

    try {
      const result = await client.post(`/payment_intents/${paymentIntentId}/submit_custom_payment_indicator`, {
        paymentMethodOptionId: paymentMethodOption.id
      }, { withCredentials: true })
      window.location.reload()
    }
    catch(e) {
      if(e.response && e.response.data.error) {
        setErrorMessage(e.response.data.error)
      }
      else {
        setErrorMessage(e.message)
      }
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      { errorMessage && <div className="card-errors" role="alert">{errorMessage}</div> }

      <CustomButton onClick={onGoBack} paymentMethodOption={paymentMethodOption} paymentMethod={paymentMethod} selected={true} />

      <div
        className="payment-instructions-box ql-content-wrapper"
        dangerouslySetInnerHTML={{__html: customDescriptionHtml }}
      />


      { allowPayLater && <button type="submit" className="submit-payment-btn">Confirm Booking</button> }

      <ShowAllPaymentMethods onClick={onGoBack} showBackToAllPaymentMethodOptions={showBackToAllPaymentMethodOptions} />
    </form>
  )
}

const CustomForm = ({ show, paymentMethod, paymentMethodOption, activePaymentMethodOption, setActivePaymentMethodOption, setLoading, paymentIntentId, allowPayLater, showBackToAllPaymentMethodOptions }) => {
  if(!show) {
    return null
  }

  return (
    <div>
      { activePaymentMethodOption.id === paymentMethodOption.id ?
        <CustomRedemptionForm
          paymentMethod={paymentMethod}
          paymentMethodOption={paymentMethodOption}
          allowPayLater={allowPayLater}
          setLoading={setLoading}
          paymentIntentId={paymentIntentId}
          showBackToAllPaymentMethodOptions={showBackToAllPaymentMethodOptions}
          onGoBack={() => setActivePaymentMethodOption({ id: null, special: null })}
        />
        :
        <CustomButton
          paymentMethod={paymentMethod}
          paymentMethodOption={paymentMethodOption}
          onClick={() => setActivePaymentMethodOption({id: paymentMethodOption.id, special: null })}
        />
      }
    </div>
  )
}

export default CustomForm
