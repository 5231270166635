import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'react-bootstrap'

function formatTitle(option) {
  return `${option.formattedDate}: ${option.title}`
}

export default class SessionPicker extends React.Component {
  static propTypes = {
    miniSessions: PropTypes.array.isRequired,
    miniSessionId: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
  }

  render() {
    const { miniSessions, miniSessionId, onChange } = this.props

    return (
      <div className="session-picker">
        <FormGroup>
          <Form.Label>Session</Form.Label>
          <Form.Select
            value={miniSessionId}
            onChange={e => onChange(parseInt(e.target.value))}
          >
            { miniSessions.map((session, index) => 
              <option
                key={session.id}
                value={session.id}
              >{ formatTitle(session) }</option>
            )}
          </Form.Select>
        </FormGroup>
      </div>
    )
  }
}
