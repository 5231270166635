import React, { useState } from 'react'
import { FormGroup, Form } from 'react-bootstrap'
import AsyncSelect from 'react-select/lib/Async'
import { inject, observer } from 'mobx-react'
import client from '../../../helpers/client'

const deserializeUsers = (serializedUsers) => {
  return serializedUsers.map(user => deserializeUser(user)).filter(user => user)
}

const deserializeUser = (user) => {
  if(user && user.id && user.attributes) {
    return {
      value: user.id,
      label: user.attributes.fullName,
      attributes: user.attributes
    }
  }
  else {
    return null
  }
}

const UserField = ({ name, label, value, errors, onChange, checklistStore }) => {
  const { apiKey } = checklistStore
  const [inputValue, setInputValue] = useState('')

  async function handleLoadOptions(inputValue, callback) {
    const url = `/api/v1/users.json?api_key=${apiKey}&text=${inputValue}&role=staff`
    const response = await client.get(url)
    const users = deserializeUsers(response.data.data)
    callback(users)
  }

  function handleChange(selected) {
    onChange(selected)
  }

  return (
    <FormGroup>
      <Form.Label>{ label }</Form.Label>
      <AsyncSelect
        cacheOptions
        name={name}
        value={value}
        loadOptions={handleLoadOptions}
        onInputChange={setInputValue}
        onChange={handleChange}
        placeholder="Start typing..."
        noOptionsMessage={() => inputValue ? "Unable to find any matching users" : "Start typing to search for users"}
        isClearable
        isMulti
      />
    </FormGroup>
  )
}

export default inject('checklistStore')(observer(UserField))
