export default class Currency {
  static formatted(amount_in_cents, currency) {
    if(isNaN(amount_in_cents)) {
      return "Invalid number"
    }
    else {
      const amount_in_dollars = amount_in_cents / 100.0;
      return `${currency.prefix}${Currency.numberWithCommas(amount_in_dollars.toFixed(currency.precision))}${currency.suffix}`
    }
  }

  static numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
}
