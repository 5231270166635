import React from 'react'
import TextField from '../ui/TextField'
import SelectField from '../ui/SelectField'
import RadioField from '../ui/RadioField'
import CheckboxGroupField from '../ui/CheckboxGroupField'
import AddressField from '../ui/AddressField'
import FileUploadField from '../ui/FileUploadField'

const CustomField = (props) => {
  const { kind } = props

  switch(kind) {
    case "text":
      return <TextField {...props} />
    case "dropdown":
      return <SelectField {...props} />
    case "radio":
      return <RadioField {...props} />
    case "checkbox_group":
      return <CheckboxGroupField {...props} />
    case 'file':
      return <FileUploadField {...props} />
    case "address":
      return <AddressField {...props} />
    default:
      return <div>{ `${kind} not handled` }</div>
  }
}

export default CustomField
