import React from 'react'
import PropTypes from 'prop-types'
import { Provider, inject, observer } from 'mobx-react'
import Store from './stores/Store.js'
import ServiceSelector from './components/ServiceSelector'
import PackageGroupSelector from './components/PackageGroupSelector'
import EventTypeSelector from './components/EventTypeSelector'
import './style.scss'

@observer
export default class PackageSelector extends React.Component {
  static propTypes = {
    host: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired,
    franchise: PropTypes.object,
    iframe: PropTypes.bool
  }
  
  static defaultProps = {
    iframe: false
  }

  store = new Store({
    host: this.props.host,
    apiKey: this.props.apiKey,
    franchise: this.props.franchise,
    eventCategories: this.props.eventCategories,
    packageGroups: this.props.packageGroups,
    eventTypes: this.props.eventTypes
  })

  componentDidMount() {
    this.store.setup()
  }

  renderContent() {
    const { store } = this
    const { ready } = store
    const { showServiceSelector, showPackageGroupSelector, showEventTypeSelector } = store

    if(ready) {
      return (
        <div className="package-selector-widget">
          { showServiceSelector && <ServiceSelector /> }
          { showPackageGroupSelector && <PackageGroupSelector /> }
          { showEventTypeSelector && <EventTypeSelector /> }
        </div>
      )
    }
    else {
      return null
    }

  }


  render() {
    const { iframe } = this.props
    const { store } = this

    return (
      <Provider
        store={store}
      >
        <div className={`wrapper ${iframe ? "within-iframe" : ""}`}>
          { this.renderContent() }
        </div>
      </Provider>
    )
  }
}

