import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap'
import {observer} from "mobx-react"
import store from '../stores/store.js'
import ImageUploaderPreview from './ImageUploaderPreview'

@observer
export default class ImageUploadField extends React.Component {
  render() {
    const { field } = this.props
    const { uuid, name, hint, imageHeight, imageWidth, required } = field
    const instance = this.props.instance || 0
    const value = store.valueFor(uuid, "file", instance)
    const errorMessage = store.errorFor(uuid, "file", instance)

    return (
      <FormGroup>
        <Form.Label>{ required ? "*" : "" } {name}</Form.Label>
        <ImageUploaderPreview
          apiKey={store.apiKey}
          value={store.valueFor(uuid, "file", instance)}
          height={imageHeight}
          width={imageWidth}
          onChange={file => {
            store.setValueFor(uuid, "file", instance, file.rawUrl)
            if(store.allowSave) {
              store.persistFormContent(null, true)
            }
          }}
          isInvalid={!!errorMessage}
          disabled={!store.editable}
        />
        { errorMessage && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{errorMessage}</Form.Control.Feedback> }
        { hint && <Form.Text muted>{hint}</Form.Text > }
      </FormGroup>
    )
  }
}
