import React from 'react';
import UserPreview from './UserPreview'

export default class CustomOption extends React.Component {
  render() {
    const { innerRef, innerProps, selectProps, value, label, data } = this.props

    const showAvailability = selectProps["checkAvailabilityForEvent"] ? true : false

    return (
      <div
        {...innerProps}
        ref={innerRef}
      >
        <UserPreview
          data={data}
          showAvailability={showAvailability}
        />
      </div>
    )
  }
}
