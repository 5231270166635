import React from 'react';
import BackdropPreview from './BackdropPreview'

export default class CustomOption extends React.Component {
  render() {
    const { innerRef, innerProps, value, label, data } = this.props

    return (
      <div
        {...innerProps}
        ref={innerRef}
      >
        <BackdropPreview data={data} />
      </div>
    )
  }
}
