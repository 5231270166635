function inGroupsOf(array, perGroup) {
  let i, j
  let grouped = []

  for (i = 0,j = array.length; i < j; i += perGroup) {
    grouped.push(array.slice(i, i + perGroup))
  }

  return grouped
}

export { inGroupsOf }
