import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap'

export default class ContactForm extends React.Component {
  static propTypes = {
    errors: PropTypes.object
  }

  renderErrors() {
    const { errors } = this.props

    return (
      <Alert variant="danger">
        <p>Please correct the errors below and try again</p>
        { errors.base &&
          <ul>
            { Object.values(errors.base).map((message, index) =>
              <li key={index}>{ message }</li>
            )}
          </ul>
        }
      </Alert>
    )
  }

  render() {
    const { errors } = this.props

    if(errors && Object.keys(errors).length > 0) {
      return this.renderErrors()
    }
    else {
      return null
    }
  }
}
