import React from 'react'
import PropTypes from 'prop-types'

export default class Input extends React.Component {
  render() {
    const { onClick, value, style, onChange, placeholder } = this.props
    return (
      <input
        type="text"
        onClick={onClick}
        value={value}
        onChange={onChange}
        style={style}
        className="form-control"
        placeholder={placeholder}
      />
    )
  }
}
