import React, { useState, useEffect } from 'react'
import client from '../../helpers/client'
import { Button, Modal } from 'react-bootstrap'
import DateField from '../ui/DateField'
import { addMonths } from 'date-fns'
import flattenGroupedEventTypes from './util/flattenGroupedEventTypes'
import SimulatorForm from './components/SimulatorForm'
import FranchisePaymentPlan from './components/FranchisePaymentPlan'
import "./index.scss"

const DEFAULT_BOOK_DATE = new Date()
const DEFAULT_SCHEDULED_DATE = addMonths(new Date(), 9)

const PaymentPlanSimulator = ({ apiKey, dateFormat, currency, groupedEventTypes, dueNumberOfDaysBeforeOrAfterEvent, dueDateIsBeforeOrAfter }) => {
  const eventTypes = flattenGroupedEventTypes(groupedEventTypes)

  const [ show, setShow ] = useState(false)
  const [eventTypeId, setEventTypeId] = useState(eventTypes[0]?.id)
  const [bookDate, setBookDate] = useState(DEFAULT_BOOK_DATE)
  const [scheduledDate, setScheduledDate] = useState(DEFAULT_SCHEDULED_DATE)
  const [total, setTotal] = useState("")
  const [franchisePaymentPlans, setFranchisePaymentPlans] = useState([])
  const [activeFranchisePaymentPlanId, setActiveFranchisePaymentPlanId] = useState(null)

  // Set the default total when a new package is chosen
  useEffect(() => {
    if(eventTypeId) {
      const eventType = eventTypes.find(eventType => eventType.id == eventTypeId)
      setTotal(eventType.attributes.baseRate)
    }
  }, [eventTypeId])

  // Fetch the payment plans when the form is updated
  useEffect(() => {
    if(bookDate && scheduledDate && eventTypeId && total) {
      simulatePaymentPlans()
    }
  }, [bookDate, scheduledDate, eventTypeId, total])


  async function simulatePaymentPlans() {
    try {
      const response = await client.get(`/api/v1/simulated_payment_plans.json`, {
        params: {
          event_type_id: eventTypeId,
          book_date: bookDate,
          scheduled_date: scheduledDate,
          total: total,
        }
      })
      const { franchisePaymentPlans } = response.data
      setFranchisePaymentPlans(franchisePaymentPlans)
    }
    catch(error) {
      console.error(error)
    }
  }

  return (
    <>
      <Button onClick={() => setShow(true)}>
        <i className="fa fa-map-marker" />
        { " Try it out" }
      </Button>
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Payment Plan Simulator</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Fill out the form below to preview the payment options a customer will be offered.</p>
          <div className="payment-plan-simulator">
            <div className="card">
              <div className="card-body">
                <SimulatorForm
                  eventTypeId={eventTypeId}
                  setEventTypeId={setEventTypeId}
                  total={total}
                  setTotal={setTotal}
                  bookDate={bookDate}
                  setBookDate={setBookDate}
                  scheduledDate={scheduledDate}
                  setScheduledDate={setScheduledDate}
                  groupedEventTypes={groupedEventTypes}
                  dateFormat={dateFormat}
                  currency={currency}
                  dueNumberOfDaysBeforeOrAfterEvent={dueNumberOfDaysBeforeOrAfterEvent}
                  dueDateIsBeforeOrAfter={dueDateIsBeforeOrAfter}
                />
              </div> </div>

            <div className="card mt-3">
              <div className="card-header">
                <h5>Your customer will be offered the following payment plans:</h5>
              </div>
              <div className="card-body">
                <ol className="list-group">
                  { franchisePaymentPlans.map(franchisePaymentPlan => (
                    <FranchisePaymentPlan
                      key={franchisePaymentPlan.id}
                      franchisePaymentPlan={franchisePaymentPlan}
                      dateFormat={dateFormat}
                      activeFranchisePaymentPlanId={activeFranchisePaymentPlanId}
                      setActiveFranchisePaymentPlanId={setActiveFranchisePaymentPlanId}
                    />
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PaymentPlanSimulator
