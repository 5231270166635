import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import parse from 'date-fns/parse'
import SlotEditor from './SlotEditor'
import SlotRow from './SlotRow'

function sortPackageSlots(packageSlots) {
  return packageSlots.map(packageSlot => {
    return {
      startsAt: parse(packageSlot.startsAtTime.trim(), "h:m a", new Date()),
      endsAt: parse(packageSlot.endsAtTime.trim(), "h:m a", new Date()),
      ...packageSlot
    }
  })
  .sort((a, b) => a.endsAt - b.endsAt )
  .sort((a, b) => a.startsAt - b.startsAt )
}

const SlotManagerPanel = (props) => {
  const [packageSlots, setPackageSlots] = useState(sortPackageSlots(props.packageSlots))
  const [activeSlot, setActiveSlot] = useState()

  const handleSlotAdded = () => {
    const blankSlot = { sunday: true, monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true }
    setActiveSlot(blankSlot)
  }

  const handleCreateSlot = (slot) => {
    setPackageSlots(sortPackageSlots([slot, ...packageSlots]))
    setActiveSlot()
  }

  const handleUpdateSlot = (slot) => {
    const filteredSlots = packageSlots.filter(s => s.id != slot.id)
    setPackageSlots(sortPackageSlots([slot, ...filteredSlots]))
    setActiveSlot()
  }

  const handleRemoveSlot = (slot) => {
    const filteredSlots = packageSlots.filter(s => s.id != slot.id)
    setPackageSlots(sortPackageSlots(filteredSlots))
    setActiveSlot()
  }

  return (
    <>
      <hr />
      <SlotEditor
        {...props}
        activeSlot={activeSlot}
        onHide={() => setActiveSlot(null)}
        onCreateSlot={handleCreateSlot}
        onUpdateSlot={handleUpdateSlot}
      />
      { packageSlots.length > 0 ?
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th />
                <th>Sunday</th>
                <th>Monday</th>
                <th>Tuesday</th>
                <th>Wednesday</th>
                <th>Thursday</th>
                <th>Friday</th>
                <th>Saturday</th>
                <th />
              </tr>
            </thead>
            <tbody>
              { packageSlots.map(packageSlot =>
              <SlotRow
                {...props}
                key={packageSlot.id}
                packageSlot={packageSlot}
                onEdit={() => setActiveSlot(packageSlot)}
                onRemove={() => handleRemoveSlot(packageSlot)}
              />
              )}
            </tbody>
          </table>
          <div className="text-end">
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => handleSlotAdded()}
            >
              <i className="fa fa-plus" />
              { " " }
              Add Slot
            </Button>
          </div>
        </>
        :
        <p className="text-center">
          You do not have any slots defined.
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              handleSlotAdded()
            }}
          >Click here to add one</a>
        </p>
      }
    </>
    )
}

export default SlotManagerPanel
