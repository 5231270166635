import { observable, autorun, action, computed } from 'mobx'
import every from 'lodash/every'

class ObservableStore {
  @observable showAllPackages = false
  @observable showAllMiniSessions = false
  @observable allCategories = false
  @observable allAddOnCategories = false
  @observable allBackdropCategories = false
  @observable allMiniSessions = false
  @observable showCustom = false
  @observable allCategoriesCollapsed = false
  @observable allAddOnCategoriesCollapsed = false
  @observable allBackdropCategoriesCollapsed = false
  @observable allMiniSessionsCollapsed = false
  @observable eventCategories = []
  @observable addOnCategories = []
  @observable backdropCategories = []
  @observable miniSessions = []
  @observable onChangeHandler = null
  @observable showRecommendedEventTypes = false
  @observable activeTab = "packages"
 
  @action setInitialEventCategories({ allCategories, allAddOnCategories, allBackdropCategories, allMiniSessions, eventCategories, addOnCategories, backdropCategories, miniSessions, showCustom, supportsPackages, supportsAddOns, supportsMiniSessions }) {
    this.allCategories = allCategories
    this.allAddOnCategories = allAddOnCategories
    this.allBackdropCategories = allBackdropCategories
    this.allMiniSessions = allMiniSessions
    this.showCustom = showCustom

    if(supportsPackages) {
      this.activeTab = "packages"
    }
    else if(supportsAddOns) {
      this.activeTab = "addons"
    }
    else if(supportsMiniSessions) {
      this.activeTab = "mini_sessions"
    }

    if(eventCategories) {
      this.eventCategories = eventCategories.map((eventCategory) => {
        eventCategory.collapsed = false
        eventCategory.packageGroups = eventCategory.packageGroups.map((packageGroup) => {
          packageGroup.collapsed = false
          return packageGroup
        })
        return eventCategory
      })
    }

    if(addOnCategories) {
      this.addOnCategories = addOnCategories.map((addOnCategory) => {
        addOnCategory.collapsed = false
        return addOnCategory
      })
    }

    if(backdropCategories) {
      this.backdropCategories = backdropCategories.map((backdropCategory) => {
        backdropCategory.collapsed = false
        return backdropCategory
      })
    }

    if(miniSessions) {
      this.miniSessions = miniSessions.map((miniSession) => {
        miniSession.collapsed = false
        miniSession.starts_at = miniSession.starts_at ? new Date(miniSession.starts_at) : new Date(miniSession.startsAt)
        return miniSession
      })
    }
  }

  @computed get viewableEventCategories() {
    if(this.showAllPackages) {
      return this.eventCategories
    }
    else {
      let eventCategories = []
      this.eventCategories.forEach((eventCategory) => {
        let packageGroups = []
        eventCategory.packageGroups.forEach((packageGroup) => {
          const eventTypes = packageGroup.eventTypes.filter(eventType => eventType.status === 'public')
          const clonedPackageGroup = {...packageGroup, eventTypes}
          if(eventTypes.length > 0) {
            packageGroups.push(clonedPackageGroup)
          }
        })
        if(packageGroups.length > 0) {
          const clonedEventCategory = {...eventCategory, packageGroups }
          eventCategories.push(clonedEventCategory)
        }
      })

      return eventCategories
    }
  }

  @computed get viewableAddOnCategories() {
    return this.addOnCategories
  }

  @computed get viewableBackdropCategories() {
    return this.backdropCategories
  }

  @computed get viewableMiniSessions() {
    if(this.showAllMiniSessions) {
      return this.miniSessions
    }
    else {
      return this.miniSessions.filter(s => s.starts_at >= new Date())
    }
  }

  @action toggleAllPackages() {
    const newValue = !this.allCategories

    this.allCategories = newValue
    this.showCustom = newValue
    
    // Select children
    this.eventCategories.forEach((eventCategory, i) => {
      this.eventCategories[i].selected = newValue

      // Select grandchildren
      eventCategory.packageGroups.forEach((packageGroup, j) => {
        this.eventCategories[i].packageGroups[j].selected = newValue


        // Select great-grandchildren
        packageGroup.eventTypes.forEach((eventType, k) => {
          this.eventCategories[i].packageGroups[j].eventTypes[k].selected = newValue
          if(!newValue) {
            this.eventCategories[i].packageGroups[j].eventTypes[k].recommended = false
          }
        })
      })
    })
  }

  @action toggleAllAddOns() {
    const newValue = !this.allAddOnCategories

    this.allAddOnCategories = newValue
    
    // Select children
    this.addOnCategories.forEach((addOnCategory, i) => {
      this.addOnCategories[i].selected = newValue

      // Select grandchildren
      addOnCategory.addOns.forEach((addOn, j) => {
        this.addOnCategories[i].addOns[j].selected = newValue
      })
    })
  }

  @action toggleAllBackdrops() {
    const newValue = !this.allBackdropCategories

    this.allBackdropCategories = newValue
    
    // Select children
    this.backdropCategories.forEach((backdropCategory, i) => {
      this.backdropCategories[i].selected = newValue

      // Select grandchildren
      backdropCategory.backdrops.forEach((backdrop, j) => {
        this.backdropCategories[i].backdrops[j].selected = newValue
      })
    })
  }

  @action toggleAllMiniSessions() {
    const newValue = !this.allMiniSessions

    this.allMiniSessions = newValue
    
    // Select children
    this.miniSessions.forEach((miniSession, i) => {
      this.miniSessions[i].selected = newValue
    })
  }

  @action toggleCustom() {
    this.showCustom = !this.showCustom
    this.calculateAllPackageSelectionStatus()
  }

  @action toggleAllPackagesCollapsed() {
    this.allCategoriesCollapsed = !this.allCategoriesCollapsed
  }

  @action toggleAllAddOnsCollapsed() {
    this.allAddOnCategoriesCollapsed = !this.allAddOnCategoriesCollapsed
  }

  @action toggleAllBackdropsCollapsed() {
    this.allBackdropCategoriesCollapsed = !this.allBackdropCategoriesCollapsed
  }

  @action toggleAllMiniSessionsCollapsed() {
    this.allMiniSessionsCollapsed = !this.allMiniSessionsCollapsed
  }

  @action toggleEventTypeSelection(id) {
    this.eventCategories.forEach((eventCategory, i) => {
      eventCategory.packageGroups.forEach((packageGroup, j) => {
        packageGroup.eventTypes.forEach((eventType, k) => {
          if(eventType.id == id) {
            let currentValue = this.eventCategories[i].packageGroups[j].eventTypes[k].selected
            let newValue = !currentValue
            this.eventCategories[i].packageGroups[j].eventTypes[k].selected = newValue

            this.calculatePackageGroupSelectionStatus(packageGroup)
            // Deselect parents
            if(!newValue) {
              this.allCategories = false
              this.eventCategories[i].selected = false
              this.eventCategories[i].packageGroups[j].selected = false
              this.eventCategories[i].packageGroups[j].eventTypes[k].recommended = false
            }
          }
        })
      })
    })
  }

  @action toggleAddOnSelection(id) {
    this.addOnCategories.forEach((addOnCategory, i) => {
      addOnCategory.addOns.forEach((addOn, j) => {
        if(addOn.id == id) {
          let currentValue = this.addOnCategories[i].addOns[j].selected
          let newValue = !currentValue
          this.addOnCategories[i].addOns[j].selected = newValue

          this.calculateAddOnCategorySelectionStatus(addOnCategory)

          // Deselect parents
          if(!newValue) {
            this.allAddOnCategories = false
            this.addOnCategories[i].selected = false
          }
        }
      })
    })
  }

  @action toggleBackdropSelection(id) {
    this.backdropCategories.forEach((backdropCategory, i) => {
      backdropCategory.backdrops.forEach((backdrop, j) => {
        if(backdrop.id == id) {
          let currentValue = this.backdropCategories[i].backdrops[j].selected
          let newValue = !currentValue
          this.backdropCategories[i].backdrops[j].selected = newValue

          this.calculateBackdropCategorySelectionStatus(backdropCategory)

          // Deselect parents
          if(!newValue) {
            this.allBackdropCategories = false
            this.backdropCategories[i].selected = false
          }
        }
      })
    })
  }

  @action toggleMiniSessionSelection(id) {
    this.miniSessions.forEach((miniSession, i) => {
      if(miniSession.id == id) {
        let currentValue = this.miniSessions[i].selected
        let newValue = !currentValue
        this.miniSessions[i].selected = newValue

        // Deselect parents
        if(!newValue) {
          this.allMiniSessions = false
        }
        else if(every(this.miniSessions, ['selected', true])) {
          this.allMiniSessions = true
        }
      }
    })
  }

  @action updateEventCategory(eventCategory, callback) {
    this.eventCategories.forEach((currentEventCategory, i) => {
      if(eventCategory.id === currentEventCategory.id) {
        callback(i)
      }
    })
  }

  @action updateAddOnCategory(addOnCategory, callback) {
    this.addOnCategories.forEach((currentAddOnCategory, i) => {
      if(addOnCategory.id === currentAddOnCategory.id) {
        callback(i)
      }
    })
  }

  @action updateBackdropCategory(backdropCategory, callback) {
    this.backdropCategories.forEach((currentBackdropCategory, i) => {
      if(backdropCategory.id === currentBackdropCategory.id) {
        callback(i)
      }
    })
  }

  @action updatePackageGroup(packageGroup, callback) {
    this.eventCategories.forEach((currentEventCategory, i) => {
      currentEventCategory.packageGroups.forEach((currentPackageGroup, j) => {
        if(packageGroup.id == currentPackageGroup.id) {
          callback(i, j)
        }
      })
    })
  }

  @action calculatePackageGroupSelectionStatus(packageGroup) {
    // All children selected
    if(every(packageGroup.eventTypes, ['selected', true])) {
      this.updatePackageGroup(packageGroup, (i, j) => this.eventCategories[i].packageGroups[j].selected = true)
    }
    // No children selected
    else if(every(packageGroup.eventTypes, ['selected', false])) {
      this.updatePackageGroup(packageGroup, (i, j) => this.eventCategories[i].packageGroups[j].selected = false)
    }
    // Some children selected
    else {
      this.updatePackageGroup(packageGroup, (i, j) => this.eventCategories[i].packageGroups[j].selected = false)
    }
    this.eventCategories.forEach(eventCategory => this.calculateEventCategorySelectionStatus(eventCategory))
  }

  @action calculateEventCategorySelectionStatus(eventCategory) {
    // All children selected
    if(every(eventCategory.packageGroups, ['selected', true]) && this.showCustom) {
      this.updateEventCategory(eventCategory, (i) => this.eventCategories[i].selected = true)
    }
    // No children selected
    else if(every(eventCategory.packageGroups, ['selected', false]) && !this.showCustom) {
      this.updateEventCategory(eventCategory, (i) => this.eventCategories[i].selected = false)
    }
    // Some children selected
    else {
      this.updateEventCategory(eventCategory, (i) => this.eventCategories[i].selected = false)
    }
    this.calculateAllPackageSelectionStatus()
  }

  @action calculateAddOnCategorySelectionStatus(addOnCategory) {
    // All children selected
    if(every(addOnCategory.addOns, ['selected', true])) {
      this.updateAddOnCategory(addOnCategory, (i) => this.addOnCategories[i].selected = true)
    }
    // No children selected
    else if(every(addOnCategory.addOns, ['selected', false])) {
      this.updateAddOnCategory(addOnCategory, (i) => this.addOnCategories[i].selected = false)
    }
    // Some children selected
    else {
      this.updateAddOnCategory(addOnCategory, (i) => this.addOnCategories[i].selected = false)
    }
    this.calculateAllAddOnSelectionStatus()
  }

  @action calculateBackdropCategorySelectionStatus(backdropCategory) {
    // All children selected
    if(every(backdropCategory.backdrops, ['selected', true])) {
      this.updateBackdropCategory(backdropCategory, (i) => this.backdropCategories[i].selected = true)
    }
    // No children selected
    else if(every(backdropCategory.backdrops, ['selected', false])) {
      this.updateBackdropCategory(backdropCategory, (i) => this.backdropCategories[i].selected = false)
    }
    // Some children selected
    else {
      this.updateBackdropCategory(backdropCategory, (i) => this.backdropCategories[i].selected = false)
    }
    this.calculateAllBackdropSelectionStatus()
  }

  @action calculateAllPackageSelectionStatus() {
    // All children selected
    if(every(this.eventCategories, ['selected', true]) && this.showCustom) {
      this.allCategories = true
    }
    // No children selected
    else if(every(this.eventCategories, ['selected', false]) && !this.showCustom) {
      this.allCategories = false
    }
    // Some children selected
    else {
      this.allCategories = false
    }
  }

  @action calculateAllAddOnSelectionStatus() {
    // All children selected
    if(every(this.addOnCategories, ['selected', true])) {
      this.allAddOnCategories = true
    }
    // No children selected
    else if(every(this.addOnCategories, ['selected', false])) {
      this.allAddOnCategories = false
    }
    // Some children selected
    else {
      this.allAddOnCategories = false
    }
  }

  @action calculateAllBackdropSelectionStatus() {
    // All children selected
    if(every(this.backdropCategories, ['selected', true])) {
      this.allBackdropCategories = true
    }
    // No children selected
    else if(every(this.backdropCategories, ['selected', false])) {
      this.allBackdropCategories = false
    }
    // Some children selected
    else {
      this.allBackdropCategories = false
    }
  }

  @action toggleEventTypeStar(id) {
    this.eventCategories.forEach((eventCategory, i) => {
      eventCategory.packageGroups.forEach((packageGroup, j) => {
        packageGroup.eventTypes.forEach((eventType, k) => {
          if(eventType.id == id) {
            const currentValue = this.eventCategories[i].packageGroups[j].eventTypes[k].recommended
            const newValue = !currentValue
            this.eventCategories[i].packageGroups[j].eventTypes[k].recommended = newValue
          }
        })
      })
    })
  }

  @action togglePackageGroupSelection(id) {
    this.eventCategories.forEach((eventCategory, i) => {
      eventCategory.packageGroups.forEach((packageGroup, j) => {
        if(packageGroup.id == id) {
          let currentValue = this.eventCategories[i].packageGroups[j].selected
          let newValue = !currentValue
          this.eventCategories[i].packageGroups[j].selected = newValue

          this.calculateEventCategorySelectionStatus(eventCategory)
          
          // Deselect parents
          if(!newValue) {
            this.allCategories = false
            this.eventCategories[i].selected = false
          }

          // Select children
          this.eventCategories[i].packageGroups[j].eventTypes.forEach((eventType, k) => {
            this.eventCategories[i].packageGroups[j].eventTypes[k].selected = newValue

            if(!newValue) {
              this.eventCategories[i].packageGroups[j].eventTypes[k].recommended = false
            }
          })
        }
      })
    })
  }

  @action toggleEventCategorySelection(id) {
    this.eventCategories.forEach((eventCategory, i) => {
      if(eventCategory.id == id) {
        let currentValue = this.eventCategories[i].selected
        let newValue = !currentValue
        this.eventCategories[i].selected = newValue

        this.calculateAllPackageSelectionStatus()

        // Deselect parents
        if(!newValue) {
          this.allCategories = false
        }

        // Select children
        eventCategory.packageGroups.forEach((packageGroup, j) => {
          this.eventCategories[i].packageGroups[j].selected = newValue

          // Select grandchildren
          packageGroup.eventTypes.forEach((eventType, k) => {
            this.eventCategories[i].packageGroups[j].eventTypes[k].selected = newValue

            if(!newValue) {
              this.eventCategories[i].packageGroups[j].eventTypes[k].recommended = false
            }
          })
        })
      }
    })
  }

  @action toggleAddOnCategorySelection(id) {
    this.addOnCategories.forEach((addOnCategory, i) => {
      if(addOnCategory.id == id) {
        let currentValue = this.addOnCategories[i].selected
        let newValue = !currentValue
        this.addOnCategories[i].selected = newValue

        this.calculateAllAddOnSelectionStatus()

        // Deselect parents
        if(!newValue) {
          this.allAddOnCategories = false
        }

        // Select children
        addOnCategory.addOns.forEach((addOn, j) => {
          this.addOnCategories[i].addOns[j].selected = newValue
        })
      }
    })
  }

  @action toggleBackdropCategorySelection(id) {
    this.backdropCategories.forEach((backdropCategory, i) => {
      if(backdropCategory.id == id) {
        let currentValue = this.backdropCategories[i].selected
        let newValue = !currentValue
        this.backdropCategories[i].selected = newValue

        this.calculateAllBackdropSelectionStatus()

        // Deselect parents
        if(!newValue) {
          this.allBackdropCategories = false
        }

        // Select children
        backdropCategory.backdrops.forEach((backdrop, j) => {
          this.backdropCategories[i].backdrops[j].selected = newValue
        })
      }
    })
  }

  @action toggleEventCategoryCollapsed(id) {
    this.eventCategories.forEach((eventCategory, i) => {
      if(eventCategory.id == id) {
        let currentValue = this.eventCategories[i].collapsed
        this.eventCategories[i].collapsed = !currentValue
      }
    })
  }

  @action togglePackageGroupCollapsed(id) {
    this.eventCategories.forEach((eventCategory, i) => {
      eventCategory.packageGroups.forEach((packageGroup, j) => {
        if(packageGroup.id == id) {
          let currentValue = this.eventCategories[i].packageGroups[j].collapsed
          this.eventCategories[i].packageGroups[j].collapsed = !currentValue
        }
      })
    })
  }

  @action toggleAddOnCategoryCollapsed(id) {
    this.addOnCategories.forEach((addOnCategory, i) => {
      if(addOnCategory.id == id) {
        let currentValue = this.addOnCategories[i].collapsed
        this.addOnCategories[i].collapsed = !currentValue
      }
    })
  }

  @action toggleBackdropCategoryCollapsed(id) {
    this.backdropCategories.forEach((backdropCategory, i) => {
      if(backdropCategory.id == id) {
        let currentValue = this.backdropCategories[i].collapsed
        this.backdropCategories[i].collapsed = !currentValue
      }
    })
  }

  @computed get selectedPackageSummary() {
    if(this.allCategories) {
      return "All packages"
    }
    else {
      const eventCategoryNames = this.selectedEventCategories.map(c => c.name)
      const packageGroupNames = this.selectedPackageGroups.map(g => g.name)
      const eventTypeNames = this.selectedEventTypes.map(c => c.name)

      let allNames = [...eventCategoryNames, ...packageGroupNames, ...eventTypeNames]

      if(this.showCustom) {
        allNames.push("Custom Packages")
      }

      if(allNames.length > 0) {
        return `The following packages: ${allNames.join(", ")}`
      }
      else {
        return `Does not apply to any of your packages`
      }
    }
  }

  @computed get selectedAddOnSummary() {
    if(this.allAddOnCategories) {
      return "All add-ons"
    }
    else {
      const addOnCategoryNames = this.selectedAddOnCategories.map(c => c.name)
      const addOnNames = this.selectedAddOns.map(c => c.name)

      const allNames = [...addOnCategoryNames, ...addOnNames]

      if(allNames.length > 0) {
        return `The following add-ons: ${allNames.join(", ")}`
      }
      else {
        // return `Does not apply to any of your add-ons` // I think this might be confusing.  Just returning blank for now
        return ``
      }
    }
  }


  @computed get selectedBackdropSummary() {
    if(this.allBackdropCategories) {
      return "All backdrops"
    }
    else {
      const backdropCategoryNames = this.selectedBackdropCategories.map(c => c.name)
      const backdropNames = this.selectedBackdrops.map(c => c.name)

      const allNames = [...backdropCategoryNames, ...backdropNames]

      if(allNames.length > 0) {
        return `The following backdrops: ${allNames.join(", ")}`
      }
      else {
        // return `Does not apply to any of your backdrops` // I think this might be confusing.  Just returning blank for now
        return ``
      }
    }
  }

  @computed get selectedMiniSessionsSummary() {
    if(this.allMiniSessions) {
      return "All mini sessions"
    }
    else {
      const miniSessionNames = this.selectedMiniSessions.map(c => c.name)

      if(miniSessionNames.length > 0) {
        return `The following mini sessions: ${miniSessionNames.join(", ")}`
      }
      else {
        // return `Does not apply to any of your mini sessions` // I think this might be confusing.  Just returning blank for now
        return ``
      }
    }
  }

  @computed get selectedEventCategories() {
    return this.eventCategories.filter(c => c.selected)
  }

  @computed get selectedPackageGroups() {
    return this.eventCategories
      .filter(c => !c.selected)
      .map(c => c.packageGroups.slice())
      .reduce((a, b) => a.concat(b), [])
      .filter(g => g.selected)
  }

  @computed get selectedEventTypes() {
    return this.eventCategories
      .filter(c => !c.selected && c.packageGroups.length > 0) // Only unselected categories with packageGroups
      .map(c => c.packageGroups.slice())
      .reduce((a, b) => a.concat(b), []) // Flatten Package Groups
      .filter(g => !g.selected && g.eventTypes.length > 0) // Only unselected package groups
      .map(g => g.eventTypes.slice())
      .reduce((a, b) => a.concat(b), []) // Flatten Event Types
      .filter(t => t.selected) // Only selected event types
  }

  @computed get selectedAddOnCategories() {
    return this.addOnCategories.filter(c => c.selected)
  }

  @computed get selectedAddOns() {
    return this.addOnCategories
      .filter(c => !c.selected)
      .map(c => c.addOns.slice())
      .reduce((a, b) => a.concat(b), [])
      .filter(g => g.selected)
  }

  @computed get selectedBackdropCategories() {
    return this.backdropCategories.filter(c => c.selected)
  }

  @computed get selectedBackdrops() {
    return this.backdropCategories
      .filter(c => !c.selected)
      .map(c => c.backdrops.slice())
      .reduce((a, b) => a.concat(b), [])
      .filter(g => g.selected)
  }

  @computed get selectedMiniSessions() {
    return this.miniSessions.filter(c => c.selected)
  }
}


// Return a simplified version of our tree structure for outside components
// that want to subscribe to changes
const changeHandler = (store) => {
  if(store.onChangeHandler) {
    const { allCategories, allAddOnCategories, allBackdropCategories, allMiniSessions, showCustom } = store

    const eventCategoryIds = store.eventCategories
      .filter((eventCategory) => eventCategory.selected)
      .map((eventCategory) => eventCategory.id)
      .sort((a, b) => a - b)

    const packageGroupIds = store.eventCategories
      .filter((eventCategory) => !eventCategory.selected) // Only unselected categories
      .map((eventCategory) => eventCategory.packageGroups.slice())
      .reduce((a, b) => a.concat(b), []) // Flatten Package Groups
      .filter((packageGroup) => packageGroup.selected) // Only selected package groups
      .map((packageGroup) => packageGroup.id)
      .sort((a, b) => a - b)

    const eventTypeIds = store.eventCategories
      .filter((eventCategory) => !eventCategory.selected && eventCategory.packageGroups.length > 0) // Only unselected categories with packageGroups
      .map((eventCategory) => eventCategory.packageGroups.slice())
      .reduce((a, b) => a.concat(b), []) // Flatten Package Groups
      .filter((packageGroup) => !packageGroup.selected && packageGroup.eventTypes.length > 0) // Only unselected package groups
      .map((packageGroup) => packageGroup.eventTypes.slice())
      .reduce((a, b) => a.concat(b), []) // Flatten Event Types
      .filter((eventType) => eventType.selected) // Only selected event types
      .map((eventType) => eventType.id)

    const addOnCategoryIds = store.addOnCategories
      .filter((addOnCategory) => addOnCategory.selected)
      .map((addOnCategory) => addOnCategory.id)
      .sort((a, b) => a - b)

    const addOnIds = store.addOnCategories
      .filter((addOnCategory) => !addOnCategory.selected && addOnCategory.addOns.length > 0) // Only unselected categories with add ons
      .map((addOnCategory) => addOnCategory.addOns.slice())
      .reduce((a, b) => a.concat(b), []) // Flatten Add Ons
      .filter((addOn) => addOn.selected) // Only selected add ons
      .map((addOn) => addOn.id)

    const backdropCategoryIds = store.backdropCategories
      .filter((backdropCategory) => backdropCategory.selected)
      .map((backdropCategory) => backdropCategory.id)
      .sort((a, b) => a - b)

    const backdropIds = store.backdropCategories
      .filter((backdropCategory) => !backdropCategory.selected && backdropCategory.backdrops.length > 0) // Only unselected categories with add ons
      .map((backdropCategory) => backdropCategory.backdrops.slice())
      .reduce((a, b) => a.concat(b), []) // Flatten Add Ons
      .filter((backdrop) => backdrop.selected) // Only selected add ons
      .map((backdrop) => backdrop.id)

    const miniSessionIds = store.miniSessions
      .filter(miniSession => miniSession.selected)
      .map(miniSession => miniSession.id)

    const recommendedEventTypeIds = store.eventCategories
      .map((eventCategory) => eventCategory.packageGroups.slice())
      .reduce((a, b) => a.concat(b), []) // Flatten Package Groups
      .map((packageGroup) => packageGroup.eventTypes.slice())
      .reduce((a, b) => a.concat(b), []) // Flatten Event Types
      .filter((eventType) => eventType.recommended) // Only recommended event types
      .map((eventType) => eventType.id)
      .sort((a, b) => a - b)
      .sort((a, b) => a - b)

    const change = {
      allCategories,
      allAddOnCategories,
      allBackdropCategories,
      allMiniSessions,
      eventCategoryIds,
      packageGroupIds,
      eventTypeIds,
      addOnCategoryIds,
      miniSessionIds,
      addOnIds,
      backdropCategoryIds,
      backdropIds,
      recommendedEventTypeIds,
      showCustom
    }
    store.onChangeHandler(change)
  }
}


export { changeHandler } 
export default ObservableStore
