import React, { useState } from 'react'
import SearchBar from './SearchBar'
import SongList from './SongList'
import searchSpotifyPlaylists from './searchSpotifyPlaylists'
import AddPlaylistButton from './AddPlaylistButton'

const SpotifyPlaylistImporter = ({ onSelect }) => {
  const [ playlistUrl, setPlaylistUrl ] = useState("")
  const [ playlistData, setPlaylistData ] = useState({
    playlistName: "",
    playlistDescription: "",
    playlistArtworkUrl: "",
    playlistUrl: "",
    songs: []
  })
  const [ loading, setLoading ] = useState(false)

  async function handleSearch(e) {
    e.preventDefault()
    setLoading(true)
    try {
      const playlistData = await searchSpotifyPlaylists(playlistUrl)
      setPlaylistData(playlistData)
    }
    catch(e) {
      console.log(e)
      alert("There was an error loading the playlist. Please try again.")
    }
    setLoading(false)
  }

  function handleSave() {
    onSelect(playlistData)
  }

  return (
    <>
      <SearchBar
        loading={loading}
        onSearch={handleSearch}
        searchText={playlistUrl}
        setSearchText={setPlaylistUrl}
        placeholder="Paste your Spotify playlist URL here"
      />
      { playlistData.songs.length > 0 &&
      <div>
        <p>Found {playlistData.songs.length} songs</p>
        <AddPlaylistButton onClick={handleSave} />
        <SongList songs={playlistData.songs} />
        <AddPlaylistButton onClick={handleSave} />
      </div>
      }
    </>
  )
}

export default SpotifyPlaylistImporter
