import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, FormControl } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export default class CopyableTextBlock extends Component {
  static propTypes = {
    value: PropTypes.string
  }

  state = {
    copied: false
  }

  componentWillUnmount() {
    if(this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  handleCopy = () => {
    this.setState({copied: true}, () => {
      if(this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.setState({copied: false})
      }, 2000)
    })
  }

  renderCopyButton() {
    const { value } = this.props
    const { copied } = this.state

    if(copied) {
      return (
        <div style={styles.copyMessage}>
          Copied to clipboard
        </div>
      )
    }
    else if(value) {
      return (
        <div style={styles.buttonWrapper}>
          <CopyToClipboard text={value} onCopy={this.handleCopy}>
            <Button variant="outline-secondary">
              <i className="fa fa-clipboard" style={styles.icon}/>
              { " " }
              Copy to clipboard
            </Button>
          </CopyToClipboard>
        </div>
      )
    }
    else {
      return null
    }
  }

  render() {
    const { value } = this.props

    return (
      <div>
        <FormControl
          as="textarea"
          readOnly
          style={styles.textarea}
          value={value}
        />
        { this.renderCopyButton() }
      </div>
    )
  }
}

const styles = {
  textarea: {
    height: 200
  },
  buttonWrapper: {
    textAlign: "center",
    marginTop: 10
  },
  icon: {
    color: "#444"
  },
  copyMessage: {
    color: "#777",
    marginTop: 10,
    fontStyle: "italic",
    textAlign: "center"
  }
}
