import fieldTypes from '../data/fieldTypes.json'

function fieldToHash(field) {
  return {
    value: field.uuid,
    name: field.name || `(Unnamed ${field.type})`,
    type: field.type
  }
}

function extractFieldsAvailableForConditionalExpressions(currentField, allFields) {
  return allFields
    .filter(field => field.uuid != currentField.uuid)
    .filter(field => fieldTypes[field.type].useInConditionals)
    .map(field => fieldToHash(field))
}

export default extractFieldsAvailableForConditionalExpressions
