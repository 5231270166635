import React from 'react'
import { observer } from "mobx-react"
import { Button } from 'react-bootstrap'
import store from '../stores/store.js'
import FaIcon from '../../../helpers/fa_icon.js'

const AddFieldInstance = observer(({ field }) => {
  const instances = store.instancesFor(field.uuid)

  if(!store.editable) return null
  if(!field.allowMultipleEntries) return null
  if(field.maximumEntries && instances >= field.maximumEntries) return null
  if(store.fieldEditingIsLocked(field)) return null

  return (
    <Button onClick={() => store.incrementInstancesFor(field.uuid)}>
      <FaIcon icon="plus" text="Add" />
    </Button>
  )
})

export default AddFieldInstance
