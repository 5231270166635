import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { autorun } from 'mobx'
import { Provider, observer } from 'mobx-react'
import { Nav } from 'react-bootstrap'
import ObservableStore, { changeHandler } from './stores/store.js'
import PackageSelector from './components/PackageSelector'
import AddOnSelector from './components/AddOnSelector'
import BackdropSelector from './components/BackdropSelector'
import MiniSessionSelector from './components/MiniSessionSelector'
import EventCategory from './components/EventCategory'
import AddOnCategory from './components/AddOnCategory'
import BackdropCategory from './components/BackdropCategory'
import TreeView from 'react-treeview'

import 'react-treeview/react-treeview.css';
import './style.css'


@observer
export default class EventTypeSelector extends Component {
  static defaultProps = {
    showCheckboxes: true,
    showActiveInactiveSelector: true,
    supportsPackages: true,
    showSummary: true,
    allowSelectAll: true,
  }

  store = new ObservableStore()

  constructor(props) {
    const { allCategories, allAddOnCategories, allBackdropCategories, allMiniSessions,
      eventCategories, addOnCategories, backdropCategories, miniSessions, onChange,
      allCategoriesTarget, allAddOnCategoriesTarget, allBackdropCategoriesTarget, allMiniSessionsTarget,
      eventCategoryTarget, addOnCategoryTarget, backdropCategoryTarget, packageGroupTarget,
      eventTypeTarget, addOnTarget, backdropTarget, miniSessionTarget, supportsPackages, supportsAddOns, supportsMiniSessions,
      recommendedEventTypeTarget, showCustom, showCustomTarget } = props

    super(props)

    this.state = {
      edit: !this.props.showSummary
    }

    this.store.setInitialEventCategories({ allCategories, allAddOnCategories, allBackdropCategories, allMiniSessions, eventCategories, addOnCategories, backdropCategories, miniSessions, showCustom, supportsPackages, supportsAddOns, supportsMiniSessions })

    if(onChange) {
      this.store.onChangeHandler = onChange
    }
    else {
      this.store.onChangeHandler = this.setTargetElements

      if(allCategoriesTarget) {
        this.allCategoriesTarget = document.getElementById(allCategoriesTarget)
      }

      if(allAddOnCategoriesTarget) {
        this.allAddOnCategoriesTarget = document.getElementById(allAddOnCategoriesTarget)
      }

      if(allBackdropCategoriesTarget) {
        this.allBackdropCategoriesTarget = document.getElementById(allBackdropCategoriesTarget)
      }

      if(allMiniSessionsTarget) {
        this.allMiniSessionsTarget = document.getElementById(allMiniSessionsTarget)
      }

      if(eventCategoryTarget) {
        this.eventCategoryTarget = document.getElementById(eventCategoryTarget)
      }

      if(addOnCategoryTarget) {
        this.addOnCategoryTarget = document.getElementById(addOnCategoryTarget)
      }

      if(backdropCategoryTarget) {
        this.backdropCategoryTarget = document.getElementById(backdropCategoryTarget)
      }

      if(packageGroupTarget) {
        this.packageGroupTarget = document.getElementById(packageGroupTarget)
      }

      if(eventTypeTarget) {
        this.eventTypeTarget = document.getElementById(eventTypeTarget)
      }

      if(addOnTarget) {
        this.addOnTarget = document.getElementById(addOnTarget)
      }

      if(backdropTarget) {
        this.backdropTarget = document.getElementById(backdropTarget)
      }

      if(miniSessionTarget) {
        this.miniSessionTarget = document.getElementById(miniSessionTarget)
      }

      if(recommendedEventTypeTarget) {
        this.recommendedEventTypeTarget = document.getElementById(recommendedEventTypeTarget)
        this.store.showRecommendedEventTypes = true
      }

      if(showCustomTarget) {
        this.showCustomTarget = document.getElementById(showCustomTarget)
      }
    }
  }

  setTargetElements = (change) => {
    if(this.eventCategoryTarget) {
      this.eventCategoryTarget.value = change.eventCategoryIds.join(",")
    }

    if(this.packageGroupTarget) {
      this.packageGroupTarget.value = change.packageGroupIds.join(",")
    }

    if(this.eventTypeTarget) {
      this.eventTypeTarget.value = change.eventTypeIds.join(",")
    }

    if(this.addOnCategoryTarget) {
      this.addOnCategoryTarget.value = change.addOnCategoryIds.join(",")
    }

    if(this.addOnTarget) {
      this.addOnTarget.value = change.addOnIds.join(",")
    }

    if(this.backdropCategoryTarget) {
      this.backdropCategoryTarget.value = change.backdropCategoryIds.join(",")
    }

    if(this.backdropTarget) {
      this.backdropTarget.value = change.backdropIds.join(",")
    }

    if(this.miniSessionTarget) {
      this.miniSessionTarget.value = change.miniSessionIds.join(",")
    }

    if(this.recommendedEventTypeTarget) {
      this.recommendedEventTypeTarget.value = change.recommendedEventTypeIds.join(",")
    }

    if(this.allCategoriesTarget) {
      this.allCategoriesTarget.value = change.allCategories
    }

    if(this.allAddOnCategoriesTarget) {
      this.allAddOnCategoriesTarget.value = change.allAddOnCategories
    }

    if(this.allBackdropCategoriesTarget) {
      this.allBackdropCategoriesTarget.value = change.allBackdropCategories
    }

    if(this.allMiniSessionsTarget) {
      this.allMiniSessionsTarget.value = change.allMiniSessions
    }

    if(this.showCustomTarget) {
      this.showCustomTarget.value = change.showCustom
    }
  }

  componentDidMount() {
    this.disposer = autorun(() => {
      changeHandler(this.store)
    })
  }

  componentWillUnmount() {
    this.disposer()
  }

  renderSelector() {
    const { showCheckboxes, showActiveInactiveSelector, supportsPackages, supportsAddOns, supportsBackdrops, supportsMiniSessions } = this.props
    const { showAllPackages, showCustom } = this.store
    const { activeTab } = this.store
    const showTabs = (supportsPackages || supportsAddOns || supportsBackdrops || supportsMiniSessions)

    return (
      <div>
        { showTabs &&
          <Nav
            variant="tabs"
            activeKey={activeTab}
            onSelect={k => (this.store.activeTab = k)}
          >
            { supportsPackages &&
              <Nav.Item title="Packages">
                <Nav.Link eventKey="packages">
                  Packages
                </Nav.Link>
              </Nav.Item>
            }
            { supportsAddOns &&
              <Nav.Item title="Add-ons">
                <Nav.Link eventKey="addons">
                  Add-ons
                </Nav.Link>
              </Nav.Item>
            }
            { supportsBackdrops &&
              <Nav.Item title="Backdrops">
                <Nav.Link eventKey="backdrops">
                  Backdrops
                </Nav.Link>
              </Nav.Item>
            }
            { supportsMiniSessions &&
              <Nav.Item title="Mini Sessions">
                <Nav.Link eventKey="mini_sessions" >
                  Minis
                </Nav.Link>
              </Nav.Item>
            }
          </Nav>
        }
        <div>
          { (activeTab === "packages" && supportsPackages) &&
            <PackageSelector {...this.props} store={this.store} />
          }
          { (activeTab === "addons" && supportsAddOns) &&
            <AddOnSelector {...this.props} store={this.store} />
          }
          { (activeTab === "backdrops" && supportsBackdrops) &&
            <BackdropSelector {...this.props} store={this.store} />
          }
          { (activeTab === "mini_sessions" && supportsMiniSessions) &&
            <MiniSessionSelector {...this.props} store={this.store} />
          }
        </div>
      </div>
    )
  }

  renderSummary() {
    const { supportsPackages, supportsAddOns, supportsBackdrops, supportsMiniSessions } = this.props

    return (
      <div>
        Applies To:
        <ul>
          { (supportsPackages && this.store.selectedPackageSummary) && <li> { this.store.selectedPackageSummary } </li> }
          { (supportsAddOns && this.store.selectedAddOnSummary) && <li> { this.store.selectedAddOnSummary } </li> }
          { (supportsBackdrops && this.store.selectedBackdropSummary) && <li> { this.store.selectedBackdropSummary } </li> }
          { (supportsMiniSessions && this.store.selectedMiniSessionsSummary) && <li> { this.store.selectedMiniSessionsSummary } </li> }
        </ul>
        <p>
          (<span
            style={styles.toggleAllPackages}
            onClick={() => this.setState({edit: true})}
          >Edit</span>)
        </p>
      </div>
    )
  }

  render() {
    const { edit } = this.state

    return (
      <Provider store={this.store}>
        { edit ? this.renderSelector() : this.renderSummary() }
      </Provider>
    )
  }
}

const styles = {
  toggleAllPackages: {
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}
