import React, { useState, useEffect } from 'react'
import client from '../../../helpers/client'
import ShowAllPaymentMethods from './ShowAllPaymentMethods'
import SavePaymentMethod from './SavePaymentMethod'
import CardButton from './CardButton'
import MandateConsent from './MandateConsent'
import {
  PaymentElement,
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

const StripeExpressElementsForm = ({ paymentMethod, paymentMethodOption, paymentIntentId, activePaymentMethodOption, setActivePaymentMethodOption, amountCents, amountCurrency, countryCode, chargeDescription, loading, setLoading, allowUserToSavePaymentMethod, showBackToAllPaymentMethodOptions, stripeClientSecret, authorizeOnly, enableAutoPay }) => {

  const [errorFields, setErrorFields] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [completedFields, setCompletedFields] = useState({});
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [savePaymentMethod, setSavePaymentMethod] = useState(paymentMethodOption.savePaymentMethodMode !== "none")

  const stripe = useStripe();
  const elements = useElements();

  const submissionUrl = authorizeOnly ? `/payment_intents/${paymentIntentId}/submit_stripe_express_authorization` : `/payment_intents/${paymentIntentId}/submit_stripe_express_payment`

  useEffect(() => {
    if (stripe) {
      const paymentRequest = stripe.paymentRequest({
        country: countryCode,
        currency: amountCurrency.toLowerCase(),
        // disableWallets: ["link"],
        total: {
          label: chargeDescription,
          amount: amountCents,
        },
        requestPayerName: false,
        requestPayerEmail: false,
      });
      
      paymentRequest.on('paymentmethod', handlePaymentRequestSubmission)

      // Check the availability of the Payment Request API.
      paymentRequest.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(paymentRequest);
        }
      });
    }
  }, [stripe]);
  
  const handlePaymentRequestSubmission = async (event) => {
    const paymentMethodId = event.paymentMethod.id

    // Call to our server
    try {
      const result = await client.post(submissionUrl, {
        paymentMethodOptionId: paymentMethodOption.id,
        stripePaymentMethodId: paymentMethodId,
        savePaymentMethod: false
      }, { withCredentials: true })

      event.complete('success')
      handleServerResponse(result)
    }
    catch(e) {
      console.warn(e.message)
      event.complete('fail')
      handleServerResponse(e.response)
      setLoading(false)
    }
  }


  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    console.log({ event })
    if (event.error) {
      setErrorMessage(event.error.message);
      setCompletedFields({})
    } else {
      setErrorMessage(null);
    }

    setErrorFields(prevState => ({
      ...prevState,
      [event.elementType]: !!event.error
    }))

    setCompletedFields(prevState => ({
      ...prevState,
      [event.elementType]: event.complete
    }))
  }

  // Handle form submission.
  const handleSubmit = async (event) => {
    setLoading(true)

    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      console.error('not loading yet??')
      return;
    }


    elements.submit()

    const paymentMethodResponse = await stripe.createPaymentMethod({ elements })

    if(paymentMethodResponse.error) {
      setErrorMessage(paymentMethodResponse.error.message);
      setLoading(false)
    }
    else {
      try {
        const result = await client.post(submissionUrl, {
          paymentMethodOptionId: paymentMethodOption.id,
          stripePaymentMethodId: paymentMethodResponse.paymentMethod.id,
          savePaymentMethod: (allowUserToSavePaymentMethod && savePaymentMethod)
        }, { withCredentials: true })

        handleServerResponse(result)
      }
      catch(error) {
        if(error.response) {
          setErrorMessage(error.response.data.error)
        }
        else {
          setErrorMessage(error.message)
        }

        setLoading(false)
      }
    }


  };

  const handleServerResponse = async (result) => {
    if(result.data.error) {
      setErrorMessage(result.data.error)
      setCompletedFields({})
    }
    else if(result.data.requiresAction) {
      console.log(`Redirecting to ${result.data.redirectUrl}`)
      window.location = result.data.redirectUrl
    }
    else if(result.data.success) {
      window.location.reload()
    }
    else {
      debugger
    }
  }

  // Controls whether to show Google Pay, Apple Pay, etc.
  const showPaymentRequestElement = !activePaymentMethodOption.id && paymentRequest && paymentMethodOption.kind == "card"

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        { (showPaymentRequestElement) &&
          <div className="stripe-payment-request-buttons">
            <PaymentRequestButtonElement
              options={{paymentRequest, style: { paymentRequestButton: { type: 'default', theme: 'dark', height: '56px' }}}}
            />
          </div>
        }
        { activePaymentMethodOption.id === paymentMethodOption.id ?
          <ActiveElement
            handleChange={handleChange}
            savePaymentMethod={savePaymentMethod}
            setSavePaymentMethod={setSavePaymentMethod}
            allowUserToSavePaymentMethod={allowUserToSavePaymentMethod}
            paymentMethod={paymentMethod}
            paymentMethodOption={paymentMethodOption}
            errorMessage={errorMessage}
            errorFields={errorFields}
            completedFields={completedFields}
            onGoBack={() => setActivePaymentMethodOption({id: null, special: 'null'}) }
            showBackToAllPaymentMethodOptions={showBackToAllPaymentMethodOptions}
            authorizeOnly={authorizeOnly}
            enableAutoPay={enableAutoPay}
          />
          :
          <CardButton id="pay-with-stripe-btn" onClick={() => setActivePaymentMethodOption({ id: paymentMethodOption.id, special: null }) } label={paymentMethodOption.label} paymentMethodOption={paymentMethodOption} />
        }

      </div>
    </form>
  );
}

const ActiveElement = ({ handleChange, errorFields, errorMessage, onGoBack, completedFields, savePaymentMethod, setSavePaymentMethod, allowUserToSavePaymentMethod, showBackToAllPaymentMethodOptions, paymentMethod, paymentMethodOption, authorizeOnly, enableAutoPay }) => (
  <div>
    { errorMessage && <div className="card-errors" role="alert">{errorMessage}</div> }
    <CardButton id="pay-with-stripe-btn" onClick={onGoBack} label={paymentMethodOption.label} paymentMethodOption={paymentMethodOption} />
        <PaymentElement
          id="stripe-express-payment-element"
          options={{
            layout: {
              type: 'accordion',
              // defaultCollapsed: true,
              // radios: true,
              // spacedAccordionItems: true,
            }
          }}
          onChange={handleChange}
          onReady={() => console.log('ready')}
          onFocus={() => console.log('focus')}
          onBlur={() => console.log('blur')}
          onComplete={() => console.log('complete')}
          onLoadError={event => console.log('load error', event)}
          onLoaderStart={() => console.log('loader start')}
        />

    { allowUserToSavePaymentMethod &&
      <SavePaymentMethod
        savePaymentMethod={savePaymentMethod}
        setSavePaymentMethod={setSavePaymentMethod}
        savePaymentMethodMode={paymentMethodOption.savePaymentMethodMode}
        authorizeOnly={authorizeOnly}
        enableAutoPay={enableAutoPay}
      />
    }
    <MandateConsent
      paymentMethod={paymentMethod}
      paymentMethodOption={paymentMethodOption}
      expanded={allowUserToSavePaymentMethod && savePaymentMethod}
    />
    <button type="submit" className="submit-payment-btn">Submit Payment</button>

    <ShowAllPaymentMethods onClick={onGoBack} showBackToAllPaymentMethodOptions={showBackToAllPaymentMethodOptions} />
  </div>
)

export default StripeExpressElementsForm
