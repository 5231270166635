import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

const ErrorBlock = ({ error }) => {
  if(!error) {
    return null
  }

  const errorMessage = Array.isArray(error) ? error.join(", ") : error

  return (
    <Form.Control.Feedback type="invalid" style={{display: "block"}}>{ errorMessage }</Form.Control.Feedback>
  )
}

export default ErrorBlock
