import React from 'react'
import PropTypes from 'prop-types'
import {observer} from "mobx-react"
import SimpleFormat from '../../ui/SimpleFormat'
import ImagePreview from './ImagePreview'

import store from '../stores/store.js'

@observer
export default class StaticImage extends React.Component {
  render() {
    const { imageUrl, imageLink, imageWidth, imageHeight } = this.props.field

    return (
      <ImagePreview
        imageLink={imageLink}
        width={imageWidth}
        height={imageHeight}
        url={imageUrl}
      />
    )
  }
}
