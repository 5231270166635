import React from 'react'
import PropTypes from 'prop-types'
import {observer} from "mobx-react"
import store from '../stores/store.js'
import FaIcon from '../../../helpers/fa_icon.js'

@observer
export default class RemoveFieldInstance extends React.Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    instance: PropTypes.number.isRequired
  }
  
  render() {
    return (
      <div className="controls" style={{paddingTop: 20}}>
        <span className="control">
          <a style={{cursor: "pointer"}} onClick={() => store.removeInstance(this.props.field.uuid, this.props.instance)}>
            <FaIcon icon="times" />
          </a>
        </span>
      </div>
    )
  }
}
