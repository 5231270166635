import React from 'react';
import PropTypes from 'prop-types'

export default class EventPreview extends React.Component {
  render() {
    const { data } = this.props
    const { title, status, formattedStatus, startsAtDate, startsAtTime, previewImages } = data.attributes

    return (
      <div
        className="event-preview"
        style={styles.containerStyle}
      >
        <div className="event-status no-print">
          { previewImages.map((previewImage, index) => 
            <div key={index} className="img-wrapper">
              <img src={previewImage.eventTypeVariant} alt={title} />
            </div>
          )}
        </div>
        <div className="event-details">
          <div className="name">{ title }</div>
          <div className="datetime">{ startsAtDate } { startsAtTime }</div>
          <div className={`status ${status}`}>{ formattedStatus }</div>
        </div>
      </div>
    )
  }
}

const styles = {
  containerStyle: {
  }
}
