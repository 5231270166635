import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'react-bootstrap'

export default class SlotPicker extends React.Component {
  static propTypes = {
    miniSessionSlots: PropTypes.array.isRequired,
    miniSessionSlotId: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
  }

  renderOption(slot) {
    const { originalMiniSessionSlotId } = this.props
    let slotName = slot.formattedSlotName
    if(originalMiniSessionSlotId == slot.id) {
      slotName = `${slotName} (Current)`
    }
    else if(!slot.available) {
      slotName = `${slotName} (Booked)`
    }

    const available = slot.available || (originalMiniSessionSlotId == slot.id)

    return <option
      key={slot.id}
      value={slot.id}
      disabled={!available}
    >{ slotName }</option>
  }

  render() {
    const {
      miniSessionSlots,
      miniSessionId,
      miniSessionSlotId,
      originalMiniSessionSlotId,
      onChange
    } = this.props
    const currentSlots = miniSessionSlots.filter(slot => slot.miniSessionId == miniSessionId)

    return (
      <div className="slot-picker">
        <FormGroup>
          <Form.Label>Slot</Form.Label>
          <Form.Select
            value={miniSessionSlotId}
            onChange={e => onChange(parseInt(e.target.value))}
          >
            { currentSlots.map(slot => this.renderOption(slot)) }
          </Form.Select>
        </FormGroup>
      </div>
    )
  }
}
