import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("store")
@observer
export default class BrandSelector extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  render() {
    const { store } = this.props
    const { showBrands, brands } = store

    if(!showBrands) return null

    return (
      <select
        onChange={e => (store.brandId = e.target.value)}
        value={store.brandId}
        className="brand-selector"
      >
        <option value="">All Brands</option>
        { brands.map(brand =>
          <option key={brand.id} value={brand.id} >{ brand.name }</option>
        )}
      </select>
    )
  }
}

