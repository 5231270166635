import React from 'react'

function getFileNameFromUrl(url) {
  try {
    const parsedUrl = new URL(url, window.location.href)
    const path = parsedUrl.pathname
    return decodeURIComponent(path.substring(path.lastIndexOf('/') + 1))
  }
  catch(e) {
    return null
  }
}

export default class FilePreview extends React.Component {
  render() {
    const { url } = this.props

    if(!url) {
      return null
    }

    const filename = getFileNameFromUrl(url)

    return (
      <div style={{marginBottom: 10, marginTop: 10 }}>
        { filename &&
          <span style={{marginRight: 10 }}>
            <a href={url}>{ filename }</a>
          </span>
        }
        <a href={url} className="btn btn-primary">
          <i className="fa fa-download" />
          { " " }
          Download File
        </a>
      </div>
    )
  }
}
