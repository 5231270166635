import React, { useState } from 'react'
import PropTypes from 'prop-types'
import groupPaymentMethodOptionsByFee from './util/groupPaymentMethodOptionsByFee'
import calculateInitialPaymentMethodOptionState from './util/calculateInitialPaymentMethodOptionState'
import PaymentPane from './components/PaymentPane'
import SavedPaymentMethodProvider from './components/SavedPaymentMethodProvider'
import SavedPaymentMethodManager from './components/SavedPaymentMethodManager'
import AutoPayCompatibleDisclaimer from './components/AutoPayCompatibleDisclaimer'
import StripeManager from './components/StripeManager'
import StripeExpressManager from './components/StripeExpressManager'
import PaypalManager from './components/PaypalManager'
import SquareManager from './components/SquareManager'
import CustomForm from './components/CustomForm'
import Loader from './components/Loader'
import './style.scss';

const components = {
  "card-square": SquareManager,
  "card-stripe": StripeManager,
  "card-stripe-express": StripeExpressManager,
  "paypal": PaypalManager,
  "custom": CustomForm
}

const PaymentForm = (props) => {
  const { enableAutoPay, paymentMethods, paymentMethodOptions, showSavedPaymentMethods, amountFormatted, userId, authorizeOnly } = props

  const applicablePaymentMethodOptions = paymentMethodOptions
    .filter(paymentMethodOption => (enableAutoPay || authorizeOnly) ? paymentMethodOption.supportsAutoPay : true)

  const [loading, setLoading] = useState(false)
  const [activePaymentMethodOption, setActivePaymentMethodOption] = useState(calculateInitialPaymentMethodOptionState(applicablePaymentMethodOptions))
  console.log({ props, applicablePaymentMethodOptions })

  const activePaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.id === activePaymentMethodOption.paymentMethodId)
  const paymentMethodOptionsByFee = groupPaymentMethodOptionsByFee(applicablePaymentMethodOptions, amountFormatted)
  const showAnyPaymentMethodFees = paymentMethodOptionsByFee.filter(group => group.feeDescription).length > 0

  function renderPaymentMethod(paymentMethodOption) {
    const Component = components[paymentMethodOption.component]
    const paymentMethod = paymentMethods.find(paymentMethod => paymentMethod.id === paymentMethodOption.paymentMethodId)

    if(!Component) {
      return null
    }

    return (
      <>
        <SavedPaymentMethodManager
          {...props}
          loading={loading}
          setLoading={setLoading}
          paymentMethodOption={paymentMethodOption}
          activePaymentMethodOption={activePaymentMethodOption}
          setActivePaymentMethodOption={setActivePaymentMethodOption}
          showSavedPaymentMethods={showSavedPaymentMethods}
        />
        <Component
          {...props}
          amountCents={paymentMethodOption.totalWithFeesCents}
          amountCurrency={paymentMethodOption.totalWithFeesCurrency}
          amountFormatted={paymentMethodOption.totalWithFeesFormatted}
          authorizeOnly={authorizeOnly}
          enableAutoPay={enableAutoPay}
          paymentMethod={paymentMethod}
          paymentMethodOption={paymentMethodOption}
          loading={loading}
          setLoading={setLoading}
          show={!activePaymentMethodOption.id || (activePaymentMethodOption.id === paymentMethodOption.id)}
          activePaymentMethodOption={activePaymentMethodOption}
          setActivePaymentMethodOption={setActivePaymentMethodOption}
          showBackToAllPaymentMethodOptions={paymentMethodOption.id}
        />
      </>
    )
  }

  function shouldShowPane(group) {
    // If nothing is selected, we show all panes
    if(!activePaymentMethodOption.id) {
      return true
    }
    // If a payment method in the current pane is selected, we show the pane
    else if(group.paymentMethodOptions.find(paymentMethod => paymentMethod.id === activePaymentMethodOption.id)) {
      return true
    }
    // Something in a different pane is selected.  Hide this pane
    else {
      return false
    }
  }

  return (
    <div className={`checkcherry-payment-widget ${activePaymentMethodOption.id}-active`}>
      <Loader loading={loading}>
        <SavedPaymentMethodProvider userId={userId}>
          { paymentMethodOptionsByFee.map(group => 
          <PaymentPane
            key={group.feeDescription}
            authorizeOnly={authorizeOnly}
            showFees={showAnyPaymentMethodFees}
            group={group}
            show={shouldShowPane(group)}
          >
            { group.paymentMethodOptions.map(paymentMethodOption => 
              <div key={paymentMethodOption.id}>
                { renderPaymentMethod(paymentMethodOption) }
              </div>
            )}
          </PaymentPane>
          )}
          <AutoPayCompatibleDisclaimer
            paymentMethodOptions={paymentMethodOptions}
            applicablePaymentMethodOptions={applicablePaymentMethodOptions}
          />
        </SavedPaymentMethodProvider>
      </Loader>
    </div>
  )
}

PaymentForm.defaultProps = {
  allowPayLater: true
}

export default PaymentForm
