class UnitTranslator {
  constructor(translations) {
    this.translations = translations
  }

  get options() {
    return this.translations
  }

  labelFor(value, key) {
    return this.translations.find(t => t.unit == value)[key]
  }
}

export default UnitTranslator
