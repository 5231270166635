import React from 'react'
import PropTypes from 'prop-types'
import SessionPicker from './SessionPicker'
import SlotPicker from './SlotPicker'

function sessionIdForSlotId(slotId, miniSessionSlots) {
  const slot = miniSessionSlots.find(s => s.id == slotId)
  if(slot) {
    return slot.miniSessionId
  }
  else {
    return null
  }
}

class MiniSessionSlotPicker extends React.Component {
  static propTypes = {
    miniSessions: PropTypes.array.isRequired,
    miniSessionSlots: PropTypes.array.isRequired,
    miniSessionSlotId: PropTypes.number.isRequired,
    target: PropTypes.string
  }

  state = {
    miniSessionId: sessionIdForSlotId(this.props.miniSessionSlotId, this.props.miniSessionSlots),
    miniSessionSlotId: this.props.miniSessionSlotId
  }

  handleMiniSessionSelected = (miniSessionId) => {
    const { miniSessionSlots } = this.props

    if(miniSessionId != this.state.miniSessionId) {
      const miniSessionSlot = miniSessionSlots
        .filter(session => session.available || session.id == this.props.miniSessionSlotId)
        .find(session => session.miniSessionId == miniSessionId)

      if(miniSessionSlot) {
        this.setState({ miniSessionId, miniSessionSlotId: miniSessionSlot.id })
        this.updateTargetElement(miniSessionSlot.id)
      }
    }
  }

  handleMiniSessionSlotSelected = (miniSessionSlotId) => {
    this.setState({ miniSessionSlotId })
    this.updateTargetElement(miniSessionSlotId)
  }

  updateTargetElement = (miniSessionSlotId) => {
    const { target } = this.props

    if(target) {
      const elem = document.querySelector(target)
      elem.value = miniSessionSlotId
    }
  }

  render() {
    const { miniSessions, miniSessionSlots } = this.props
    const { miniSessionId } = this.state

    return (
      <div className="row">
        <div className="col-sm-6">
          <SessionPicker
            miniSessions={miniSessions}
            miniSessionId={miniSessionId}
            onChange={this.handleMiniSessionSelected}
            />
        </div>
        <div className="col-sm-6">
          <SlotPicker
            miniSessionSlots={miniSessionSlots}
            miniSessionId={miniSessionId}
            miniSessionSlotId={this.state.miniSessionSlotId}
            originalMiniSessionSlotId={this.props.miniSessionSlotId}
            onChange={this.handleMiniSessionSlotSelected}
          />
        </div>
      </div>
    )
  }
}

export default MiniSessionSlotPicker
