import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Form, FormGroup, FormControl } from 'react-bootstrap'

const IntervalControl = ({ store }) => {
  const { interval } = store

  return (
    <div className="mb-2">
      <FormGroup>
        <div className="group-by-control">
          <Form.Label>Group By</Form.Label>
          <Form.Select
            name="group-by"
            value={interval}
            onChange={e => (store.interval = e.target.value)}
            size="sm"
          >
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
            <option value="quarter">Quarter</option>
            <option value="year">Year</option>
          </Form.Select>
        </div>
      </FormGroup>
    </div>
  )
}

export default inject("store")(observer(IntervalControl))
