function extractErrors(e) {
  if(e.response && e.response.data && e.response.data.errors) {
    return e.response.data.errors
  }

  return {
    base: [e.message]
  }
}

export default extractErrors
