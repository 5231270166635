const CATEGORIES_ALL = "All Categories"
const CATEGORIES_ACTIVE = "Categories with backdrops only"

const BACKDROPS_ACTIVE = "Active backdrops only"
const BACKDROPS_ARCHIVED = "Archived backdrops only"
const BACKDROPS_ALL = "All backdrops"

const showCategoriesOptions = [
  CATEGORIES_ACTIVE,
  CATEGORIES_ALL
]

const showBackdropsOptions = [
  BACKDROPS_ACTIVE,
  BACKDROPS_ARCHIVED,
  BACKDROPS_ALL
]

export {
  CATEGORIES_ALL,
  CATEGORIES_ACTIVE,
  BACKDROPS_ACTIVE,
  BACKDROPS_ARCHIVED,
  BACKDROPS_ALL,
  showCategoriesOptions,
  showBackdropsOptions
}
