import React from 'react'
import DebouncedInput from '../../ui/DebouncedInput'

const SearchBar = ({ value, onChange }) => (
  <div className="row">
    <div className="col-sm-3 offset-sm-9">
      <div className="form-group string optional">
        <DebouncedInput
          type="search"
          placeholder="Search Backdrops"
          className="form-control input-sm string optional"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  </div>
)

export default SearchBar
