import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import TreeView from 'react-treeview'
import store from '../stores/store.js'

@inject("store")
@observer
export default class MiniSession extends Component {
  static propTypes = {
    miniSession: PropTypes.object.isRequired,
    onClickMiniSession: PropTypes.func,
    showCheckboxes: PropTypes.bool
  }

  handleSelection = (id) => {
    const { store } = this.props
    store.toggleMiniSessionSelection(id)
  }

  renderName() {
    const { miniSession, onClickMiniSession } = this.props

    if(onClickMiniSession) {
      return (
        <span
          onClick={() => onClickMiniSession({miniSession})}
          style={{...styles.active, ...styles.clickableMiniSession}}
        >{ miniSession.name }</span>
      )
    }
    else {
      return <span style={styles.active}>{ miniSession.name }</span>
    }
  }

  render() {
    const { miniSession, showCheckboxes } = this.props
    const { id, selected, name } = miniSession

    if(showCheckboxes) {
      return (
        <Form.Check
          type="checkbox"
          onChange={() => this.handleSelection(id)}
          checked={selected}
          style={styles.checkbox}
          label={this.renderName()}
          id={`miniSession-${id}`}
        />
      )
    }
    else {
      return (
        <div>
          { this.renderName() }
        </div>
      )
    }
  }
}

const styles = {
  checkbox: {
    display: "block",
    marginTop: 0,
    marginBottom: 5
  },
  clickableMiniSession: {
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}
