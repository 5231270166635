import React from 'react';
import { toJS } from 'mobx';
import FaIcon from '../../../helpers/fa_icon.js'
import TimelineLengthField from './TimelineLengthField.js'
import TimelineStartTimeField from './TimelineStartTimeField.js'
import TimelineTimeRangeField from './TimelineTimeRangeField.js'

const TimelineFieldForm = ({ field, timelineMode }) => {
  if(!timelineMode) return null

  switch(timelineMode) {
    case 'length':
      return <TimelineLengthField field={field} />
    case 'start_time':
      return <TimelineStartTimeField field={field} />
    case 'start_and_end_time':
      return <TimelineTimeRangeField field={field} />
    default:
      throw new Error(`Unknown timeline mode: ${timelineMode}`)
  }
}

const TimelineWrapper = ({ field, children }) => {
  const { timelineEnabled, timelineSettings } = field

  if(!timelineEnabled || !timelineSettings) {
    return children
  }

  const { timelineMode } = timelineSettings

  return (
    <div className="timeline-wrapper">
      {children}

      <div className="timeline-editor">
        <TimelineFieldForm field={field} timelineMode={timelineMode} />
      </div>
    </div>
  )
}

export default TimelineWrapper;
