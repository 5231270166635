import React from 'react'

const NewBackdrop = ({ category }) => (
  <div className="categorized-item">
    <div className="add-item-title">
      <a href={`/admin/photobooth_backgrounds/new?backdrop_category_id=${category.id}`}>
        New Backdrop
      </a>
    </div>

    <div className="add-item-icon">
      <a href={`/admin/photobooth_backgrounds/new?backdrop_category_id=${category.id}`}>
        <i className="fa fa-plus" />
      </a>
    </div>
  </div>
)

export default NewBackdrop
