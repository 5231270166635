import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Modal, Row, Col, FormGroup, FormControl } from 'react-bootstrap'
import ColorPicker from '../ColorPicker'
import EventTypeSelector from '../EventTypeSelector'
import CopyableTextBlock from '../CopyableTextBlock'
import stringifyProps from './stringifyProps'

export default class AvailabilityCalendarWidget extends Component {
  state = {
    showModal: false,
    style: "embed",
    showBookNowButton: true,
    showCustomBookingUrl: false,
    customBookingUrl: "",
    bookNowButtonText: "Book Now",
    availableMessage: "Congratulations, we are currently available for this day and package.  Just click 'Book Now' to reserve your date now.",
    unavailableMessage: "Sorry, but we are not able to take automated bookings for this date or package.  Please contact us directly to check for available options.",
    showAdvanced: false,
    theme: "normal",
    activeEventType: null,
    activePackageGroup: null,
    activeEventCategory: null,
    iframe: false
  }

  static propTypes = {
    type: PropTypes.string.isRequired,
    host: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired,
    eventCategories: PropTypes.array.isRequired
  }

  showModal = () => {
    this.setState({showModal: true})
  }

  hideModal = () => {
    this.setState({showModal: false})
  }

  showAdvanced = (event) => {
    event.preventDefault()
    this.setState({ showAdvanced: true })
  }

  handlePackageSelected = ({eventCategory, packageGroup, eventType}) => {
    if(eventCategory) {
      this.setState({
        activeEventCategory: eventCategory,
        activePackageGroup: null,
        activeEventType: null
      })
    }
    else if(packageGroup) {
      this.setState({
        activeEventCategory: null,
        activePackageGroup: packageGroup,
        activeEventType: null
      })
    }
    else if(eventType) {
      this.setState({
        activeEventCategory: null,
        activePackageGroup: null,
        activeEventType: eventType
      })
    }
  }

  get html() {
    const {
      style,
      showBookNowButton,
      bookNowButtonText,
      showCustomBookingUrl,
      customBookingUrl,
      availableMessage,
      unavailableMessage,
      activeEventCategory,
      activePackageGroup,
      activeEventType,
      theme,
      showAdvanced,
      iframe
    } = this.state
    const { host, apiKey } = this.props

    const baseProps = { apiKey, iframe, host: `https://${host}` }
    let dataProps = { showBookNowButton, bookNowButtonText, showCustomBookingUrl, customBookingUrl, availableMessage, unavailableMessage, theme }

    if(showAdvanced) {
      if(activeEventType) {
        dataProps.eventTypeId = activeEventType.id
        dataProps.eventTypeToken = activeEventType.token
      }
      else if(activePackageGroup) {
        dataProps.packageGroupId = activePackageGroup.id
        dataProps.packageGroupToken = activePackageGroup.token
      }
      else if(activeEventCategory) {
        dataProps.eventCategoryId = activeEventCategory.id
        dataProps.eventCategoryToken = activeEventCategory.token
      }
    }

    const combinedProps = {...baseProps, ...dataProps}

    if(style === "basicLink") {
      let url = `https://${host}/availability_calendar`
      if(Object.keys(dataProps).length > 0) {
        url = `${url}?props=${encodeURIComponent(stringifyProps(dataProps))}`
      }
      return url
    }
    else if(style === "embed" && !iframe) {
      return `<script src="https://${host}/api/checkcherry_widgets" type="text/javascript" charset="utf-8"></script><div class="checkcherry__widget__availability-calendar" data-props='${stringifyProps(combinedProps)}'></div>`
    }
    else if(style === "embed" && iframe) {
      return `<iframe class="checkcherry-autoresize-frame" src="https://${host}/availability_calendar?iframe=true&props=${encodeURIComponent(stringifyProps(dataProps))}" style="margin: 0; padding: 0; border: none; width: 315px; height: 320px" scrolling="auto" allowtransparency="true"></iframe><script src="https://${host}/api/checkcherry_widgets/iframe" type="text/javascript" charset="utf-8"></script>`
    }
    else {
      return ""
    }
  }

  render() {
    const { type, eventCategories } = this.props
    const { showModal, style, showBookNowButton, showCustomBookingUrl, customBookingUrl, bookNowButtonText, availableMessage, unavailableMessage, showAdvanced, theme, iframe } = this.state

    return (
      <div>
        <div className="btn-group">
          <Button onClick={this.showModal} variant="primary">
            <i className="fa fa-code" />
            { " " }
            Get Embed Code
          </Button>
        </div>
        <Modal show={showModal} onHide={this.hideModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Availability Calendar Widget</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={5}>
                <h3>Customize</h3>
                <FormGroup className="mb-2">
                  <Form.Label>Choose Style</Form.Label>
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "basicLink"}
                    onChange={() => this.setState({style: "basicLink"})}
                    label="Link Only"
                    id="availability-calendar-style-link"
                  />
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "embed"}
                    onChange={() => this.setState({style: "embed"})}
                    label="Embed on my site"
                    id="availability-calendar-style-embed"
                  />
                </FormGroup>
                <FormGroup className="mb-2">
                  <Form.Label>Message to display if the date is available</Form.Label>
                  <FormControl
                    as="textarea"
                    rows={5}
                    value={availableMessage}
                    onChange={(e) => this.setState({availableMessage: e.target.value})}
                  />
                </FormGroup>
                <FormGroup className="mb-2">
                  <Form.Label>Message to display if the date is not available</Form.Label>
                  <FormControl
                    as="textarea"
                    rows={5}
                    value={unavailableMessage}
                    onChange={(e) => this.setState({unavailableMessage: e.target.value})}
                  />
                </FormGroup>
                { style === "embed" &&
                  <FormGroup className="mb-2">
                    <Form.Label>Enable Compatibility Mode?</Form.Label>
                    <Form.Check
                      type="radio"
                      name="iframe"
                      checked={iframe === true}
                      onChange={() => this.setState({iframe: true})}
                      label="Yes"
                      id="availability-calendar-iframe-yes"
                    />
                    <Form.Check
                      type="radio"
                      name="iframe"
                      checked={iframe === false}
                      onChange={() => this.setState({iframe: false})}
                      label="No"
                      id="availability-calendar-iframe-no"
                    />
                    <Form.Text muted>Compatibility mode embeds widget within an iFrame for greater compatibility with some website builders</Form.Text>
                  </FormGroup>
                }
                { !showAdvanced &&
                  <div>
                    <a href="#" onClick={this.showAdvanced}>
                      Show Advanced Options
                    </a>
                  </div>
                }
                { showAdvanced &&
                  <div>
                    <h3>Advanced</h3>
                    <FormGroup className="mb-2">
                      <Form.Label>Show "Book Now" button if date is available?</Form.Label>
                      <Form.Check
                        type="radio"
                        name="showBookNowButton"
                        checked={(showBookNowButton === true && !showCustomBookingUrl)}
                        onChange={() => this.setState({showBookNowButton: true, showCustomBookingUrl: false})}
                        label="Yes"
                        id="availability-calendar-show-book-now-button-yes"
                      />
                      <Form.Check
                        type="radio"
                        name="showBookNowButton"
                        checked={showBookNowButton === true && showCustomBookingUrl}
                        onChange={() => this.setState({showBookNowButton: true, showCustomBookingUrl: true})}
                        label="Yes, but redirect to a custom URL"
                        id="availability-calendar-show-book-now-button-yes-custom-url"
                      />
                      <Form.Check
                        type="radio"
                        name="showBookNowButton"
                        checked={showBookNowButton === false}
                        onChange={() => this.setState({showBookNowButton: false, showCustomBookingUrl: false})}
                        label="No"
                        id="availability-calendar-show-book-now-button-no"
                      />
                    </FormGroup>
                    { showCustomBookingUrl &&
                    <FormGroup className="mb-2">
                      <Form.Label>Custom Booking URL</Form.Label>
                      <FormControl
                        name="customBookingUrl"
                        value={customBookingUrl}
                        onChange={(e) => this.setState({customBookingUrl: e.target.value})}
                      />
                    </FormGroup>
                    }
                    { showBookNowButton &&
                    <FormGroup className="mb-2">
                      <Form.Label>Button Text</Form.Label>
                      <FormControl
                        name="bookNowButtonText"
                        value={bookNowButtonText}
                        onChange={(e) => this.setState({bookNowButtonText: e.target.value})}
                      />
                    </FormGroup>
                    }
                    <FormGroup className="mb-2">
                      <Form.Label>Packages to Display</Form.Label>
                      <EventTypeSelector
                        eventCategories={eventCategories}
                        showCheckboxes={false}
                        onClickPackage={this.handlePackageSelected}
                        showActiveInactiveSelector={true}
                        showSummary={false}
                        allowSelectAll={false}
                      />
                    </FormGroup>
                  </div>
                }
              </Col>
              <Col sm={{span: 5, offset: 2}}>
                <h3>HTML</h3>

                <p>Copy and paste the HTML below into your website to add your contact form</p>

                <FormGroup className="mb-2">
                  <CopyableTextBlock value={this.html} />
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const styles = {
  previewStyle: {
    wordWrap: 'break-word'
  },
  embedStyle: {
    height: 300
  }
}
