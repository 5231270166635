import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import TreeView from 'react-treeview'
import EventType from './EventType.js'
import store from '../stores/store.js'

@inject("store")
@observer
export default class PackageGroup extends React.Component {
  static propTypes = {
    packageGroup: PropTypes.object.isRequired,
    onClickPackage: PropTypes.func,
    showCheckboxes: PropTypes.bool
  }

  handleSelection = (id) => {
    const { store } = this.props
    store.togglePackageGroupSelection(id)
  }

  handleTreeClick = (id) => {
    const { store } = this.props
    store.togglePackageGroupCollapsed(id)
  }

  renderPackageGroupName(packageGroup) {
    const { onClickPackage } = this.props

    if(onClickPackage) {
      return (
        <span
          onClick={() => onClickPackage({packageGroup})}
          style={{...styles.package, ...styles.clickablePackageGroup}}
        >{ packageGroup.name }</span>
      )
    }
    else {
      return <span style={styles.package}>{ packageGroup.name }</span>
    }
  }

  renderLabel() {
    const { packageGroup, showCheckboxes } = this.props

    if(showCheckboxes) {
      return (
        <Form.Check
          type="checkbox"
          style={styles.checkbox}
          id={`packageGroup-${packageGroup.id}`}
          >
          <Form.Check.Input
            checked={!!packageGroup.selected}
            onChange={() => this.handleSelection(packageGroup.id)}
          />
          <Form.Check.Label>
            { this.renderPackageGroupName(packageGroup) }
          </Form.Check.Label>
        </Form.Check>
      )
    }
    else {
      return this.renderPackageGroupName(packageGroup)
    }
  }
  
  render() {
    const { packageGroup, showCheckboxes, onClickPackage } = this.props
    const label = this.renderLabel()

    return (
      <TreeView
        collapsed={packageGroup.collapsed}
        nodeLabel={label}
        onClick={() => this.handleTreeClick(packageGroup.id)}
        >
        <div style={styles.eventTypeWrapper}>
          {packageGroup.eventTypes.map(eventType =>
            <EventType
              key={eventType.id}
              eventType={eventType}
              showCheckboxes={showCheckboxes}
              onClickPackage={onClickPackage}
            />
          )}
        </div>
      </TreeView>
    )
  }
}

const styles = {
  eventTypeWrapper: {
    marginLeft: 20
  },
  checkbox: {
    display: "inline-block",
    marginTop: 0,
    marginBottom: 5
  },
  clickablePackageGroup: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  package: {},
}
