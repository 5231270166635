import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import AddOnCategory from './AddOnCategory'
import TreeView from 'react-treeview'


@inject("store")
@observer
export default class AddOnSelector extends Component {
  renderAllAddOnsCheckbox() {
    const { store } = this.props
    return (
      <Form.Check
        onChange={() => store.toggleAllAddOns()}
        checked={store.allAddOnCategories || false}
        style={styles.checkbox}
        label="All Add-ons"
        id="allAddOns"
      />
    )
  }

  render() {
    const { allowSelectAll, showCheckboxes, onClickAddOn, store } = this.props
    const { viewableAddOnCategories } = store

    if(allowSelectAll) {
      return (
        <TreeView
          onClick={() => store.toggleAllAddOnsCollapsed() }
          collapsed={store.allAddOnCategoriesCollapsed}
          nodeLabel={this.renderAllAddOnsCheckbox()}
        >
          <div style={styles.offset}>
            {viewableAddOnCategories.map((addOnCategory, i) =>
              <AddOnCategory
                key={addOnCategory.id}
                addOnCategory={addOnCategory}
                onClickAddOn={onClickAddOn}
                showCheckboxes={showCheckboxes}
              />
            )}
          </div>
        </TreeView>
      )
    }
    else {
      return (
        <div style={styles.offset}>
          {viewableAddOnCategories.map((addOnCategory, i) =>
          <AddOnCategory
            key={addOnCategory.id}
            addOnCategory={addOnCategory}
            onClickAddOn={onClickAddOn}
            showCheckboxes={showCheckboxes}
          />
          )}
        </div>
      )
    }
  }
}

const styles = {
  checkbox: {
    display: "inline-block",
    marginTop: 0,
    marginBottom: 5
  }
}
