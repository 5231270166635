import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import moment from 'moment'

@inject("store")
@observer
export default class DateDropdowns extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    const currentYear = new Date().getFullYear()

    this.years = []
    for(let i = currentYear - 10; i <= currentYear + 10; i++) {
      this.years.push(i)
    }

    this.months = moment.months()

    this.days = []
    for(let i = 1; i <= 31; i++) {
      this.days.push(i)
    }
  }

  handlePrevious = (e) => {
    const { store } = this.props
    e.preventDefault()
    store.previous()
  }

  handleNext = (e) => {
    const { store } = this.props
    e.preventDefault()
    store.next()
  }

  render() {
    const { store } = this.props
    const { view } = store

    return (
      <div className="date-dropdowns">
        <a
          href="#previous-month"
          onClick={this.handlePrevious}
          className="arrow"
        >
          <i className="fa fa-arrow-circle-left" />
        </a>

        { view === "day" &&
          <select
            onChange={e => (store.day = e.target.value)}
            value={store.day}
            className="dropdown day-dropdown"
          >
            { store.daysInMonth.map(day =>
              <option
                key={day}
                value={day}
              >{ day }</option>
            )}
          </select>
        }


        { (view === "month" || view === "day") &&
          <select
            onChange={e => (store.monthZeroIndexed = e.target.value)}
            value={store.monthZeroIndexed}
            className="dropdown month-dropdown"
          >
            { this.months.map((month, index) =>
              <option
                key={index}
                value={index}
              >{ month }</option>
            )}
          </select>
        }


        { view === "week" &&
          <select
            onChange={e => (store.week = e.target.value)}
            value={store.week}
            className="dropdown day-dropdown"
          >
            { store.weeksInYear.map(week =>
              <option
                key={week.value}
                value={week.value}
              >{ week.label }</option>
            )}
          </select>
        }

        <select
          onChange={e => (store.year = e.target.value)}
          value={store.year}
          className="dropdown year-dropdown"
        >
          { this.years.map(year =>
            <option
              key={year}
              value={year}
            >{ year }</option>
          )}
        </select>

        <a
          href="#next-month"
          onClick={this.handleNext}
          className="arrow"
        >
          <i className="fa fa-arrow-circle-right" />
        </a>
      </div>
    )
  }
}

