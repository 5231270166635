import React from 'react'
import PropTypes from 'prop-types'
import Currency from '../../../../helpers/currency.js'

export default class TooltipRow extends React.Component {
  static propTypes = {
    currency: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
  }

  render() {
    const { data, currency } = this.props
    const { name, value, fill } = data

    return (
      <div style={{color: fill}}>
        <span>{ name }: { Currency.formatted(value, currency) }</span>
      </div>
    )
  }
}
