function sortByFullName(staffMembers) {
  return staffMembers.sort((a, b) => {
    if (a.fullName < b.fullName) {
      return -1
    }
    if (a.fullName > b.fullName) {
      return 1
    }
    return 0
  })
}

function sortByAvailability(staffMembers) {
  return staffMembers.sort((a, b) => {
    if (a.isAvailable && !b.isAvailable) {
      return -1
    }
    if (!a.isAvailable && b.isAvailable) {
      return 1
    }
    return 0
  })
}

function sortByPartialAvailability(staffMembers) {
  return staffMembers.sort((a, b) => {
    if (a.isPartiallyAvailable && !b.isPartiallyAvailable) {
      return -1
    }
    if (!a.isPartiallyAvailable && b.isPartiallyAvailable) {
      return 1
    }
    return 0
  })
}


function sortStaff(staffMembers) {
  return sortByAvailability(sortByPartialAvailability(sortByFullName(staffMembers)))
}

export default sortStaff
