import React from 'react'
import PropTypes from 'prop-types'
import { Provider, observer } from 'mobx-react'
import { createPersistedStore } from './stores/Store.js'
import ChartTypeSelector from './components/ChartTypeSelector'
import BrandSelector from './components/BrandSelector'
import IntervalControl from './components/IntervalControl'
import DateControl from './components/DateControl'
import ChartView from './components/ChartView'
import ChartTitle from './components/ChartTitle'
import Toolbar from './components/Toolbar'
import './style.scss'

@observer
export default class ProposalComparisonChart extends React.Component {
  static propTypes = {
    apiKey: PropTypes.string.isRequired,
    currency: PropTypes.object.isRequired,
    dateFormat: PropTypes.string.isRequired,
    showBrands: PropTypes.bool.isRequired,
    brands: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired,
  }

  store = createPersistedStore(this.props.persistenceKey, {
    apiKey: this.props.apiKey,
    staffId: this.props.staffId,
    dateFormat: this.props.dateFormat,
    showBrands: this.props.showBrands,
    brands: this.props.brands
  })

  render() {
    const { currency, dateFormat } = this.props
    const { loading, ready } = this.store

    return (
      <Provider store={this.store} dateFormat={dateFormat} currency={currency}>
        <div className={`dashboard-chart-container ${loading ? 'loading' : ''}`}>
          <Toolbar {...this.props} />
          <div className="chart-body">
            { <ChartTitle /> }
            { ready && <ChartView /> }
          </div>
        </div>
      </Provider>
    )
  }
}

