import React from 'react'
import PropTypes from 'prop-types'
import {observer} from "mobx-react"
import { Card, Button } from 'react-bootstrap'
import store from '../stores/store.js'

const confirmationMessage = "Are you sure you are done with this questionnaire and wish to submit it?  Once you submit your questionnaire you will not be able to make further edits."
const unlockableConfirmationMessage = "Are you sure you are done with this questionnaire and wish to submit it?"
const incompleteMessage = "Please make sure all required fields are complete before finalizing your questionnaire"

const LockForm = ({ onHandleLockForm, infoMessage }) => (
  <Card>
    <Card.Header>
      <Card.Title>Done making changes?</Card.Title>
    </Card.Header>
    <Card.Body>
      <p>{ infoMessage }</p>
      <div className="d-grid mt-2">
        <Button variant="warning" size="large" onClick={onHandleLockForm}>Finalize &amp; Lock</Button>
      </div>
    </Card.Body>
  </Card>
)

const UnlockForm = ({ onHandleUnlockForm, infoMessage }) => (
  <Card>
    <Card.Header>
      <h3 className="panel-title">Unlock Questionnaire</h3>
  </Card.Header>
    <Card.Body>
      <p>{ infoMessage }</p>

      <div className="d-grid mt-2">
        <Button variant="warning" size="large" onClick={onHandleUnlockForm}>Unlock Questionnaire</Button>
      </div>
    </Card.Body>
  </Card>
)


const SendForm = observer(() => {
  function handleLockForm() {
    const confirmationMessage = store.unlockable ? unlockableConfirmationMessage : confirmationMessage
    store.persistFormContent(() => {
      if(store.validateForm()) {
        if(confirm(confirmationMessage)) {
          store.setFormAsComplete()
        }
      }
      else {
        alert(incompleteMessage)
      }
    }, true)
  }

  function handleUnlockForm() {
    store.setFormAsIncomplete()
  }

  if(store.complete && store.mode == 'edit') {
    return (
      <UnlockForm
        onHandleUnlockForm={handleUnlockForm}
        infoMessage="This questionnaire has been submitted by the user and they can no longer make edits.  Unlock this questionnaire to allow the user to make further edits."
      />
    )
  }
  else if(store.complete && store.unlockable) {
    return (
      <UnlockForm
        onHandleUnlockForm={handleUnlockForm}
        infoMessage="This questionnaire has already been submitted.  If you would like to make further changes, click the button below to unlock the questionnaire."
      />
    )
  }
  else if(!store.complete && store.mode !== "preview" && store.mode !== "build") {
    return (
      <LockForm
        infoMessage={`When you're done making changes click "Finalize & Lock" to let us know you're finished.`}
        onHandleLockForm={handleLockForm}
      />
    )
  }
  else {
    return null
  }
})

const styles = {
  buttonWrapper: {
    marginTop: 20
  }
}

export default SendForm
