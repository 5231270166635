import React from 'react'
import { Form } from 'react-bootstrap'

const HintField = ({ hint, errorMessage }) => {
  if(errorMessage) {
    return (
      <Form.Control.Feedback type="invalid" style={{display: "block"}}>
        { errorMessage }
      </Form.Control.Feedback>
    )
  }
  else if(hint) {
    return <Form.Text muted>{ hint }</Form.Text>
  }
  else {
    return null
  }
}

export default HintField
