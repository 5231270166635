import React from 'react'
import PropTypes from 'prop-types'
import {observer} from "mobx-react"
import SimpleFormat from '../../ui/SimpleFormat'

import store from '../stores/store.js'

@observer
export default class PlainText extends React.Component {
  render() {
    const { content } = this.props.field

    return (
      <SimpleFormat autolink={true} text={content} />
    )
  }
}
