import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AddOnWidgetButton from './AddOnWidgetButton'
import AvailabilityCalendarWidgetButton from './AvailabilityCalendarWidgetButton'
import BookNowButton from './BookNowButton'
import ContactWidgetButton from './ContactWidgetButton'
import DesignTemplateWidgetButton from './DesignTemplateWidgetButton'
import EmployeeWidgetButton from './EmployeeWidgetButton'
import MiniSessionButton from './MiniSessionButton'
import PackageBookNowButton from './PackageBookNowButton'
import PhotoboothBackgroundWidgetButton from './PhotoboothBackgroundWidgetButton'
import SignInButton from './SignInButton'

export default class EmbedButton extends Component {
  static propTypes = {
    type: PropTypes.oneOf([
      "addonwidget",
      "availabilitycalendarwidget",
      "booknow",
      "contactwidget",
      "designtemplatewidget",
      "employeewidget",
      "minisession",
      "packagebooknow",
      "photoboothbackgroundwidget",
      "signin",
    ]).isRequired,
  }

  render() {
    const { type } = this.props

    switch(type) {
      case 'signin':
        return <SignInButton {...this.props} />
      case 'booknow':
        return <BookNowButton {...this.props} />
      case 'packagebooknow':
        return <PackageBookNowButton {...this.props} />
      case 'minisession':
        return <MiniSessionButton {...this.props} />
      case 'contactwidget':
        return <ContactWidgetButton {...this.props} />
      case 'addonwidget':
        return <AddOnWidgetButton {...this.props} />
      case 'photoboothbackgroundwidget':
        return <PhotoboothBackgroundWidgetButton {...this.props} />
      case 'designtemplatewidget':
        return <DesignTemplateWidgetButton {...this.props} />
      case 'employeewidget':
        return <EmployeeWidgetButton {...this.props} />
      case 'availabilitycalendarwidget':
        return <AvailabilityCalendarWidgetButton {...this.props} />
      default:
        return null
    }

  }
}
