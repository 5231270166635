import React from 'react'
import { Row } from 'react-bootstrap'

class FormContainer extends React.Component {
  render() {
    const { showSidebar, children } = this.props

    if(showSidebar) {
      return (
        <Row> { children } </Row>
      )
    }
    else {
      return children
    }
  }
}

export default FormContainer
