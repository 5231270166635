import React, { useState, useEffect } from 'react'
import Backdrop from './Backdrop'
import Pager from './Pager'
import { backdropsForCategory, paginatedResults, sortByName } from '../util'

const RecommendedBackdrops = ({ backdrops, chooseUrl, csrfToken }) => {
  if(!backdrops.length) {
    return null
  }

  return (
    <div className="categorized-group">
      <div className="categorized-group-header">
        <div className="title">Recommended Backdrops</div>
      </div>
      <div className="categorized-group-body">
        <div className="categorized-item-container">
          { sortByName(backdrops).map(backdrop => 
          <Backdrop
            key={backdrop.id}
            backdrop={backdrop}
            chooseUrl={chooseUrl}
            csrfToken={csrfToken}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default RecommendedBackdrops
