import React from 'react'
import { Button } from 'react-bootstrap'
import FaIcon from '../../../helpers/fa_icon'

const AddPlaylistButton = ({ onClick }) => (
  <div className="text-center">
    <Button variant="primary" onClick={onClick}>
      <FaIcon icon="plus" text="Use Playlist" />
    </Button>
  </div>
)

export default AddPlaylistButton
