import React, { useState, useEffect, useContext } from 'react'
import extractErrorMessage from '../util/extractErrorMessage'
import SavedPaymentMethodList from './SavedPaymentMethodList'
import SavedPaymentMethodButton from './SavedPaymentMethodButton'
import SavedPaymentMethodContext from '../context/SavedPaymentMethodContext'

const SavedPaymentMethodManager = ({ 
  showSavedPaymentMethods,
  showBackToAllPaymentMethodOptions,
  paymentMethod,
  paymentMethodOption,
  activePaymentMethodOption,
  setActivePaymentMethodOption,
  setLoading,
  paymentIntentId,
  userId
}) => {
  const { savedPaymentMethods } = useContext(SavedPaymentMethodContext)
  const matchingSavedPaymentMethods = savedPaymentMethods
    .filter(pm => pm.kind === paymentMethodOption.kind)

  // Hide if the widget has saved cards disabled
  if(!showSavedPaymentMethods) {
    return null
  }

  // Hide if the payment method option does not support saved cards
  if(!paymentMethodOption.supportsSavedCards) {
    return null
  }

  // Hide if another payment method option is selected
  if(activePaymentMethodOption.id && activePaymentMethodOption.id !== paymentMethodOption.id) {
    return null
  }

  // Hide if the current payment method option is selected but the "saved-card" special is not set
  if(activePaymentMethodOption.id === paymentMethodOption.id && activePaymentMethodOption.special !== "saved-cards") {
    return null
  }

  // Hide if we don't have any matching saved payment methods
  if(matchingSavedPaymentMethods.length === 0) {
    return null
  }

  // If no payment option is set, just show the button
  if(!activePaymentMethodOption.id) {
    return (
      <SavedPaymentMethodButton
        label={paymentMethodOption.name}
        onClick={() => setActivePaymentMethodOption({ id: paymentMethodOption.id, special: "saved-cards"})}
      />
    )
  }

  // Show the full component listing all saved cards
  return (
    <SavedPaymentMethodList
      matchingSavedPaymentMethods={matchingSavedPaymentMethods}
      userId={userId}
      paymentMethod={paymentMethod}
      paymentMethodOption={paymentMethodOption}
      setLoading={setLoading}
      paymentIntentId={paymentIntentId}
      onGoBack={() => setActivePaymentMethodOption({ id: null, special: null })}
    />
  )
}

export default SavedPaymentMethodManager
