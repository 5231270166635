import React, { useState } from 'react'
import { Card, Button, Row, Col } from 'react-bootstrap'
import DateModeSelector from './DateModeSelector'
import CalendarModeSelector from './CalendarModeSelector'
import SlotManager from './SlotManager'
import SlotInterval from './SlotInterval'


const SchedulingModePanel = (props) => {
  const { onSave, loading, formValues  } = props

  return (
    <Card className="package-editor spacer-bottom">
      <Card.Header>
        <Card.Title>Scheduling Mode</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col sm={3}>
            <DateModeSelector {...props} />
          </Col>
          <Col sm={9}>
            <CalendarModeSelector {...props} />
          </Col>
        </Row>
        { (formValues.dateMode === "date_time" && formValues.calendarMode == "predefined_slots") &&
          <SlotManager {...props} />
        }
        { (formValues.dateMode === "date_time" && formValues.calendarMode == "automatic_slots") &&
          <SlotInterval {...props} />
        }
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          onClick={onSave}
          variant="primary"
          disabled={loading}
        >{ loading ? "Saving..." : "Save" }</Button>
      </Card.Footer>
    </Card>
  )
}
export default SchedulingModePanel
