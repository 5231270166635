import React from 'react'

const MandateConsent = (props) => {
  const {  paymentMethod, paymentMethodOption, expanded } = props
  if(!paymentMethod) return null
  if(!paymentMethodOption) return null
  if(!paymentMethodOption.kind) return null

  switch(paymentMethodOption.kind) {
    case "us_bank_account":
      return <MandateConsentUsBankAccount {...props} />
    default:
      return null
  }
}

const MandateConsentUsBankAccount = ({ paymentMethod, expanded }) => (
  <div className="card mt-1 mb-1">
    <div className="card-body">
        <p>By clicking "Submit Payment", you authorize { paymentMethod.descriptor } to debit the bank account specified above for any amount owed for charges arising from your use of services and/or purchase of products provided by { paymentMethod.descriptor }, pursuant to the website and terms of { paymentMethod.descriptor }, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to { paymentMethod.descriptor } with 30 (thirty) days notice.</p>
        { expanded && <p>If you use { paymentMethod.descriptor } services or purchase additional products periodically pursuant to { paymentMethod.descriptor } terms, you authorize { paymentMethod.descriptor } to debit your bank account periodically. Payments that fall outside of the regular debits authorized above will only be debited after your authorization is obtained.</p> }
    </div>
  </div>
)

export default MandateConsent
