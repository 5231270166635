import React from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

const UserPreview = ({ userId, checklistStore }) => {
  const user = checklistStore.users.get(`${userId}`)
  const fullName = user ? user.attributes.fullName : ""

  return (
    <span className="badge bg-primary me-1" key={userId}>
      { fullName }
    </span>
  )
}

export default inject("checklistStore")(observer(UserPreview))
