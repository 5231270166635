import React, { useState } from 'react'

import { Row, Col, Form, FormGroup, HelpBlock } from 'react-bootstrap'
import ErrorBlock, { validationStateFor } from './ErrorBlock'

const SlotInterval = ({ formValues, setFormValues, errors, hourlySlotIntervals }) => (
  <>
    <hr />
    <Row>
      <Col sm={{ span: 4, offset: 3 }}>
        <FormGroup>
          <Form.Label>
            Slot Interval
          </Form.Label>
          <Form.Select
            className="input-lg select optional"
            value={formValues.slotIntervalMinutes}
            onChange={e => setFormValues({ slotIntervalMinutes: e.target.value })}
            isInvalid={!!errors.slotIntervalMinutes}
          >
            { hourlySlotIntervals.map(slotInterval =>
            <option key={slotInterval[0]} value={slotInterval[1]}>{ slotInterval[0] }</option>
            )}
          </Form.Select>
          <Form.Text muted>How far apart are your slot start times?</Form.Text>
          <ErrorBlock error={errors.slotIntervalMinutes} />
        </FormGroup>
      </Col>
    </Row>
  </>
)

export default SlotInterval
