import React from 'react'
import { Form, Card, Button, Row, Col, FormGroup, InputGroup } from 'react-bootstrap'
import ErrorBlock, { validationStateFor } from './ErrorBlock'
import HourSelector from './HourSelector'
import DaySelector from './DaySelector'
import TimeControl from '../../ui/TimeControl'

import { parse, format } from 'date-fns'

const AvailabilityPanel = ({ formValues, setFormValues, errors, onSave, hourlySlotIntervals, timepickerFormat, loading }) => {
  return (
    <Card className="package-editor spacer-bottom">
      <Card.Header>
        <Card.Title>Availability</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col sm={4}>
            <FormGroup size="lg">
              <Form.Label>
                Is this package available every day of the week?
              </Form.Label>
              <Form.Check
                type="radio"
                onChange={() => setFormValues({ availableEveryDayOfTheWeek: "yes" })}
                checked={formValues.availableEveryDayOfTheWeek === "yes"}
                label="Yes"
                id="availableEveryDayOfTheWeek-yes"
              />
              <Form.Check
                type="radio"
                onChange={() => setFormValues({ availableEveryDayOfTheWeek: "yes_certain_hours" })}
                checked={formValues.availableEveryDayOfTheWeek === "yes_certain_hours"}
                label="Yes, with limited hours"
                id="availableEveryDayOfTheWeek-yes_certain_hours"
              />
              <Form.Check
                type="radio"
                onChange={() => setFormValues({ availableEveryDayOfTheWeek: "no" })}
                checked={formValues.availableEveryDayOfTheWeek === "no"}
                label="No"
                id="availableEveryDayOfTheWeek-no"
              />
              <ErrorBlock error={errors.availableEveryDayOfTheWeek} />
            </FormGroup>
          </Col>
          <Col sm={8}>
            { formValues.availableEveryDayOfTheWeek === "yes_certain_hours" &&
              <Row>
                <Col sm={4}>
                  <FormGroup size="lg">
                    <Form.Label>Earliest Start</Form.Label>
                    <InputGroup>
                      <TimeControl
                        timepickerFormat={timepickerFormat}
                        step={1}
                        value={formValues.availableStartsAtTime || ""}
                        onChange={value => setFormValues({ availableStartsAtTime: value })}
                        isInvalid={!!errors.availableStartsAtTime}
                      />
                    </InputGroup>
                    <ErrorBlock error={errors.availableStartsAtTime} />
                  </FormGroup>
                </Col>
                <Col sm={4}>
                  <FormGroup size="lg">
                    <Form.Label>Latest Start</Form.Label>
                    <InputGroup>
                      <TimeControl
                        timepickerFormat={timepickerFormat}
                        step={1}
                        value={formValues.availableEndsAtTime || ""}
                        onChange={value => setFormValues({ availableEndsAtTime: value })}
                        isInvalid={!!errors.availableEndsAtTime}
                      />
                    </InputGroup>
                    <ErrorBlock error={errors.availableEndsAtTime} />
                  </FormGroup>
                </Col>
              </Row>
            }
            { formValues.availableEveryDayOfTheWeek === "no" &&
              <>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Form.Label>
                        Available Sunday?
                      </Form.Label>
                      <Form.Select
                        className="input-lg select optional"
                        value={formValues.availableSunday}
                        onChange={e => setFormValues({ availableSunday: e.target.value })}
                        isInvalid={!!errors.availableSunday}
                      >
                        <option value="yes">Yes, all day</option>
                        <option value="partial">Yes, certain hours</option>
                        <option value="no">No</option>
                      </Form.Select>
                      <ErrorBlock error={errors.availableSunday} />
                    </FormGroup>

                  </Col>
                  { formValues.availableSunday == "partial" &&
                    <>
                      <Col sm={3}>
                        <FormGroup size="lg">
                          <Form.Label>Earliest Start</Form.Label>
                          <InputGroup>
                            <TimeControl
                              timepickerFormat={timepickerFormat}
                              step={1}
                              value={formValues.availableSundayStartsAtTime || ""}
                              onChange={value => setFormValues({ availableSundayStartsAtTime: value })}
                              isInvalid={!!errors.availableSundayStartsAtTime}
                            />
                          </InputGroup>
                          <ErrorBlock error={errors.availableSundayStartsAtTime} />
                        </FormGroup>
                      </Col>
                      <Col sm={3}>
                        <FormGroup size="lg">
                          <Form.Label>Latest Start</Form.Label>
                          <InputGroup>
                            <TimeControl
                              timepickerFormat={timepickerFormat}
                              step={1}
                              value={formValues.availableSundayEndsAtTime || ""}
                              onChange={value => setFormValues({ availableSundayEndsAtTime: value })}
                              isInvalid={!!errors.availableSundayEndsAtTime}
                            />
                          </InputGroup>
                          <ErrorBlock error={errors.availableSundayEndsAtTime} />
                        </FormGroup>
                      </Col>
                    </>
                  }
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Form.Label>
                        Available Monday?
                      </Form.Label>
                      <Form.Select
                        className="input-lg select optional"
                        value={formValues.availableMonday}
                        onChange={e => setFormValues({ availableMonday: e.target.value })}
                        isInvalid={!!errors.availableMonday}
                      >
                        <option value="yes">Yes, all day</option>
                        <option value="partial">Yes, certain hours</option>
                        <option value="no">No</option>
                      </Form.Select>
                      <ErrorBlock error={errors.availableMonday} />
                    </FormGroup>

                  </Col>
                  { formValues.availableMonday == "partial" &&
                    <>
                      <Col sm={3}>
                        <FormGroup size="lg">
                          <Form.Label>Earliest Start</Form.Label>
                          <InputGroup>
                            <TimeControl
                              timepickerFormat={timepickerFormat}
                              step={1}
                              value={formValues.availableMondayStartsAtTime || ""}
                              onChange={value => setFormValues({ availableMondayStartsAtTime: value })}
                              isInvalid={!!errors.availableMondayStartsAtTime}
                            />
                          </InputGroup>
                          <ErrorBlock error={errors.availableMondayStartsAtTime} />
                        </FormGroup>
                      </Col>
                      <Col sm={3}>
                        <FormGroup size="lg">
                          <Form.Label>Latest Start</Form.Label>
                          <InputGroup>
                            <TimeControl
                              timepickerFormat={timepickerFormat}
                              step={1}
                              value={formValues.availableMondayEndsAtTime || ""}
                              onChange={value => setFormValues({ availableMondayEndsAtTime: value })}
                              isInvalid={!!errors.availableMondayEndsAtTime}
                            />
                          </InputGroup>
                          <ErrorBlock error={errors.availableMondayEndsAtTime} />
                        </FormGroup>
                      </Col>
                    </>
                  }
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Form.Label>
                        Available Tuesday?
                      </Form.Label>
                      <Form.Select
                        className="input-lg select optional"
                        value={formValues.availableTuesday}
                        onChange={e => setFormValues({ availableTuesday: e.target.value })}
                        isInvalid={!!errors.availableTuesday}
                      >
                        <option value="yes">Yes, all day</option>
                        <option value="partial">Yes, certain hours</option>
                        <option value="no">No</option>
                      </Form.Select>
                      <ErrorBlock error={errors.availableTuesday} />
                    </FormGroup>

                  </Col>
                  { formValues.availableTuesday == "partial" &&
                    <>
                      <Col sm={3}>
                        <FormGroup size="lg">
                          <Form.Label>Earliest Start</Form.Label>
                          <InputGroup>
                            <TimeControl
                              timepickerFormat={timepickerFormat}
                              step={1}
                              value={formValues.availableTuesdayStartsAtTime || ""}
                              onChange={value => setFormValues({ availableTuesdayStartsAtTime: value })}
                              isInvalid={!!errors.availableTuesdayStartsAtTime}
                            />
                          </InputGroup>
                          <ErrorBlock error={errors.availableTuesdayStartsAtTime} />
                        </FormGroup>
                      </Col>
                      <Col sm={3}>
                        <FormGroup size="lg">
                          <Form.Label>Latest Start</Form.Label>
                          <InputGroup>
                            <TimeControl
                              timepickerFormat={timepickerFormat}
                              step={1}
                              value={formValues.availableTuesdayEndsAtTime || ""}
                              onChange={value => setFormValues({ availableTuesdayEndsAtTime: value })}
                              isInvalid={!!errors.availableTuesdayEndsAtTime}
                            />
                          </InputGroup>
                          <ErrorBlock error={errors.availableTuesdayEndsAtTime} />
                        </FormGroup>
                      </Col>
                    </>
                  }
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Form.Label>
                        Available Wednesday?
                      </Form.Label>
                      <Form.Select
                        className="input-lg select optional"
                        value={formValues.availableWednesday}
                        onChange={e => setFormValues({ availableWednesday: e.target.value })}
                        isInvalid={!!errors.availableWednesday}
                      >
                        <option value="yes">Yes, all day</option>
                        <option value="partial">Yes, certain hours</option>
                        <option value="no">No</option>
                      </Form.Select>
                      <ErrorBlock error={errors.availableWednesday} />
                    </FormGroup>

                  </Col>
                  { formValues.availableWednesday == "partial" &&
                    <>
                      <Col sm={3}>
                        <FormGroup size="lg">
                          <Form.Label>Earliest Start</Form.Label>
                          <InputGroup>
                            <TimeControl
                              timepickerFormat={timepickerFormat}
                              step={1}
                              value={formValues.availableWednesdayStartsAtTime || ""}
                              onChange={value => setFormValues({ availableWednesdayStartsAtTime: value })}
                              isInvalid={!!errors.availableWednesdayStartsAtTime}
                            />
                          </InputGroup>
                          <ErrorBlock error={errors.availableWednesdayStartsAtTime} />
                        </FormGroup>
                      </Col>
                      <Col sm={3}>
                        <FormGroup size="lg">
                          <Form.Label>Latest Start</Form.Label>
                          <InputGroup>
                            <TimeControl
                              timepickerFormat={timepickerFormat}
                              step={1}
                              value={formValues.availableWednesdayEndsAtTime || ""}
                              onChange={value => setFormValues({ availableWednesdayEndsAtTime: value })}
                              isInvalid={!!errors.availableWednesdayEndsAtTime}
                            />
                          </InputGroup>
                          <ErrorBlock error={errors.availableWednesdayEndsAtTime} />
                        </FormGroup>
                      </Col>
                    </>
                  }
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Form.Label>
                        Available Thursday?
                      </Form.Label>
                      <Form.Select
                        className="input-lg select optional"
                        value={formValues.availableThursday}
                        onChange={e => setFormValues({ availableThursday: e.target.value })}
                        isInvalid={!!errors.availableThursday}
                      >
                        <option value="yes">Yes, all day</option>
                        <option value="partial">Yes, certain hours</option>
                        <option value="no">No</option>
                      </Form.Select>
                      <ErrorBlock error={errors.availableThursday} />
                    </FormGroup>

                  </Col>
                  { formValues.availableThursday == "partial" &&
                    <>
                      <Col sm={3}>
                        <FormGroup size="lg">
                          <Form.Label>Earliest Start</Form.Label>
                          <InputGroup>
                            <TimeControl
                              timepickerFormat={timepickerFormat}
                              step={1}
                              value={formValues.availableThursdayStartsAtTime || ""}
                              onChange={value => setFormValues({ availableThursdayStartsAtTime: value })}
                              isInvalid={!!errors.availableThursdayStartsAtTime}
                            />
                          </InputGroup>
                          <ErrorBlock error={errors.availableThursdayStartsAtTime} />
                        </FormGroup>
                      </Col>
                      <Col sm={3}>
                        <FormGroup size="lg">
                          <Form.Label>Latest Start</Form.Label>
                          <InputGroup>
                            <TimeControl
                              timepickerFormat={timepickerFormat}
                              step={1}
                              value={formValues.availableThursdayEndsAtTime || ""}
                              onChange={value => setFormValues({ availableThursdayEndsAtTime: value })}
                              isInvalid={!!errors.availableThursdayEndsAtTime}
                            />
                          </InputGroup>
                          <ErrorBlock error={errors.availableThursdayEndsAtTime} />
                        </FormGroup>
                      </Col>
                    </>
                  }
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Form.Label>
                        Available Friday?
                      </Form.Label>
                      <Form.Select
                        className="input-lg select optional"
                        value={formValues.availableFriday}
                        onChange={e => setFormValues({ availableFriday: e.target.value })}
                        isInvalid={!!errors.availableFriday}
                      >
                        <option value="yes">Yes, all day</option>
                        <option value="partial">Yes, certain hours</option>
                        <option value="no">No</option>
                      </Form.Select>
                      <ErrorBlock error={errors.availableFriday} />
                    </FormGroup>

                  </Col>
                  { formValues.availableFriday == "partial" &&
                    <>
                      <Col sm={3}>
                        <FormGroup size="lg">
                          <Form.Label>Earliest Start</Form.Label>
                          <InputGroup>
                            <TimeControl
                              timepickerFormat={timepickerFormat}
                              step={1}
                              value={formValues.availableFridayStartsAtTime || ""}
                              onChange={value => setFormValues({ availableFridayStartsAtTime: value })}
                              isInvalid={!!errors.availableFridayStartsAtTime}
                            />
                          </InputGroup>
                          <ErrorBlock error={errors.availableFridayStartsAtTime} />
                        </FormGroup>
                      </Col>
                      <Col sm={3}>
                        <FormGroup size="lg">
                          <Form.Label>Latest Start</Form.Label>
                          <InputGroup>
                            <TimeControl
                              timepickerFormat={timepickerFormat}
                              step={1}
                              value={formValues.availableFridayEndsAtTime || ""}
                              onChange={value => setFormValues({ availableFridayEndsAtTime: value })}
                              isInvalid={!!errors.availableFridayEndsAtTime}
                            />
                          </InputGroup>
                          <ErrorBlock error={errors.availableFridayEndsAtTime} />
                        </FormGroup>
                      </Col>
                    </>
                  }
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Form.Label>
                        Available Saturday?
                      </Form.Label>
                      <Form.Select
                        className="input-lg select optional"
                        value={formValues.availableSaturday}
                        onChange={e => setFormValues({ availableSaturday: e.target.value })}
                        isInvalid={!!errors.availableSaturday}
                      >
                        <option value="yes">Yes, all day</option>
                        <option value="partial">Yes, certain hours</option>
                        <option value="no">No</option>
                      </Form.Select>
                      <ErrorBlock error={errors.availableSaturday} />
                    </FormGroup>

                  </Col>
                  { formValues.availableSaturday == "partial" &&
                    <>
                      <Col sm={3}>
                        <FormGroup size="lg">
                          <Form.Label>Earliest Start</Form.Label>
                          <InputGroup>
                            <TimeControl
                              timepickerFormat={timepickerFormat}
                              step={1}
                              value={formValues.availableSaturdayStartsAtTime || ""}
                              onChange={value => setFormValues({ availableSaturdayStartsAtTime: value })}
                              isInvalid={!!errors.availableSaturdayStartsAtTime}
                            />
                          </InputGroup>
                          <ErrorBlock error={errors.availableSaturdayStartsAtTime} />
                        </FormGroup>
                      </Col>
                      <Col sm={3}>
                        <FormGroup size="lg">
                          <Form.Label>Latest Start</Form.Label>
                          <InputGroup>
                            <TimeControl
                              timepickerFormat={timepickerFormat}
                              step={1}
                              value={formValues.availableSaturdayEndsAtTime || ""}
                              onChange={value => setFormValues({ availableSaturdayEndsAtTime: value })}
                              isInvalid={!!errors.availableSaturdayEndsAtTime}
                            />
                          </InputGroup>
                          <ErrorBlock error={errors.availableSaturdayEndsAtTime} />
                        </FormGroup>
                      </Col>
                    </>
                  }
                </Row>
              </>
            }
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          onClick={onSave}
          variant="primary"
          disabled={loading}
        >{ loading ? "Saving..." : "Save" }</Button>
      </Card.Footer>
    </Card>
  )
}

const styles = {
  timeInput: {
    fontSize: "inherit"
  },
  timeLabel: {
    fontSize: "inherit"
  }
}

export default AvailabilityPanel
