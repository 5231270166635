import React from 'react'

function feeDescriptionFor({ authorizeOnly, feeDescription, feeAmountFormatted }) {
  if(authorizeOnly) {
    if(feeDescription) {
      return `Automatic payments using this method will incur a ${feeDescription} processing fee`
    }
    else {
      return null
    }
  }
  else {
    if(feeDescription) {
      return `Includes ${feeAmountFormatted} processing fee`
    }
    else if(authorizeOnly) {
      return "No processing fee"
    }
  }
}

const PaymentPane = ({ show, group, showFees, authorizeOnly, children }) => {
  const { totalWithFeesFormatted, feeDescription, feeAmountFormatted } = group
  const processingFee = feeDescriptionFor({ authorizeOnly, feeDescription, feeAmountFormatted })

  return (
    <div className={`panel panel-default spacer-bottom ${show ? "" : "d-none"}`}>
      <div className="panel-body">
        <>
          { !authorizeOnly && <div className="fs-3 payment-amount">Pay: { totalWithFeesFormatted }</div> }
          { (showFees && processingFee) && <p className="processing-fee">{ processingFee }</p> }
        </>
        { children }
      </div>
    </div>
  )
}

export default PaymentPane
