import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer, Provider } from 'mobx-react'
import Form from './Form'
import SubmittedMessage from './SubmittedMessage'
import Store from './Store'
import redirectTo from '../../helpers/redirectTo'
import './style.scss'
import retainTrackingParams from '../../helpers/retainTrackingParams'

@inject("widgetStore")
@observer
export default class ContactForm extends React.Component {
  static propTypes = {
    contactFormId: PropTypes.number.isRequired,
    packageId: PropTypes.number,
    widgetStore: PropTypes.object.isRequired,
    labelsAsPlaceholders: PropTypes.bool,
    wideSubmitButtons: PropTypes.bool,
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    buttonBackgroundColor: PropTypes.string,
    buttonForegroundColor: PropTypes.string,
    fontFamily: PropTypes.string,
    labelColor: PropTypes.string,
    labelFontWeight: PropTypes.string,
    labelFontSize: PropTypes.string,
    borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    borderWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    borderColor: PropTypes.string,
    inputBackgroundColor: PropTypes.string,
    referredByUserId: PropTypes.number
  }

  static defaultProps = {
    labelsAsPlaceholders: false,
    maxWidth: "100%",
    wideSubmitButtons: false
  }

  formStore = new Store({ widgetStore: this.props.widgetStore, packageId: this.props.packageId, referredByUserId: this.props.referredByUserId })

  state = {
    showSubmissionMessage: false,
  }

  handleSubmit = (responseData) => {
    const { submitAction, redirectUrl, bookingUrl } = this.formStore.formSettings

    switch(submitAction) {
      case "redirect_to_url":
        redirectTo(redirectUrl)
        break
      case "redirect_to_booking":
        const additionalParams = `lead_id=${responseData.attributes.id}&token=${responseData.attributes.token}`
        const finalBookingUrl = retainTrackingParams(bookingUrl.includes("?") ? `${bookingUrl}&${additionalParams}` : `${bookingUrl}?${additionalParams}`)
        console.log({ finalBookingUrl})
        redirectTo(finalBookingUrl)
        break
      default:
        this.setState({showSubmissionMessage: true})
    }
  }

  componentDidMount() {
    const { contactFormId } = this.props
    this.formStore.setup(contactFormId)
  }

  render() {
    const { labelsAsPlaceholders, maxWidth, wideSubmitButtons,
      buttonForegroundColor, buttonBackgroundColor, fontFamily, labelColor,
      labelFontWeight, labelFontSize, borderRadius, borderWidth, borderColor, inputBackgroundColor } = this.props
    const { formSettings } = this.formStore
    const { showSubmissionMessage } = this.state

    if(!this.formStore.ready) {
      return null
    }
    if(formSettings.deleted) {
      console.warn(`This form has been deleted`)
      return null
    }

    return (
      <div style={{maxWidth: maxWidth}}>
        { showSubmissionMessage ?
          <SubmittedMessage formStore={this.formStore} />
          :
          <Form
            formStore={this.formStore}
            onSubmit={this.handleSubmit}
            labelsAsPlaceholders={labelsAsPlaceholders}
            wideSubmitButtons={wideSubmitButtons}
            buttonForegroundColor={buttonForegroundColor}
            buttonBackgroundColor={buttonBackgroundColor}
            fontFamily={fontFamily}
            labelColor={labelColor}
            labelFontWeight={labelFontWeight}
            labelFontSize={labelFontSize}
            borderRadius={borderRadius}
            borderWidth={borderWidth}
            borderColor={borderColor}
            inputBackgroundColor={inputBackgroundColor}
          />
        }
      </div>
    )
  }
}
