import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

export default class FaIcon extends React.Component {
  render() {
    const { icon, spin, style, text, title } = this.props
    const spinClass = spin ? 'fa-spin' : ''
    const iconClass = icon ? `fa-${icon}` : ''
    const className = `fa ${spinClass} ${iconClass}`

    if(this.props.text) {
      return (
        <span style={style}>
          { icon && <i className={className} title={title} /> }
          { " " }
          { text }
        </span>
      )
    }
    else {
      return <span style={style}><i className={className} title={title} /></span>
    }
  }
}

React.propTypes = {
  icon: PropTypes.string.required,
  text: PropTypes.string,
  style: PropTypes.object,
  spin: PropTypes.bool
}
