import React from 'react';
import { ListGroup } from 'react-bootstrap';
import UserBadge from './UserBadge';

const SearchResultUser = ({ result, ...props }) => {
  const { fullName, email, companyName, phone, url, previewImage } = result.attributes

  return (
    <ListGroup.Item action {...props} href={url} className="global-search-result-item" active={false}>
      <div className="global-search-preview-image-container text-dark">
        <img src={previewImage} alt={fullName} className="img-fluid" />
      </div>
      <div className="global-search-preview-details-container">
        <div>
          <span className="text-dark fw-medium"> { fullName } </span>
          <UserBadge {...result.attributes} />
        </div>
        <div className="text-muted">{ companyName }</div>
        <div className="text-muted">{ email }</div>
        <div className="text-muted">{ phone }</div>
      </div>
    </ListGroup.Item>
  )
}

export default SearchResultUser
