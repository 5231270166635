import React, { useEffect } from 'react'
import ScheduledPayment from './ScheduledPayment'
import DepositPayment from './DepositPayment'
import FinalPayment from './FinalPayment'

const FranchisePaymentPlan = ({
  franchisePaymentPlan,
  dateFormat,
  activeFranchisePaymentPlanId,
  setActiveFranchisePaymentPlanId,
  enableAutoPay,
  setEnableAutoPay
}) => {
  return (
    <li className={`list-group-item d-flex justify-content-between align-items-start list-group-item-action payment-plan-selection ${activeFranchisePaymentPlanId == franchisePaymentPlan.id ? 'payment-plan-active' : 'payment-plan-inactive'}`} onClick={() => setActiveFranchisePaymentPlanId(franchisePaymentPlan.id)} id={`payment-plan-${franchisePaymentPlan.id}`}>
      <div>
        { activeFranchisePaymentPlanId == franchisePaymentPlan.id ?
          <i className="virtual-radio-button fa fa-check-square-o me-2"></i>
          :
          <i className="virtual-radio-button fa fa-square-o me-2"></i>
        }
      </div>
      <div className="ms-2 me-auto">
        <div className="fw-bold">{ franchisePaymentPlan.attributes.customerTitle }</div>
        {/* <div className="">$300.00 already applied</div> */}
        { activeFranchisePaymentPlanId == franchisePaymentPlan.id && (
          <div>
            <div className="mt-2 mb-2">
              { franchisePaymentPlan.attributes.customerSubtitle &&
              <span className="pe-2">{ franchisePaymentPlan.attributes.customerSubtitle }.</span>
              }
              { franchisePaymentPlan.attributes.amountPaidCents > 0 && (
                <span>{franchisePaymentPlan.attributes.amountPaidFormatted} in payments have already been applied</span>
              )}
            </div>
            { franchisePaymentPlan.attributes.showsPaymentBreakdown && (
              <ul>
                <DepositPayment franchisePaymentPlan={franchisePaymentPlan} dateFormat={dateFormat} />
                { franchisePaymentPlan.attributes.scheduledPayments.map((scheduledPayment, index) => (
                  <ScheduledPayment key={index} scheduledPayment={scheduledPayment} dateFormat={dateFormat} />
                ))}
                <FinalPayment franchisePaymentPlan={franchisePaymentPlan} dateFormat={dateFormat} />
              </ul>
            )}
          </div>
        )}
      </div>
    </li>
  )
}

export default FranchisePaymentPlan
