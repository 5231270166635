import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { observer, inject } from 'mobx-react'
import { minutesToString } from '../util'

@inject("store")
@observer
export default class HourlySelector extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  }

  render() {
    const { store } = this.props
    const { lengthInMinutes, availableLengthsInMinutes } = store

    return (
      <div className="length-selector">
        <div className="length-selector-label">
          How many hours would you like to book?
        </div>
        <Form.Select
          name="length-selector"
          value={lengthInMinutes}
          onChange={e => store.setLengthInMinutes(e.target.value)}
          aria-label="Select the number of hours you would like to book"
        >
          { availableLengthsInMinutes.map(length => 
            <option key={length} value={length}>
              {minutesToString(length)}
            </option>
          )}
        </Form.Select>
      </div>
    )
  // }
    return null
  }
}

