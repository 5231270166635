import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, FormControl, Row, Col } from 'react-bootstrap'
import {observer} from "mobx-react"
import store from '../stores/store.js'

@observer
export default class DoubleTextField extends React.Component {
  render() {
    const { field } = this.props
    const { name, placeholder, placeholder2, uuid, hint, required } = field
    const instance = this.props.instance || 0
    const errorMessage = store.errorFor(uuid, "text", instance)

    return (
      <FormGroup>
        <Form.Label>{ required ? "*" : "" } {name}</Form.Label>
        <Row>
          <Col xs={6}>
            <FormControl
              type="text"
              placeholder={placeholder}
              value={store.valueFor(uuid, "text", instance)}
              onChange={e => store.setValueFor(uuid, "text", instance, e.target.value)}
              disabled={!store.editable}
              isInvalid={!!errorMessage}
            />
          </Col>
          <Col xs={6}>
            <FormControl
              type="text"
              placeholder={placeholder2}
              value={store.valueFor(uuid, "text2", instance)}
              onChange={e => store.setValueFor(uuid, "text2", instance, e.target.value)}
              disabled={!store.editable}
              isInvalid={!!errorMessage}
            />
          </Col>
        </Row>
        { errorMessage && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{ errorMessage }</Form.Control.Feedback> }
        { field.hint && <Form.Text muted>{field.hint}</Form.Text> }
      </FormGroup>
    )
  }
}
