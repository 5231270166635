import React from 'react'
import PropTypes from 'prop-types'
import ToolbarButtonGroup from './ToolbarButtonGroup'
import ToolbarButton from './ToolbarButton'
import { observer, inject } from 'mobx-react'

const ChartTypeSelector = ({ store }) => {
  const { chartType } = store

  return (
    <ToolbarButtonGroup>
      <ToolbarButton
        active={chartType === "created_on"}
        onClick={() => (store.chartType = "created_on")}
      >
        Date Created
      </ToolbarButton>
      <ToolbarButton
        active={chartType === "scheduled_on"}
        onClick={() => (store.chartType = "scheduled_on")}
      >
        Date Scheduled
      </ToolbarButton>
    </ToolbarButtonGroup>
  )
}

export default inject("store")(observer(ChartTypeSelector))
