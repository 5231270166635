import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import Currency from '../../../../helpers/currency.js'
import { ResponsiveContainer, BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar } from 'recharts'
import Statistics from './Statistics'
import TooltipDisplay from './TooltipDisplay'

@inject("store")
@inject("currency")
@observer
export default class BookedOnChart extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    currency: PropTypes.object.isRequired
  }

  loadReport = (e) => {
    const path = e.payload.reportPath
    window.location = path
  }

  render() {
    const { store, currency } = this.props
    const { data, loading } = store

    return (
      <div>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data} margin={{top: 20, right: 30, left: 20, bottom: 5}}>
            <XAxis dataKey="name"/>
            <YAxis tickFormatter={amountCents => Currency.formatted(amountCents, currency) }/>
            <CartesianGrid strokeDasharray="3 3"/>
            <Tooltip content={<TooltipDisplay currency={currency} />} />
            <Legend />
            <Bar isAnimationActive={!loading} className="dashboard-chart-data-bar" dataKey="amountPaidCents" stackId="a" fill="#4DA143" name="Amount Paid" onClick={this.loadReport} />
            <Bar isAnimationActive={!loading} className="dashboard-chart-data-bar" dataKey="projectedCents" stackId="a" fill="#0545ca" name="Projected Revenue" onClick={this.loadReport} />
            <Bar isAnimationActive={!loading} className="dashboard-chart-data-bar" dataKey="postponedCents" stackId="a" fill="#ebae53" name="Postponed" onClick={this.loadReport} />
            <Bar isAnimationActive={!loading} className="dashboard-chart-data-bar" dataKey="pastDueCents" stackId="a" fill="#b00000" name="Past Due" onClick={this.loadReport} />
          </BarChart>
        </ResponsiveContainer>
        <Statistics />
      </div>
    )
  }
}
