import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("checklistStore")
@observer
export default class ReloadButton extends React.Component {
  render() {
    const { checklistStore } = this.props
    
    return (
      <button 
        onClick={() => checklistStore.loadChecklistItems() }
        className="btn btn-secondary"
      >
        Reload
      </button>
    )
  }
}

