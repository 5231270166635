import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'react-bootstrap'
import {observer} from "mobx-react"
import store from '../stores/store.js'

@observer
export default class RadioGroup extends React.Component {
  render() {
    const { field } = this.props
    const { name, placeholder, uuid, hint, required } = field
    const instance = this.props.instance || 0
    const value = store.valueFor(uuid, "radio", instance)
    const errorMessage = store.errorFor(uuid, "radio", instance)
    const radioName = `${uuid}-${instance}`

    return (
      <FormGroup>
        <Form.Label>{ required ? "*" : "" } {name}</Form.Label>
        {field.values.map((option, index) =>
        <Form.Check
          type="radio"
          key={index}
          name={radioName}
          checked={value == option}
          onChange={() => store.setValueFor(uuid, "radio", instance, option)}
          disabled={!store.editable}
          isInvalid={!!errorMessage}
          label={option}
          id={`${uuid}-${instance}-${index}`}
        />
        )}
        { errorMessage && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{errorMessage}</Form.Control.Feedback> }
        { hint && <Form.Text muted>{hint}</Form.Text > }
      </FormGroup>
    )
  }
}
