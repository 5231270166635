function deserializeDate(date) {
  if(!date) {
    return null
  }

  const parsed = moment(date, "YYYY-MM-DD")
  if(parsed) {
    return parsed.toDate()
  }
  else {
    return null
  }
}

function serializeDate(date) {
  if(!date) {
    return null
  }
  return moment(date).format("YYYY-MM-DD")
}

function serializeType(type, value) {
  switch(type) {
    case 'date':
      return serializeDate(value)
    default:
      return value
  }
}

function deserializeType(type, value) {
  switch(type) {
    case 'date':
      return deserializeDate(value)
    default:
      return value
  }
}

export { serializeType, deserializeType }
