import React, { useState, useEffect } from 'react'

const Loader = ({ loading, children }) => {
  return (
    <div className="loader-container">
      { loading &&
        <div className="loading-dialog">
          <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" />
        </div>
      }
      <div>{ children }</div>
    </div>
  )
}

export default Loader
