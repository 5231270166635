import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Modal, Row, Col, FormGroup, FormControl } from 'react-bootstrap'
import ColorPicker from '../ColorPicker'
import EventTypeSelector from '../EventTypeSelector'
import CopyableTextBlock from '../CopyableTextBlock'
import stringifyProps from './stringifyProps'


export default class PhotoboothBackgroundWidgetButton extends Component {
  state = {
    showModal: false,
    style: "embed",
    showBookNowButton: true,
    bookNowButtonText: "Book Now",
    showPrice: true,
    showTitle: true,
    iframe: false,
    maxWidth: "",
    showAdvancedStylingOptions: false,
    buttonBackgroundColor: "",
    buttonForegroundColor: "",
    textColor: "",
    fontFamily: "",
    perPage: ""
  }

  static propTypes = {
    type: PropTypes.string.isRequired,
    host: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired
  }

  showModal = () => {
    this.setState({showModal: true})
  }

  hideModal = () => {
    this.setState({showModal: false})
  }

  showAdvancedStylingOptions = (e) => {
    e.preventDefault()
    this.setState({ showAdvancedStylingOptions: true })
  }

  hideAdvancedStylingOptions = (e) => {
    e.preventDefault()
    this.setState({ showAdvancedStylingOptions: false })
  }

  get html() {
    const { style, showBookNowButton, bookNowButtonText, showPrice, showTitle, iframe, maxWidth, showAdvancedStylingOptions, buttonBackgroundColor, buttonForegroundColor, textColor, fontFamily, perPage } = this.state
    const { host, apiKey } = this.props

    const baseProps = { apiKey, iframe, host: `https://${host}`}
    let dataProps = { showBookNowButton, bookNowButtonText, showPrice, showTitle }
    if(maxWidth) {
      dataProps.maxWidth = maxWidth
    }
    if(showAdvancedStylingOptions) {
      if(buttonBackgroundColor) {
        dataProps.buttonBackgroundColor = buttonBackgroundColor
      }
      if(buttonForegroundColor) {
        dataProps.buttonForegroundColor = buttonForegroundColor
      }
      if(textColor) {
        dataProps.textColor = textColor
      }
      if(fontFamily) {
        dataProps.fontFamily = fontFamily
      }
      if(perPage && parseInt(perPage) > 0) {
        dataProps.perPage = parseInt(perPage)
      }
    }
    const combinedProps = {...baseProps, ...dataProps}

    if(style === "basicLink") {
      let url = `https://${host}/photobooth_backdrops`
      if(Object.keys(dataProps).length > 0) {
        url = `${url}?props=${encodeURIComponent(stringifyProps(dataProps))}`
      }
      return url
    }
    else if(style === "embed" && !iframe) {
      return `<script src="https://${host}/api/checkcherry_widgets" type="text/javascript" charset="utf-8"></script><div class="checkcherry__widget__photobooth-background-gallery" data-props='${stringifyProps(combinedProps)}'></div>`
    }
    else if(style === "embed" && iframe) {
      return `<iframe class="checkcherry-autoresize-frame" src="https://${host}/photobooth_backdrops/?iframe=true&props=${encodeURIComponent(stringifyProps(dataProps))}" style="margin: 0; padding: 0; border: none; width: ${maxWidth || "100%"}; height: 400px" scrolling="auto" allowtransparency="true"></iframe><script src="https://${host}/api/checkcherry_widgets/iframe" type="text/javascript" charset="utf-8"></script>`
    }
    else {
      return ""
    }
  }

  render() {
    const { type } = this.props
    const { showModal, style, showBookNowButton, bookNowButtonText, showPrice, showTitle, iframe, maxWidth, showAdvancedStylingOptions, textColor, fontFamily, buttonBackgroundColor, buttonForegroundColor, perPage } = this.state

    return (
      <div>
        <div className="btn-group">
          <Button onClick={this.showModal} variant="primary">
            <i className="fa fa-code" />
            { " " }
            Get Embed Code
          </Button>
        </div>
        <Modal show={showModal} onHide={this.hideModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Backdrop Gallery Widget</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={5}>
                <h3>Customize</h3>
                <FormGroup className="mb-2">
                  <Form.Label>Choose Style</Form.Label>
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "basicLink"}
                    onChange={() => this.setState({style: "basicLink"})}
                    label="Link Only"
                    id="photobooth-background-widget-style-basic-link"
                  />
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "embed"}
                    onChange={() => this.setState({style: "embed"})}
                    label="Embed on my site"
                    id="photobooth-background-widget-style-embed"
                  />
                </FormGroup>
                <FormGroup className="mb-2">
                  <Form.Label>Show Book Now Button?</Form.Label>
                  <Form.Check
                    type="radio"
                    name="showBookNowButton"
                    checked={showBookNowButton === true}
                    onChange={() => this.setState({showBookNowButton: true})}
                    label="Yes"
                    id="photobooth-background-widget-show-book-now-button-yes"
                  />
                  <Form.Check
                    type="radio"
                    name="showBookNowButton"
                    checked={showBookNowButton === false}
                    onChange={() => this.setState({showBookNowButton: false})}
                    label="No"
                    id="photobooth-background-widget-show-book-now-button-no"
                  />
                </FormGroup>
                { showBookNowButton &&
                  <FormGroup className="mb-2">
                    <Form.Label>Button Text</Form.Label>
                    <FormControl
                      type="bookNowButtonText"
                      value={bookNowButtonText}
                      onChange={(e) => this.setState({bookNowButtonText: e.target.value})}
                    />
                  </FormGroup>
                }
                <FormGroup className="mb-2">
                  <Form.Label>Show Title?</Form.Label>
                  <Form.Check
                    type="radio"
                    name="showTitle"
                    checked={showTitle === true}
                    onChange={() => this.setState({showTitle: true})}
                    label="Yes"
                    id="photobooth-background-widget-show-title-yes"
                  />
                  <Form.Check
                    type="radio"
                    name="showTitle"
                    checked={showTitle === false}
                    onChange={() => this.setState({showTitle: false})}
                    label="No"
                    id="photobooth-background-widget-show-title-no"
                  />
                </FormGroup>
                { showTitle &&
                  <FormGroup className="mb-2">
                    <Form.Label>Show Pricing?</Form.Label>
                    <Form.Check
                      type="radio"
                      name="showPrice"
                      checked={showPrice === true}
                      onChange={() => this.setState({showPrice: true})}
                      label="Yes"
                      id="photobooth-background-widget-show-price-yes"
                    />
                    <Form.Check
                      type="radio"
                      name="showPrice"
                      checked={showPrice === false}
                      onChange={() => this.setState({showPrice: false})}
                      label="No"
                      id="photobooth-background-widget-show-price-no"
                    />
                  </FormGroup>
                }
                { style === "embed" &&
                  <FormGroup className="mb-2">
                    <Form.Label>Enable Compatibility Mode?</Form.Label>
                    <Form.Check
                      type="radio"
                      name="iframe"
                      checked={iframe === true}
                      onChange={() => this.setState({iframe: true})}
                      label="Yes"
                      id="photobooth-background-widget-iframe-yes"
                    />
                    <Form.Check
                      type="radio"
                      name="iframe"
                      checked={iframe === false}
                      onChange={() => this.setState({iframe: false})}
                      label="No"
                      id="photobooth-background-widget-iframe-no"
                    />
                    <Form.Text muted>Compatibility mode embeds widget within an iFrame for greater compatibility with some website builders</Form.Text>
                  </FormGroup>
                }
                { (style === "embed" && iframe) &&
                  <FormGroup className="mb-2">
                    <Form.Label>Maximum Widget Width</Form.Label>
                    <FormControl
                      type="text"
                      placeholder="Width in pixels or percentage"
                      value={maxWidth}
                      onChange={e => this.setState({ maxWidth: e.target.value })}
                    />
                  </FormGroup>
                }
                { !showAdvancedStylingOptions &&
                  <div>
                    <a href="#" onClick={this.showAdvancedStylingOptions}>
                      Show Advanced Options
                    </a>
                  </div>
                }
                { showAdvancedStylingOptions &&
                  <div>
                    <h3>Advanced</h3>
                    <Row>
                      <Col sm={12}>
                        <Form.Label>Override Text Color</Form.Label>
                        <ColorPicker
                          value={textColor}
                          onChange={value => this.setState({ textColor: value })}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Form.Label>Override Font Family</Form.Label>
                        <FormControl
                          type="text"
                          placeholder="Example: Helvetica, Arial, sans-serif"
                          value={fontFamily}
                          onChange={e => this.setState({ fontFamily: e.target.value })}
                        />
 
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Form.Label>Override Button Background Color</Form.Label>
                        <ColorPicker
                          value={buttonBackgroundColor}
                          onChange={value => this.setState({ buttonBackgroundColor: value })}
                        />
                      </Col>
                      <Col sm={12}>
                        <label>Override Button Text Color</label>
                        <ColorPicker
                          value={buttonForegroundColor}
                          onChange={value => this.setState({ buttonForegroundColor: value })}
                        />
                      </Col>
                      <Col sm={12}>
                        <FormGroup className="mb-2">
                          <Form.Label>Max Backdrops Per Page</Form.Label>
                          <FormControl
                            type="text"
                            value={perPage}
                            onChange={e => this.setState({perPage: e.target.value})}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                }
              </Col>
              <Col sm={{ span: 5, offset: 2 }}>
                <h3>HTML</h3>

                <p>Copy and paste the HTML below into your website to add your contact form</p>

                <FormGroup className="mb-2">
                  <CopyableTextBlock value={this.html} />
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const styles = {
  previewStyle: {
    wordWrap: 'break-word'
  },
  embedStyle: {
    height: 300
  }
}
