import React from 'react'
import TextField from '../ui/TextField'

const HoneypotField = ({ value, onChange, style }) => (
  <div className="cc-field-full-name-wrapper">
    <TextField
      name="fullName"
      label="Full Name"
      value={value}
      onChange={onChange}
      required
      style={style}
    />
  </div>
)

export default HoneypotField
