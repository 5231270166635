import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'
import { Modal } from 'react-bootstrap'
import TextField from '../../ui/TextField'
import TextAreaField from '../../ui/TextAreaField'
import DateField from '../../ui/DateField'
import SubmitField from '../../ui/SubmitField'
import UserField from './UserField'

@inject("dateFormat")
@inject("checklistStore")
@observer
export default class ChecklistItemEditor extends React.Component {
  static propTypes = {
    modalTitle: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    title: PropTypes.string,
    checkableType: PropTypes.string,
    checkableId: PropTypes.number,
    notes: PropTypes.string,
    loading: PropTypes.bool,
    errors: PropTypes.object,
  }

  static defaultProps = {
    title: "",
    notes: "",
    users: [],
    dueOn: null,
    loading: false,
    errors: {}
  }

  state = {
    title: this.props.title,
    notes: this.props.notes,
    users: this.props.users,
    dueOn: this.props.dueOn,
    checkableType: this.props.checkableType,
    checkableId: this.props.checkableId
  }

  componentDidUpdate(prevProps) {
    if(this.props.title !== prevProps.title || this.props.notes !== prevProps.notes || this.props.dueOn !== prevProps.dueOn || this.props.users !== prevProps.users || this.props.checkableType !== prevProps.checkableType || this.props.checkableId !== prevProps.checkableId) {
      this.setState({ title: this.props.title, notes: this.props.notes, dueOn: this.props.dueOn, users: this.props.users, checkableType: this.props.checkableType, checkableId: this.props.checkableId })
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    const { checklistStore, onSubmit } = this.props
    const { title, notes, dueOn, users } = this.state

    users.forEach(user => {
      const userRecord = {
        id: `${user.value}`,
        type: 'user',
        attributes: {...user.attributes}
      }
      checklistStore.users.set(`${user.value}`, userRecord)
    })

    if(await onSubmit({ title, notes, dueOn, users })) {
      this.setState({ title: this.props.title, notes: this.props.notes, dueOn: this.props.dueOn, users: this.props.users })
    }
  }

  valid = () => {
    const { title } = this.state
    return title.length > 0
  }

  render() {
    const { show, errors, onHide, onSubmit, loading, dateFormat, modalTitle, checklistStore } = this.props
    const { title, notes, dueOn, users, checkableType, checkableId } = this.state

    const userIds = users ? users.map(user => user.value) : []

    return (
      <Modal show={show}>
        <Modal.Header closeButton onHide={onHide}>
          <Modal.Title>{ modalTitle }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { errors["base"] &&
            <div className="alert alert-danger">
              { errors["base"] }
            </div>
          }
          <form onSubmit={this.handleSubmit}>
            <TextField
              name="title"
              label="Title"
              value={title}
              onChange={val => this.setState({ title: val })}
              labelStyle={styles.labelStyle}
              errors={errors}
              autoFocus
            />
            { checkableType != "User" &&
              <UserField
                name="assignedTo"
                label="Assigned To"
                value={users}
                onChange={val => this.setState({ users: val })}
              />
            }

            <TextAreaField
              name="notes"
              label="Additional Notes"
              value={notes}
              labelStyle={styles.labelStyle}
              onChange={val => this.setState({ notes: val })}
              errors={errors}
            />
            <div className="row mb-2">
              <div className="col-sm-6">
                <DateField
                  name="dueOn"
                  label="Due Date"
                  dateFormat={dateFormat}
                  value={dueOn}
                  labelStyle={styles.labelStyle}
                  onChange={date => this.setState({ dueOn: date}) }
                  errors={errors}
                />
              </div>
            </div>

            <SubmitField
              value={loading ? "Saving..." : "Save"}
              disabled={loading || !this.valid()}
              block
            />
          </form>
        </Modal.Body>
      </Modal>
    )
  }
}

const styles = {
  labelStyle: {
    fontSize: 14
  }
}
