import client from '../../../helpers/client'
// Make it easy to switch between getOnce and getAlways

function getAlways(...args) {
  const key = JSON.stringify(args)
  console.log(`fetching ${key}`)
  return new Promise((resolve, reject) => {
    client.get(...args)
      .then(response => {
        resolve(response)
      })
      .catch(error => reject(error))
  })
}

export default getAlways
