import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form, FormGroup } from 'react-bootstrap'

@inject("checklistStore")
@observer
export default class FilterControl extends React.Component {
  static propTypes = {
    checklistStore: PropTypes.object.isRequired
  }

  render() {
    const { checklistStore } = this.props
    const { assignedFilter, availableAssignedFilters } = checklistStore
    
    return (
      <div className="checklist-filter">
        <FormGroup>
          <Form.Select
            name={"form-select"}
            value={assignedFilter}
            onChange={e => (checklistStore.assignedFilter = e.target.value)}
            aria-label="Assigned Filter"
          >
            { availableAssignedFilters.map(assignedFilter =>
              <option key={assignedFilter} value={assignedFilter}>
                { assignedFilter }
              </option>
            )}
          </Form.Select>
        </FormGroup>
      </div>
    )
  }
}

