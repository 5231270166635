import React from 'react'
import { observer } from "mobx-react"
import { toJS, isObservableArray } from 'mobx'
import store from '../stores/store.js'
import ConditionalWrapperBuilderPreview from './ConditionalWrapperBuilderPreview'
import elementsForField from '../util/elementsForField.js'
import evaluateAllConditionals from '../util/evaluateAllConditionals'

const ConditionalWrapper = observer(({ field, children }) => {
  const { name, conditional } = field

  // If this field is not conditional, always render
  if(!conditional || !field.conditions || field.conditions.length == 0) {
    return children
  }

  // We're in builder mode -- always render it but show an indicator that it is conditional
  if(store.buildable) {
    return <ConditionalWrapperBuilderPreview field={field}>{children}</ConditionalWrapperBuilderPreview>
  }

  const shouldDisplayField = store.shouldDisplayField(field)

  if(shouldDisplayField) {
    return children
  }
  else {
    return null
  }
})

export default ConditionalWrapper
