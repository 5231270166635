import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'

const BasicToken = (props) => {
  const { data, innerProps, selectProps } = props

  const [ showModal, setShowModal ] = useState(false)


  function handleShowModal(e) {
    e.preventDefault()
    setShowModal(true)
  }

  function handleCloseModal() {
    setShowModal(false)
  }

  function handleSaveAndClose(e) {
    e.preventDefault()

    const currentValue = selectProps.value

    const newMergedValue = currentValue.map(currentvalue => {
      if(currentvalue.value === data.value) {
        return { ...currentvalue}
      }
      else {
        return currentvalue
      }
    })
    selectProps.onChange(newMergedValue)

    handleCloseModal()
  }

  return (
    <div className="token" {...innerProps}>
      <a href="#" role="button" onClick={handleShowModal} className="text-dark">
        { data.label }
      </a>
    </div>
  )
}



export default BasicToken
