import React from 'react'
import Booking from './Booking'

const StaffMember = ({ staffMember }) => {
  const { isBooked, isBlocked, isAvailableOnCurrentWeekday, isAvailable, isPartiallyAvailable } =  staffMember

  return (
    <div className="list-group-item">
      <div>
        <a href={`/admin/users/${staffMember.id}`} className="fw-medium">{ staffMember.fullName }</a>
        { isBooked && <span className="badge text-bg-secondary ms-1">Booked</span> }
        { isBlocked && <span className="badge text-bg-secondary ms-1">Blockout Date</span> }
        { isAvailable && <span className="badge text-bg-success ms-1">Available</span> }
        { isPartiallyAvailable && <span className="badge text-bg-warning ms-1">Partially Available</span>}
        { (!isAvailable && !isPartiallyAvailable) && <span className="badge text-bg-secondary ms-1">Not Available</span>}
      </div>
      { (staffMember.blockoutDateRanges.length > 0) &&
        <div>
          { staffMember.blockoutDateRanges.filter(blockoutDateRange => blockoutDateRange.reason).map(blockoutDateRange => (
            <div className="text-muted" key={blockoutDateRange.id}>
              <div>Blockout Date: { blockoutDateRange.reason }</div>
            </div>
          ))
          }
        </div>
      }
      { (staffMember.bookings.length > 0) && 
        <div>
          { staffMember.bookings.map(booking => (
            <Booking booking={booking} key={booking.id} />
          ))
          }
        </div>
      }
      { isPartiallyAvailable &&
        <div className="text-muted">
          <div>{ staffMember.availableOnCurrentWeekdayDescription }</div>
        </div>
      }
    </div>
  )
}

export default StaffMember
