import React, { useState, useEffect, forwardRef } from 'react'
import { buildApiClient } from '../../helpers/client'
import { addDays, subDays, format } from 'date-fns'
import DatePicker from 'react-datepicker'
import "./index.css"
import StaffMember from './components/StaffMember'
import sortStaff from './helpers/sort_staff'

const StaffForDate = ({ apiKey, dateFormat }) => {
  const [ready, setReady] = useState(false)
  const [activeDate, setActiveDate] = useState(new Date())
  const [staffMembers, setStaffMembers ] = useState([])
  const client = buildApiClient(apiKey)

  useEffect(() => {
    loadStaffActivityForDate()
  }, [activeDate])

  async function loadStaffActivityForDate() {
    const url = `/api/v1/reporting/staff_activity?active_date=${format(activeDate, 'yyyy-MM-dd')}`
    const response = await client.get(url)
    const { staffMembers } = response.data
    setStaffMembers(sortStaff(staffMembers))
    setReady(true)
  }

  function decrementDate(e) {
    e.preventDefault()
    setActiveDate(subDays(activeDate, 1))
  }

  function incrementDate(e) {
    e.preventDefault()
    setActiveDate(addDays(activeDate, 1))
  }

  if(!ready) {
    return null
  }

  const TitleInput = forwardRef(({ value, onClick }, ref) => {
    function handleClick(e) {
      e.preventDefault()
      onClick(e)
    }

    return (
      <a href="#" onClick={handleClick} ref={ref}>
        <h5>Staff Activity For { format(activeDate, dateFormat) }</h5>
      </a>
    )
  });

  return (
    <div className="widget__activity-for-date">
      <div className="d-flex align-items-baseline">
        <a href="#" onClick={decrementDate} className="me-2 subtle-edit">
          <i className="fa fa-chevron-left" />
        </a>
        <DatePicker
          name={name}
          customInput={<TitleInput />}
          onChange={setActiveDate}
          selected={activeDate}
          dateFormat={dateFormat}
        />
        <a href="#" onClick={incrementDate} className="ms-2 subtle-edit">
          <i className="fa fa-chevron-right" />
        </a>
      </div>

      { staffMembers.length > 0 ? (
        <div className="list-group list-group-flush">
          { staffMembers.map(staffMember => <StaffMember staffMember={staffMember} key={staffMember.id} />)}
        </div>
      ) : (
        <div className="text-muted">You do not have any active staff.</div>
      )}
    </div>
  )
}

export default StaffForDate
