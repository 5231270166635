import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, FormControl, InputGroup, Button } from 'react-bootstrap'
import TimelineTimeField from './TimelineTimeField.js'
import {observer} from "mobx-react"
import store from '../stores/store.js'
import FaIcon from '../../../helpers/fa_icon.js'

const TimelineStartTimeField = observer(({ field }) => {
  const [editable, setEditable] = useState(false)

  const { uuid } = field
  const instance = 0
  const timelineStartsAt = store.valueFor(uuid, "timelineStartsAt", instance)
  const errorMessage = store.errorFor(uuid, "timelineStartsAt", instance)
  const timeFormat = store.timeFormat

  function startEditing(e) {
    e.preventDefault()
    setEditable(true)
  }

  function finishEditing(e) {
    e.preventDefault()
    setEditable(false)
  }

  if(editable) {
    return (
      <FormGroup validationState={errorMessage ? "error" : null} className="timeline-editor-form">
        <Form.Label>Start Time</Form.Label>
        <InputGroup>
          <TimelineTimeField
            name={name}
            onChange={timelineStartsAt => store.setValueFor(uuid, "timelineStartsAt", instance, timelineStartsAt)}
            value={timelineStartsAt}
            timeFormat={timeFormat}
            disabled={!store.editable}
            isInvalid={!!errorMessage}
          />
          <Button type="submit" onClick={finishEditing} variant="primary">Done</Button>
        </InputGroup>
        { errorMessage && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{errorMessage}</Form.Control.Feedback> }
      </FormGroup>
    )
  }
  else if(timelineStartsAt) {
    return (
      <a className="timeline-preview__label" href="#" onClick={startEditing}>
        <FaIcon icon="clock-o" text={timelineStartsAt} />
      </a>
    )
  }
  else {
    return (
      <a className="timeline-preview__label" href="#" onClick={startEditing}>
        <FaIcon icon="clock-o" text="Set Time" />
      </a>
    )
  }

})

export default TimelineStartTimeField
