import React, { useState } from 'react'
import Category from './components/Category'
import RecommendedBackdrops from './components/RecommendedBackdrops'
import NoResults from './components/NoResults'
import SearchBar from './components/SearchBar'
import { backdropsForCategory, resultsForSearch, sortByPosition, getCsrfToken } from './util'

class BackdropBrowser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search: ""
    }

    this.csrfToken = getCsrfToken()
    const alternateBackdropPrices = props.alternateBackdropPrices
    const allBackdrops = props.backdrops.map(backdrop => {
      const alternatePrice = alternateBackdropPrices.find(price => price.photoboothBackgroundId === backdrop.id)
      if(alternatePrice) {
        return {
          ...backdrop,
          price: alternatePrice.price,
          priceCents: alternatePrice.priceCents,
          priceCurrency: alternatePrice.priceCurrency,
          priceFormatted: alternatePrice.priceFormatted
        }
      }
      else {
        return backdrop
      }
    })
    this.recommendedBackdrops = allBackdrops.filter(backdrop => props.recommendedBackdropIds.includes(backdrop.id))
    this.remainingBackdrops = allBackdrops.filter(backdrop => !props.recommendedBackdropIds.includes(backdrop.id))
  }

  setSearch = (search) => {
    this.setState({ search })
  }

  render() {
    const { categories, perPage, chooseUrl  } = this.props
    const { search } = this.state

    const recommendedBackdrops = resultsForSearch(this.recommendedBackdrops, search)
    const remainingBackdrops = resultsForSearch(this.remainingBackdrops, search)
    const anyResultsFound = recommendedBackdrops.length + remainingBackdrops.length > 0

    return (
      <>
        <div className="mb-3">
          <SearchBar value={search} onChange={this.setSearch} />
        </div>
        { (search && !anyResultsFound) &&
          <NoResults onChange={this.setSearch} />
        }
        <RecommendedBackdrops
          backdrops={recommendedBackdrops}
          chooseUrl={chooseUrl}
          csrfToken={this.csrfToken}
        />
        { sortByPosition(categories).map(category => 
          <Category
            key={category.id}
            category={category}
            backdrops={backdropsForCategory(remainingBackdrops, category)}
            chooseUrl={chooseUrl}
            search={search}
            perPage={perPage}
            csrfToken={this.csrfToken}
          />
        )}
      </>
    )
  }
}

export default BackdropBrowser
