import {observer} from "mobx-react"
import React from 'react'
import PropTypes from 'prop-types'

import store from '../stores/store.js'

@observer
export default class Visibility extends React.Component {
  render() {
    if(this.props.showOnPrint) {
      if(store.printVersion) {
        return <div>{this.props.children}</div>
      }
      else {
        return null
      }
    }
    else if(this.props.hideOnPrint) {
      if(store.printVersion) {
        return null
      }
      else {
        return <div>{this.props.children}</div>
      }
    }
  }
}
