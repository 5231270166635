import React from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Button, FormGroup, FormControl } from 'react-bootstrap'
import {observer} from "mobx-react"
import store from '../stores/store.js'
import FaIcon from '../../../helpers/fa_icon.js'
import 'whatwg-fetch'

@observer
export default class SpotifyPlaylist extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      value: store.valueFor(this.props.field.uuid, "spotify", this.props.instance || 0),
      playlistName: null,
      songs: [],
      loading: false,
      error: false
    }

    store.registerLoadableComponent()
  }

  componentDidMount() {
    this.loadSongs()
  }

  handleSaveModal = () => {
    this.setState({showModal: false})
    store.setValueFor(this.props.field.uuid, "spotify", this.props.instance || 0, this.state.value)
    this.loadSongs()
  }

  handleShowModal = (e) => {
    e.preventDefault()
    this.setState({showModal: true})
  }

  loadSongs() {
    if(this.state.value) {
      this.setState({loading: true, songs: []})
      const { eventId, formId } = store
      let url = `/events/${eventId}/forms/${formId}/spotify_songs.json?playlist_url=${this.state.value}`

      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin'
      }).then((response) => {
        if(!response.ok) {
          let error = new Error(response.statusText)
          error.response = response
          throw error
        }
        return response
      })
      .then(response => response.json())
      .then((response) => {
        this.setState({playlistName: response.playlist_name, songs: response.songs, loading: false, error: false})
        store.componentFinishedLoading()
      })
      .catch((error) => {
        this.setState({error: true, loading: false, songs: []})
        store.componentFinishedLoading()
      })
    }
    else {
      store.componentFinishedLoading()
    }
  }

  renderSpotifyLink() {
    const { value, loading, error, playlistName } = this.state

    if(loading) {
      return (
        <FaIcon icon="spinner" spin text="Loading Spotify Playlist..." />
      )
    }
    else if(error) {
      const url = store.valueFor(this.props.field.uuid, "spotify", this.props.instance || 0)
      return (
        <FaIcon icon="exclamation-circle" text={`Unable to load: ${url}.  Please try again.`} />
      )
    }
    else if(playlistName) {
      return (
        <FaIcon icon="spotify" text={playlistName} />
      )
    }
    else {
      return (
        <FaIcon icon="spotify" text={this.props.field.label} />
      )
    }
  }

  renderSongs() {
    const { songs } = this.state

    if(!songs || !songs.length) {
      const url = store.valueFor(this.props.field.uuid, "spotify", this.props.instance || 0)
      if(url) {
        return (
          <div>No songs found in playlist { url }</div>
        )
      }
      else {
        return null
      }
    }

    return (
      <div className="spotify-container">
        { songs.map((song, index) => 
          <div key={index} className="row">
            <div className="col-1 text-center no-print">
              <img src={song.image_url} className="img-fluid" />
            </div>
            <div className="col-4">
              <p>
                { song.track_name }
                <br />
                { song.artist_name }
              </p>
            </div>

            <div className="col-3">
              { song.album_name }
            </div>

            <div className="col-4 no-print">
              { song.play_url ?
                <audio src={song.play_url} controls={true} style={styles.audio} />
              : 
                <p>No Preview Available</p>
              }
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    const { field } = this.props
    const { placeholder, uuid } = field
    let instance = this.props.instance || 0

    const { showModal, value } = this.state

    return (
      <div className="outer-form-container">
        <FormGroup>
          <div className="d-flex flex-row justify-content-between">
            <Form.Label onClick={this.handleShowModal} style={styles.link}>
              { this.renderSpotifyLink() }
            </Form.Label>
            { (!showModal && value) ?
              <div>
                <a href={value} target="_blank">Show on Spotify</a>
                { " | " }
                <a href="#" onClick={this.handleShowModal}>Change Playlist</a>
              </div>
              : 
              <div>
                <a href="#" onClick={this.handleShowModal}>Set Playlist</a>
              </div>
            }
          </div>
        </FormGroup>
        { this.renderSongs() }
        <Modal show={showModal} onHide={() => this.setState({showModal: false})}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FaIcon icon="spotify" text="Spotify Playlist" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <FormControl
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={e => this.setState({value: e.target.value})}
                disabled={!store.editable}
              />
              <Form.Text>To get a link to your Spotify playlist choose "Share" and then "Copy Spotify Link". Any changes you make to your Spotify playlist in the future will automatically be reflected here.</Form.Text>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" className="w-100" onClick={this.handleSaveModal}>Save</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const styles = {
  link: {
    cursor: 'pointer'
  },
  audio: {
    maxWidth: "100%"
  }
}
