import React from 'react';

const LeadBadge = ({ archived, spam, convertedToEvent }) => {
  return (
    <>
      <div className="badge text-bg-orange me-1">Lead</div>
      { archived && <div className="badge text-bg-danger me-1">Archived</div> }
      { spam && <div className="badge text-bg-danger me-1">Flagged as Spam</div> }
      { convertedToEvent && <div className="badge text-bg-dark me-1">Converted to Event</div> }
    </>
  )
}

export default LeadBadge;
