import React from 'react'
import { toJS } from 'mobx'
import PropTypes from 'prop-types'

export default class TooltipDisplay extends React.Component {
  static propTypes = {
    payload: PropTypes.array,
    label: PropTypes.string,
  }

  render() {
    const { payload, label } = this.props

    if(payload && payload.length > 0) {
      const currentName = payload[0].payload.currentName
      const currentCount = payload[0].payload.currentCount

      const previousName = payload[0].payload.previousName
      const previousCount = payload[0].payload.previousCount

      const differenceCount = currentCount - previousCount
      const differenceCountPercent = (differenceCount / previousCount) * 100

      return (
        <div className="tooltip-display">
          <div className="tooltip-display-total">
            <div className="tooltip-display-label">Current Period: { currentName }</div>
            <div>{ currentCount } { currentCount === 1 ? "Proposal" : "Proposals" }</div>
          </div>
          <div className="tooltip-display-total">
            <div className="tooltip-display-label">Previous Period: { previousName }</div>
            <div>{ previousCount } { previousCount === 1 ? "Proposal" : "Proposals" }</div>
          </div>
          <div className="tooltip-display-total">
            <div className="tooltip-display-label">Change</div>
            <div className={differenceCount >= 0 ? "text-success" : "text-danger" }>
              { differenceCount } { differenceCount === 1 ? "Proposal" : "Proposals" } ({ differenceCountPercent.toFixed(2) }%)
            </div>
          </div>
        </div>
      )
    }
    else {
      return null
    }
  }
}
