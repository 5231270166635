import { autorun, observable, action, computed } from 'mobx'
import { create, persist } from 'mobx-persist'
import client from '../../../helpers/client'
import { asNormalizedMaps, asNormalizedArrays } from '../../../helpers/json-normalizer'
import { format, add, sub, startOfMonth, startOfWeek, startOfQuarter, startOfYear, endOfMonth, endOfYear } from 'date-fns'
import flatMap from 'lodash/flatMap'

const DATE_PRESETS = [
  {value: "last7days", label: "Last 7 days", validFor: ["bookings", "payments", "bookedOn"]},
  {value: "last4weeks", label: "Last 4 weeks", validFor: ["bookings", "payments", "bookedOn"]},
  {value: "last3months", label: "Last 3 months", validFor: ["bookings", "payments", "bookedOn"]},
  {value: "last12months", label: "Last 12 months", validFor: ["bookings", "payments", "bookedOn"]},
  {value: "mtd", label: "Month to date", validFor: ["bookings", "payments", "bookedOn"]},
  {value: "qtd", label: "Quarter to date", validFor: ["bookings", "payments", "bookedOn"]},
  {value: "ytd", label: "Year to date", validFor: ["bookings", "payments", "bookedOn"]},
  {value: "lastyear", label: "Last year", validFor: ["bookings", "payments", "bookedOn"]},
  {value: "thisyear", label: "This year", validFor: ["bookings", "payments", "bookedOn"]},
  {value: "nextyear", label: "Next year", validFor: ["bookings"]},
  {value: "last3next3", label: "+/- 3 Months", validFor: ["bookings"]},
  {value: "custom", label: "Custom", validFor: ["bookings", "payments", "bookedOn"]},
]


class Store {
  @observable ready = false
  @observable loading = false
  @persist @observable chartType = "bookings"
  @persist @observable interval = "month"
  @persist @observable _datePreset = "last12months"
  @persist @observable brandId = ""
  @observable data
  @observable startDate
  @observable endDate

  constructor({ apiKey, dateFormat, showBrands, brands }) {
    this.apiKey = apiKey
    this.dateFormat = dateFormat
    this.showBrands = showBrands
    this.brands = brands
  }

  async setup() {
    if(this._datePreset == "custom") {
      this.datePreset = "last12months"
    }
    else {
      this.datePreset = this._datePreset // Set our default dates if they have it set to something like "last year"
    }
    await this.fetchReportData()
    this.ready = true

    autorun(() => {
      if(this.ready) {
        this.startDate, this.endDate, this.interval, this.chartType
        this.fetchReportData()
      }
    })
  }

  @computed get datePreset() {
    return this._datePreset
  }

  set datePreset(newDatePreset) {
    this._datePreset = newDatePreset
    switch(newDatePreset) {
      case "last7days":
        this.startDate = sub(new Date(), {days: 6})
        this.endDate = new Date()
        break;
      case "last4weeks":
        this.startDate = startOfWeek(sub(new Date(), {weeks: 3}))
        this.endDate = new Date()
        break;
      case "last3months":
        this.startDate = startOfMonth(sub(new Date(), {months: 2}))
        this.endDate = new Date()
        break;
      case "last12months":
        this.startDate = startOfMonth(sub(new Date(), {months: 11}))
        this.endDate = new Date()
        break;
      case "mtd":
        this.startDate = startOfMonth(new Date())
        this.endDate = new Date()
        break;
      case "qtd":
        this.startDate = startOfQuarter(new Date())
        this.endDate = new Date()
        break;
      case "ytd":
        this.startDate = startOfYear(new Date())
        this.endDate = new Date()
        break;
      case "last3next3":
        this.startDate = startOfMonth(sub(new Date(), {months: 3}))
        this.endDate = endOfMonth(add(new Date(), {months: 3}))
        break;
      case "lastyear":
        this.startDate = startOfYear(sub(new Date(), {years: 1}))
        this.endDate = endOfYear(this.startDate)
        break;
      case "thisyear":
        this.startDate = startOfYear(new Date())
        this.endDate = endOfYear(new Date())
        break;
      case "nextyear":
        this.startDate = startOfYear(add(new Date(), {years: 1}))
        this.endDate = endOfYear(this.startDate)
        break;
      default:
        console.log(newDatePreset)
    }
  }

  @computed get formattedStartDate() {
    if(!this.startDate) {
      return null
    }

    try {
      return format(this.startDate, this.dateFormat)
    }
    catch(e) {
      console.warn(e)
      return null
    }
  }

  @computed get formattedEndDate() {
    if(!this.endDate) {
      return null
    }

    try {
      return format(this.endDate, this.dateFormat)
    }
    catch(e) {
      console.warn(e)
      return null
    }
  }

  @computed get datePresets() {
    return DATE_PRESETS.filter(preset => preset.validFor.includes(this.chartType))
  }

  await
  fetchReportData() {
    switch(this.chartType) {
      case "bookings":
        return this.fetchBookingsReport()
      case "payments":
        return this.fetchPaymentsReport()
      case "bookedOn":
        return this.fetchBookedOnReport()
      default:
        throw new Error(`Invalid chartType: ${this.chartType}`)
    }
  }

  fetchBookingsReport() {
    const { interval, formattedStartDate, formattedEndDate } = this

    const params = new URLSearchParams({
      api_key: this.apiKey,
      interval,
      starts_at: formattedStartDate,
      ends_at: formattedEndDate,
      brand_id: this.brandId,
    })

    this.loading = true
    return client.get(`/api/v1/reporting/revenue_projection_summary?${params.toString()}`)
    .then(response => {
      this.data = response.data.results
      this.loading = false
    })
  }

  fetchPaymentsReport() {
    const { interval, formattedStartDate, formattedEndDate } = this

    const params = new URLSearchParams({
      api_key: this.apiKey,
      interval,
      starts_at: formattedStartDate,
      ends_at: formattedEndDate,
      brand_id: this.brandId,
    })

    this.loading = true
    return client.get(`/api/v1/reporting/payment_receipt_summary?${params.toString()}`)
    .then(response => {
      this.data = response.data.results
      this.loading = false
    })
  }

  fetchBookedOnReport() {
    const { interval, formattedStartDate, formattedEndDate } = this

    const params = new URLSearchParams({
      api_key: this.apiKey,
      interval,
      starts_at: formattedStartDate,
      ends_at: formattedEndDate,
      brand_id: this.brandId,
    })

    this.loading = true
    return client.get(`/api/v1/reporting/booked_on_summary?${params.toString()}`)
    .then(response => {
      this.data = response.data.results
      console.log({ data: this.data })
      this.loading = false
    })
  }
}

export function createPersistedStore(persistenceKey, args) {
  if(persistenceKey) {
    const hydrate = create({})
    const store = new Store(args)
    hydrate(persistenceKey, store).then(() => store.setup())
    return store
  }
  else {
    const store = new Store(args)
    store.setup()
    return store
  }
}

export default Store
