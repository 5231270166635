import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import BookingChart from './BookingChart'
import PaymentChart from './PaymentChart'
import BookedOnChart from './BookedOnChart'

const ChartView = ({ store }) => {
  const { chartType } = store

  switch(chartType) {
    case "bookings":
      return <BookingChart />
    case "payments":
      return <PaymentChart />
    case "bookedOn":
      return <BookedOnChart />
    default:
      return null
  }
}

export default inject("store")(observer(ChartView))
