import React from 'react'
import { parseISO, format } from 'date-fns'

const ScheduledPayment = ({ scheduledPayment, dateFormat }) => (
  <li>
    { format(parseISO(scheduledPayment.attributes.dueOn, new Date()), dateFormat)}:
    { " " }
    { (scheduledPayment.attributes.remainingAmountCents < scheduledPayment.attributes.amountCents) ?
      <>
        { scheduledPayment.attributes.remainingAmountFormatted }
        { " " }
        ({ scheduledPayment.attributes.appliedAmountFormatted } already applied)
      </>
      :
      scheduledPayment.attributes.amountFormatted
    }
  </li>
)

export default ScheduledPayment
