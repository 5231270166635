import React, { useRef, useState } from 'react';
import ToolbarExpanded from './ToolbarExpanded';
import ToolbarCompact from './ToolbarCompact';
import useSize from '@react-hook/size'

const Toolbar = ({ children, ...props }) => {
  const target = useRef(null)
  const [width, height] = useSize(target)

  return (
    <div ref={target}>
      { width < 500 ?
        <ToolbarCompact {...props}>{ children }</ToolbarCompact>
        :
        <ToolbarExpanded {...props}>{ children }</ToolbarExpanded>
      }
    </div>
  )
}

export default Toolbar;
