import React from 'react'
import PropTypes from 'prop-types'
import { Provider, inject, observer } from 'mobx-react'
import Store from './stores/Store.js'
import CartPreview from './components/CartPreview'
import Continue from './components/Continue'
import DailySelector from './components/DailySelector'
import DateSelector from './components/DateSelector'
import HourlySelector from './components/HourlySelector'
import Loader from './components/Loader'
import PackageSelector from './components/PackageSelector'
import TimeSlotSelector from './components/TimeSlotSelector'
import Unavailable from './components/Unavailable'
import UnitRangeSelector from './components/UnitRangeSelector'
import UnitSelector from './components/UnitSelector'
import { getFormValue, setFormValue, parseLength, parseDate, formatDate, persistFormValue } from './util'
import './style.scss'

@observer
export default class AvailabilityChecker extends React.Component {
  static propTypes = {
    host: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired,
    dateFormat: PropTypes.string.isRequired,
    selectedPackage: PropTypes.object.isRequired,
    showPackageSelector: PropTypes.bool,
    dateTarget: PropTypes.string,
    timeTarget: PropTypes.string,
    formTarget: PropTypes.string,
    lengthInMinutesTarget: PropTypes.string,
    lengthInDaysTarget: PropTypes.string,
    unitsTarget: PropTypes.string,
    packageUnitRangeIdTarget: PropTypes.string,
    franchise: PropTypes.object,
    unavailableHtml: PropTypes.string.isRequired,
    packageUnitRanges: PropTypes.array
  }
  
  static defaultProps = {
    showPackageSelector: true
  }

  store = new Store({
    host: this.props.host,
    apiKey: this.props.apiKey,
    selectedPackage: this.props.selectedPackage,
    franchise: this.props.franchise,
    cartHtml: this.props.cartHtml,
    unavailableHtml: this.props.unavailableHtml
  })

  componentDidMount() {
    const {
      dateFormat,
      dateTarget,
      formTarget,
      lengthInDaysTarget,
      lengthInMinutesTarget,
      packageUnitRangeIdTarget,
      timeTarget,
      unitsTarget,
    } = this.props

    const selectedDate = parseDate(getFormValue(dateTarget), dateFormat)
    const selectedTime = getFormValue(timeTarget)
    const lengthInMinutes = parseLength(getFormValue(lengthInMinutesTarget))
    const lengthInDays = parseLength(getFormValue(lengthInDaysTarget))
    const units = parseLength(getFormValue(unitsTarget))
    const packageUnitRangeId = getFormValue(packageUnitRangeIdTarget)

    this.store.setup({
      lengthInMinutes,
      lengthInDays,
      selectedDate,
      selectedTime,
      units,
      packageUnitRangeId,
      packageUnitRangeIdTarget,
      onSetLengthInMinutes: (lengthInMinutes) => {
        setFormValue(lengthInMinutesTarget, lengthInMinutes)
        persistFormValue(lengthInMinutesTarget, lengthInMinutes)
      },
      onSetLengthInDays: (lengthInDays) => {
        setFormValue(lengthInDaysTarget, lengthInDays)
        persistFormValue(lengthInDaysTarget, lengthInDays)
      },
      onSetDate: (date) => {
        setFormValue(dateTarget, formatDate(date, dateFormat))
        persistFormValue(dateTarget, formatDate(date, dateFormat))
      },
      onSetTime: (localizedTime) => {
        setFormValue(timeTarget, localizedTime)
        persistFormValue(timeTarget, localizedTime)
        // document.querySelector(formTarget).submit()
      },
      onSetUnits: (units) => {
        setFormValue(unitsTarget, units)
        persistFormValue(unitsTarget, units)
      },
      onSetPackageUnitRangeId: (id) => {
        setFormValue(packageUnitRangeIdTarget, id)
        persistFormValue(packageUnitRangeIdTarget, id)
      },
      onSubmit: () => {
        document.querySelector(formTarget).submit()
      }
    })
  }

  renderContent() {
    const { store } = this
    const { ready } = store

    const {
      showCartPreview,
      showContinue,
      showDailySelector,
      showDateSelector,
      showHourlySelector,
      showLoader,
      showPackageSelector,
      showTimeSlotSelector,
      showUnavailable,
      showUnitRangeSelector,
      showUnitSelector,
    } = store

    if(ready) {
      return (
        <div className="availability-checker-widget">
          <div className="package-col">
            { showPackageSelector && <PackageSelector /> }
          </div>
          <div className="calendar-col">
            { showDateSelector && <DateSelector /> }
          </div>
          <div className="time-col">
            { showLoader ?
              <Loader />
              :
              <>
                { showHourlySelector && <HourlySelector /> }
                { showDailySelector && <DailySelector /> }
                { showUnitSelector && <UnitSelector /> }
                { showUnitRangeSelector && <UnitRangeSelector /> }
                { (showHourlySelector || showDailySelector || showUnitSelector || showUnitRangeSelector ) &&
                  <hr />
                }
                { showTimeSlotSelector && <TimeSlotSelector /> }
                { showCartPreview && <CartPreview /> }
                { showContinue && <Continue /> }
                { showUnavailable && <Unavailable /> }
              </>
            }
          </div>
        </div>
      )
    }
    else {
      return null
    }

  }


  render() {
    const { iframe, dateFormat, showPackageSelector, packageUnitRanges, formErrors } = this.props
    const { store } = this
    console.log({ formErrors })

    return (
      <Provider
        store={store}
        dateFormat={dateFormat}
        showPackageSelector={showPackageSelector}
        packageUnitRanges={packageUnitRanges}
        formErrors={formErrors}
      >
        <div className={`wrapper ${iframe ? "within-iframe" : ""}`}>
          { this.renderContent() }
        </div>
      </Provider>
    )
  }
}

