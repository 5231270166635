import React from 'react'

const ChooseButton = ({ chooseUrl, csrfToken, backdrop, btnClass }) => (
  <form method="POST" action={chooseUrl} className="inline-block">
    <input type="hidden" value={csrfToken} name="authenticity_token" />
    <input type="hidden" value={backdrop.id} name="event_photobooth_background[photobooth_background_id]" />
    <input type="submit" value="Choose" className={`btn btn-call-to-action ${btnClass}`} id={`select-photobooth-background-${backdrop.id}`} />
  </form>
)

export default ChooseButton
