import React from 'react'

const SongProviderLabel = ({ provider }) => {
  switch(provider) {
    case 'spotify':
      return <span className="provider-label"><i className="fa fa-spotify" /> Spotify</span>
    case 'apple':
      return <span className="provider-label"><i className="fa fa-apple" /> Apple Music</span>
    case 'youtube':
      return <span className="provider-label"><i className="fa fa-youtube" /> YouTube</span>
    case 'tidal':
      return <span className="provider-label"><i className="fa fa-tidal" /> Tidal</span>
    case 'soundcloud':
      return <span className="provider-label"><i className="fa fa-soundcloud" /> SoundCloud</span>
    default:
      return null
  }
}

export default SongProviderLabel
