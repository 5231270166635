import React from 'react'
import PropTypes from 'prop-types'



export default class SimulationResults extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    taxRate: PropTypes.string,
    errorMessage: PropTypes.string
  }

  renderErrors() {
    const { errorMessage } = this.props
    return (
      <div className="text-danger">
        <strong>Please correct the following errors in order to calculate tax rates: </strong>
        { errorMessage }
      </div>
    )
  }

  renderTaxRate() {
    const { name, taxRate } = this.props

    return (
      <div>
        <div>Zone Name: { name }</div>
        <div>Tax Rate: { taxRate }</div>
      </div>
    )
  }

  renderNotFound() {
    return (
      <div>
        This address does not fall within any of your tax zones
      </div>
    )
  }

  renderResults() {
    const { errorMessage, name, taxRate } = this.props

    if(errorMessage) {
      return this.renderErrors()
    }
    else if(name || taxRate) {
      return this.renderTaxRate()
    }
    else {
      return this.renderNotFound()
    }

  }

  render() {
    return (
      <div style={styles.wrapper}>
        <hr />
        <h4>Tax Rate Results</h4>
        { this.renderResults() }
      </div>
    )
  }
}

const styles = {
  wrapper: {
    textAlign: "center"
  }
}
