function backdropsForCategory(backdrops, category) {
  return backdrops.filter(backdrop => backdrop.backdropCategoryId === category.id)
}

function extractSlidesFromBackdrop(backdrop) {
  let slides = []

  if(backdrop.thirdPartyVideoId) {
    slides.push({ type: "video", videoId: backdrop.thirdPartyVideoId })
  }

  if(backdrop.primaryImage && backdrop.primaryImage.videoVariant) {
    slides.push({type: "image", url: backdrop.primaryImage.videoVariant})
  }

  backdrop.additionalImages.forEach(image => {
    if(image && image.videoVariant) {
      slides.push({type: "image", url: image.videoVariant})
    }
  })

  return slides
}

function getCsrfToken() {
  const elem = document.getElementsByName("csrf-token")[0]

  if(elem) {
    return elem.content
  }
  else {
    return null
  }
}


function paginatedResults(results, currentStartPage, currentEndPage, perPage) {
  return results.slice((currentStartPage - 1) * perPage, currentEndPage * perPage)
}

function sortByPosition(collection) {
  return collection.sort((a, b) => a.position - b.position)
}

function sortByName(collection) {
  return collection.sort((a, b) => a.name.localeCompare(b.name))
}

function backdropContains(backdrop, search) {
  const normalizedSearch = search.toLowerCase()
  if(backdrop.name.toLowerCase().includes(normalizedSearch)) {
    return true
  }
  if(backdrop.description.toLowerCase().includes(normalizedSearch)) {
    return true
  }

  return false
}

function resultsForSearch(backdrops, search) {
  if(!search) {
    return backdrops
  }

  return backdrops.filter(backdrop => backdropContains(backdrop, search))
}


export {
  backdropsForCategory,
  extractSlidesFromBackdrop,
  getCsrfToken,
  paginatedResults,
  sortByName,
  sortByPosition,
  resultsForSearch
}
