import { observable, action, computed, toJS } from 'mobx'
import client from '../../helpers/client'
import { asNormalizedMaps, asNormalizedArrays } from '../../helpers/json-normalizer'
import { snakeCase } from 'change-case'
import format from 'date-fns/format'

class ObservableStore {
  @observable ready = false
  @observable loading = true
  @observable formSettings = {}
  @observable eventTypes

  @observable firstName = ""
  @observable lastName = ""
  @observable companyName = ""
  @observable email = ""
  @observable phone = ""
  @observable venueName = ""
  @observable venueStreet = ""
  @observable venueCity = ""
  @observable venueState = ""
  @observable venueZip = ""
  @observable customerStreet = ""
  @observable customerCity = ""
  @observable customerState = ""
  @observable customerZip = ""
  @observable estimatedBudget = ""
  @observable estimatedNumberGuests = ""
  @observable indoorOutdoor = ""
  @observable stairSetup = ""
  @observable leadSource = ""
  @observable subject = ""
  @observable message = ""
  @observable eventType = ""
  @observable serviceId = ""
  @observable packageId = ""
  @observable employeeId = ""
  @observable eventDate = null
  @observable eventTime = null
  @observable eventLength = ""
  @observable contactPreference = ""
  @observable customQuestionValues = {}
  @observable fullName = "" // Honeypot Field !!!

  constructor({ widgetStore, packageId, referredByUserId }) {
    this.widgetStore = widgetStore
    this.host = widgetStore.host
    if(packageId) {
      this.packageId = packageId
    }
    if(referredByUserId) {
      this.referredByUserId = referredByUserId
    }
  }

  async setup(contactFormId) {
    this.loading = true
    await this.loadContactForm(contactFormId)
    this.ready = true
    this.loading = false
  }

  loadContactForm(contactFormId) {
    return client.get(`${this.host}/api/v1/contact_forms/${contactFormId}.json`)
      .then(response => {
        this.formSettings = response.data.data.attributes
      })
      .catch(error => (this.widgetStore.unrecoverableError = error))
  }

  @action postLead({ onSuccess, onError }) {
    client.post(`${this.host}/api/v1/leads.json`, this.apiData)
      .then(response => onSuccess && onSuccess(response) )
      .catch(error => {
        try {
          onError && onError(error)
        }
        catch(error) {
          this.widgetStore.unrecoverableError = error
        }
      })
  }

  @computed get apiData() {
    const { formSettings, firstName, lastName, companyName, email, phone, venueName, venueStreet,
      venueCity, venueState, venueZip, customerStreet, customerCity, customerState, customerZip, estimatedBudget, estimatedNumberGuests,
      subject, message, leadSource, eventType, serviceId, packageId, employeeId, eventDate,
      eventTime, eventLength, contactPreference, indoorOutdoor, stairSetup, referredByUserId
    } = this
    const { fullName } = this // Honeypot field!

    let data = {
      contactFormId: formSettings.id
    }

    if(referredByUserId) {
      data["referredByUserId"] = referredByUserId
    }
    if(formSettings.firstNameField) {
      data["firstName"] = firstName
    }
    if(formSettings.lastNameField) {
      data["lastName"] = lastName
    }
    if(formSettings.companyNameField) {
      data["companyName"] = companyName
    }
    if(formSettings.emailField) {
      data["email"] = email
    }
    if(formSettings.phoneField) {
      data["phone"] = phone
    }
    if(formSettings.venueNameField) {
      data["venueName"] = venueName
    }
    if(formSettings.venueAddressField) {
      data["venueStreet"] = venueStreet
      data["venueCity"] = venueCity
      data["venueState"] = venueState
      data["venueZip"] = venueZip
    }
    if(formSettings.customerAddressField) {
      data["customerStreet"] = customerStreet
      data["customerCity"] = customerCity
      data["customerState"] = customerState
      data["customerZip"] = customerZip
    }
    if(formSettings.estimatedBudgetField) {
      data["estimatedBudget"] = estimatedBudget
    }
    if(formSettings.estimatedNumberGuestsField) {
      data["estimatedNumberGuests"] = estimatedNumberGuests
    }
    if(formSettings.indoorOutdoorField) {
      data["indoorOutdoor"] = indoorOutdoor
    }
    if(formSettings.stairSetupField) {
      data["stairSetup"] = stairSetup
    }
    if(formSettings.subjectField) {
      data["subject"] = subject
    }
    if(formSettings.messageField) {
      data["message"] = message
    }
    if(formSettings.leadSourceField) {
      data["leadSource"] = leadSource
    }
    if(formSettings.eventTypeField) {
      data["eventType"] = eventType
    }
    if(formSettings.serviceIdField) {
      data["serviceId"] = serviceId
    }
    if(formSettings.packageIdField || packageId) {
      data["packageId"] = packageId
    }
    if(formSettings.employeeIdField) {
      data["employeeId"] = employeeId
    }
    if(formSettings.eventDateField) {
      data["eventDate"] = eventDate ? format(eventDate, formSettings.dateFormat) : ""
    }
    if(formSettings.eventTimeField) {
      data["eventTime"] = eventTime ? format(eventTime, formSettings.timeFormat) : ""
    }
    if(formSettings.eventLengthField) {
      data["eventLength"] = eventLength
    }
    if(formSettings.contactPreferenceField) {
      data["contactPreference"] = contactPreference
    }

    data["customQuestions"] = toJS(this.customQuestionValues)

    // Honeypot field!!
    if(fullName) {
      data["fullName"] = fullName
    }

    return data
  }

  @computed get bookingUrl() {
    const allowedKeys = [ "firstName", "lastName", "companyName", "email",
      "phone", "venueName", "venueStreet", "venueCity", "venueState",
      "venueZip", "serviceId", "packageId" ]

    const queryString = Object.keys(this.apiData)
      .filter(key => allowedKeys.includes(key))
      .map(key => {
        return encodeURIComponent(snakeCase(key)) + '=' + encodeURIComponent(this.apiData[key])
      }).join('&');

    return `${this.formSettings.bookingUrl}?${queryString}`
  }

  customValueFor(columnName) {
    const question = this.formSettings.customQuestions.find(question => question.columnName == columnName)
    switch(question.kind) {
      case "address":
        return this.customQuestionValues[columnName] || {street: "", city: "", state: "", postalCode: ""}
      case "file_field":
        return this.customQuestionValues[columnName] || {id: "", url: "", rawUrl: "", filename: ""}
      default:
        return this.customQuestionValues[columnName] || ""
    }
  }

  setCustomValueFor(columnName, value) {
    const customQuestionValues = {...this.customQuestionValues, [columnName]: value}
    this.customQuestionValues = customQuestionValues
  }
}

export default ObservableStore
