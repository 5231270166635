import { autorun, observable, action, computed } from 'mobx'
import { create, persist } from 'mobx-persist'
import { toJS } from 'mobx'
import axios from 'axios'
import applyConverters from 'axios-case-converter'
import { asNormalizedMaps, asNormalizedArrays } from '../../../helpers/json-normalizer'
import { startOfDay } from 'date-fns'
import { parseDate, dueSoon, pastDue, itemContainsText } from '../util'
import toParams from '../../../helpers/to-params'

class ChecklistStore {
  @observable ready = false
  @observable loading = true
  @observable error = null
  @observable sortedItemIds = []
  @observable items = new Map()
  @observable users = new Map()
  @observable availableFilters = []
  @observable availableAssignedFilters = []
  @observable searchText = ""
  @observable editChecklist = false
  @observable bulkEditing = false
  @observable bulkSelectedItemIds = []

  @persist @observable filter
  @persist @observable assignedFilter
  @persist @observable sort
  @persist @observable showProposals = false

  @observable totalCount = 0
  @observable currentPage = 1
  @observable perPage = 25

  constructor({ apiKey, checklistId, availableFilters, availableAssignedFilters, defaultFilter, defaultAssignedFilter, editChecklist, showProposals, perPage }) {
    this.apiKey = apiKey
    this.checklistId = checklistId
    this.availableFilters = availableFilters
    this.availableAssignedFilters = availableAssignedFilters
    this.filter = defaultFilter
    this.assignedFilter = defaultAssignedFilter
    this.editChecklist = editChecklist
    this.showProposals = showProposals

    if(perPage) {
      this.perPage = perPage
    }

    if(editChecklist && checklistId) {
      this.availableSortOptions = ["Sort Manually", "Sort by Due Date"]
      this.enableDragAndDrop = true
    }
    else if(checklistId) {
      this.availableSortOptions = ["Default Sort", "Sort by Due Date"]
      this.enableDragAndDrop = false
    }
    else {
      this.availableSortOptions = ["Sort by Due Date", "Sort by Event Date"]
      this.enableDragAndDrop = false
    }

    this.sort = this.availableSortOptions[0]
    
    this.client = applyConverters(axios.create({
      headers: {
        'Content-Type': 'application/json',
        'API_KEY': apiKey
      },
    }))

    autorun(this.changeHandler, {delay: 300})
  }

  changeHandler = () => {
    // Declare variables to watch
    this.checklistId
    this.searchText
    this.filter
    this.assignedFilter
    this.perPage
    this.showProposals
    this.currentPage
    this.sort

    // Run our refresh
    this.loadChecklistItems()
  }

  async setup() {
    await this.loadChecklistItems()
    this.ready = true
  }

  async reorder(itemId, sourceIndex, destinationIndex) {
    // https://stackoverflow.com/questions/2440700/reordering-arrays/2440723
    this.sortedItemIds.splice(destinationIndex, 0, this.sortedItemIds.splice(sourceIndex, 1)[0]);

    await this.client.put(`/api/v1/checklist_items/${itemId}/move_to_position`, { position: destinationIndex })
      .catch(e => this.handleError(e, "Unable to reorder list"))
  }

  @computed get totalPages() {
    return Math.ceil(this.totalCount / this.perPage)
  }

  @computed get sortedItems() {
    return this.sortedItemIds.map(id => this.items.get(`${id}`))
      .filter(item => item)
  }

  @action
  async loadChecklistItems() {
    this.loading = true

    const params = {
      query: this.searchText,
      filter: this.filter,
      assigned_filter: this.assignedFilter,
      sort_by: this.sort,
      show_proposals: this.showProposals,
      page: this.currentPage,
      per: this.perPage,
      include: "users"
    }

    if(this.checklistId) {
      params.checklist_id = this.checklistId
    }
    else {
      params.only_show_items_in_global_list = true
    }

    try {
      const response = await this.client.get(`/api/v1/checklist_items?${toParams(params)}`)
      const data = asNormalizedMaps(response.data)

      if(data.user) {
        this.users = data.user
      }

      if(data.checklistItem) {
        this.sortedItemIds = response.data.data.map(item => item.id)
        this.items = data.checklistItem
        this.totalCount = response.data.meta.totalCount
      }
      else {
        this.sortedItemIds = []
        this.items = new Map()
        this.totalCount = 0
      }

      this.loading = false
    }
    catch(e) {
      this.handleError(e, "Unable to load checklist")
    }
  }

  @action
  createItem({ title, notes, dueOn, users }) {
    const userIds = users ? users.map(user => user.value) : []

    return this.client.post(`/api/v1/checklist_items`, {
      checklistId: this.checklistId,
      title,
      notes,
      dueOn,
      userIds
    })
      .then(response => {
        this.items.set(response.data.data.id, response.data.data)
        this.sortedItemIds.push(response.data.data.id)
      })
      // .catch(e => this.handleError(e, "Unable to add item"))
  }


  @action
  updateItem(item, { title, notes, dueOn, users }) {
    const userIds = users ? users.map(user => user.value) : []

    return this.client.put(`/api/v1/checklist_items/${item.id}`, {
      title,
      notes,
      dueOn,
      userIds
    })
      .then(response => {
        const item = response.data.data
        this.items.set(`${item.id}`, item)
      })
      // .catch(e => this.handleError(e, "Unable to update item"))
  }

  @action
  deleteItem(item) {
    this.items.delete(`${item.id}`)
    this.sortedItemIds = this.sortedItemIds.filter(id => `${id}` !== `${item.id}`)

    return this.client.delete(`/api/v1/checklist_items/${item.id}`)
      .then(() => {
      })
      .catch(e => this.handleError(e, "Unable to delete item"))
  }

  @action
  markItemChecked(itemId) {
    // Update optimistically
    const record = this.items.get(`${itemId}`)
    record.attributes.checkedAt = new Date()
    record.attributes.checked = true
    this.items.set(`${itemId}`, record)

    return this.client.put(`/api/v1/checklist_items/${itemId}/mark_as_checked`)
      .then(response => {
        this.items.set(`${itemId}`, response.data.data)
      })
      .catch(e => this.handleError(e, "Unable to mark item as checked"))
  }

  @action
  markItemUnchecked(itemId) {
    // Update optimistically
    const record = this.items.get(`${itemId}`)
    record.attributes.checkedAt = null
    record.attributes.checked = false
    this.items.set(`${itemId}`, record)

    return this.client.put(`/api/v1/checklist_items/${itemId}/mark_as_unchecked`)
      .then(response => {
        const item = response.data.data
        this.items.set(`${itemId}`, response.data.data)
      })
      .catch(e => this.handleError(e, "Unable to mark item as unchecked"))
  }

  @action handleError = (error, message) => {
    this.errorMessage = message
    console.error(error)
  }

  @action
  async bulkUpdateSelectedItems(action) {
    await this.client.put(`/api/v1/checklist_items/bulk_update`, {
      checklistItemIds: this.bulkSelectedItemIds,
      updateAction: action
    })
    await this.loadChecklistItems()
  }

  @computed get bulkSelectedItems() {
    return this.bulkSelectedItemIds.map(id => this.items.get(`${id}`))
      .filter(item => item)
  }

  @action toggleItemBulkSelected = (itemId) => {
    if(this.bulkSelectedItemIds.includes(itemId)) {
      this.bulkSelectedItemIds = this.bulkSelectedItemIds.filter(id => id !== itemId)
    }
    else {
      this.bulkSelectedItemIds.push(itemId)
    }
  }

  @action clearBulkSelectedItems = () => {
    this.bulkSelectedItemIds = []
  }

  @action selectAllBulkSelectedItems = () => {
    this.bulkSelectedItemIds = [...this.sortedItemIds]
  }
}

export function createPersistedStore(persistenceKey, args) {
  if(persistenceKey) {
    const hydrate = create({})
    const persistedStore = new ChecklistStore(args)
    hydrate(persistenceKey, persistedStore)
    return persistedStore
  }
  else {
    return new ChecklistStore(args)
  }
}

export default ChecklistStore
