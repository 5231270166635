import React from 'react'

const NewButtons = () => (
  <div className="btn-group">
    <a href="/admin/photobooth_backgrounds/new" className="btn btn-secondary">
      <i className="fa fa-plus" />
      { " " }
      New Backdrop
    </a>
    <a href="/admin/photobooth_backgrounds/new_bulk" className="btn btn-secondary">
      <i className="fa fa-plus-square" />
      { " " }
      Bulk Upload
    </a>
    <a href="/admin/backdrop_categories/new" className="btn btn-secondary">
      <i className="fa fa-plus" />
      { " " }
      New Category
    </a>
  </div>
)

export default NewButtons

