import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'react-bootstrap'
import {observer} from "mobx-react"
import store from '../stores/store.js'
import ImageUploaderPreview from './ImageUploaderPreview'
import FileUploader from './FileUploader'
import FilePreview from './FilePreview'

const FileUploadField = observer(({ field, instance, onChange }) => {
  const { name, uuid, hint, required } = field
  const currentInstance = instance || 0
  const value = store.valueFor(uuid, "file", currentInstance)
  const errorMessage = store.errorFor(uuid, "file", currentInstance)

  return (
    <FormGroup>
      <Form.Label>{ required ? "*" : "" } {name}</Form.Label>
      <FilePreview url={value} />
      <FileUploader
        apiKey={store.apiKey}
        disabled={!store.editable}
        onChange={file => {
          store.setValueFor(uuid, "file", currentInstance, file.rawUrl)
          if(store.allowSave) {
            store.persistFormContent(null, true)
          }
        }}
        isInvalid={!!errorMessage}
      />

      { errorMessage && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{errorMessage}</Form.Control.Feedback> }
      { hint && <Form.Text muted>{hint}</Form.Text > }
    </FormGroup>
  )
})

export default FileUploadField
