import React from 'react'
import FaIcon from '../../../helpers/fa_icon.js'

const SideControls = ({ buildable, allowSort }) => {
  if(!buildable && !allowSort) return null

  return (
    <div className="side-controls">
      <span className="control">
        <FaIcon icon="arrows" />
      </span>
    </div>
  )
}

export default SideControls
