import React from 'react'
import PropTypes from 'prop-types'

const SPINNER_DELAY = 300

export default class Loader extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired
  }

  state = {
    showIndicator: false
  }

  componentDidUpdate(prevProps) {
    if(this.props.loading && !prevProps.loading && !this.timeout) {
      this.timeout = setTimeout(() => {
        this.setState({ showIndicator: true })
      }, SPINNER_DELAY)
    }
    else if(!this.props.loading && this.state.showIndicator) {
      this.setState({ showIndicator: false })
      clearTimeout(this.timeout)
      this.timeout = null
    }
  }

  componentWillUnmount() {
    if(this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  render() {
    const { checklistStore, children, loading } = this.props
    const { showIndicator } = this.state

    if(loading && showIndicator) {
      return (
        <div className="loader">
          <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
        </div>
      )
    }
    else {
      return children
    }
  }
}

