import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import TreeView from 'react-treeview'
import Backdrop from './Backdrop.js'
import store from '../stores/store.js'

@inject("store")
@observer
export default class BackdropCategory extends Component {
  static propTypes = {
    backdropCategory: PropTypes.object.isRequired,
    onClickBackdrop: PropTypes.func,
    showCheckboxes: PropTypes.bool
  }

  handleTreeClick = (id) => {
    const { store } = this.props
    store.toggleBackdropCategoryCollapsed(id)
  }

  handleSelection = (id) => {
    const { store } = this.props
    store.toggleBackdropCategorySelection(id)
  }

  renderBackdropCategory() {
    const { backdropCategory, onClickBackdrop } = this.props

    if(onClickBackdrop) {
      return (
        <span
          onClick={() => onClickBackdrop({backdropCategory})}
          style={{...styles.backdrop, ...styles.clickableBackdropCategory}}
        >
          { backdropCategory.name }
        </span>
      )
    }
    else {
      return <span style={styles.backdrop}>{ backdropCategory.name }</span>
    }
  }

  renderBackdropCategoryCheckbox() {
    const { backdropCategory, showCheckboxes } = this.props

    if(showCheckboxes) {
      return (
        <Form.Check
          type="checkbox"
          style={styles.checkbox}
          id={`backdropCategory-${backdropCategory.id}`}
          >
          <Form.Check.Input
            onChange={() => this.handleSelection(backdropCategory.id)}
            checked={backdropCategory.selected}
            type="checkbox"
          />
          <Form.Check.Label>
            { this.renderBackdropCategory() }
          </Form.Check.Label>
        </Form.Check>
      )
    }
    else {
      return this.renderBackdropCategory()
    }
  }

  render() {
    const { backdropCategory, showCheckboxes, onClickBackdrop } = this.props

    return (
      <TreeView
        onClick={() => this.handleTreeClick(backdropCategory.id)}
        collapsed={backdropCategory.collapsed}
        nodeLabel={this.renderBackdropCategoryCheckbox()}
        >
        <div style={styles.offset}>
          {backdropCategory.backdrops.map(backdrop =>
            <Backdrop
              key={backdrop.id}
              backdrop={backdrop}
              onClickBackdrop={onClickBackdrop}
              showCheckboxes={showCheckboxes}
            />
          )}
        </div>
      </TreeView>
    )
  }
}

const styles = {
  checkbox: {
    display: "inline-block",
    marginTop: 0,
    marginBottom: 5
  },
  offset: {
    marginLeft: 10
  },
  clickableBackdropCategory: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  backdrop: {},
}
