import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import scrollToComponent from 'react-scroll-to-component'
import SimpleFormat from '@16g/react-simple-format'

@observer
export default class SubmittedMessage extends React.Component {
  static propTypes = {
    formStore: PropTypes.object.isRequired,
  }

  componentDidMount() {
    scrollToComponent(this.formRef, { offset: -150, align: 'top' })
  }

  render() {
    const { confirmationText } = this.props.formStore.formSettings

    return(
      <div
        style={styles.wrapper}
        ref={ref => this.formRef = ref}
      >
        <SimpleFormat text={confirmationText} />
      </div>
    )
  }
}

const styles = {
  wrapper: {
    maxWidth: "100%",
    background: "#fff",
    color: "#2a2a2a",
    padding: 30,
    fontSize: "1.25em",
    textAlign: "center"
  }
}
