import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import NewChecklistItem from './NewChecklistItem'
import SearchBox from './SearchBox'
import AssignedFilter from './AssignedFilter'
import FilterControl from './FilterControl'
import SortControl from './SortControl'
import ProposalToggle from './ProposalToggle'

const ToolbarCompact = ({ showProposalToggle }) => {
  const [showFilterModal, setShowFilterModal] = useState(false)

  return (
    <div>
      <div className="d-flex w-100 mb-2 align-items">
        <div className="flex-grow-1">
          <h5>Checklist</h5>
        </div>
        <div>
          <NewChecklistItem
            buttonContent={<i className="fa fa-plus" />}
            buttonProps={{variant: "light"}}
          />
        </div>
      </div>
      <div className="d-flex w-100">
        <div className="flex-grow-1">
          <SearchBox />
        </div>
        <div className="ms-1">
          <Button
            variant="light"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
            { " " }
            <i className="fa fa-filter" />
          </Button>
        </div>
      </div>
      <Modal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <AssignedFilter />
          </div>
          <div className="mb-3">
            <FilterControl />
          </div>
          <div className="mb-3">
            <SortControl />
          </div>
          <div className="mb-3">
            { showProposalToggle &&
              <div className="text-end">
                <ProposalToggle />
              </div>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowFilterModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ToolbarCompact

