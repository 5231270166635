import React, { useContext } from 'react'
import ResponsiveContext from './ResponsiveContext'

const ResponsiveColumn = ({ className, children }) => {
  const { isMobile } = useContext(ResponsiveContext)

  return (
    <div className={isMobile ? `col-12 ${className}` : `col ${className}`}>
      {children}
    </div>
  )
}


export default ResponsiveColumn
