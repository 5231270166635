import React, { useState } from 'react';
import {loadConnectAndInitialize} from '@stripe/connect-js';
import { ConnectComponentsProvider, ConnectAccountOnboarding, } from "@stripe/react-connect-js";
import OnboardingWidget from './OnboardingWidget'
import RefreshWidget from './RefreshWidget'

const redirectURL = `/admin/payment_methods/`


const StripeEmbeddedOnboarding = ({ apiKey, publishableKey, primaryColor, stripeComponent }) => {
  const [ onboardingComplete, setOnboardingComplete ] = useState(false)

  const redirectToPaymentSettings = () => {
    window.location.href = redirectURL
  }

  const handleOnboardingExit = () => {
    setOnboardingComplete(true)
  }

  if(onboardingComplete) {
    return (
      <RefreshWidget
        apiKey={apiKey}
        onSuccess={redirectToPaymentSettings}
        onTimeout={redirectToPaymentSettings}
      />
    )
  }
  else {
    return (
      <OnboardingWidget
        apiKey={apiKey}
        publishableKey={publishableKey}
        primaryColor={primaryColor}
        stripeComponent={stripeComponent}
        onExit={handleOnboardingExit}
      />
    )
  }
}

export default StripeEmbeddedOnboarding
