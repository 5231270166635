import React, { useState, useEffect } from 'react'
import DateField from '../ui/DateField'
import FranchisePaymentPlan from './components/FranchisePaymentPlan'
import AutoPayControl from './components/AutoPayControl'
import "./index.scss"

const PaymentPlanSelector = ({
  dateFormat,
  currency,
  franchisePaymentPlans,
  targetFranchisePaymentPlanId,
  targetEnableAutoPayId,
  franchisePaymentPlanId,
  enableAutoPay
}) => {
  const [activeFranchisePaymentPlanId, setActiveFranchisePaymentPlanId] = useState(franchisePaymentPlanId)
  const [activeEnableAutoPay, setActiveEnableAutoPay] = useState(enableAutoPay)

  // When the activeFranchisePaymentPlanId changes, update the target element
  useEffect(() => {
    const target = document.querySelector(targetFranchisePaymentPlanId)
    if (target) {
      target.value = activeFranchisePaymentPlanId
    }
  }, [activeFranchisePaymentPlanId])

  // When enableAutoPay changes, update the target element
  useEffect(() => {
    const target = document.querySelector(targetEnableAutoPayId)
    if (target) {
      target.value = activeEnableAutoPay
    }
  }, [activeEnableAutoPay])

  return (
    <div className="payment-plan-selector">
      <ol className="list-group">
        { franchisePaymentPlans.map(franchisePaymentPlan => (
          <FranchisePaymentPlan
            key={franchisePaymentPlan.id}
            franchisePaymentPlan={franchisePaymentPlan}
            dateFormat={dateFormat}
            activeFranchisePaymentPlanId={activeFranchisePaymentPlanId}
            setActiveFranchisePaymentPlanId={setActiveFranchisePaymentPlanId}
            enableAutoPay={activeEnableAutoPay}
            setEnableAutoPay={setActiveEnableAutoPay}
          />
        ))}
      </ol>
      <AutoPayControl
        enableAutoPay={activeEnableAutoPay}
        setEnableAutoPay={setActiveEnableAutoPay}
        franchisePaymentPlan={franchisePaymentPlans.find(fpp => fpp.id === activeFranchisePaymentPlanId)}
      />
    </div>
  )
}

export default PaymentPlanSelector
