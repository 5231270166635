import React from 'react'
import MandatoryAutoPayControl from './MandatoryAutoPayControl'
import OptionalAutoPayControl from './OptionalAutoPayControl'

const AutoPayControl = ({ enableAutoPay, setEnableAutoPay, franchisePaymentPlan }) => {
  switch(franchisePaymentPlan.attributes.autoPay) {
    case "required":
      return <MandatoryAutoPayControl enableAutoPay={enableAutoPay} setEnableAutoPay={setEnableAutoPay} />
    case "optional":
      return <OptionalAutoPayControl enableAutoPay={enableAutoPay} setEnableAutoPay={setEnableAutoPay} />
    case "none":
      return null
    default:
      throw new Error(`Invalid autoPay value: ${franchisePaymentPlan.attributes.autoPay}`)
  }
}

export default AutoPayControl
