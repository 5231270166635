import React from 'react'
import MusicBrowser from '../ui/MusicBrowser'
import FaIcon from '../../helpers/fa_icon'

const AddSongButton = ({ onChange, position }) => (
  <div className="add-song-button-wrapper">
    <MusicBrowser
      onChange={song => onChange(song, position)}
      label={<FaIcon icon="plus" text="Add Song" />}
    />
  </div>
)

export default AddSongButton
