import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import TreeView from 'react-treeview'
import AddOn from './AddOn.js'
import store from '../stores/store.js'

@inject("store")
@observer
export default class AddOnCategory extends Component {
  static propTypes = {
    addOnCategory: PropTypes.object.isRequired,
    onClickAddOn: PropTypes.func,
    showCheckboxes: PropTypes.bool
  }

  handleTreeClick = (id) => {
    const { store } = this.props
    store.toggleAddOnCategoryCollapsed(id)
  }

  handleSelection = (id) => {
    const { store } = this.props
    store.toggleAddOnCategorySelection(id)
  }

  renderAddOnCategory() {
    const { addOnCategory, onClickAddOn } = this.props

    if(onClickAddOn) {
      return (
        <span
          onClick={() => onClickAddOn({addOnCategory})}
          style={{...styles.addOn, ...styles.clickableAddOnCategory}}
        >
          { addOnCategory.name }
        </span>
      )
    }
    else {
      return <span style={styles.addOn}>{ addOnCategory.name }</span>
    }
  }

  renderAddOnCategoryCheckbox() {
    const { addOnCategory, showCheckboxes } = this.props

    if(showCheckboxes) {
      return (
        <Form.Check
          type="checkbox"
          style={styles.checkbox}
          id={`addOnCategory-${addOnCategory.id}`}
          >
          <Form.Check.Input
            onChange={() => this.handleSelection(addOnCategory.id)}
            checked={addOnCategory.selected}
          />
          <Form.Check.Label>
            { this.renderAddOnCategory() }
          </Form.Check.Label>
        </Form.Check>
      )
    }
    else {
      return this.renderAddOnCategory()
    }
  }

  render() {
    const { addOnCategory, showCheckboxes, onClickAddOn } = this.props

    return (
      <TreeView
        onClick={() => this.handleTreeClick(addOnCategory.id)}
        collapsed={addOnCategory.collapsed}
        nodeLabel={this.renderAddOnCategoryCheckbox()}
        >
        <div style={styles.offset}>
          {addOnCategory.addOns.map(addOn =>
            <AddOn
              key={addOn.id}
              addOn={addOn}
              onClickAddOn={onClickAddOn}
              showCheckboxes={showCheckboxes}
            />
          )}
        </div>
      </TreeView>
    )
  }
}

const styles = {
  checkbox: {
    display: "inline-block",
    marginTop: 0,
    marginBottom: 5
  },
  offset: {
    marginLeft: 10
  },
  clickableAddOnCategory: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  addOn: {},
}
