function calculateinitialPaymentMethodOptionState(paymentMethodOptions) {
  if(paymentMethodOptions && paymentMethodOptions.length === 1 && paymentMethodOptions[0].component === "custom") {
    return {
      id: paymentMethodOptions[0].id,
      special: null
    }
  }
  else {
    return {
      id: null,
      special: null
    }
  }
}

export default calculateinitialPaymentMethodOptionState
