import React from 'react'
import { Form, Card, Button, Row, Col, FormControl, InputGroup, } from 'react-bootstrap'
import ErrorBlock, { hasValidationError } from './ErrorBlock'
import HourSelector from './HourSelector'
import DaySelector from './DaySelector'
import UnitRangeManager from './UnitRangeManager'
import UnitTranslator from '../helpers/unit-translator'


const PricingPanel = (props) => {
  const { formValues, setFormValues, errors, onSave, supportsTax, hourlySlotIntervals, currency, unitTranslations, loading } = props
  const unitTranslator = new UnitTranslator(unitTranslations)

  return (
    <Card className="package-editor spacer-bottom">
      <Card.Header>
        <Card.Title>Pricing</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col sm={{ span: 9, offset: 3}}>
            <Row>
              <Col sm={6}>
                <Form.Group size="lg">
                  <Form.Label>
                    Starting Rate
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text>{ currency.symbol }</InputGroup.Text>
                    <FormControl
                      type="text"
                      value={formValues.baseRate}
                      onChange={e => setFormValues({ baseRate: e.target.value })}
                      isInvalid={hasValidationError("baseRate", errors)}
                    />
                  </InputGroup>
                  <ErrorBlock error={errors.baseRate} />
                </Form.Group>
              </Col>
              <Col sm={6}>
                { formValues.dateMode === "date_time" &&
                  <HourSelector
                    label="Number of hours included"
                    hint="How many hours are included in your starting rate?"
                    value={formValues.defaultLengthInMinutes}
                    onChange={defaultLengthInMinutes => setFormValues({ defaultLengthInMinutes })}
                    error={errors.defaultLengthInMinutes}
                  />
                }
                { formValues.dateMode === "date_only" &&
                  <DaySelector
                    label="Number of days included"
                    hint="How many days are included in your starting rate?"
                    value={formValues.defaultLengthInDays}
                    onChange={defaultLengthInDays => setFormValues({ defaultLengthInDays })}
                    error={errors.defaultLengthInDays}
                  />
                }
              </Col>
            </Row>
          </Col>
        </Row>
        { supportsTax &&
          <Row>
            <Col sm={{ span: 9, offset: 3}}>
              <Form.Check
                type="checkbox"
                onChange={e => setFormValues({ taxable: e.target.checked })}
                checked={formValues.taxable}
                label="Taxable"
                id="taxable"
              />
              <Form.Text muted>Tax is only applied to bookings that fall into one of your tax zones</Form.Text>
            </Col>
          </Row>
        }
        { formValues.dateMode === "date_time" &&
          <Row>
            <Col sm={3}>
              <Form.Group size="lg">
                <Form.Label>
                  Charge for extra hours?
                </Form.Label>
                <Form.Check
                  type="radio"
                  onChange={() => setFormValues({ enableCustomerCanBookExtraHours: true })}
                  checked={formValues.enableCustomerCanBookExtraHours}
                  label="Yes"
                  id="charge-for-extra-hours-yes"
                />
                <Form.Check
                  type="radio"
                  onChange={() => setFormValues({ enableCustomerCanBookExtraHours: false })}
                  checked={!formValues.enableCustomerCanBookExtraHours}
                  label="No"
                  id="charge-for-extra-hours-no"
                />
                <ErrorBlock error={errors.enableCustomerCanBookExtraHours} />
              </Form.Group>
            </Col>
            { formValues.enableCustomerCanBookExtraHours &&
            <Col sm={9}>
              <Row>
                <Col sm={4}>
                  <Form.Group size="lg">
                    <Form.Label>
                      Hourly Rate
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text>{ currency.symbol }</InputGroup.Text>
                      <FormControl
                        type="text"
                        value={formValues.pricePerExtraHour}
                        onChange={e => setFormValues({ pricePerExtraHour: e.target.value })}
                        isInvalid={hasValidationError("pricePerExtraHour", errors)}
                      />
                    </InputGroup>
                    <Form.Text>How much extra do you charge for additional hours?</Form.Text>
                    <ErrorBlock error={errors.pricePerExtraHour} />
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <HourSelector
                    label="Minimum length"
                    value={formValues.minLengthInMinutes}
                    onChange={minLengthInMinutes => setFormValues({ minLengthInMinutes })}
                    error={errors.minLengthInMinutes}
                  />
                </Col>
                <Col sm={4}>
                  <HourSelector
                    label="Maximum length"
                    value={formValues.maxLengthInMinutes}
                    onChange={maxLengthInMinutes => setFormValues({ maxLengthInMinutes })}
                    error={errors.maxLengthInMinutes}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>
                      Booking Increment
                    </Form.Label>
                    <Form.Select
                      className="input-lg select optional"
                      value={formValues.stepInMinutes}
                      onChange={e => setFormValues({ stepInMinutes: e.target.value })}
                    >
                      { hourlySlotIntervals.map(slotInterval =>
                      <option key={slotInterval[0]} value={slotInterval[1]}>{ slotInterval[0] }</option>
                      )}
                    </Form.Select>
                    <Form.Text>Do you allow booking portions of an hour?</Form.Text>
                    <ErrorBlock error={errors.stepInMinutes} />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            }
          </Row>
        }
        { formValues.dateMode === "date_only" &&
          <Row>
            <Col sm={3}>
              <Form.Group size="lg">
                <Form.Label>
                  Allow customer to book extra days?
                </Form.Label>
                <Form.Check
                  type="radio"
                  onChange={() => setFormValues({ enableCustomerCanBookExtraDays: true })}
                  checked={formValues.enableCustomerCanBookExtraDays}
                  label="Yes"
                  id="charge-for-extra-days-yes"
                />
                <Form.Check
                  type="radio"
                  onChange={() => setFormValues({ enableCustomerCanBookExtraDays: false })}
                  checked={!formValues.enableCustomerCanBookExtraDays}
                  label="No"
                  id="charge-for-extra-days-no"
                />
                <ErrorBlock error={errors.enableCustomerCanBookExtraDays} />
              </Form.Group>
            </Col>
            { formValues.enableCustomerCanBookExtraDays &&
              <Col sm={9}>
                <Row>
                  <Col sm={4}>
                    <Form.Group size="lg">
                      <Form.Label>
                        Daily Rate
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Text>{ currency.symbol }</InputGroup.Text>
                        <FormControl
                          type="text"
                          value={formValues.pricePerExtraDay}
                          onChange={e => setFormValues({ pricePerExtraDay: e.target.value })}
                          isInvalid={hasValidationError("pricePerExtraDay", errors)}
                        />
                      </InputGroup>
                      <Form.Text>How much extra do you charge for additional days?</Form.Text>
                      <ErrorBlock error={errors.pricePerExtraDay} />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <DaySelector
                      label="Minimum length"
                      value={formValues.minLengthInDays}
                      onChange={minLengthInDays => setFormValues({ minLengthInDays })}
                      error={errors.minLengthInDays}
                    />
                  </Col>
                  <Col sm={4}>
                    <DaySelector
                      label="Maximum length"
                      value={formValues.maxLengthInDays}
                      onChange={maxLengthInDays => setFormValues({ maxLengthInDays })}
                      error={errors.maxLengthInDays}
                    />
                  </Col>
                </Row>
              </Col>
            }
            </Row>
        }
        <br />
        <Row>
          <Col sm={3}>
            <Form.Group size="lg">
              <Form.Label>
                Charge by unit?
              </Form.Label>
              <Form.Check
                type="radio"
                onChange={() => setFormValues({ enableCustomerCanBookPerUnit: true, enableCustomerCanBookPerUnitRange: false })}
                checked={formValues.enableCustomerCanBookPerUnit}
                label="Yes, charge per unit"
                id="charge-for-per-unit-yes"
              />
              <Form.Check
                type="radio"
                onChange={() => setFormValues({ enableCustomerCanBookPerUnit: false, enableCustomerCanBookPerUnitRange: true })}
                checked={formValues.enableCustomerCanBookPerUnitRange}
                label="Yes, charge by unit range"
                id="charge-for-per-unit-range-yes"
              />
              <Form.Check
                type="radio"
                onChange={() => setFormValues({ enableCustomerCanBookPerUnit: false, enableCustomerCanBookPerUnitRange: false })}
                checked={!formValues.enableCustomerCanBookPerUnit && !formValues.enableCustomerCanBookPerUnitRange}
                label="No"
                id="charge-for-per-unit-no"
              />
              <ErrorBlock error={errors.enableCustomerCanBookPerUnit} />
            </Form.Group>
          </Col>
          { (formValues.enableCustomerCanBookPerUnit || formValues.enableCustomerCanBookPerUnitRange) &&
          <Col sm={9}>
            <Row>
              <Col sm={4}>
                <Form.Group>
                  <Form.Label>
                    Unit Name
                  </Form.Label>
                  <Form.Select
                    className="input-lg select optional"
                    value={formValues.unitName}
                    onChange={e => setFormValues({ unitName: e.target.value })}
                  >
                    { unitTranslator.options.map(unitTranslation =>
                    <option key={unitTranslation.unit} value={unitTranslation.unit}>{ unitTranslation.unitTitle }</option>
                    )}
                  </Form.Select>
                  <ErrorBlock error={errors.unitName} />
                </Form.Group>
              </Col>
              <Col sm={6}>
              </Col>
            </Row>
            <UnitRangeManager
              unitTranslator={unitTranslator}
              {...props}
            />
          </Col>
          }
        </Row>
        <hr />
        <Row>
          <Col sm={3}>
            <Form.Group size="lg">
              <Form.Label>
                Require Deposit?
              </Form.Label>
              <Form.Check
                type="radio"
                onChange={() => setFormValues({ depositMethod: "flat_rate" })}
                checked={formValues.depositMethod === "flat_rate" }
                label="Flat Rate"
                id="deposit-method-flat-rate"
              />
              <Form.Check
                type="radio"
                onChange={() => setFormValues({ depositMethod: "percent" })}
                checked={formValues.depositMethod === "percent" }
                label="Percentage"
                id="deposit-method-percent"
              />
              <Form.Check
                type="radio"
                onChange={() => setFormValues({ depositMethod: "none" })}
                checked={formValues.depositMethod === "none" }
                label="None"
                id="deposit-method-none"
              />
              <ErrorBlock error={errors.depositMethod} />
            </Form.Group>
          </Col>
          <Col sm={5}>
            { (formValues.depositMethod === "flat_rate") &&
              <Form.Group size="lg">
                <Form.Label>
                  Deposit Amount
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text>{ currency.symbol }</InputGroup.Text>
                  <FormControl
                    type="text"
                    value={formValues.depositAmount}
                    onChange={e => setFormValues({ depositAmount: e.target.value })}
                    isInvalid={hasValidationError("depositAmount", errors)}
                  />
                </InputGroup>
                <Form.Text>Charge a flat rate, regardless of the total booking cost</Form.Text>
                <ErrorBlock error={errors.depositAmount} />
              </Form.Group>
            }
            { (formValues.depositMethod === "percent") &&
              <Form.Group size="lg">
                <Form.Label>
                  Deposit Percent
                </Form.Label>
                <InputGroup>
                  <FormControl
                    type="text"
                    value={formValues.depositMultiplierPercent}
                    onChange={e => setFormValues({ depositMultiplierPercent: e.target.value })}
                    isInvalid={hasValidationError("depositMultiplierPercent", errors)}
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
                <Form.Text>Charge a percentage of the total booking cost</Form.Text>
                <ErrorBlock error={errors.depositMultiplierPercent} />
              </Form.Group>
            }
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          onClick={onSave}
          variant="primary"
          disabled={loading}
        >{ loading ? "Saving..." : "Save" }</Button>
      </Card.Footer>
    </Card>
  )
}

export default PricingPanel
