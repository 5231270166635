import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'react-bootstrap'
import SelectOption from '../SelectOption'
import { camelCase } from 'change-case'
import HintField from '../HintField'

export default class CheckboxGroupField extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelStyle: PropTypes.object,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ]).isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    style: PropTypes.object,
    hint: PropTypes.string,
    errors: PropTypes.object,
    required: PropTypes.bool,
  }

  static defaultProps = {
    labelStyle: {}
  }

  get errorMessage() {
    const { errors, name } = this.props

    if(errors && errors[camelCase(name)]) {
      return errors[camelCase(name)][0]
    }
    return null
  }

  get helpMessage() {
    const { hint } = this.props
    return this.errorMessage || hint || null
  }

  toggleCheckbox = (option) => {
    const { value, onChange } = this.props

    let values = Array.isArray(value) ? value : value.split(/,/)

    if(values.includes(option)) {
      const newValue = values.filter(v => v != option)
      onChange(newValue)
    }
    else {
      values.push(option)
      const newValue2 = values.filter(v => v)
      onChange(newValue2)
    }

  }

  render() {
    const { name, label, value, onChange, hint, style, errors, required, options, labelStyle } = this.props
    const { helpMessage } = this

    const values = Array.isArray(value) ? value : value.split(/,/)

    return (
      <FormGroup className={name} style={{...styles.wrapper, ...style}}>
        <Form.Label style={{...styles.label, ...labelStyle}}>
          { label }
          { required && <span style={styles.required}>*</span> }
        </Form.Label>
        {options.map(option =>
          <Form.Check
            type="checkbox"
            key={option}
          >
            <Form.Check.Input
              type="checkbox"
              value={option}
              checked={values.includes(option)}
              onChange={() => this.toggleCheckbox(option)}
              isInvalid={!!this.errorMessage}
            />
            <Form.Check.Label style={{...styles.label, ...labelStyle}}>{ option }</Form.Check.Label>
          </Form.Check>
        )}
        <HintField
          hint={hint}
          errorMessage={this.errorMessage}
        />
      </FormGroup>
    )
  }
}

const styles = {
  label: {
    fontSize: 18,
  },
  input: {
    fontSize: 18
  },
  required: {
    color: "#cc0000",
    fontWeight: "normal",
    paddingLeft: 2
  }
}
