import React from 'react'
import { parseISO, format } from 'date-fns'

const FinalPayment = ({ franchisePaymentPlan, dateFormat }) => (
  <li>
    { format(parseISO(franchisePaymentPlan.attributes.finalDueDate, new Date()), dateFormat) }: { franchisePaymentPlan.attributes.finalDueAmountFormatted } (Final Payment)
  </li>
)

export default FinalPayment

