import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import Currency from '../../../../helpers/currency.js'

@inject("currency")
@inject("store")
@observer
export default class Statistics extends React.Component {
  static propTypes = {
    currency: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
  }

  get totalAmountPaid() {
    const { store } = this.props
    const { data } = store

    return data
      .map(period => period.amountPaidCents)
      .filter(amountPaidCents => amountPaidCents)
      .reduce((acc, value) => acc + value, 0)
  }


  get paymentCount() {
    const { store } = this.props
    const { data } = store

    return data
      .map(period => period.paymentCount)
      .filter(paymentCount => paymentCount)
      .reduce((acc, value) => acc + value, 0)
  }

  render() {
    const { currency, store } = this.props
    const { data } = store
    
    const { totalAmountPaid, paymentCount } = this

    if(!data || !totalAmountPaid) {
      return null
    }

    return (
      <div style={styles.statistics}>
        <div className="row">
          <div className="col-sm-6" style={styles.cell}>
            <div style={styles.totalAmountPaid}>
              Amount Paid: <span style={styles.value}>{ Currency.formatted(totalAmountPaid, currency)}</span>
            </div>
          </div>
          <div className="col-sm-6" style={styles.cell}>
            <div style={styles.paymentCount}>
              Payment Count: <span style={styles.value}>{ paymentCount }</span>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

const styles = {
  statistics: {
    marginTop: 10
  },
  cell: {
    textAlign: "center"
  },
  value: {
    fontWeight: "bold"
  },
  paymentCount: {
    marginTop: 3,
    textAlign: "center"
  }
}

