import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { ResponsiveContainer, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line } from 'recharts'
import TooltipDisplay from './TooltipDisplay'

@inject("store")
@observer
export default class ChartView extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  }

  loadReport = (clickEvent) => {
    if(!clickEvent) return false
    const { activeTooltipIndex } = clickEvent

    if(!activeTooltipIndex) return false

    // Not sure where to send them, so we're just logging for now
    console.log({ activeTooltipIndex })
  }

  render() {
    const { store } = this.props
    const { data, loading } = store

    return (
      <div>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data} margin={{top: 20, right: 30, left: 20, bottom: 5}} onClick={this.loadReport}>
            <XAxis dataKey="name"/>
            <YAxis />
            <CartesianGrid strokeDasharray="3 3"/>
            <Tooltip content={<TooltipDisplay  />} />
            <Legend />
            <Line
              type="monotone"
              isAnimationActive={false}
              className="dashboard-chart-data-line"
              dataKey="previousCount"
              name="Previous Period"
              strokeWidth={3}
              stroke="#c1c8d1"
              dot={true}
            />
            <Line
              type="monotone"
              isAnimationActive={false}
              className="dashboard-chart-data-line"
              dataKey="currentCount"
              name="Current Period"
              strokeWidth={4}
              // stroke="#4DA143"
              stroke="#333"
              dot={true}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    )
  }
}
