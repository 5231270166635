import { useEffect } from "react"
import { observer } from "mobx-react"
import React from 'react'
import performRefreshPlaylist from '../../ui/PlaylistImporter/performRefreshPlaylist'
import store from '../stores/store.js'

async function performRefreshFieldPlaylist(field) {
  const playlistMode = store.valueFor(field.uuid, "metaPlaylistMode", 0)
  const playlistUrl = store.valueFor(field.uuid, "metaPlaylistUrl", 0)
  const playlistProvider = store.valueFor(field.uuid, "metaPlaylistProvider", 0)

  if(!playlistMode || !playlistUrl || !playlistProvider) return

  const refreshedPlaylist = await performRefreshPlaylist({ playlistMode, playlistUrl, playlistProvider })

  if(refreshedPlaylist) {
    const { playlistName, playlistDescription, playlistArtworkUrl, songs } = refreshedPlaylist
    store.clearAllInstances(field.uuid)
   
    // Set playlist info
    store.setValueFor(field.uuid, "metaPlaylistMode", 0, playlistMode)
    store.setValueFor(field.uuid, "metaPlaylistName", 0, playlistName)
    store.setValueFor(field.uuid, "metaPlaylistDescription", 0, playlistDescription)
    store.setValueFor(field.uuid, "metaPlaylistArtworkUrl", 0, playlistArtworkUrl)
    store.setValueFor(field.uuid, "metaPlaylistUrl", 0, playlistUrl)
    store.setValueFor(field.uuid, "metaPlaylistProvider", 0, playlistProvider)

    // Set song info
    songs.forEach((song, index) => {
      store.setValueFor(field.uuid, "songId", index, song.attributes.id)
      store.setValueFor(field.uuid, "songName", index, song.attributes.name)
      store.setValueFor(field.uuid, "songArtistName", index, song.attributes.artistName)
      store.setValueFor(field.uuid, "songAlbumName", index, song.attributes.albumName)
      store.setValueFor(field.uuid, "songPreviewUrl", index, song.attributes.previewUrl)
      store.setValueFor(field.uuid, "songAlbumUrl", index, song.attributes.albumImageUrl)
      store.setValueFor(field.uuid, "songDurationSeconds", index, song.attributes.durationSeconds)
      store.setValueFor(field.uuid, "songProvider", index, song.attributes.provider)
    })
  }
}

const FieldInstanceLoadHandler = observer(({ field }) => {
  useEffect(() => {
    switch(field.type) {
      case "song_list":
        performRefreshFieldPlaylist(field)
      default:
        break;
    }
  }, [ field.type ])

  return null
})


export default FieldInstanceLoadHandler
