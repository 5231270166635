import React, { useState } from 'react';
import client from '../../helpers/client'
import {loadConnectAndInitialize} from '@stripe/connect-js';
import { ConnectComponentsProvider, ConnectAccountOnboarding, } from "@stripe/react-connect-js";
import OnboardingWidget from './OnboardingWidget'
import RefreshWidget from './RefreshWidget'

export default function OnboardWidget({ apiKey, publishableKey, primaryColor, stripeComponent, onExit }) {
  const [ errorMessage, setErrorMessage ] = useState(null)

  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await client.post(`/api/v1/stripe_express/account_sessions`, { api_key: apiKey })
        const { clientSecret } = response.data.data
        return clientSecret
      }
      catch(error) {
        const errorMessage = error?.response?.data?.errors || error.message || 'An error occurred'
        setErrorMessage(errorMessage)
      }
    }

    const proximaNovaFontSource = {
      family: 'proxima-nova',
      src: 'https://use.typekit.net/cvf5ozf.css'
    }

    return loadConnectAndInitialize({
      publishableKey,
      fetchClientSecret,
      fonts: [
        {
          cssSrc: 'https://use.typekit.net/cvf5ozf.css'
        }
      ],
      appearance: {
        variables: {
          fontFamily: 'proxima-nova',
          colorPrimary: primaryColor,
          badgeSuccessColorBackground: '#639f4f',
          badgeSuccessColorText: '#ffffff',
          badgeSuccessColorBorder: '#639f4f',
        }
      }
    })
  });


  return (
    <>
      { errorMessage &&
      <div className="alert alert-danger text-center" role="alert">
        {errorMessage}
      </div>
      }
      <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        <ConnectAccountOnboarding onExit={onExit} />
      </ConnectComponentsProvider>
    </>
  )
}
