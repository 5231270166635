import React from 'react'
import PropTypes from 'prop-types'
import TooltipRow from './TooltipRow'
import Currency from '../../../../helpers/currency.js'

export default class TooltipDisplay extends React.Component {
  static propTypes = {
    payload: PropTypes.array,
    label: PropTypes.string,
    currency: PropTypes.object.isRequired
  }

  get total() {
    const { payload } = this.props
    if(payload && payload.length > 0) {
      return payload
        .map(data => data.value)
        .reduce((acc, value) => acc + value)
    }
    else {
      return 0
    }
  }

  render() {
    const { payload, label, currency } = this.props
    const { total } = this

    if(payload && payload.length > 0) {
      const eventCount = payload[0].payload.eventCount
      return (
        <div className="tooltip-display">
          <div className="tooltip-display-label">{ label }</div>
          { payload.map((data, index) => <TooltipRow key={index} data={data} currency={currency} /> )}
          <div>
            { eventCount } { eventCount === 1 ? "Booking" : "Bookings" }
          </div>
          <div className="tooltip-display-total">
            Total: { Currency.formatted(total, currency) }
          </div>
        </div>
      )
    }
    else {
      return null
    }
  }
}
