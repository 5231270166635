import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import FaIcon from '../../../helpers/fa_icon.js'
import performRefreshPlaylist from './performRefreshPlaylist'

const LoadingButton = () => (
  <Button disabled>
    <FaIcon icon="refresh" spin text="Refreshing..." />
  </Button>
)

const DefaultButton = ({ onClick }) => (
  <Button onClick={onClick}>
    <FaIcon icon="refresh" text="Refresh Playlist" />
  </Button>
)

const RefreshButton = ({ disabled, onChange, currentPlaylist }) => {
  const [ loading, setLoading ] = useState(false)

  async function handleRefresh() {
    setLoading(true)
    try {
      const data = await performRefreshPlaylist(currentPlaylist)
      onChange(data)
    }
    catch(e) {
      console.log(e)
      alert("There was an error loading the playlist. Please try again.")
    }
    setLoading(false)
  }

  if(!currentPlaylist) {
    return null
  }
  else if(loading) {
    return <LoadingButton />
  }
  else {
    return <DefaultButton onClick={handleRefresh} />
  }
}

export default RefreshButton
