import React from 'react'

const SavedPaymentMethodPreview = ({ savedPaymentMethod, onClick, active }) => (
  <div className="saved-card-selector-wrapper">
    <div className={`saved-card-selector ${active ? 'active' : 'inactive'}`} onClick={onClick} >
      <div className="brand">{ savedPaymentMethod.brand }</div>
      <div className="card-number">•••• •••• •••• { savedPaymentMethod.last4 }</div>
      { (savedPaymentMethod.expMonth && savedPaymentMethod.expYear) &&
        <div className="expiration">{ savedPaymentMethod.expMonth }/{ savedPaymentMethod.expYear }</div> 
      }
    </div>
  </div>
)

export default SavedPaymentMethodPreview
