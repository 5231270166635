import React, { useState, useEffect, useRef } from 'react'
import client from '../../../helpers/client'
import ErrorBlock, { validationStateFor } from './ErrorBlock'
import { Form, FormGroup, FormControl, InputGroup, Button, Row, Col, Modal } from 'react-bootstrap'
import TimeControl from '../../ui/TimeControl'

const SlotEditor = ({ eventType, apiKey, activeSlot, onHide, timepickerFormat, onCreateSlot, onUpdateSlot }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const [startsAtTime, setStartsAtTime] = useState(activeSlot ? activeSlot.startsAtTime : "")
  const [endsAtTime, setEndsAtTime] = useState(activeSlot ? activeSlot.endsAtTime : "")
  const [availableSunday, setAvailableSunday] = useState(activeSlot ? activeSlot.sunday : true)
  const [availableMonday, setAvailableMonday] = useState(activeSlot ? activeSlot.monday : true)
  const [availableTuesday, setAvailableTuesday] = useState(activeSlot ? activeSlot.tuesday : true)
  const [availableWednesday, setAvailableWednesday] = useState(activeSlot ? activeSlot.wednesday : true)
  const [availableThursday, setAvailableThursday] = useState(activeSlot ? activeSlot.thursday : true)
  const [availableFriday, setAvailableFriday] = useState(activeSlot ? activeSlot.friday : true)
  const [availableSaturday, setAvailableSaturday] = useState(activeSlot ? activeSlot.saturday : true)
  const [label, setLabel] = useState(activeSlot ? activeSlot.label : "")

  useEffect(() => {
    if(activeSlot) {
      setStartsAtTime(activeSlot.startsAtTime)
      setEndsAtTime(activeSlot.endsAtTime)
      setAvailableSunday(activeSlot.sunday)
      setAvailableMonday(activeSlot.monday)
      setAvailableTuesday(activeSlot.tuesday)
      setAvailableWednesday(activeSlot.wednesday)
      setAvailableThursday(activeSlot.thursday)
      setAvailableFriday(activeSlot.friday)
      setAvailableSaturday(activeSlot.saturday)
      setLabel(activeSlot.label)
    }
  }, [activeSlot])


  const handleSave = () => {
    const { id } = activeSlot
    setLoading(true)

    const formData = {
      startsAtTime: startsAtTime,
      endsAtTime: endsAtTime,
      sunday: availableSunday,
      monday: availableMonday,
      tuesday: availableTuesday,
      wednesday: availableWednesday,
      thursday: availableThursday,
      friday: availableFriday,
      saturday: availableSaturday,
      label: label
    }

    if(id) {
      client.put(`/api/v1/event_types/${eventType.id}/package_slots/${id}/`, { ...formData, apiKey })
        .then(response => {
          const slot = response.data.data.attributes
          setLoading(false)
          setErrors({})
          onUpdateSlot(slot)
        })
        .catch(e => {
          if(e.response && e.response.data && e.response.data.errors) {
            setErrors(e.response.data.errors)
          }
          else {
            setErrors({ base: "Unable to save" })
          }
          setLoading(false)
        })
    }
    else {
      client.post(`/api/v1/event_types/${eventType.id}/package_slots/`, { ...formData, apiKey })
        .then(response => {
          const slot = response.data.data.attributes
          setLoading(false)
          setErrors({})
          onCreateSlot(slot)
        })
        .catch(e => {
          if(e.response && e.response.data && e.response.data.errors) {
            setErrors(e.response.data.errors)
          }
          else {
            setErrors({ base: "Unable to save" })
          }
          setLoading(false)
        })
    }
  }

  return (
    <Modal show={!!activeSlot} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Slot</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { activeSlot &&
        <>
        <Row>
          <Col sm={4}>
            <FormGroup size="lg">
              <Form.Label>Start Time</Form.Label>
              <InputGroup>
                <TimeControl
                  timepickerFormat={timepickerFormat}
                  step={1}
                  value={startsAtTime}
                  onChange={setStartsAtTime}
                  isInvalid={!!errors.startsAtTime}
                />
              </InputGroup>
              <ErrorBlock error={errors.startsAtTime} />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup size="lg">
              <Form.Label>End Time</Form.Label>
              <InputGroup>
                <TimeControl
                  timepickerFormat={timepickerFormat}
                  step={1}
                  value={endsAtTime}
                  onChange={setEndsAtTime}
                  isInvalid={!!errors.endsAtTime}
                />
              </InputGroup>
              <ErrorBlock error={errors.endsAtTime} />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup size="lg">
              <Form.Label>Optional Label</Form.Label>
              <InputGroup>
                <FormControl
                  type="text"
                  value={label}
                  onChange={e => setLabel(e.target.value)}
                  isInvalid={!!errors.label}
                />
              </InputGroup>
              <Form.Text muted>If blank defaults to showing start and end time</Form.Text>
              <ErrorBlock error={errors.label} />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup size="lg">
          <Form.Label>Days Available</Form.Label>
          <div>
            <Form.Check
              type="checkbox"
              inline
              checked={availableSunday}
              onChange={e => setAvailableSunday(e.target.checked)}
              label="Sunday"
              id="sunday"
            />
            <Form.Check
              type="checkbox"
              inline
              checked={availableMonday}
              onChange={e => setAvailableMonday(e.target.checked)}
              label="Monday"
              id="monday"
            />
            <Form.Check
              type="checkbox"
              inline
              checked={availableTuesday}
              onChange={e => setAvailableTuesday(e.target.checked)}
              label="Tuesday"
              id="tuesday"
            />
            <Form.Check
              type="checkbox"
              inline
              checked={availableWednesday}
              onChange={e => setAvailableWednesday(e.target.checked)}
              label="Wednesday"
              id="wednesday"
            />
            <Form.Check
              type="checkbox"
              inline
              checked={availableThursday}
              onChange={e => setAvailableThursday(e.target.checked)}
              label="Thursday"
              id="thursday"
            />
            <Form.Check
              type="checkbox"
              inline
              checked={availableFriday}
              onChange={e => setAvailableFriday(e.target.checked)}
              label="Friday"
              id="friday"
            />
            <Form.Check
              type="checkbox"
              inline
              checked={availableSaturday}
              onChange={e => setAvailableSaturday(e.target.checked)}
              label="Saturday"
              id="saturday"
            />
          </div>
          <ErrorBlock error={errors.base} />
        </FormGroup>
        </>
        }
      </Modal.Body>
      <Modal.Footer className="text-end">
        <Button
          disabled={loading}
          variant="primary"
          onClick={handleSave}
        >{ loading ? "Saving..." : "Save" }</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SlotEditor
