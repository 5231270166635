import { parse, format, isBefore, startOfDay, endOfDay, addDays } from 'date-fns'

const parseDate = (dateString) => {
  if(dateString) {
    return parse(dateString, 'yyyy-MM-dd', new Date())
  }
  else {
    return null
  }
}

const formatCompact = (dateObject) => {
  if(!dateObject) {
    return null
  }

  return format(dateObject, 'MMM d')
}

const pastDue = (dateObject) => {
  const todayCutoff = startOfDay(new Date())
  return isBefore(dateObject, todayCutoff)
}

const dueSoon = (dateObject) => {
  const dueSoonCutoff = endOfDay(addDays(new Date(), 7))
  return isBefore(dateObject, dueSoonCutoff)
}

const itemContainsText = (item, text) => {
  const lowerText = text.toLowerCase()
  return (item.attributes.title && item.attributes.title.toLowerCase().includes(lowerText))
    || (item.attributes.notes && item.attributes.notes.toLowerCase().includes(lowerText))
    || (item.attributes.taskableDescription && item.attributes.taskableDescription.toLowerCase().includes(lowerText))
    || (item.attributes.eventTitle && item.attributes.eventTitle.toLowerCase().includes(lowerText))
}

export { parseDate, formatCompact, pastDue, dueSoon, itemContainsText }
