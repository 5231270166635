import React from 'react';
import EventPreview from './EventPreview'

export default class CustomOption extends React.Component {
  render() {
    const { innerRef, innerProps, value, label, data } = this.props

    return (
      <div
        {...innerProps}
        ref={innerRef}
      >
        <EventPreview data={data} />
      </div>
    )
  }
}
