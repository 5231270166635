import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'react-bootstrap'
import SelectOption from '../SelectOption'
import { camelCase } from 'change-case'
import HintField from '../HintField'

export default class RadioField extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelStyle: PropTypes.object,
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    style: PropTypes.object,
    hint: PropTypes.string,
    errors: PropTypes.object,
    required: PropTypes.bool,
  }

  static defaultProps = {
    labelStyle: {}
  }

  get errorMessage() {
    const { errors, name } = this.props

    if(errors && errors[camelCase(name)]) {
      return errors[camelCase(name)][0]
    }
    return null
  }

  get helpMessage() {
    const { hint } = this.props
    return this.errorMessage || hint || null
  }

  render() {
    const { name, label, value, onChange, hint, style, errors, required, options, labelStyle } = this.props
    const { helpMessage } = this

    return (
      <FormGroup className={name} style={{...styles.wrapper, ...style}}>
        <Form.Label style={{...styles.label, ...labelStyle}}>
          { label }
          { required && <span style={styles.required}>*</span> }
        </Form.Label>
        {options.map(option =>
          <Form.Check
            type="radio"
            key={option}
          >
            <Form.Check.Input
              type="radio"
              value={option}
              checked={value == option}
              onChange={e => onChange(e.target.value)}
              isInvalid={!!this.errorMessage}
            />
            <Form.Check.Label style={{...styles.label, ...labelStyle}}>{option}</Form.Check.Label>
          </Form.Check>
        )}
        <HintField
          hint={hint}
          errorMessage={this.errorMessage}
        />
      </FormGroup>
    )
  }
}

const styles = {
  label: {
    fontSize: 18,
  },
  input: {
    fontSize: 18
  },
  required: {
    color: "#cc0000",
    fontWeight: "normal",
    paddingLeft: 2
  }
}
