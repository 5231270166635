import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'

const FlashMessages = ({ success, setSuccess, errors, setErrors }) => {
  if(errors && Object.keys(errors).length > 0) {
    const errorMessage = errors["base"] ? errors["base"] : "One or more errors prevented your changes from being saved:"
    return (
      <Alert variant="danger" className="spacer-bottom text-center">
        { errorMessage }
      </Alert>
    )
  }
  else if(success) {
    return (
      <Alert variant="success" className="spacer-bottom text-center" onClose={() => setSuccess("") } dismissible>
        { success }
      </Alert>
    )
  }
  else {
    return null
  }
}

export default FlashMessages
