import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'react-bootstrap'
import ImageUploaderPreview from './ImageUploaderPreview'
import FileUploader from './FileUploader'
import FilePreview from './FilePreview'
import { camelCase } from 'change-case'
import HintField from '../HintField'


export default class FileUploadField extends React.Component {
  static propTypes = {
    apiKey: PropTypes.string.isRequired,
    host: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    label: PropTypes.string.isRequired,
    labelStyle: PropTypes.object,
    onChange: PropTypes.func,
    style: PropTypes.object,
    hint: PropTypes.string,
    errors: PropTypes.object,
    required: PropTypes.bool,
  }

  get errorMessage() {
    const { errors, name } = this.props

    if(errors && errors[camelCase(name)]) {
      return errors[camelCase(name)][0]
    }
    return null
  }

  get helpMessage() {
    const { hint } = this.props
    return this.errorMessage || hint || null
  }

  render() {
    const { apiKey, host, name, label, labelStyle, value, onChange, style, hint, errors, required } = this.props
    const { helpMessage } = this
    // const instance = this.props.instance || 0
    // const value = store.valueFor(uuid, "file", instance)
    // const errorMessage = store.errorFor(uuid, "file", instance)

    return (
      <FormGroup className={name} style={{...styles.wrapper, ...style}}>
        <Form.Label style={{...styles.label, ...labelStyle}}>
          { label }
          { required && <span style={styles.required}>*</span> }
        </Form.Label>
        <FilePreview file={value} />
        <FileUploader
          apiKey={apiKey}
          host={host}
          onChange={onChange}
          isInvalid={!!this.errorMessage}
        />
        <HintField
          hint={hint}
          errorMessage={this.errorMessage}
        />
      </FormGroup>
    )
  }
}

const styles = {
  label: {
    fontSize: 18,
  },
  input: {
    fontSize: 18
  },
  required: {
    color: "#cc0000",
    fontWeight: "normal",
    paddingLeft: 2
  }
}
