import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("store")
@observer
export default class SyncEvent extends React.Component {
  static propTypes = {
    event: PropTypes.shape({
      attributes: PropTypes.shape({
        id: PropTypes.number.isRequired,
        summary: PropTypes.string.isRequired,
        formattedTimeRange: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }

  render() {
    const { event, store } = this.props
    const { id, summary, formattedTimeRange, brandName, htmlLink } = event.attributes

    return (
      <a href={htmlLink} className="calendar-event" target="_blank" rel="noopener" data-calendar-id={id}>
        <div>{ formattedTimeRange }</div>
        <div style={{fontWeight: "bold"}}>{ summary }</div>
      </a>
    )
  }
}

