import React, { useState } from 'react'
import Category from './components/Category'
import SearchBar from './components/SearchBar'
import Toolbar from './components/Toolbar'
import NewButtons from './components/NewButtons'
import NoResults from './components/NoResults'
import { activeBackdropsForCategory, getCsrfToken, resultsForSearch, sortByPosition } from './util'
import { CATEGORIES_ACTIVE, BACKDROPS_ACTIVE } from './options'
import createPersistedState from 'use-persisted-state'

const useCategoryState = createPersistedState('backdropCategoryState')
const useBackdropState = createPersistedState('backdropBackdropState')

const AdminBackdropBrowser = (props) => {
  const [categories, setCategories ] = useState(props.categories)
  const [backdrops, setBackdrops ] = useState(props.backdrops)
  const { perPage } = props

  const [ search, setSearch ] = useState("")
  const [ showCategories, setShowCategories ] = useCategoryState(CATEGORIES_ACTIVE)
  const [ showBackdrops, setShowBackdrops ] = useBackdropState(BACKDROPS_ACTIVE)

  const csrfToken = getCsrfToken()
  const activeCategories = sortByPosition(categories, showCategories)
  const activeBackdrops = resultsForSearch(backdrops, search)

  return (
    <div className="categorized-manager">
      <div className="categorized-manager-header">
        <div className="row">
          <div className="col-sm-7">
            <NewButtons />
          </div>
          <div className="col-sm-5">
            <div className="mb-2">
              <Toolbar
                showCategories={showCategories}
                showBackdrops={showBackdrops}
                onSetShowCategories={setShowCategories}
                onSetShowBackdrops={setShowBackdrops}
              />
            </div>
            <div className="mb-2">
              <SearchBar value={search} onChange={val => setSearch(val)} />
            </div>
          </div>
        </div>
      </div>
      <div className="categorized-manager-body">
        { (search && !activeBackdrops.length) &&
          <NoResults onChange={setSearch} />
        }
        { activeCategories.map(category => 
          <Category
            key={category.id}
            category={category}
            count={categories.length}
            showCategories={showCategories}
            backdrops={activeBackdropsForCategory(activeBackdrops, category, showBackdrops)}
            search={search}
            perPage={perPage}
            allCategories={categories}
            csrfToken={csrfToken}
          />
        )}
      </div>
    </div>
  )
}

export default AdminBackdropBrowser
