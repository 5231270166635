import { TempusDominus } from '@eonasdan/tempus-dominus';
import "@eonasdan/tempus-dominus/dist/css/tempus-dominus.css";
import { addHours, startOfHour } from "date-fns"
// Font awesome 4.7 icons
const icons = {
  type: 'icons',
  time: 'fa fa-clock-o',
  date: 'fa fa-calendar',
  up: 'fa fa-chevron-up',
  down: 'fa fa-chevron-down',
  previous: 'fa fa-chevron-left',
  next: 'fa fa-chevron-right',
  today: 'fa fa-calendar-check-o',
  clear: 'fa fa-trash',
  close: 'fa fa-times'
}

document.addEventListener('focusin', function(event) {
  const { target } = event
  if(target.classList.contains('datepicker') && target.hasAttribute("data-picker-format")) {
    setupDatePicker(target)
  }
  if(target.classList.contains('timepicker') && target.hasAttribute("data-picker-format")) {
    setupTimePicker(target)
  }
})

function setupDatePicker(target) {
  if(target.hasAttribute("has-date-picker")) return

  const dateFormat = target.getAttribute("data-picker-format")

  const elem = new TempusDominus(target, {
    display: {
      icons,
      buttons: {
        today: false,
        clear: false,
        close: false
      },
      components: {
        decades: false,
        year: true,
        month: true,
        date: true,
        hours: false,
        minutes: false,
        seconds: false
      },
    },
    localization: {
      format: dateFormat
    },
  })

  target.setAttribute("has-date-picker", true)
}

function setupTimePicker(target) {
  if(target.hasAttribute("has-time-picker")) return

  const timeFormat = target.getAttribute("data-picker-format")
  const minuteIncrement = target.getAttribute("data-step") || 15

  const startOfNextHour = addHours(startOfHour(new Date()), 1)


  const elem = new TempusDominus(target, {
    display: {
      viewMode: 'clock',
      icons,
      components: {
        decades: false,
        year: false,
        month: false,
        date: false,
        hours: true,
        minutes: true,
        seconds: false
      },
    },
    localization: {
      format: timeFormat
    },
    stepping: minuteIncrement,
    defaultDate: target.value || startOfNextHour,
  })

  target.setAttribute("has-time-picker", true)
}
