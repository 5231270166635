import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("store")
@observer
export default class TodayButton extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  isActive() {
    const { date, view } = this.props.store


    const today = new Date()

    if(view === "day" && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear() && date.getDay() === today.getDay()) {
      return true
    }
    else if(view === "month" && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
      return true
    }

    return false
  }

  render() {
    const { store } = this.props

    return (
      <div className="rbc-btn-group">
        <button
          type="button"
          className={this.isActive() ? 'rbc-active' : null}
          onClick={() => (store.date = new Date()) }
        >Today</button>
      </div>
    )
  }
}

