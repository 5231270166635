import React from 'react'
import PropTypes from 'prop-types'

export default class FileUploader extends React.Component {
  state = {
    loading: false
  }

  handleImageChosen = (e) => {
    if(this.imageFieldRef.files[0]) {
      this.performUpload()
    }
    else {
      onChange({ url: null, rawUrl: null })
    }
  }

  performUpload = () => {
    const { apiKey, onChange } = this.props

    this.setState({ loading: true })

    const form = new FormData
    form.append('attachment', this.imageFieldRef.files[0])
    const xhr = new XMLHttpRequest;
    xhr.open("POST", "/api/v1/embedded_attachments", true);
    xhr.setRequestHeader("API_KEY", apiKey)

    xhr.onload = () => {
      if(xhr.status === 200) {
        const parsedResponse = JSON.parse(xhr.responseText)
        const url = parsedResponse["data"]["attributes"]["url"]
        const rawUrl = parsedResponse["data"]["attributes"]["raw_url"]
        this.setState({ loading: false })
        this.imageFieldRef.value = null
        onChange({ url, rawUrl })
      }
      else {
        alert("Unable to upload file")
        console.warn("Unable to upload file")
        this.setState({ loading: false })
        this.imageFieldRef.value = null
        onChange({ url: null, rawUrl: null })
      }
    }

    xhr.onerror = () => {
      alert("Unable to upload file")
      console.warn("Unable to upload file")
      this.setState({ loading: false })
      this.imageFieldRef.value = null
      onChange({ url: null, rawUrl: null })
    }

    xhr.send(form)
  }

  render() {
    const { apiKey, disabled, acceptedFormats } = this.props
    const { loading } = this.state

    return (
      <div style={{display: "flex", flexDirection: "row"}}>
        <input
          type="file"
          ref={ref => this.imageFieldRef = ref}
          onChange={this.handleImageChosen}
          disabled={disabled || loading}
          accept={acceptedFormats}
        />
        { loading && 
          <div>
            <i className="fa fa-spinner fa-spin fa-fw" />
            <span>Uploading...</span>
          </div>
        }
      </div>
    )

  }
}
