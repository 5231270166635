import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form, FormGroup } from 'react-bootstrap'

@inject("checklistStore")
@observer
export default class FilterControl extends React.Component {
  static propTypes = {
    checklistStore: PropTypes.object.isRequired
  }

  render() {
    const { checklistStore } = this.props
    const { filter, availableFilters } = checklistStore
    
    return (
      <div className="checklist-filter">
        <FormGroup>
          <Form.Select
            name={"form-select"}
            value={filter}
            onChange={e => (checklistStore.filter = e.target.value)}
            aria-label="Filter by"
          >
            { availableFilters.map(filter =>
              <option key={filter} value={filter}>
                { filter }
              </option>
            )}
          </Form.Select>
        </FormGroup>
      </div>
    )
  }
}

