import React from 'react'
import PropTypes from 'prop-types'

export default class SelectOption extends React.Component {
  static propTypes = {
    option: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Array)
    ]).isRequired
  }

  render() {
    const { option } = this.props

    if(option instanceof Array) {
      const [ value, label ] = option
      return (
        <option value={value}>{ label }</option>
      )
    }
    else {
      return (
        <option key={option} value={option}>{ option }</option>
      )
    }

  }
}
