import React from 'react';

const AutoPayCompatibleDisclaimer = ({ paymentMethodOptions, applicablePaymentMethodOptions }) => {
  if(paymentMethodOptions.length == applicablePaymentMethodOptions.length) {
    return null
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="font-style-italic text-center">* Showing auto-pay compatible payment methods only</div>
      </div>
    </div>
  )
}

export default AutoPayCompatibleDisclaimer;
