import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, FormControl, Button } from 'react-bootstrap'
import TimelineTimeField from './TimelineTimeField.js'
import {observer} from "mobx-react"
import store from '../stores/store.js'
import FaIcon from '../../../helpers/fa_icon.js'

const TimelineTimeRangeField = observer(({ field }) => {
  const [editable, setEditable] = useState(false)

  const { uuid } = field
  const instance = 0
  const timelineStartsAt = store.valueFor(uuid, "timelineStartsAt", instance)
  const timelineEndsAt = store.valueFor(uuid, "timelineEndsAt", instance)
  const errorMessage = store.errorFor(uuid, "timelineStartsAt", instance) ||
    store.errorFor(uuid, "timelineEndsAt", instance)
  const timeFormat = store.timeFormat

  function startEditing(e) {
    e.preventDefault()
    setEditable(true)
  }

  function finishEditing(e) {
    e.preventDefault()
    setEditable(false)
  }

  if(editable) {
    return (
      <FormGroup validationState={errorMessage ? "error" : null} className="timeline-editor-form">
        <div style={{display: "flex", alignItems: "flex-end"}}>
          <div style={{flex: 1}}>
            <Form.Label>Start Time</Form.Label>
            <TimelineTimeField
              name={name}
              onChange={timelineStartsAt => store.setValueFor(uuid, "timelineStartsAt", instance, timelineStartsAt)}
              value={timelineStartsAt}
              timeFormat={timeFormat}
              disabled={!store.editable}
            />
          </div>
          <div style={{flex: 1}}>
            <Form.Label>End Time</Form.Label>
            <TimelineTimeField
              name={name}
              onChange={timelineEndsAt => store.setValueFor(uuid, "timelineEndsAt", instance, timelineEndsAt)}
              value={timelineEndsAt}
              timeFormat={timeFormat}
              disabled={!store.editable}
            />
          </div>
          <Button type="submit" onClick={finishEditing} variant="primary">Done</Button>
        </div>
        { errorMessage && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{errorMessage}</Form.Control.Feedback> }
      </FormGroup>
    )
  }
  else if(timelineStartsAt && timelineEndsAt) {
    return (
      <a className="timeline-preview__label" href="#" onClick={startEditing}>
        <FaIcon icon="clock-o" text={`${timelineStartsAt} - ${timelineEndsAt}`} />
      </a>
    )
  }
  else if(timelineStartsAt) {
    return (
      <a className="timeline-preview__label" href="#" onClick={startEditing}>
        <FaIcon icon="clock-o" text={`${timelineStartsAt} - Set End`} />
      </a>
    )
  }
  else if(timelineStartsAt) {
    return (
      <a className="timeline-preview__label" href="#" onClick={startEditing}>
        <FaIcon icon="clock-o" text={`Set Start - ${timelineEndsAt}`} />
      </a>
    )
  }
  else {
    return (
      <a className="timeline-preview__label" href="#" onClick={startEditing}>
        <FaIcon icon="clock-o" text="Set Time" />
      </a>
    )
  }

})

export default TimelineTimeRangeField
