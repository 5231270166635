import React from 'react'

const OptionalAutoPayControl = ({ enableAutoPay, setEnableAutoPay }) => {
  return (
    <div className="alert alert-warning mt-3" role="alert">
      <label className="form-check-label ms-2">
        <input
          type="checkbox"
          checked={enableAutoPay}
          onChange={() => setEnableAutoPay(!enableAutoPay)}
          className="form-check-input enable-auto-pay"
        />
        <span className="fw-bold ps-1 pe-1">Enable Auto Pay:</span> 
        I agree to have my payment method charged automatically on each future payment date.
      </label>
    </div>
  )
}

export default OptionalAutoPayControl
