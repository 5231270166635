import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("store")
@observer
export default class MonthWeekDaySelector extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  render() {
    const { store } = this.props
    const { view } = store

    return (
      <div className="rbc-btn-group">
        <button
          type="button"
          className={store.view === "month" ? 'rbc-active' : null}
          onClick={() => (store.view = "month") }
        >Month View</button>
        <button
          type="button"
          className={store.view === "day" ? 'rbc-active' : null}
          onClick={() => (store.view = "day") }
        >Day View</button>
      </div>
    )
  }
}

