import React, { useState, useEffect, useRef } from 'react'
import client from '../../../helpers/client'
import ErrorBlock, { validationStateFor } from './ErrorBlock'
import { Form, FormGroup, InputGroup, FormControl, Button, Row, Col, Modal } from 'react-bootstrap'

const PackageUnitRangeEditor = ({
  eventType,
  apiKey,
  activePackageUnitRange,
  onHide,
  timepickerFormat,
  onCreatePackageUnitRange,
  onUpdatePackageUnitRange,
  currency,
  formValues,
  unitTranslator
}) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const minUnitsRef = useRef(null)
  const maxUnitsRef = useRef(null)
  const priceRef = useRef(null)

  const handleSave = () => {
    const { id } = activePackageUnitRange
    setLoading(true)

    const formData = {
      minUnits: minUnitsRef.current.value,
      maxUnits: maxUnitsRef.current.value,
      price: priceRef.current.value
    }

    if(id) {
      client.put(`/api/v1/event_types/${eventType.id}/package_unit_ranges/${id}/`, { ...formData, apiKey })
        .then(response => {
          const packageUnitRange = response.data.data.attributes
          setLoading(false)
          setErrors({})
          onUpdatePackageUnitRange(packageUnitRange)
        })
        .catch(e => {
          if(e.response && e.response.data && e.response.data.errors) {
            setErrors(e.response.data.errors)
          }
          else {
            setErrors({ base: "Unable to save" })
          }
          setLoading(false)
        })
    }
    else {
      client.post(`/api/v1/event_types/${eventType.id}/package_unit_ranges/`, { ...formData, apiKey })
        .then(response => {
          const packageUnitRange = response.data.data.attributes
          setLoading(false)
          setErrors({})
          onCreatePackageUnitRange(packageUnitRange)
        })
        .catch(e => {
          if(e.response && e.response.data && e.response.data.errors) {
            setErrors(e.response.data.errors)
          }
          else {
            setErrors({ base: "Unable to save" })
          }
          setLoading(false)
        })
    }
  }

  return (
    <Modal show={!!activePackageUnitRange} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Pricing Tier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { activePackageUnitRange &&
          <>
            <Row>
              <Col sm={4}>
                <FormGroup size="lg">
                  <Form.Label>
                    Minimum { unitTranslator.labelFor(formValues.unitName, "unitsTitle")}
                  </Form.Label>
                  <InputGroup>
                    <FormControl
                      type="text"
                      defaultValue={activePackageUnitRange.minUnits}
                      ref={ref => minUnitsRef.current = ref}
                      isInvalid={!!errors.minUnits}
                    />
                  </InputGroup>
                  <Form.Text muted>Leave blank for no minimum</Form.Text>
                  <ErrorBlock error={errors.minUnits} />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup size="lg">
                  <Form.Label>
                    Maximum { unitTranslator.labelFor(formValues.unitName, "unitsTitle")}
                  </Form.Label>
                  <InputGroup>
                    <FormControl
                      type="text"
                      defaultValue={activePackageUnitRange.maxUnits}
                      ref={ref => maxUnitsRef.current = ref}
                      isInvalid={!!errors.maxUnits}
                    />
                  </InputGroup>
                  <Form.Text muted>Leave blank for unlimited</Form.Text>
                  <ErrorBlock error={errors.maxUnits} />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup size="lg">
                  <Form.Label>
                    { formValues.enableCustomerCanBookPerUnit &&
                      <>Price per { unitTranslator.labelFor(formValues.unitName, "unitTitle")} </>
                    }
                    { formValues.enableCustomerCanBookPerUnitRange &&
                      <>Flat Rate </>
                    }
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text>{ currency.symbol }</InputGroup.Text>
                    <FormControl
                      type="text"
                      defaultValue={activePackageUnitRange.price}
                      ref={ref => priceRef.current = ref}
                      isInvalid={!!errors.price}
                    />
                  </InputGroup>
                  <ErrorBlock error={errors.price} />
                </FormGroup>
              </Col>
            </Row>
          </>
        }
      </Modal.Body>
      <Modal.Footer className="text-end">
        <Button
          disabled={loading}
          variant="primary"
          onClick={handleSave}
        >{ loading ? "Saving..." : "Save" }</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PackageUnitRangeEditor
