import React from 'react';
import PropTypes from 'prop-types'

export default class BackdropPreview extends React.Component {
  render() {
    const { data } = this.props
    const { name, description, primaryImage } = data.attributes

    return (
      <div
        className="backdrop-profile-preview"
        style={styles.containerStyle}
      >
        <div className="backdrop-photo no-print">
          <img src={primaryImage.videoVariant} alt={name} />
        </div>
        <div className="backdrop-details">
          <div className="name">{ name }</div>
          <div className="email">{ description }</div>
        </div>
      </div>
    )
  }
}

const styles = {
  containerStyle: {
    margin: "5px 10px 10px 10px",
    padding: "5px 10px",
    border: "1px solid #eee",
    borderRadius: 5,
    cursor: "pointer",
    background: "#f7f7f7"
  }
}
