import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("store")
@observer
export default class EventBooking extends React.Component {
  static propTypes = {
    event: PropTypes.shape({
      attributes: PropTypes.shape({
        title: PropTypes.string.isRequired,
        formattedStatus: PropTypes.string.isRequired,
        formattedTimeRange: PropTypes.string.isRequired,
        eventId: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  }

  render() {
    const { event, store } = this.props
    const { showBrands } = store
    const {
      eventId,
      title,
      formattedStatus,
      formattedTimeRange,
      staffMemberAbbreviations,
      brandName,
      canceled,
      postponed,
      expiredProposal
    } = event.attributes

    return (
      <a href={`/events/${eventId}`} className="calendar-event">
        <div>{ formattedTimeRange }</div>
        { showBrands &&
          <div>[{ brandName }]</div>
        }
        <div style={{fontWeight: "bold"}}>{ title }</div>
        { staffMemberAbbreviations && <div>{`(${staffMemberAbbreviations})`}</div>
        }
        <div>
          { formattedStatus }
          <em>{ expiredProposal ? " (Expired)" : "" }</em>
          <em>{ canceled ? " (Canceled)" : "" }</em>
          <em>{ postponed ? " (Postponed)" : "" }</em>
        </div>
      </a>
    )
  }
}

