import React, { useState, useEffect } from 'react'
import Backdrop from './Backdrop'
import Pager from './Pager'
import { backdropsForCategory, paginatedResults, sortByName } from '../util'

const NoResults = ({ onChange }) => {
  function handleShowAll(e) {
    e.preventDefault()
    onChange("")
  }

  return (
    <div className="categorized-group">
      <div className="categorized-group-body">
        <div className="categorized-item-centered-block">
          <div className="text-center">No results were found for your search term.  Try another search term or <a href="#" onClick={handleShowAll}>show all results</a></div>
        </div>
      </div>
    </div>
  )
}

export default NoResults
