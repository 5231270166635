import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap'
import FaIcon from '../../../helpers/fa_icon.js'

const MoreOptions = ({ managePaths }) => {
  if(!managePaths || !managePaths.length) return null

  return (
    <div style={styles.buttonContainer}>
      <Dropdown id="manage-questionnaire">
        <Dropdown.Toggle size="lg" variant="outline-secondary" className="w-100">
          Manage
        </Dropdown.Toggle>

        <Dropdown.Menu>
          { managePaths.map(([ label, path, additionalProps ], index) => (
            <Dropdown.Item
              {...additionalProps}
              eventKey={index}
              href={path}
              key={index}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

const styles = {
  buttonContainer: {
    marginTop: 20
  }
}

export default MoreOptions
