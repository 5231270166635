import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Modal, Row, Col, FormGroup, FormControl, Checkbox } from 'react-bootstrap'
import ColorPicker from '../ColorPicker'
import EventTypeSelector from '../EventTypeSelector'
import CopyableTextBlock from '../CopyableTextBlock'
import stringifyProps from './stringifyProps'

export default class EmployeeWidgetButton extends Component {
  state = {
    showModal: false,
    style: "embed",
    showBookNowButton: false,
    bookNowButtonText: "Book Now",
  }

  static propTypes = {
    type: PropTypes.string.isRequired,
    host: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired
  }

  showModal = () => {
    this.setState({showModal: true})
  }

  hideModal = () => {
    this.setState({showModal: false})
  }

  get html() {
    const { style, showBookNowButton, bookNowButtonText } = this.state
    const { host, apiKey} = this.props

    const props = { apiKey, host: `https://${host}`, showBookNowButton, bookNowButtonText }

    switch(style) {
      case "embed":
        return `<script src="https://${host}/api/checkcherry_widgets" type="text/javascript" charset="utf-8"></script><div class="checkcherry__widget__employee-gallery" data-props='${stringifyProps(props)}'></div>`
      default:
        return null;
    }
  }

  render() {
    const { type } = this.props
    const { showModal, style, showBookNowButton, bookNowButtonText } = this.state

    return (
      <div>
        <div className="btn-group">
          <Button onClick={this.showModal} variant="primary">
            <i className="fa fa-code" />
            { " " }
            Get Embed Code
          </Button>
        </div>
        <Modal show={showModal} onHide={this.hideModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Employee Profile Widget</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={5}>
                <h3>Customize</h3>
                <FormGroup className="mb-2">
                  <Form.Label>Choose Style</Form.Label>
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "embed"}
                    onChange={() => this.setState({style: "embed"})}
                    label="Embed on my site"
                    id="employee-widget-style-embed"
                  />
                </FormGroup>
                <FormGroup className="mb-2">
                  <Form.Label>Show Book Now Button?</Form.Label>
                  <Form.Check
                    type="radio"
                    name="showBookNowButton"
                    checked={showBookNowButton === true}
                    onChange={() => this.setState({showBookNowButton: true})}
                    label="Yes"
                    id="employee-widget-show-book-now-button-yes"
                  />
                  <Form.Check
                    type="radio"
                    name="showBookNowButton"
                    checked={showBookNowButton === false}
                    onChange={() => this.setState({showBookNowButton: false})}
                    label="No"
                    id="employee-widget-show-book-now-button-no"
                  />
                </FormGroup>
                { showBookNowButton &&
                  <FormGroup className="mb-2">
                    <Form.Label>Button Text</Form.Label>
                    <FormControl
                      type="bookNowButtonText"
                      value={bookNowButtonText}
                      onChange={(e) => this.setState({bookNowButtonText: e.target.value})}
                    />
                  </FormGroup>
                }
              </Col>
              <Col sm={{ span: 5, offset: 2}}>
                <h3>HTML</h3>

                <p>Copy and paste the HTML below into your website to add your contact form</p>

                <FormGroup className="mb-2">
                  <CopyableTextBlock value={this.html} />
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const styles = {
  previewStyle: {
    wordWrap: 'break-word'
  },
  embedStyle: {
    height: 300
  }
}
