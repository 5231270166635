import React from 'react'
import { Pagination } from 'react-bootstrap'

const bufferPages = 4

const Pager = ({ onChangePage, onLoadMore, currentPage, totalPages }) => {
  if(totalPages <= 1) {
    return null
  }

  const startPage = Math.max(1, currentPage - bufferPages)
  const endPage = Math.min(totalPages, currentPage + bufferPages)

  let visiblePages = []
  for(let i = startPage; i <= endPage; i++) {
    visiblePages.push(i)
  }
  const showStartEllipses = startPage > 1
  const showEndEllipses = endPage < totalPages


  return (
    <>
      <div className="d-block d-sm-none">
        { currentPage < totalPages &&
        <button onClick={onLoadMore} className="btn btn-secondary w-100">Load more...</button>
        }
      </div>
      <div className="d-none d-sm-block">
        <Pagination>
          { currentPage > 1 && <Pagination.Item onClick={() => onChangePage(1)}>« First</Pagination.Item> }
          { currentPage > 1 && <Pagination.Item onClick={() => onChangePage(currentPage - 1)}>‹ Prev</Pagination.Item> }
          { showStartEllipses && <Pagination.Ellipsis /> }
          { visiblePages.map(page => 
            <Pagination.Item
              key={page}
              onClick={() => onChangePage(page)}
              active={currentPage === page}
            >
              { page }
            </Pagination.Item>
          )}

          { showEndEllipses && <Pagination.Ellipsis /> }
          { currentPage < totalPages && <Pagination.Item onClick={() => onChangePage(currentPage + 1)}>Next ›</Pagination.Item> }
          { currentPage < totalPages && <Pagination.Item onClick={() => onChangePage(totalPages)}>Last »</Pagination.Item> }
        </Pagination>
      </div>
    </>
  )
}


export default Pager
