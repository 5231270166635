import React, { useState, useEffect, forwardRef } from 'react'
import { buildApiClient } from '../../helpers/client'
import { addDays, subDays, format } from 'date-fns'
import DatePicker from 'react-datepicker'
import "./index.css"


const ActivityForDate = ({ apiKey, brandId, dateFormat }) => {
  const [ready, setReady] = useState(false)
  const [activeDate, setActiveDate] = useState(new Date())
  const [leads, setLeads] = useState([])
  const [proposals, setProposals] = useState([])
  const [confirmedBookings, setConfirmedBookings] = useState([])
  const client = buildApiClient(apiKey)

  console.log({ brandId })

  useEffect(() => {
    loadLeadsAndBookingsForDate()
  }, [activeDate])

  async function loadLeadsAndBookingsForDate() {
    let params = {
      active_date: format(activeDate, 'yyyy-MM-dd'),
    }
    if(brandId) {
      params.brand_id = brandId
    }
    const url = `/api/v1/reporting/leads_and_bookings?${new URLSearchParams(params)}`
    const response = await client.get(url)
    const { confirmedBookings, proposals, leads } = response.data
    setConfirmedBookings(confirmedBookings)
    setProposals(proposals)
    setLeads(leads)
    setReady(true)
  }

  function decrementDate(e) {
    e.preventDefault()
    setActiveDate(subDays(activeDate, 1))
  }

  function incrementDate(e) {
    e.preventDefault()
    setActiveDate(addDays(activeDate, 1))
  }

  if(!ready) {
    return null
  }

  const TitleInput = forwardRef(({ value, onClick }, ref) => {
    function handleClick(e) {
      e.preventDefault()
      onClick(e)
    }

    return (
      <a href="#" onClick={handleClick} ref={ref}>
        <h5>Activity For { format(activeDate, dateFormat) }</h5>
      </a>
    )
  });

  return (
    <div className="widget__activity-for-date">
      <div className="d-flex align-items-baseline">
        <a href="#" onClick={decrementDate} className="me-2 subtle-edit">
          <i className="fa fa-chevron-left" />
        </a>
        <DatePicker
          name={name}
          customInput={<TitleInput />}
          onChange={setActiveDate}
          selected={activeDate}
          dateFormat={dateFormat}
        />
        <a href="#" onClick={incrementDate} className="ms-2 subtle-edit">
          <i className="fa fa-chevron-right" />
        </a>
      </div>

      { confirmedBookings.length > 0 || proposals.length > 0 || leads.length > 0 ? (
        <div className="list-group list-group-flush">
          { confirmedBookings.map(booking => (
            <a href={`/events/${booking.id}`} className="list-group-item list-group-item-action" key={booking.id}>
              <div>
                <span className="fw-medium">{ booking.title }</span>
                <span className="badge text-bg-success ms-1">{ booking.status }</span>
              </div>
              <div className="text-muted">
                { booking.timeDescription }
              </div>
            </a>
          )) }
          { proposals.map(proposal => (
            <a href={`/events/${proposal.id}`} className="list-group-item list-group-item-action" key={proposal.id}>
              <div>
                <span className="fw-medium">{ proposal.title }</span>
                <span className="badge text-bg-purple ms-1">{ proposal.status }</span>
              </div>
              <div className="text-muted">
                { proposal.timeDescription }
              </div>
            </a>
          )) }
          { leads.map(lead => (
            <a href={`/admin/leads/${lead.id}`} className="list-group-item list-group-item-action" key={lead.id}>
              <div>
                <span className="fw-medium">{ lead.title }</span>
                <span className="badge text-bg-orange ms-1">{ lead.status }</span>
              </div>
              <div className="text-muted">
                { lead.timeDescription }
              </div>
            </a>
          )) }
        </div>
      ) : (
        <div className="text-muted">No leads, proposals, or bookings for this date.</div>
      )}
    </div>
  )
}

export default ActivityForDate
