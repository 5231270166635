import React, { useCallback } from 'react'
import { Button } from 'react-bootstrap'
import FaIcon from '../../../helpers/fa_icon.js'
import store from '../stores/store.js'
import { observer } from "mobx-react"

const AddFieldButton = observer(({ fieldGroupIndex, position }) => {
  const addField = useCallback(() => {
    store.addField(fieldGroupIndex, position)
  }, [fieldGroupIndex, position])

  if(!store.buildable) return null

  return (
    <div className="add-field-wrapper">
      <Button variant="primary" onClick={addField}>
        <FaIcon icon="plus" text="Add New Field" />
      </Button>
    </div>
  )
})

export default AddFieldButton
