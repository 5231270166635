import React from 'react'
import { toJS } from 'mobx'
import { observer } from "mobx-react"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import FieldInstanceCollection from './FieldInstanceCollection.js'
import store from '../stores/store.js'

const FieldGroupCollection = observer(({ fieldGroupIndex }) => {
  const { fields, allowSort } = store.fieldGroups[fieldGroupIndex]
  const sortable = (store.buildable || allowSort)

  const handleDragEnd = ({ draggableId, source, destination }) => {
    store.reorderField(fieldGroupIndex, source.index, destination.index)
  }

  if(fields.length) {
    return (
      <div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >

                {fields.map((field, index) =>
                <Draggable
                  key={field.uuid}
                  draggableId={`${field.uuid}`}
                  index={index}
                  isDragDisabled={!sortable}
                >
                  {(provided, snapshot) => (
                    <div
                      key={index}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <FieldInstanceCollection
                        field={field}
                        index={index}
                        fieldGroupIndex={fieldGroupIndex}
                      />
                    </div>
                  )}
                </Draggable>
                )}
                { provided.placeholder }
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
  else if (store.buildable) {
    return (
      <p>Click "Add New Field" to start adding your fields for this tab.</p>
    )
  }
})

export default FieldGroupCollection
