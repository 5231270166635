import React, { useState, useEffect } from 'react'
import SavedPaymentMethodContext from '../context/SavedPaymentMethodContext'
import client from '../../../helpers/client'


const SavedPaymentMethodProvider = ({ userId, children }) => {
  const [savedPaymentMethods, setSavedPaymentMethods] = useState([])

  useEffect(() => {
    if(userId) {
      fetchSavedCards()
    }
  }, [userId]);


  async function fetchSavedCards() {
    try {
      const response = await client.get(`/users/${userId}/saved_credit_cards.json`)
      console.log(response.data)
      const savedPaymentMethods = response.data.data.map(pm => pm.attributes)
        // .filter(pm => pm.kind === paymentMethodOption.kind)
      setSavedPaymentMethods(savedPaymentMethods)
    }
    catch(e) {
      console.error(e)
    }
  }

  return (
    <SavedPaymentMethodContext.Provider value={{ savedPaymentMethods }}>
      {children}
    </SavedPaymentMethodContext.Provider>
  )
}

export default SavedPaymentMethodProvider
