import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("store")
@observer
export default class MiniSession extends React.Component {
  static propTypes = {
    event: PropTypes.shape({
      attributes: PropTypes.shape({
        title: PropTypes.string.isRequired,
        formattedTimeRange: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  }

  render() {
    const { event, store } = this.props
    const { showBrands } = store
    const { attributes, relationships } = event
    const { id, title, formattedStatus, formattedTimeRange, staffMemberAbbreviations, brandName } = attributes

    const slots = store.bookedSlotsForMiniSession(id)

    return (
      <a href={`/admin/mini_sessions/${id}`} className="calendar-event">
        <div>{ formattedTimeRange }</div>
        { showBrands &&
          <div>[{ brandName }]</div>
        }
        <div style={{fontWeight: "bold"}}>{ title }</div>
        <div>{ formattedStatus }</div>
        { slots.map(slot => 
          <div
            key={slot.attributes.id}
          >
            { slot.attributes.formattedSlotName }: { slot.attributes.bookedByName }
          </div>
        )}
      </a>
    )
  }
}

