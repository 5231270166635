import React from 'react'
import { PayPalButton } from "react-paypal-button-v2";
import Loader from './Loader'
import client from '../../../helpers/client'

export default class PaypalManager extends React.Component {
  ready = false

  state = {
    errorMessage: null
  }

  componentDidMount() {
    const { setLoading } = this.props
    setLoading(true)
    this.timeout = setTimeout(() => {
      if(!this.ready) {
        setLoading(true)
      }
    }, 1000)
  }

  componentWillUnmount() {
    if(this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  handleDidLoad =() => {
    const { setLoading } = this.props
    this.ready = true
    setLoading(false)
  }

  render() {
    const {
      paymentMethod,
      paymentMethodOption,
      amountCents,
      paymentIntentId,
      amountCurrency,
      locale,
      setLoading,
      activePaymentMethodOption,
      setActivePaymentMethodOption
    } = this.props
    const { errorMessage } = this.state

    const { paypalClientId, paypalMerchantId, disableCards, disablePayLater, debug } = paymentMethod

    // Only hide if a different payment method option is currently selected
    if(activePaymentMethodOption.id && activePaymentMethodOption.id !== paymentMethodOption.id) {
      return null
    }

    const options = {
      "client-id": paypalClientId,
      "integration-date": "2021-02-23",
      "merchant-id": paypalMerchantId,
      "vault": false,
      "currency": amountCurrency,
      "locale": locale,
      "debug": debug
    }

    if(disablePayLater && disableCards) {
      options["disable-funding"] = "credit,card"
    }
    else if(disableCards) {
      options["disable-funding"] = "card"
    }
    else if(disablePayLater) {
      options["disable-funding"] = "credit"
    }

    const amountDollars = (amountCents / 100).toFixed(2)

    return (
      <div className="paypal-form">
        { errorMessage && <div className="card-errors" role="alert">{errorMessage}</div> }
        <PayPalButton
          createOrder={(data, actions) => {
            setActivePaymentMethodOption(paymentMethodOption.id)

            return actions.order.create({
              application_context: {
                shipping_preference: "NO_SHIPPING"
              },
              purchase_units: [{
                amount: {
                  currency_code: amountCurrency,
                  value: amountDollars
                }
              }]
            })
          }}
          // amount={amountDollars}
          // clientId="AWwaYO2Nqm6RTSmc-vaJN06k3y_EsNvKpSzri5T3yeimVNFmKp8g3WmgiKA6IhTPyi3jc6rE286kkyl6"
          shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
          options={options}
          onCancel={(args) => {
            setLoading(false)
            setActivePaymentMethodOption({ id: null, subtype: null, special: null})
          }}
          onButtonReady={this.handleDidLoad}
          onApprove={(data, actions) => {
            setLoading(true)
            client.post(`/payment_intents/${paymentIntentId}/submit_paypal_payment`, {
              paypalOrderId: data.orderID,
              paypalPayerId: data.payerID
            }, { withCredentials: true })
              .then(result => {
                window.location.reload()
              })
              .catch(error => {
                setLoading(false)
                alert(error.response.data.error)
                setActivePaymentMethodOption({ id: null, subtype: null, special: null })
              })
          }}
          onError={(error) => {
            setLoading(false)
            this.setState({ errorMessage: error.message})
            setActivePaymentMethodOption({ id: null, subtype: null, special: null })
          }}
          catchError={(error) => {
            setLoading(false)
            this.setState({ errorMessage: error.message})
            setActivePaymentMethodOption({ id: null, subtype: null, special: null })
          }}
          // onSuccess={(details, data) => {
          //   debugger
          //   alert("Transaction completed by " + details.payer.name.given_name);

          //   // OPTIONAL: Call your server to save the transaction
          //   return fetch("/paypal-transaction-complete", {
          //     method: "post",
          //     body: JSON.stringify({
          //       orderID: data.orderID
          //     })
          //   });
          // }}
        />
      </div>
    );
  }
}
