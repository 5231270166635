import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, FormControl, InputGroup, Button } from 'react-bootstrap'
import {observer} from "mobx-react"
import store from '../stores/store.js'
import FaIcon from '../../../helpers/fa_icon.js'

const TimelineLengthField = observer(({ field }) => {
  const [editable, setEditable] = useState(false)

  const { uuid } = field
  const instance = 0
  const value = store.valueFor(uuid, "timelineLength", instance)
  const errorMessage = store.errorFor(uuid, "timelineLength", instance)

  function startEditing(e) {
    e.preventDefault()
    setEditable(true)
  }

  function finishEditing(e) {
    e.preventDefault()
    setEditable(false)
  }

  if(editable) {
    return (
      <FormGroup validationState={errorMessage ? "error" : null} className="timeline-editor-form">
        <Form.Label>Length (minutes)</Form.Label>
        <InputGroup>
          <FormControl
            type="text"
            value={value}
            onChange={e => store.setValueFor(uuid, "timelineLength", instance, e.target.value)}
            disabled={!store.editable}
            isInvalid={!!errorMessage}
          />
          <Button type="submit" onClick={finishEditing} variant="primary">Done</Button>
        </InputGroup>
        { errorMessage && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{errorMessage}</Form.Control.Feedback> }
      </FormGroup>
    )
  }
  else if(value) {
    return (
      <a className="timeline-preview__label" href="#" onClick={startEditing}>
        <FaIcon icon="clock-o" text={`${value} minutes`} />
      </a>
    )
  }
  else {
    return (
      <a className="timeline-preview__label" href="#" onClick={startEditing}>
        <FaIcon icon="clock-o" text="Set length" />
      </a>
    )
  }

})

export default TimelineLengthField
