import React from 'react'
import { ListGroup } from 'react-bootstrap'
import SearchResultUser from './SearchResultUser'

const SearchResultListUsers = ({ users, searchValue }) => {
  if(users.data.length === 0) {
    return null
  }

  const params = new URLSearchParams({
    "user_query[search]" : searchValue,
  })
  const moreResultsPath = `/admin/users?${params.toString()}`

  return (
    <div className="mb-3">
      <h5>
        <a href={moreResultsPath} className="text-dark">
          Users &raquo;
        </a>
      </h5>
      <ListGroup>
        {users.data.map(result => (
          <SearchResultUser key={result.id} result={result} />
        ))}
      </ListGroup>
      <div className="text-end mt-2">
        <a className="text-muted" href={moreResultsPath}>Search all user accounts &raquo;</a>
      </div>
    </div>
  )
}

export default SearchResultListUsers
