import React from 'react';

const UserBadge = ({ adminRole, staffRole, customerRole }) => {
  return (
    <>
      { adminRole && <div className="badge bg-secondary me-1">Admin</div> }
      { staffRole && <div className="badge bg-secondary me-1">Staff</div> }
      { customerRole && <div className="badge bg-secondary me-1">Customer</div> }
    </>
  )
}

export default UserBadge;
