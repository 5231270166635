import React, { useState, useEffect } from 'react';
import client from '../../helpers/client'

const retryInterval = 3000
const retryTimeout = 20000

// Check every few seconds if our Stripe Express account has been activated
// Wait a maximum of 20 seconds before redirecting to the payment methods page
const RefreshWidget = ({ apiKey, onSuccess, onTimeout }) => {
  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const response = await client.put(`/api/v1/stripe_express/account_sessions/refresh`, { api_key: apiKey })
        const { enabled, detailsSubmitted, stripeExpressChargesEnabled } = response.data.data
        console.log({ enabled, detailsSubmitted, stripeExpressChargesEnabled })
        if(enabled && detailsSubmitted && stripeExpressChargesEnabled) {
          console.log('Account is activated')
          clearInterval(interval)
          onSuccess()
        }
        else {
          console.log('Account is not activated')
        }
      }
      catch(error) {
        console.error(error)
      }
    }, retryInterval)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onTimeout()
    }, retryTimeout)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className="text-center">
      <p>
        <i className="fa fa-spinner fa-spin"></i>
        { " " }
        Refreshing account status...
      </p>
    </div>
  )
}

export default RefreshWidget
