import React from 'react';
import PropTypes from 'prop-types'

const AvailabilityBadge = ({ showAvailability, conflicts }) => {
  if(!showAvailability) return null

  if(conflicts && conflicts.length > 0) {
    return (
      <div>
        { conflicts.map((conflict, index) => 
          <div key={index} className="badge bg-danger">{ conflict }</div>
        )}
      </div>
    )
  }
  else {
    return (
      <div className="badge bg-secondary">Available</div>
    )
  }
}

export default class UserPreview extends React.Component {
  render() {
    const { data, showAvailability } = this.props
    const { fullName, email, phone, primaryImage, conflicts } = data.attributes

    return (
      <div
        className="user-profile-preview"
        style={styles.containerStyle}
      >
        <div className="user-photo no-print">
          <img src={primaryImage} alt={fullName} />
        </div>
        <div className="user-details">
          <div className="name">{ fullName }</div>
          <div className="email">{ email }</div>
          <div className="phone">{ phone }</div>
          <AvailabilityBadge
            showAvailability={showAvailability}
            conflicts={conflicts}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  containerStyle: {
    margin: "5px 10px 10px 10px",
    padding: "5px 10px",
    border: "1px solid #eee",
    borderRadius: 5,
    cursor: "pointer",
    background: "#f7f7f7"
  }
}
