import React  from 'react'
import { Form, FormGroup, FormControl } from 'react-bootstrap'
import extractFieldsAvailableForConditionalExpressions from '../util/extractFieldsAvailableForConditionalExpressions.js'
import { observer } from 'mobx-react'
import store from '../stores/store.js'
import { toJS } from 'mobx'
import ConditionalFieldEventTypeSelector from './ConditionalFieldEventTypeSelector'
import conditionalMatchers from '../data/conditionalMatchers.json'
import fieldTypes from '../data/fieldTypes.json'


function extractConditionalMatchersForField(conditionalFieldUuid, selectedConditionalField, availableConditionalMatchers) {
  if(conditionalFieldUuid === "_package_matcher") {
    return availableConditionalMatchers = conditionalMatchers.filter(matcher => matcher.usesPackageSelector)
  }
  else if(selectedConditionalField) {
    return availableConditionalMatchers = conditionalMatchers.filter(matcher => {
      return matcher.fieldTypes.includes(selectedConditionalField.type)
    })
  }
  else {
    return []
  }
}


const ConditionalFieldInstance = observer(({ condition, onChange, availableConditionalFields }) => {
  const { conditionalFieldUuid, conditionalMatcher, conditionalText, conditionalPackageSelectorSettings } = condition

  const selectedConditionalField = availableConditionalFields.find(field => field.value == conditionalFieldUuid)
  const availableConditionalMatchers = extractConditionalMatchersForField(conditionalFieldUuid, selectedConditionalField, availableConditionalMatchers)
  const selectedConditionalMatcher = availableConditionalMatchers.find(matcher => matcher.value == conditionalMatcher)


  // console.log({ conditionalPackageSelectorSettings: toJS(conditionalPackageSelectorSettings) })
  // console.log(toJS(store.eventTypeSelectorSettings))


  return (
    <div className="row">
      <div className="col-4">
        <Form.Select
          value={conditionalFieldUuid || ""}
          onChange={e => onChange({conditionalFieldUuid: e.target.value})}
          >
          <option value=""></option>
          <optgroup label="General">
            <option value="_package_matcher"> Selected Package</option>
          </optgroup>
          <optgroup label="Other Fields">
            { availableConditionalFields.map(field =>
              <option key={field.value} value={field.value}>
                { field.name }
              </option>
            )}
          </optgroup>
        </Form.Select>
      </div>
      <div className="col-3">
        <Form.Select
          value={conditionalMatcher}
          onChange={e => onChange({conditionalMatcher: e.target.value})}
          >
          <option value=""></option>
            { availableConditionalMatchers.map(matcher =>
              <option key={matcher.value} value={matcher.value}>
                {matcher.label}
              </option>
            )}
        </Form.Select>
      </div>
      <div className="col-5">
        { (selectedConditionalMatcher && selectedConditionalMatcher.usesValue) && 
          <FormControl
            type="text"
            value={conditionalText || ''}
            onChange={e => onChange({conditionalText: e.target.value})}
          />
        }
        {
          (selectedConditionalMatcher && selectedConditionalMatcher.usesPackageSelector) &&
          <ConditionalFieldEventTypeSelector
            value={conditionalPackageSelectorSettings}
            onChange={conditionalPackageSelectorSettings => onChange({ conditionalPackageSelectorSettings })}
          />
        }
      </div>
    </div>
  )
})


const ConditionalFieldEditor = observer(({ field, fieldGroupIndex, fieldIndex }) => {
  const conditions = field.conditions || [{
    conditionalFieldUuid: '',
    conditionalMatcher: '',
    conditionalText: ''
  }]

  const allFields = store.fieldGroups[fieldGroupIndex].fields
  const availableConditionalFields = extractFieldsAvailableForConditionalExpressions(field, allFields)

  function setCondition(index, properties) {
    const condition = conditions[index]
    conditions[index] = Object.assign({}, condition, properties)
    store.setFieldProperties(fieldGroupIndex, fieldIndex, { conditions })
  }

  return (
    <FormGroup>
      <Form.Label>Only show this field if:</Form.Label>
      { conditions.map((condition, index) =>
        <ConditionalFieldInstance
          key={index}
          condition={condition}
          onChange={properties => setCondition(index, properties)}
          availableConditionalFields={availableConditionalFields}
          allFields={allFields}
        />
      )}
    </FormGroup>
  )
})

export default ConditionalFieldEditor
