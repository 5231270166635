import client from '../../../helpers/client'

async function searchSpotifyPlaylists(playlistUrl) {
  const url = `/api/v1/spotify/songs/playlist?playlist_url=${encodeURIComponent(playlistUrl)}`
  const response = await client.get(url)
  const songs = deserializeSpotifySongs(response.data.data)
  const { playlistName, playlistDescription, playlistArtworkUrl } = response.data.meta
  return {
    playlistName,
    playlistDescription,
    playlistArtworkUrl,
    playlistUrl,
    songs,
    playlistProvider: "spotify"
  }
}

function deserializeSpotifySongs(songs) {
  return songs.map(song => {
    return {
      id: song.id,
      attributes: {
        ...song.attributes,
        provider: "spotify"
      }
    }
  })
}

export default searchSpotifyPlaylists
