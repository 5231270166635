function redirectTo(url) {
  if(top.location != location) {
    top.location = url
  }
  else {
    window.location = url
  }
}

export default redirectTo
