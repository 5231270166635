const enumerateDaysBetweenDates = function(startDate, endDate) {
  let now = startDate.clone()
  let dates = []

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.toDate());
    now.add(1, 'days');
  }

  return dates;
};

export default enumerateDaysBetweenDates
