import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import MiniSession from './MiniSession'
import TreeView from 'react-treeview'


@inject("store")
@observer
export default class MiniSessionSelector extends Component {
  renderAllMiniSessionsCheckbox() {
    const { store } = this.props
    return (
      <Form.Check
        type="checkbox"
        onChange={() => store.toggleAllMiniSessions()}
        checked={store.allMiniSessions || false}
        style={styles.checkbox}
        label="All Mini Sessions"
        id={`mini-session-selector-all`}
      />
    )
  }

  renderAllMiniSessionsElement() {
    const { allowSelectAll, showCheckboxes, onClickMiniSession, store } = this.props
    const { viewableMiniSessions } = store

    if(allowSelectAll) {
      return (
        <TreeView
          onClick={() => store.toggleAllMiniSessionsCollapsed() }
          collapsed={store.allMiniSessionsCollapsed}
          nodeLabel={this.renderAllMiniSessionsCheckbox()}
        >
          <div style={styles.offset}>
            {viewableMiniSessions.map((miniSession, i) =>
              <MiniSession
                key={miniSession.id}
                miniSession={miniSession}
                onClickMiniSession={onClickMiniSession}
                showCheckboxes={showCheckboxes}
              />
            )}
          </div>
        </TreeView>
      )
    }
    else {
      return (
        <div style={styles.offset}>
          {viewableMiniSessions.map((miniSession, i) =>
          <MiniSession
            key={miniSession.id}
            miniSession={miniSession}
            onClickMiniSession={onClickMiniSession}
            showCheckboxes={showCheckboxes}
          />
          )}
        </div>
      )
    }
  }

  render() {
    const { showActiveInactiveSelector, store } = this.props
    const { showAllMiniSessions } = store

    return (
      <div>
        { this.renderAllMiniSessionsElement() }
        { showActiveInactiveSelector &&
          <div
            style={styles.toggleAllMiniSessionsWrapper}
          >
            { showAllMiniSessions ?  "* Showing All Mini Sessions" : "* Showing Future Mini Sessions Only" }
            <span
              style={styles.toggleAllMiniSessions}
              onClick={() => store.showAllMiniSessions = !store.showAllMiniSessions}
            >
              { showAllMiniSessions ?  "(Show future mini sessions only)" : "(Show all mini sessions)" }
            </span>
          </div>
        }
      </div>
    )
  }
}

const styles = {
  checkbox: {
    display: "inline-block",
    marginTop: 0,
    marginBottom: 5
  },
  offset: {
    marginLeft: 20
  },
  toggleAllMiniSessionsWrapper: {
    fontSize: 11,
    fontStyle: 'italic',
    marginTop: 10
  },
  toggleAllMiniSessions: {
    cursor: 'pointer',
    textDecoration: 'underline',
    paddingLeft: 4
  }
}
