import React from 'react'
import PropTypes from 'prop-types'
import client from '../../helpers/client'



export default class SimulationResults extends React.Component {
  static propTypes = {
    travelZoneName: PropTypes.string,
    travelZoneDescription: PropTypes.string,
    travelZoneFee: PropTypes.string,
    travelZoneError: PropTypes.string
  }

  renderErrors() {
    const { travelZoneError } = this.props
    return (
      <div className="text-danger">
        <strong>Please correct the following errors in order to calculate travel charges: </strong>
        { travelZoneError }
      </div>
    )
  }

  renderTravelZone() {
    const { travelZoneName, travelZoneDescription, travelZoneFee } = this.props

    return (
      <div>
        <div>Zone Name: { travelZoneName }</div>
        <div>Total Charge: { travelZoneFee }</div>
        <div>{ travelZoneDescription }</div>

      </div>
    )
  }

  renderNotFound() {
    return (
      <div>
        This address does not fall within any of your travel zones.
      </div>
    )
  }

  renderResults() {
    const { travelZoneName, travelZoneDescription, travelZoneFee, travelZoneError } = this.props

    if(travelZoneError) {
      return this.renderErrors()
    }
    else if(travelZoneName || travelZoneDescription || travelZoneFee) {
      return this.renderTravelZone()
    }
    else {
      return this.renderNotFound()
    }

  }

  render() {
    return (
      <div style={styles.wrapper}>
        <hr />
        <h4>Travel Charge Results</h4>
        { this.renderResults() }
      </div>
    )
  }
}

const styles = {
  wrapper: {
    textAlign: "center"
  }
}
