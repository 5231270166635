import React, { useCallback, useState } from 'react'
import { Form, FormGroup, FormControl } from 'react-bootstrap'
import FaIcon from '../../../helpers/fa_icon'
import MusicBrowser from '../MusicBrowser'
import SongProviderLabel from '../SongProviderLabel'
import AudioElement from './AudioElement'
import './style.scss'


const SongPreview = (props) => {
  const { id, name, artistName, albumName, previewUrl, albumImageUrl, provider, durationSeconds, onAdd, onUpdate, songNotes: initialSongNotes, showConfirmAdd: initialShowConfirmAdd, musicBrowserProps } = props
  const [ showConfirmAdd, setShowConfirmAdd ] = useState(initialShowConfirmAdd)
  const [ songNotes, setSongNotes ] = useState(initialSongNotes || '')

  const handleAddSong = useCallback((e) => {
    e.preventDefault()
    onAdd({ id, name, artistName, albumName, previewUrl, albumImageUrl, provider, durationSeconds, songNotes })
  }, [id, name, artistName, albumName, previewUrl, albumImageUrl, provider, durationSeconds, onAdd, songNotes ])

  const handleConfirmAdd = useCallback((e) => {
    e.preventDefault()
    setShowConfirmAdd(true)
  }, [])

  const handleCancelAdd = useCallback((e) => {
    e.preventDefault()
    setShowConfirmAdd(false)
  }, [])


  if(!id) {
    return null
  }

  return (
    <div className="cc-song-preview">
      <div className="song-preview-row">
        { (onAdd && !showConfirmAdd) &&
          <a href="#"
            className="btn btn-secondary"
            onClick={handleConfirmAdd}
          >
            <FaIcon icon="plus" title="Add" />
          </a>
        }
        { onUpdate &&
          <MusicBrowser
            {...musicBrowserProps}
            currentSong={props}
            onChange={onUpdate}
            label={<FaIcon icon="pencil" />}
          />
        }
        <div className="album-cover">
          { albumImageUrl && <img src={albumImageUrl} /> }
        </div>
        <div className="track-info">
          <div className="track-title">{ name }</div>
          { !!artistName && <div className="artist-name">Artist: { artistName }</div> }
          { !!albumName && <div className="album-name">Album: { albumName }</div> }
          <SongProviderLabel provider={provider} />
          { !!initialSongNotes && <div className="song-notes">Notes: { initialSongNotes }</div> }
        </div>
        <div className="preview">
          { previewUrl ? <AudioElement src={previewUrl} /> : <div className="no-preview">No preview available</div> }
        </div>
      </div>
      { showConfirmAdd &&
        <div className="song-preview-row">
          <form className="song-notes-form">
            <FormGroup>
              <Form.Label>Additional Notes</Form.Label>
              <FormControl
                as="textarea"
                className="song-notes-textarea"
                type="text"
                value={songNotes}
                onChange={e => setSongNotes(e.target.value)}
              />
            </FormGroup>
            <div className="song-notes-buttons mt-2">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleAddSong}
              >
                { showConfirmAdd ? "Save" : "Add Song" }
              </button>
              { !showConfirmAdd &&
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCancelAdd}
                >
                  Cancel
                </button>
              }
            </div>
          </form>
        </div>
      }
    </div>
  )
}

export default SongPreview
