import React from 'react'
import ChartTypeSelector from './ChartTypeSelector'
import BrandSelector from './BrandSelector'
import DateControl from './DateControl'
import IntervalControl from './IntervalControl'

const ToolbarExpanded = () => (
  <>
    <h2>Proposal Activity</h2>
    <div className="chart-header">
      <div className="chart-header-left-col">
        <ChartTypeSelector />
        <BrandSelector />
      </div>
      <div className="chart-header-right-col">
        <DateControl />
        <IntervalControl />
      </div>
    </div>
  </>
)

export default ToolbarExpanded
