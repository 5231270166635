import React, { useState } from 'react';

import CardButton from './CardButton'
import ShowAllPaymentMethods from './ShowAllPaymentMethods'
import SavePaymentMethod from './SavePaymentMethod'
import client from '../../../helpers/client'
import extractErrorMessage from '../util/extractErrorMessage'
import { ApplePay, GooglePay, CreditCard, PaymentForm } from 'react-square-web-payments-sdk'

const CardForm = ({ cardErrorMessages, onGoBack, savePaymentMethod, setSavePaymentMethod, showBackToAllPaymentMethodOptions, allowUserToSavePaymentMethod, paymentMethodOption, enableAutoPay, authorizeOnly }) => (
  <div>
    <CardButton paymentMethodOption={paymentMethodOption} onClick={onGoBack} />
    { (cardErrorMessages && cardErrorMessages.length > 0) &&
      <div className="sq-error-message card-errors">
        {cardErrorMessages.map(errorMessage => (
          <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
        ))}
      </div>
    }
    <fieldset className="sq-fieldset">
      <CreditCard
        includeInputLabels
        render={(Button) => (
          <>
            { allowUserToSavePaymentMethod &&
            <SavePaymentMethod
              savePaymentMethod={savePaymentMethod}
              setSavePaymentMethod={setSavePaymentMethod}
              savePaymentMethodMode={paymentMethodOption.savePaymentMethodMode}
              enableAutoPay={enableAutoPay}
              authorizeOnly={authorizeOnly}
            />
            }
            <Button style={styles.submitButton}>Submit Payment</Button>
          </>
        )}
      >
        Submit Payment
      </CreditCard>
    </fieldset>


    <ShowAllPaymentMethods onClick={onGoBack} showBackToAllPaymentMethodOptions={showBackToAllPaymentMethodOptions} />
  </div>
)

const SquareForm = ({
  paymentMethod,
  paymentMethodOption,
  activePaymentMethodOption,
  paymentIntentId,
  setActivePaymentMethodOption,
  amountCents,
  amountCurrency,
  countryCode,
  setLoading,
  chargeDescription,
  allowUserToSavePaymentMethod,
  showBackToAllPaymentMethodOptions,
  authorizeOnly,
  enableAutoPay
}) => {
  const { squareApplicationId, squareLocationId, squareSandbox } = paymentMethod

  const [cardErrorMessages, setCardErrorMessages] = useState([]);
  const [paymentRequestErrorMessages, setPaymentRequestErrorMessages] = useState([]);
  const amountDollars = (amountCents / 100).toFixed(2)
  const { savePaymentMethodMode } = paymentMethodOption

  const [savePaymentMethod, setSavePaymentMethod] = useState(savePaymentMethodMode !== "none")

  const submissionUrl = authorizeOnly ? `/payment_intents/${paymentIntentId}/submit_square_authorization` : `/payment_intents/${paymentIntentId}/submit_square_payment`
  console.log({ submissionUrl })


  function handleCardSubmission({ token, verifiedBuyer }) {
    setLoading(true)

    const isCardPayment = (paymentMethodOption && activePaymentMethodOption.id === paymentMethodOption.id) ? true : false


    setCardErrorMessages([]);
    setPaymentRequestErrorMessages([]);

    const result = client.post(submissionUrl, {
      squareNonce: token,
      buyerVerificationToken: null,
      paymentMethodOptionId: paymentMethodOption.id,
      savePaymentMethod: (isCardPayment && allowUserToSavePaymentMethod && savePaymentMethod)
    }, { withCredentials: true })
      .then((result) => {
        window.location.reload()
      })
      .catch((e) => {
        setCardErrorMessages([extractErrorMessage(e)])
        setLoading(false)
      })
  }

  function createPaymentRequest() {
    return {
      requestShippingAddress: false,
      requestBillingInfo: false,
      currencyCode: amountCurrency,
      countryCode: countryCode,
      total: {
        label: chargeDescription,
        amount: amountDollars,
        pending: false,
      },
      lineItems: [
        {
          label: chargeDescription,
          amount: amountDollars,
          pending: false,
        },
      ],
    };
  }

  function createVerificationDetails() {
    return {
      amount: amountDollars,
      currencyCode: amountCurrency,
      intent: 'CHARGE',
      billingContact: {
        country: countryCode,
      },
    };
  }

  const loadingView = <div className="sq-wallet-loading"></div>;
  const unavailableApple = null
  const unavailableGoogle = null
  const unavailableMasterpass = null
  // Hide if another payment method option is selected
  if(activePaymentMethodOption.id && activePaymentMethodOption.id !== paymentMethodOption.id) {
    return null
  }

  // Hide if the current payment method option is selected but the "saved-card" special is set
  if(activePaymentMethodOption.id === paymentMethodOption.id && activePaymentMethodOption.special === "saved-cards") {
    return null
  }

  return (
    <div className="checkcherry-payment-widget-square-form">
      <PaymentForm
        sandbox={squareSandbox}
        applicationId={squareApplicationId}
        locationId={squareLocationId}
        cardTokenizeResponseReceived={handleCardSubmission}
        createPaymentRequest={createPaymentRequest}
        createVerificationDetails={createVerificationDetails}
      >
        { (paymentRequestErrorMessages && paymentRequestErrorMessages.length > 0) &&
        <div className="sq-error-message card-errors">
          {paymentRequestErrorMessages.map(errorMessage => (
            <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
          ))}
        </div>
        }

        { activePaymentMethodOption.id === paymentMethodOption.id ?
          <>
            <CardForm
              paymentMethodOption={paymentMethodOption}
              savePaymentMethod={savePaymentMethod}
              setSavePaymentMethod={setSavePaymentMethod}
              allowUserToSavePaymentMethod={allowUserToSavePaymentMethod}
              cardErrorMessages={cardErrorMessages}
              onGoBack={() => setActivePaymentMethodOption({ id: null, special: null }) }
              showBackToAllPaymentMethodOptions={showBackToAllPaymentMethodOptions}
              enableAutoPay={enableAutoPay}
              authorizeOnly={authorizeOnly}
            />
          </>
          :
          <>
            <ApplePay />
            <GooglePay buttonColor="black" />
            <CardButton onClick={() => setActivePaymentMethodOption({ id: paymentMethodOption.id, special: null })} paymentMethodOption={paymentMethodOption} />
          </>
        }
      </PaymentForm>
    </div>
  );
};

const styles = {
  submitButton: {
    backgroundColor: "#ffdc5d",
    color: "#000",
    borderRadius: 23,
    fontSize: 20,
    fontWeight: 700,
    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
    margin: "20px auto 50px",
  }
}

export default SquareForm;
