import React from 'react'
import { Button } from 'react-bootstrap'
import { observer } from "mobx-react"
import store from '../stores/store.js'
import FaIcon from '../../../helpers/fa_icon'

const clearConfirmationMessage = 'Are you sure you want to clear this playlist? This will remove all songs from this playlist and can not be undone!'

const SongPlaylistClearButton = observer(({ field }) => {
  function handleClear(playlist) {
    if(confirm(clearConfirmationMessage)) {
      store.clearAllInstances(field.uuid)
    }
  }

  const metaPlaylistUrl = store.valueFor(field.uuid, "metaPlaylistUrl", 0)
  if(!metaPlaylistUrl) return null

  return (
    <Button
      onClick={handleClear}
      disabled={!store.editable}
    >
      <FaIcon icon="times" text="Clear Playlist" />
    </Button>
  )
})

export default SongPlaylistClearButton
