import React from 'react'
import { observer, inject } from 'mobx-react'

function titleForChartType(chartType) {
  switch(chartType) {
    case "created_on":
      return "Lead Count by Date Created"
    case "scheduled_on":
      return "Lead Count by Scheduled Date"
    default:
      return null
  }
}

const ChartTitle = ({ store }) => {
  const title = titleForChartType(store.chartType)
  return (
    <h5 className="text-center mb-1">{ title }</h5>
  )
}

export default inject("store")(observer(ChartTitle))
