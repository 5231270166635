import normalize from 'json-api-normalizer'

export const asNormalizedMaps = (data) => {
  let normalized = normalize(data)
  Object.keys(normalized).forEach(key =>
    normalized[key] = new Map(Object.entries(normalized[key]))
  )

  return normalized
}

export const asNormalizedArrays = (data) => {
  let normalized = normalize(data)
  Object.keys(normalized).forEach(key =>
    normalized[key] = Object.values(normalized[key])
  )

  return normalized
}
