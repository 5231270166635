import React from 'react'
import { observer, inject } from 'mobx-react'

function titleForChartType(chartType) {
  switch(chartType) {
    case "bookings":
      return "Projected Revenue by Scheduled Date"
    case "payments":
      return "Collected Revenue by Payment Date"
    case "bookedOn":
      return "Projected Revenue by Date Booked"
    default:
      return null
  }
}

const ChartTitle = ({ store }) => {
  const title = titleForChartType(store.chartType)
  return (
    <h5 className="text-center mb-1">{ title }</h5>
  )
}

export default inject("store")(observer(ChartTitle))
