import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("store")
@observer
export default class Loader extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  render() {
    const { store } = this.props
    const { loading } = store

    if(loading) {
      return (
        <div className="calendar-loading-message">
          <i className="fa fa-spinner fa-spin fa-fw" />
          <span>Loading...</span>
        </div>
      )
    }
    else {
      return null
    }
  }
}

