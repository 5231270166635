import React, { useState, useEffect } from 'react'
import { Row, Col, Form, FormGroup, FormControl, InputGroup } from 'react-bootstrap'
import ErrorBlock from './ErrorBlock'

const HourSelector = ({ label, value, onChange, error, hint }) => {
  const [minutes, setMinutes] = useState("")
  const [hours, setHours] = useState("")

  useEffect( () => {
    if(value) {
      setMinutes(value % 60)
      setHours(Math.trunc(value / 60))
    }
  }, [value])

  useEffect(() => {
    const calculatedHours = hours ? parseInt(hours) : 0
    const calculatedMinutes = minutes ? parseInt(minutes) : 0
    const totalMinutes = (calculatedHours * 60) + calculatedMinutes
    onChange(totalMinutes)
  }, [minutes, hours])

  return (
    <FormGroup size="lg" className="hour_selector">
      { label && <Form.Label> { label } </Form.Label> }
      <Row>
        <Col xs={6} className="left-col">
          <InputGroup>
            <FormControl
              type="number"
              value={hours}
              onChange={e => setHours(e.target.value)}
              isInvalid={!!error}
            />
            <InputGroup.Text>Hr</InputGroup.Text>
          </InputGroup>
        </Col>
        <Col xs={6} className="right-col">
          <InputGroup>
            <FormControl
              type="number"
              value={minutes}
              onChange={e => setMinutes(e.target.value)}
              isInvalid={!!error}
            />
            <InputGroup.Text>Min</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      { hint && <Form.Text muted>{ hint }</Form.Text> }
      <ErrorBlock error={error} />
    </FormGroup>
  )
}

export default HourSelector
