import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

export default class SubmitField extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    bsStyle: PropTypes.string,
    style: PropTypes.object,
    block: PropTypes.bool,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    value: "Submit",
    variant: "primary",
    block: false,
    disabled: false
  }

  render() {
    const { value, block, disabled, ...remainingProps } = this.props

    return (
      <Button className={block ? "w-100" : ""} type="submit" size="lg" disabled={disabled} {...remainingProps} >{ value }</Button>
    )
  }
}
