import React from 'react'
import SongPreview from '../SongPreview'

const SongList = ({ songs, onSelect }) => (
  <>
    { songs.map(song => (
      <SongPreview
        key={song.id}
        id={song.id}
        name={song.attributes.name}
        artistName={song.attributes.artistName}
        albumName={song.attributes.albumName}
        albumImageUrl={song.attributes.albumImageUrl}
        previewUrl={song.attributes.previewUrl}
        durationSeconds={song.attributes.durationSeconds}
        provider={song.attributes.provider}
      />
    ))}
  </>
)

export default SongList
