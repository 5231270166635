import React, { useState, useEffect, useRef } from 'react'
import FaIcon from '../../../helpers/fa_icon'

const AudioElement = ({ src }) => {
  const [ playing, setPlaying ] = useState(false)
  const audioRef = useRef(null)

  window.globalPlayingArray = window.globalPlayingArray || []

  // Register to listen for play events
  useEffect(() => {
    if(!audioRef.current) return
    audioRef.current.addEventListener('play', startPlaying)
    audioRef.current.addEventListener('pause', stopPlaying)
    return () => {
      audioRef.current.removeEventListener('play', startPlaying)
      audioRef.current.removeEventListener('pause', stopPlaying)
    }
  }, [audioRef.current])

  function startPlaying() {
    // Stop all other playing audio elements
    window.globalPlayingArray.forEach(el => {
      if(el !== audioRef.current) {
        el.pause()
        window.globalPlayingArray = window.globalPlayingArray.filter(el => el !== audioRef.current)
      }
    })
    window.globalPlayingArray.push(audioRef.current)
    setPlaying(true)
  }

  function stopPlaying() {
    window.globalPlayingArray = window.globalPlayingArray.filter(el => el !== audioRef.current)
    setPlaying(false)
  }

  function togglePlay(e) {
    e.preventDefault()
    if(!audioRef.current) return

    if(audioRef.current.src !== src) {
      audioRef.current.src = src
    }

    playing ? audioRef.current.pause() : audioRef.current.play()
  }

  return (
    <>
      <audio ref={audioRef} />
      <a href="#" className="btn btn-secondary" onClick={togglePlay}>
        <FaIcon icon={playing ? 'pause' : 'play'} />
      </a>
    </>
  )
}

export default AudioElement
