import React from 'react'
import PropTypes from 'prop-types'
import { Provider, observer } from 'mobx-react'
import { create, persist } from 'mobx-persist'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { createPersistedStore } from './stores/FranchiseCalendarStore'
import CalendarEvent from './components/CalendarEvent'
import Toolbar from './components/Toolbar'
import Loader from './components/Loader'
import extractAttributes from './util/extractAttributes'
import "react-big-calendar/lib/css/react-big-calendar.css"
import './style.scss'

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  })

// https://stackoverflow.com/questions/45671732/react-big-calendar-start-week-from-monday-instead-of-sunday
const localizers = {
  sunday: {week: { dow: 0, doy: 0 }},
  monday: {week: { dow: 1, doy: 1 }}
}

@observer
export default class FranchiseCalendar extends React.Component {
  static propTypes = {
    apiKey: PropTypes.string.isRequired,
    staffId: PropTypes.number,
    weeksStartOn: PropTypes.oneOf(['sunday', 'monday']),
    enableLeads: PropTypes.bool,
    enableMiniSessions: PropTypes.bool,
    showBrands: PropTypes.bool.isRequired,
    brands: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })),
    enableSynced: PropTypes.bool,
    enableConfirmed: PropTypes.bool,
    enableProposals: PropTypes.bool,
    enableCancelled: PropTypes.bool,
    enableStaffOff: PropTypes.bool,
    enableBlockoutDates: PropTypes.bool,
    hideStatusSelector: PropTypes.bool,
    enableGrouping: PropTypes.bool,
  }

  static defaultProps = {
    enableLeads: true,
    enableMiniSessions: true,
    enableConfirmed: true,
    enableProposals: true,
    enableCancelled: true,
    enableStaffOff: true,
    enableBlockoutDates: true,
    enableSynced: false,
    hideStatusSelector: false,
    enableGrouping: true
  }

  constructor(props) {
    super(props)
    this.store = createPersistedStore({
      ...props
    })

    moment.locale('ko', localizers[props.weeksStartOn])
    this.localizer = momentLocalizer(moment)

  }

  componentDidMount() {
    this.store.refreshEvents()
  }

  handleNavigate = (date) => {
    this.store.date = date
  }

  render() {
    const { store } = this
    const { calendarEvents } = store

    return (
      <Provider store={store}>
        <div>
          <Toolbar />
          <Loader />
          <div className="franchise-calendar-wrapper">
            <Calendar
              className={`franchise-calendar ${store.view} ${store.loading ? 'loading' : 'loaded'}`}
              events={store.calendarEvents.slice()}
              views={["month", "day"]}
              view={store.view}
              date={store.date}
              onNavigate={this.handleNavigate}
              onView={(view) => store.view = view}
              localizer={this.localizer}
              components={{event: CalendarEvent}}
              toolbar={false}
              showMultiDayTimes={true}
              popup={true}
              eventPropGetter={(event, start, end, isSelected) => {
                const attributes = extractAttributes(event)
                const { expiredProposal, canceled, postponed } = attributes
                return {
                  className: `event-view ${event.type} ${event.status} ${expiredProposal ? "expired" : ""} ${canceled ? "canceled" : ""} ${postponed ? "postponed" : ""}`
                }
              }}
            />
          </div>
        </div>
      </Provider>
    )
  }
}
