import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import TimeSlot from './TimeSlot'

@inject("store")
@observer
export default class TimeSlotContainer extends React.Component {
  static propTypes = {
    slots: PropTypes.array.isRequired
  }

  componentDidMount() {
    const { store } = this.props
    store.slotsLoadedCallback = this.slotsLoaded
    this.slotsLoaded()
  }

  componentWillUnmount() {
    const { store } = this.props
    store.slotsLoadedCallback = null
  }

  slotsLoaded = () => {
    return
    const outerHeight = this.outerRef.clientHeight
    const innerHeight = this.innerRef.clientHeight

    const scrollY = (innerHeight - outerHeight) / 2

    this.outerRef.scrollTo(0, scrollY)
  }

  render() {
    const { slots } = this.props

    return (
      <div ref={ref => this.outerRef = ref} className="time-slot-container">
        <div className="time-slot-inner-container" ref={ref => this.innerRef = ref}>
          { slots.map(slot => <TimeSlot key={slot.id} slot={slot} />) }
        </div>
      </div>
    )
  }
}
