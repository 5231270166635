import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import BackdropCategory from './BackdropCategory'
import TreeView from 'react-treeview'


@inject("store")
@observer
export default class BackdropSelector extends Component {
  render() {
    const { allowSelectAll, showCheckboxes, onClickBackdrop, store } = this.props
    const { viewableBackdropCategories } = store

    if(allowSelectAll) {
      return (
        <TreeView
          onClick={() => store.toggleAllBackdropsCollapsed() }
          collapsed={store.allBackdropCategoriesCollapsed}
          nodeLabel={this.renderAllBackdropsCheckbox()}
        >
          <div style={styles.offset}>
            {viewableBackdropCategories.map((backdropCategory, i) =>
              <BackdropCategory
                key={backdropCategory.id}
                backdropCategory={backdropCategory}
                onClickBackdrop={onClickBackdrop}
                showCheckboxes={showCheckboxes}
              />
            )}
          </div>
        </TreeView>
      )
    }
    else {
      return (
        <div style={styles.offset}>
          {viewableBackdropCategories.map((backdropCategory, i) =>
          <BackdropCategory
            key={backdropCategory.id}
            backdropCategory={backdropCategory}
            onClickBackdrop={onClickBackdrop}
            showCheckboxes={showCheckboxes}
          />
          )}
        </div>
      )
    }
  }

  renderAllBackdropsCheckbox() {
    const { store } = this.props
    return (
      <Form.Check
        type="checkbox"
        onChange={() => store.toggleAllBackdrops()}
        checked={store.allBackdropCategories || false}
        style={styles.checkbox}
        label="All Backdrops"
        id={`backdropCategory-all`}
      />
    )
  }
}

const styles = {
  checkbox: {
    display: "inline-block",
    marginTop: 0,
    marginBottom: 5
  }
}
