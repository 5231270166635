import React from 'react'

const TaskableLabel = ({ taskableDescription }) => {
  if(taskableDescription) {
    return (
      <span className={`badge text-bg-secondary`}>
        { taskableDescription }
      </span>
    )
  }
  else {
    return null
  }
}

export default TaskableLabel
