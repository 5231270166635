import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import SimpleFormat from '@16g/react-simple-format'
import Slider from '../../ui/Slider'
import ChooseButton from './ChooseButton'
import { extractSlidesFromBackdrop } from '../util'

const Backdrop = ({ chooseUrl, backdrop, csrfToken }) => {
  const [showModal, setShowModal ] = useState(false)

  function handleShowModal(e) {
    e.preventDefault()
    setShowModal(true)
  }

  function handleHideModal() {
    setShowModal(false)
  }

  return (
    <div className="categorized-item">
      <div className={`photobooth-background photobooth-background-${backdrop.id}`}>
        <div className="name">
          <a href={`#photobooth-background-modal-${backdrop.id}`} onClick={handleShowModal} aria-label={`Learn more about ${backdrop.name}`} >
            { backdrop.name }
          </a>
        </div>
        <div className="name">
          { backdrop.priceFormatted }
        </div>
        <div className="image-wrapper">
          <a href={`#photobooth-background-modal-${backdrop.id}`} onClick={handleShowModal} aria-label={`Learn more about ${backdrop.name}`} >
            <img src={backdrop.primaryImage.videoVariant} className="image" alt={backdrop.name} />
          </a>
        </div>
        <div className="text-center spacer-top spacer-bottom">
          <ChooseButton
            chooseUrl={chooseUrl}
            csrfToken={csrfToken}
            backdrop={backdrop}
            btnClass={"btn-sm"}
          />
        </div>
      </div>
      <Modal show={showModal} onHide={handleHideModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{ backdrop.name }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-8">
              <Slider slides={extractSlidesFromBackdrop(backdrop)} />
            </div>
            <div className="col-sm-4 wrap-text">
              <SimpleFormat text={backdrop.description} />
            </div>
          </div>
          <hr />
          <h4 className="text-end">{ backdrop.priceCents > 0 ? backdrop.priceFormatted : "No additional charge" }</h4>
        </Modal.Body>
        <Modal.Footer>
          <ChooseButton
            chooseUrl={chooseUrl}
            csrfToken={csrfToken}
            backdrop={backdrop}
          />
          { " " }
          <Button onClick={handleHideModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Backdrop
