import React, { useState } from 'react'
import { observer } from 'mobx-react'
import store from './../stores/store.js'
import FaIcon from '../../../helpers/fa_icon.js'
import { Form, Modal, Button, FormGroup, FormControl, Nav, NavItem } from 'react-bootstrap'

const AddTabButton = () => {
  const fieldGroups = store.fieldGroups
  const [showModal, setShowModal] = useState(false)
  const [tabName, setTabName] = useState('')

  const handleAddTab = (e) => {
    e.preventDefault()
    setShowModal(true)
  }

  const handleSubmitTab = (e) => {
    e.preventDefault()
    store.addFieldGroup(tabName)
    setShowModal(false)
    setTabName('')
  }

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="large">
        <Modal.Header closeButton>
          <Modal.Title>Add Tab</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Label>Tab Name</Form.Label>
            <Form.Control
              type="text"
              value={tabName}
              onChange={(e) => setTabName(e.target.value)}
              autoFocus
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
          <Button variant="primary" onClick={handleSubmitTab}>Add Tab</Button>
        </Modal.Footer>
      </Modal>
      <Button onClick={handleAddTab} variant="outline-secondary">
        <FaIcon icon="plus" text="Add Tab" />
      </Button>
    </>
  )
}

export default observer(AddTabButton)
