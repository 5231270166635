import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Modal, Row, Col, FormGroup, Radio, FormControl } from 'react-bootstrap'
import ColorPicker from '../ColorPicker'
import EventTypeSelector from '../EventTypeSelector'
import CopyableTextBlock from '../CopyableTextBlock'

export default class PackageBookNowButton extends Component {
  state = {
    showModal: false,
    text: "Book Now",
    foregroundColor: this.props.contrastColor,
    backgroundColor: this.props.primaryColor,
    style: "basicLink",
    activeEventCategory: null,
    activePackageGroup: null,
    activeEventType: null
  }

  static propTypes = {
    type: PropTypes.string.isRequired,
    bookNowUrl: PropTypes.string.isRequired,
    primaryColor: PropTypes.string.isRequired,
    contrastColor: PropTypes.string.isRequired,
    eventCategories: PropTypes.array.isRequired
  }

  showModal = () => {
    this.setState({showModal: true})
  }

  hideModal = () => {
    this.setState({showModal: false})
  }

  get bookingUrl() {
    const { bookNowUrl } = this.props
    const { activeEventCategory, activePackageGroup, activeEventType } = this.state
    
    if(activeEventCategory) {
      return `${bookNowUrl}/set_event?event_category_id=${activeEventCategory.id}`
    }
    else if(activePackageGroup) {
      return `${bookNowUrl}/set_event?package_group_id=${activePackageGroup.id}`
    }
    else if(activeEventType) {
      return `${bookNowUrl}/set_event?event_type_id=${activeEventType.id}`
    }
    else {
      return bookNowUrl
    }

  }

  get selectedPackageName() {
    const { activeEventCategory, activePackageGroup, activeEventType } = this.state

    if(activeEventCategory) {
      return activeEventCategory.name
    }
    else if(activePackageGroup) {
      return activePackageGroup.name
    }
    else if(activeEventType) {
      return activeEventType.name
    }
    else {
      return ""
    }

  }

  renderPreview() {
    const { style, text, backgroundColor, foregroundColor } = this.state
    const { bookingUrl } = this

    switch(style) {
      case "basicLink":
        return (
          <div>
            { bookingUrl }
          </div>
        )
      case "customizedLink":
        return (
          <div>
            <a href={bookingUrl} target="_top">{ text }</a>
          </div>
        )
      case "button":
        const buttonStyle = {
          color: foregroundColor,
          display: "inline-block",
          margin: 0,
          fontWeight: "normal",
          cursor: "pointer",
          border: `1px solid ${backgroundColor}`,
          textDecoration: "none !important",
          background: backgroundColor,
          whiteSpace: "nowrap",
          padding: "6px 12px",
          fontSize: "14px",
          lineHeight: "18px",
          borderRadius: 0
        }
        return (
          <div>
            <a href={bookingUrl} style={buttonStyle} target="_top">{ text }</a>
          </div>
        )
      default:
        return null;
    }
  }

  get html() {
    const { style, text, backgroundColor, foregroundColor } = this.state
    const { bookingUrl } = this

    switch(style) {
      case "basicLink":
        return bookingUrl
      case "customizedLink":
        return `<a href="${bookingUrl} target="_top"">${text}</a>`
      case "button":
        const buttonStyle = `color: ${foregroundColor}; display: inline-block; margin: 0; font-weight: normal; cursor: pointer, border: 1px solid ${backgroundColor}; text-decoration: none !important; background: ${backgroundColor}; white-space: nowrap; padding: 6px 12px; font-size: 14px; line-height: 18px; border-radius: 0`
        return `<a href="${bookingUrl}" style="${buttonStyle}" target="_top">${text}</a>`
      default:
        return null;
    }
  }

  handlePackageSelected = ({eventCategory, packageGroup, eventType}) => {
    if(eventCategory) {
      this.setState({activeEventCategory: eventCategory, activePackageGroup: null, activeEventType: null})
    }
    else if(packageGroup) {
      this.setState({activeEventCategory: null, activePackageGroup: packageGroup, activeEventType: null})
    }
    else if(eventType) {
      this.setState({activeEventCategory: null, activePackageGroup: null, activeEventType: eventType})
    }
  }

  render() {
    const { type, eventCategories } = this.props
    const { showModal, style, text, backgroundColor, foregroundColor,
      activeEventCategory, activePackageGroup, activeEventType } = this.state

    return (
      <div>
        <div>
          <Button onClick={this.showModal} variant="primary">
            <i className="fa fa-code" />
            { " " }
            Get Embed Code
          </Button>
        </div>
        <Modal show={showModal} onHide={this.hideModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Customize Package Link</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={4}>
                <h3>Customize</h3>
                <FormGroup>
                  <Form.Label>Select a package</Form.Label>
                  <EventTypeSelector
                    eventCategories={eventCategories}
                    showCheckboxes={false}
                    onClickPackage={this.handlePackageSelected}
                    showActiveInactiveSelector={true}
                    showSummary={false}
                    allowSelectAll={false}
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Label>Choose Style</Form.Label>
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "basicLink"}
                    onChange={() => this.setState({style: "basicLink"})}
                    label="Link Only"
                    id="package-book-link-only"
                  />
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "customizedLink"}
                    onChange={() => this.setState({style: "customizedLink"})}
                    label="Customized Link"
                    id="package-book-customized-link"
                  />
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "button"}
                    onChange={() => this.setState({style: "button"})}
                    label="Button"
                    id="package-book-button"
                  />
                </FormGroup>
                { (style === "customizedLink" || style === "button") &&
                  <FormGroup>
                    <Form.Label>Text</Form.Label>
                    <FormControl
                      type="text"
                      value={text}
                      onChange={(e) => this.setState({text: e.target.value})}
                    />
                  </FormGroup>
                }

                { style === "button" &&
                  <FormGroup>
                    <Form.Label>Button Color</Form.Label>
                    <ColorPicker
                      value={backgroundColor}
                      onChange={backgroundColor => this.setState({backgroundColor})}
                    />
                  </FormGroup>
                }

                { style === "button" &&
                  <FormGroup>
                    <Form.Label>Text Color</Form.Label>
                    <ColorPicker
                      value={foregroundColor}
                      onChange={foregroundColor => this.setState({foregroundColor})}
                    />
                  </FormGroup>
                }
              </Col>
              <Col sm={4}>
                <h3>Selected Package</h3>
                { this.selectedPackageName ?
                    <p>You have selected the <strong>{ this.selectedPackageName }</strong> package</p>
                    :
                    <p>No package selected</p>
                }

                <h3>Preview Link</h3>

                <div style={styles.previewStyle}>
                  { this.renderPreview() }
                </div>
              </Col>
              <Col sm={4}>
                <h3>HTML</h3>

                <p>Copy and paste the HTML below into your website to add your sign in link.</p>

                <FormGroup>
                  <CopyableTextBlock value={this.html} />
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const styles = {
  previewStyle: {
    wordWrap: 'break-word'
  },
  embedStyle: {
    height: 300
  }
}
