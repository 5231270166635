import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { parse, format } from 'date-fns'

const parseTime = (dateString, timeFormat) => {
  if(!dateString) {
    return null
  }

  const parsedDate = parse(dateString, timeFormat, new Date())
  if(parsedDate == "Invalid Date") {
    return null
  }

  return parsedDate
}

const formatTime = (date, timeFormat) => {
  const formatted = format(date, timeFormat)
  return formatted
}

class Input extends React.Component {
  render() {
    const { onClick, value, style, onChange, disabled } = this.props
    return (
      <input
        type="text"
        onClick={onClick}
        value={value}
        onChange={onChange}
        style={style}
        className="form-control"
        disabled={disabled}
      />
    )
  }
}

const TimelineTimeField = ({ name, onChange, value, timeFormat, disabled }) => (
  <ReactDatePicker
    name={name}
    showTimeSelect
    showTimeSelectOnly
    timeIntervals={15}
    customInput={<Input  />}
    onChange={value => onChange(formatTime(value, timeFormat))}
    selected={parseTime(value, timeFormat)}
    dateFormat={timeFormat}
    timeFormat={timeFormat}
    timeCaption="Time"
    disabled={disabled}
  />
)

export default TimelineTimeField
