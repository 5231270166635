import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'react-bootstrap'
import {observer} from "mobx-react"
import store from '../stores/store.js'

@observer
export default class CheckboxGroup extends React.Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    uuid: PropTypes.string,
    instance: PropTypes.number
  }

  static defaultProps = {
    instance: 0
  }

  handleOptionChecked = (e, option) => {
    const { field, instance } = this.props
    const { uuid } = field

    let value = store.valueFor(uuid, "checkbox", instance) || []

    // Remove from array if already present
    value = value.filter(v => v !== option)

    // Only add back if it was checked
    if(e.target.checked) {
      value.push(option)
    }

    store.setValueFor(uuid, "checkbox", instance, value)
  }

  render() {
    const { field } = this.props
    const { name, uuid, hint, required } = field
    const instance = this.props.instance || 0
    const value = store.valueFor(uuid, "checkbox", instance) || []
    const checkboxName = `${uuid}-${instance}`
    const errorMessage = store.errorFor(uuid, "checkbox", instance)

    return (
      <FormGroup>
        <Form.Label>{ required ? "*" : "" } {name}</Form.Label>
        {field.values.map((option, index) =>
          <Form.Check
            type="checkbox"
            key={index}
            name={checkboxName}
            checked={value.includes(option)}
            onChange={e => this.handleOptionChecked(e, option)}
            disabled={!store.editable}
            isInvalid={!!errorMessage}
            label={option}
            id={`checkbox-group-item-${checkboxName}-${index}`}
          />
        )}
        { errorMessage && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{errorMessage}</Form.Control.Feedback> }
        { hint && <Form.Text muted>{hint}</Form.Text > }
      </FormGroup>
    )
  }
}
