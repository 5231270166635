import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import parse from 'date-fns/parse'
import PackageUnitRangeEditor from './PackageUnitRangeEditor'
import PackageUnitRangeRow from './PackageUnitRangeRow'

function sortUnitRanges(unitRanges) {
  return unitRanges
    .sort((a, b) => a.maxUnits - b.maxUnits )
    .sort((a, b) => a.minUnits - b.minUnits )
}

const UnitRangeManager = (props) => {
  const [packageUnitRanges, setPackageUnitRanges] = useState(props.packageUnitRanges)
  const [activePackageUnitRange, setActivePackageUnitRange] = useState()
  const { formValues, unitTranslator } = props

  const handlePackageUnitRangeAdded = () => {
    const blankPackageUnitRange = { minUnits: 0, maxUnits: 100, price: "0.00" }
    setActivePackageUnitRange(blankPackageUnitRange)
  }

  const handleCreatePackageUnitRange = (packageUnitRange) => {
    setPackageUnitRanges([...packageUnitRanges, packageUnitRange])
    setActivePackageUnitRange()
  }

  const handleUpdatePackageUnitRange = (packageUnitRange) => {
    const filteredPackageUnitRanges = packageUnitRanges.filter(u => u.id != packageUnitRange.id)
    setPackageUnitRanges([...filteredPackageUnitRanges, packageUnitRange])
    setActivePackageUnitRange()
  }

  const handleRemovePackageUnitRange = (packageUnitRange) => {
    const filteredPackageUnitRanges = packageUnitRanges.filter(u => u.id != packageUnitRange.id)
    setPackageUnitRanges(filteredPackageUnitRanges)
    setActivePackageUnitRange()
  }

  return (
    <>
      <PackageUnitRangeEditor
        {...props}
        activePackageUnitRange={activePackageUnitRange}
        onHide={() => setActivePackageUnitRange(null)}
        onCreatePackageUnitRange={handleCreatePackageUnitRange}
        onUpdatePackageUnitRange={handleUpdatePackageUnitRange}
      />
      { packageUnitRanges.length > 0 ?
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>
                  Minimum { unitTranslator.labelFor(formValues.unitName, "unitsTitle")}
                </th>
                <th>
                  Maximum { unitTranslator.labelFor(formValues.unitName, "unitsTitle")}
                </th>
                <th>
                  { formValues.enableCustomerCanBookPerUnit &&
                    <>Price per { unitTranslator.labelFor(formValues.unitName, "unitTitle")} </>
                  }
                  { formValues.enableCustomerCanBookPerUnitRange &&
                    <>Flat Rate </>
                  }
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              { sortUnitRanges(packageUnitRanges).map(packageUnitRange =>
              <PackageUnitRangeRow
                {...props}
                key={packageUnitRange.id}
                packageUnitRange={packageUnitRange}
                onEdit={() => setActivePackageUnitRange(packageUnitRange)}
                onRemove={() => handleRemovePackageUnitRange(packageUnitRange)}
              />
              )}
            </tbody>
          </table>
          <div className="text-end">
            <Button
              variant="default"
              size="sm"
              onClick={() => handlePackageUnitRangeAdded()}
            >
              <i className="fa fa-plus" />
              { " " }
              Add Price Tier
            </Button>
          </div>
        </>
        :
        <p className="text-center">
          You do not have any pricing tiers defined.  
          &nbsp;
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              handlePackageUnitRangeAdded()
            }}
          >Click here to add one</a>
        </p>
      }
      <hr />
    </>
    )
}

export default UnitRangeManager
