import React from 'react'
import client from '../../../helpers/client'

const PackageUnitRange = ({ eventType, packageUnitRange, onEdit, onRemove, apiKey }) => {
  console.log({ packageUnitRange })
  const handleEdit = (e) => {
    e.preventDefault()
    onEdit(packageUnitRange)
  }

  const handleRemove = (e) => {
    e.preventDefault()
    if(confirm("Are you sure you want to remove this item?")) {
      client.delete(`/api/v1/event_types/${eventType.id}/package_unit_ranges/${packageUnitRange.id}?api_key=${apiKey}`)
        .then(() => onRemove(packageUnitRange))
        .catch(e => {
          console.warn(e)
          alert(e.message)
      })
    }
  }

  return (
    <tr className="slot-row">
      <td>
        <a href="#" onClick={handleEdit}>
          { packageUnitRange.minUnits }
        </a>
      </td>
      <td>
        <a href="#" onClick={handleEdit}>
          { packageUnitRange.maxUnits }
        </a>
      </td>
      <td>
        <a href="#" onClick={handleEdit}>
          { packageUnitRange.priceFormatted }
        </a>
      </td>
      <td className="text-end">
        <a href="#" onClick={handleEdit}>
          <i className="fa fa-pencil" />
        </a>
        &nbsp;
        &nbsp;
        <a href="#" onClick={handleRemove} className="remove" >
          <i className="fa fa-trash" />
        </a>
      </td>
    </tr>
  )
}

export default PackageUnitRange
