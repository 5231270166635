import React from 'react'
import PropTypes from 'prop-types'
import Pager from './Pager'
import { observer, inject } from 'mobx-react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ChecklistItem from './ChecklistItem'

@inject("checklistStore")
@observer
export default class ChecklistView extends React.Component {
  static propTypes = {
    checklistStore: PropTypes.object.isRequired,
    showCheckables: PropTypes.bool.isRequired
  }

  handleDragEnd = ({ draggableId, source, destination }) => {
    const { checklistStore } = this.props
    const itemId = draggableId
    if(source && destination) {
      const sourceIndex = source.index
      const destinationIndex = destination.index
      checklistStore.reorder(itemId, sourceIndex, destinationIndex)
    }
  }

  render() {
    const { checklistStore, showCheckables } = this.props
    const { sortedItems, currentPage, perPage, totalPages, enableDragAndDrop } = checklistStore

    if(sortedItems.length > 0) {
      return (
        <div>
          <DragDropContext onDragEnd={this.handleDragEnd}>
            <div className="item-list">
              <Droppable droppableId="item-list-droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {sortedItems.map((item, index) =>
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                        isDragDisabled={!enableDragAndDrop}>
                        {(provided, snapshot) => (
                          <ChecklistItem item={item} provided={provided} showCheckables={showCheckables} />
                        )}
                      </Draggable>
                    )}
                    { provided.placeholder }
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
          <Pager
            currentPage={currentPage}
            per={perPage}
            totalPages={totalPages}
            handleChange={page => (checklistStore.currentPage = page)}
          />
        </div>
      )
    }
    else {
      return (
        <p className="text-center">
          You do not have any items in this category.
        </p>
      )
    }

  }
}

