import React from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import AddFieldButton from './AddFieldButton.js'
import TabSettingsEditor from './TabSettingsEditor.js'
import FieldGroupCollection from './FieldGroupCollection.js'

const FieldGroup = ({ fieldGroup, fieldGroupIndex }) => (
  <div>
    <div className="tab-settings-wrapper">
      { fieldGroup.fields.length > 2 &&
      <div>
        <AddFieldButton fieldGroupIndex={fieldGroupIndex} position="top" />
      </div>
      }
      <div>
        <TabSettingsEditor fieldGroupIndex={fieldGroupIndex} />
      </div>
    </div>
    <FieldGroupCollection fieldGroupIndex={fieldGroupIndex} />
    <AddFieldButton fieldGroupIndex={fieldGroupIndex} position="bottom" />
  </div>
)

export default observer(FieldGroup)
