import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, FormControl, Modal, Button } from 'react-bootstrap'
import {observer} from "mobx-react"
import store from '../stores/store.js'
import client from '../../../helpers/client'

@observer
export default class ESignatureField extends React.Component {
  state = {
    signer: "",
    showModal: false
  }

  provideSignature = () => {
    const { signer } = this.state
    const { formId, eventId } = store
    const uuid = this.props.field.uuid
    const instance = this.props.instance || 0
    
    client.put(`/events/${eventId}/forms/${formId}/provide_signature.json`, { signer } ,{ withCredentials: true })
      .then(response => {
        const { signer, timestamp, ip } = response.data
        store.setValueFor(uuid, "signer", instance, signer)
        store.setValueFor(uuid, "timestamp", instance, timestamp)
        store.setValueFor(uuid, "ip", instance, ip)
        store.persistFormContent(null, true)

        this.setState({ showModal: false })
      })
      .catch(error => {
        this.setState({ showModal: false })

        if(error.response) {
          const { data, status } = error.response
          const { errors } = data
          const errorMessage = status === 422 ? errors.join(", ") : "Error contacting server"
          alert(errorMessage)
        }
        else {
          console.warn(error.message)
          alert(error.message)
        }
      })

  }

  handleProvideNewSignatureClick = (e) => {
    e.preventDefault()
    this.setState({ showModal: true })
  }

  render() {
    const { field } = this.props
    const { showModal, signer } = this.state
    const instance = this.props.instance || 0
    const { uuid, name, hint, placeholder, required } = field
    const errorMessage = store.errorFor(uuid, "signer", instance)

    return (
      <>
        <Modal show={showModal} onHide={() => this.setState({ showModal: false })}>
          <Modal.Header closeButton>
            <Modal.Title>E-Signature</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Type your full name to serve as your electronic signature</p>
            <FormGroup>
              <Form.Label>{name}</Form.Label>
              <FormControl
                type="text"
                className="cursive-font"
                placeholder={placeholder}
                value={signer}
                onChange={e => this.setState({ signer: e.target.value }) }
                disabled={!store.editable}
              />
              { hint && <Form.Text muted>{hint}</Form.Text > }
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={() => this.setState({ showModal: false })}>Cancel</Button>
            <Button
              variant="primary"
              onClick={this.provideSignature}
              disabled={signer.length === 0}
            >
              Submit E-Signature
            </Button>
          </Modal.Footer>
        </Modal>
        <FormGroup>
          <Form.Label>{ required ? "*" : "" } {name}</Form.Label>
          <div>
            { store.valueFor(uuid, "signer", instance) ?
              <>
                <div>
                  <div>Signed By: <span className="cursive-font">{ store.valueFor(uuid, "signer", instance) }</span></div>
                  <div>Signed On: <em>{ store.valueFor(uuid, "timestamp", instance) }</em></div>
                  <div>IP Address: <em>{ store.valueFor(uuid, "ip", instance) }</em></div>
                  { store.editable &&
                    <a href="#provide-new-signature" onClick={this.handleProvideNewSignatureClick} style={styles.updateSignatureLink}>(Provide New Signature)</a>
                  }
                </div>
              </>
            :
              <Button
                variant="info"
                disabled={!store.editable}
                onClick={() => this.setState({ showModal: true })}>
                  Click Here to E-Sign
              </Button>
            }
          </div>
          { errorMessage && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{errorMessage}</Form.Control.Feedback> }
          { hint && <Form.Text muted>{hint}</Form.Text > }
        </FormGroup>
      </>
    )
  }
}

const styles = {
  updateSignatureLink: {
    textDecoration: "underline"
  }
}
