import React from 'react'
import { Row, Col } from 'react-bootstrap'
import SongProviderLabel from '../../ui/SongProviderLabel'
import FaIcon from '../../../helpers/fa_icon'

const CurrentPlaylistDisplay = ({ currentPlaylist, onSelect }) => {
  if(!currentPlaylist) return null

  const { playlistName, playlistDescription, playlistArtworkUrl, playlistUrl, playlistProvider } = currentPlaylist

  return (
    <Row>
      { playlistArtworkUrl &&
      <Col xs={3}>
        <img src={playlistArtworkUrl} style={styles.artwork} />
      </Col>
      }
      <Col xs={9}>
        <div style={styles.title}>Playlist Details</div>
        { playlistName && <div style={styles.playlistName}>{ playlistName }</div> }
        { playlistDescription && <p>{ playlistDescription }</p> }
        { playlistUrl && <p><a href={playlistUrl} target="_blank">{ playlistUrl }</a></p> }
        { playlistProvider && <SongProviderLabel provider={playlistProvider} /> }
        { playlistUrl && 
          <div style={styles.btnWrapper}>
            <a href={playlistUrl} target="_blank" className="btn btn-secondary"><FaIcon icon="external-link" text="Open Playlist"/> </a>
          </div>
        }
      </Col>
    </Row>
  )
}

const styles = {
  title: {
    fontSize: 24,
    marginBottom: 10
  },
  playlistName: {
    fontSize: 18,
    marginBottom: 10
  },
  artwork: {
    maxWidth: 200,
    maxHeight: 200,
    marginBottom: 10
  },
  btnWrapper: {
    marginTop: 10
  }
}
export default CurrentPlaylistDisplay
