import React, { useState } from 'react';

const SavePaymentMethod = ({ savePaymentMethod, setSavePaymentMethod, savePaymentMethodMode, authorizeOnly, enableAutoPay }) => {
  if(authorizeOnly || enableAutoPay || savePaymentMethodMode == "required") {
    return (
      <div className="save-payment-method-wrapper">
        <div style={styles.savedForFuture}>This payment method will be saved for future use</div>
      </div>
    )
  }
  else if(savePaymentMethodMode == "optional") {
    return (
      <div className="save-payment-method-wrapper">
        <label>
          <input
            type="checkbox"
            checked={savePaymentMethod}
            onChange={() => setSavePaymentMethod(!savePaymentMethod)}
          />
          <span>Save this payment method for future use</span>
        </label>
      </div>
    )
  }
  else {
    return null
  }
}

const styles = {
  savedForFuture: {
    textAlign: 'center',
    fontStyle: 'italic',
  }
}


export default SavePaymentMethod
