import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'react-bootstrap'
import store from '../stores/store.js'

import {observer} from "mobx-react"

import { toJS } from 'mobx'

@observer
export default class Dropdown extends React.Component {
  render() {
    const { field } = this.props
    const { uuid, name, required } = field
    const instance = this.props.instance || 0
    const errorMessage = store.errorFor(uuid, "dropdown", instance)
    const value = store.valueFor(uuid, "dropdown", instance) || ""

    return (
      <FormGroup>
        <Form.Label>{ required ? "*" : "" } {name}</Form.Label>
        <Form.Select
          value={value}
          onChange={e => { store.setValueFor(uuid, "dropdown", instance, e.target.value) }}
          disabled={!store.editable}
          isInvalid={!!errorMessage}
          >
          <option value="" />
          {field.values.map((option, index) => {
            return (
              <option key={index} value={option}>{option}</option>
            )
          })}
        </Form.Select>
        { errorMessage && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{errorMessage}</Form.Control.Feedback> }
        { field.hint && <Form.Text muted>{field.hint}</Form.Text> }
      </FormGroup>
    )
  }
}
