import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, FormControl } from 'react-bootstrap'
import Address from './Address'
import HintField from '../HintField'

export default class AddressField extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    stateName: PropTypes.string.isRequired,
    zipName: PropTypes.string.isRequired,
    stateCodes: PropTypes.array,
    onChange: PropTypes.func,
    style: PropTypes.object,
    hint: PropTypes.string,
    errors: PropTypes.object,
    required: PropTypes.bool
  }

  get errorMessage() {
    const { errors, name } = this.props

    if(errors && errors[name]) {
      return errors[name][0]
    }
    return null
  }

  get helpMessage() {
    const { hint } = this.props
    return this.errorMessage || hint || null
  }

  handleStreetChange = (e) => {
    const { value, onChange } = this.props
    const newAddress = new Address(value)
    newAddress.street = e.target.value
    onChange && onChange(newAddress)
  }

  handleCityChange = (e) => {
    const { value, onChange } = this.props
    const newAddress = new Address(value)
    newAddress.city = e.target.value
    onChange && onChange(newAddress)
  }

  handleStateChange = (e) => {
    const { value, onChange } = this.props
    const newAddress = new Address(value)
    newAddress.state = e.target.value
    onChange && onChange(newAddress)
  }

  handlePostalCodeChange = (e) => {
    const { value, onChange } = this.props
    const newAddress = new Address(value)
    newAddress.postalCode = e.target.value
    onChange && onChange(newAddress)
  }

  render() {
    const { name, label, value, onChange, hint, style, errors, required, stateName, zipName, stateCodes } = this.props
    const { helpMessage } = this

    return (
      <div className={name} style={styles.containerWrapper}>
        <div style={styles.streetWrapper}>
          <FormGroup>
            <Form.Label style={styles.label}>
              { label }: Street
              { required && <span style={styles.required}>*</span> }
            </Form.Label>
            <FormControl
              style={styles.input}
              name={name}
              value={value.street}
              onChange={this.handleStreetChange}
              isInvalid={!!this.errorMessage}
            />
            <HintField
              hint={hint}
              errorMessage={this.errorMessage}
            />
          </FormGroup>
        </div>
        <div style={styles.line2Wrapper}>
          <FormGroup style={styles.city}>
            <Form.Label style={styles.label}>
              City
              { required && <span style={styles.required}>*</span> }
            </Form.Label>
            <FormControl
              style={styles.input}
              name={name}
              value={value.city}
              onChange={this.handleCityChange}
              isInvalid={!!this.errorMessage}
            />
            <HintField
              hint={hint}
              errorMessage={this.errorMessage}
            />
          </FormGroup>
          { (stateCodes && stateCodes.length > 0) &&
            <FormGroup style={styles.state}>
              <Form.Label style={styles.label}>
                { stateName }
                { required && <span style={styles.required}>*</span> }
              </Form.Label>
              <Form.Select
                style={styles.input}
                name={name}
                value={value.state}
                onChange={this.handleStateChange}
                isInvalid={!!this.errorMessage}
              >
                <option value="" />
                { stateCodes.map(stateCode =>
                  <option key={stateCode} name={stateCode}>{ stateCode }</option>
                )}
              </Form.Select>
              <HintField
                hint={hint}
                errorMessage={this.errorMessage}
              />
            </FormGroup>
          }
          <FormGroup style={styles.postalCode}>
            <Form.Label style={styles.label}>
              { zipName }
              { required && <span style={styles.required}>*</span> }
            </Form.Label>
            <FormControl
              style={styles.input}
              name={name}
              value={value.postalCode}
              onChange={this.handlePostalCodeChange}
              isInvalid={!!this.errorMessage}
            />
            <HintField
              hint={hint}
              errorMessage={this.errorMessage}
            />
          </FormGroup>
        </div>
      </div>
    )
  }
}

const styles = {
  containerWrapper: {
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10
  },
  line2Wrapper: {
    display: "flex",
    flexDirection: "row"
  },
  city: {
    flex: 5,
    paddingRight: 5,
  },
  state: {
    flex: 3,
    paddingRight: 10,
    paddingLeft: 5
  },
  postalCode: {
    flex: 4,
    paddingLeft: 5
  },
  label: {
    fontSize: 18,
  },
  input: {
    fontSize: 18
  },
  required: {
    color: "#cc0000",
    fontWeight: "normal",
    paddingLeft: 2
  }
}
