import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import Currency from '../../../../helpers/currency.js'

@inject("currency")
@inject("store")
@observer
export default class Statistics extends React.Component {
  static propTypes = {
    currency: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
  }

  get totalRevenue() {
    return this.totalAmountPaid + this.totalProjected + this.totalPastDue
  }

  get totalAmountPaid() {
    const { store } = this.props
    const { data } = store

    return data
      .map(period => period.amountPaidCents)
      .reduce((acc, value) => acc + value, 0)
  }

  get totalProjected() {
    const { store } = this.props
    const { data } = store

    return data
      .map(period => period.projectedCents)
      .reduce((acc, value) => acc + value, 0)
  }

  get totalPastDue() {
    const { store } = this.props
    const { data } = store

    return data
      .map(period => period.pastDueCents)
      .reduce((acc, value) => acc + value, 0)
  }

  get totalPostponed() {
    const { store } = this.props
    const { data } = store

    return data
      .map(period => period.postponedCents)
      .reduce((acc, value) => acc + value, 0)
  }

  get pricePerBooking() {
    if(this.eventCount == 0 || this.totalRevenue == 0) {
      return 0
    }

    return this.totalRevenue / this.eventCount
  }

  get eventCount() {
    const { store } = this.props
    const { data } = store

    return data
      .map(period => period.eventCount)
      .reduce((acc, value) => acc + value, 0)
  }

  render() {
    const { currency, store } = this.props
    const { data } = store
    
    const { totalRevenue, totalAmountPaid, totalProjected, totalPastDue, totalPostponed, eventCount, pricePerBooking } = this

    if(!data || !totalRevenue) {
      return null
    }

    return (
      <div className="statistics">
        <div className="row">
          <div className="col-sm-6 cell">
            <div style={styles.totalRevenue}>
              Total Revenue: <span className="value">{ Currency.formatted(totalRevenue, currency)}</span>
            </div>
          </div>
          <div className="col-sm-6 cell">
            Past Due: <span className="value">{ Currency.formatted(totalPastDue, currency)}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 cell">
            <div style={styles.totalRevenue}>
              Amount Paid: <span className="value">{ Currency.formatted(totalAmountPaid, currency)}</span>
            </div>
          </div>
          <div className="col-sm-6 cell">
            Postponed: <span className="value">{ Currency.formatted(totalPostponed, currency)}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 cell">
            <div style={styles.totalRevenue}>
              Projected Revenue: <span className="value">{ Currency.formatted(totalProjected, currency)}</span>
            </div>
          </div>
          <div className="col-sm-6 cell">
            Average per Booking: <span className="value">{ Currency.formatted(pricePerBooking, currency) }</span>
          </div>
        </div>

        <div style={styles.eventCount}>
          Booking Count: <span className="value">{ eventCount }</span>
        </div>
      </div>
    )
  }
}

const styles = {
  value: {
    fontWeight: "bold"
  },
  eventCount: {
    marginTop: 3,
    textAlign: "center"
  }
}

