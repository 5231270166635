import moment from 'moment'

const parseTime = (iso8601Date) => {
  // We don't want to use the browser time zones because the time zone is set on
  // the franchise side of things and having the displayed times differ from
  // that is confusing.
  let modifiedTime = iso8601Date.replace(/[-+][0-9]{2}:[0-9]{2}$/, "")
  modifiedTime = modifiedTime.replace(/Z$/, "")

  return new moment(modifiedTime).toDate()
}

export default parseTime
