import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import {observer} from "mobx-react"
import store from '../stores/store.js'
import { parse, format } from 'date-fns'

const parseTime = (dateString, timeFormat) => {
  if(!dateString) {
    return null
  }

  const parsedDate = parse(dateString, timeFormat, new Date())
  if(parsedDate == "Invalid Date") {
    return null
  }

  return parsedDate
}

const formatTime = (date, timeFormat) => {
  const formatted = format(date, timeFormat)
  return formatted
}

class Input extends React.Component {
  render() {
    const { onClick, value, style, onChange, placeholder, disabled } = this.props
    return (
      <input
        type="text"
        onClick={onClick}
        value={value}
        onChange={onChange}
        style={style}
        className="form-control"
        placeholder={placeholder}
        disabled={disabled}
      />
    )
  }
}

@observer
export default class TimeField extends React.Component {
  render() {
    const { field } = this.props
    const { name, placeholder, uuid, hint, required } = field
    const instance = this.props.instance || 0
    const value = store.valueFor(uuid, "text", instance)
    const errorMessage = store.errorFor(uuid, "text", instance)
    const timeFormat = store.timeFormat

    return (
      <FormGroup>
        <Form.Label>{ required ? "*" : "" } {name}</Form.Label>
        <ReactDatePicker
          name={name}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          customInput={<Input  />}
          onChange={startDate => store.setValueFor(uuid, "text", instance, formatTime(startDate, timeFormat))}
          selected={parseTime(value, timeFormat)}
          placeholderText={placeholder}
          dateFormat={timeFormat}
          timeFormat={timeFormat}
          timeCaption="Time"
          disabled={!store.editable}
          isInvalid={!!errorMessage}

        />
        { errorMessage && <Form.Control.Feedback type="invalid" style={{display: "block"}}>{errorMessage}</Form.Control.Feedback> }
        { hint && <Form.Text muted>{hint}</Form.Text > }
      </FormGroup>
    )
  }
}
