import React from 'react'
import PropTypes from 'prop-types'
import { Pagination } from 'react-bootstrap'


export default class Pager extends React.Component {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    per: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired
  }

  setPage = (page) => {
    const { handleChange } = this.props
    handleChange(page)
  }

  render() {
    const { checklistStore, currentPage, per, totalPages } = this.props

    const showFirst = currentPage > 1
    const showPrevious = currentPage > 1
    const showNext = currentPage < totalPages
    const showLast = currentPage < totalPages

    if(totalPages > 1) {
      return (
        <div>
          <Pagination>
            { showFirst && 
              <Pagination.Item onClick={() => this.setPage(1)}> « First </Pagination.Item>
            }

            { showPrevious &&
              <Pagination.Item onClick={() => this.setPage(currentPage - 1)}> ‹ Prev </Pagination.Item>
            }

            { (currentPage - 2 > 1) &&
              <Pagination.Ellipsis disabled />
            }

            { (currentPage - 1 > 1) &&
              <Pagination.Item onClick={() => this.setPage(currentPage - 1)}> { currentPage - 1 } </Pagination.Item>
            }

            <Pagination.Item active> { currentPage } </Pagination.Item>

            { (currentPage + 1 <= totalPages) &&
              <Pagination.Item onClick={() => this.setPage(currentPage + 1)}> { currentPage + 1 } </Pagination.Item>
            }

            { (currentPage + 2 <= totalPages) &&
              <Pagination.Ellipsis disabled />
            }

            { showNext &&
              <Pagination.Item onClick={() => this.setPage(currentPage + 1)}> Next ›</Pagination.Item>
            }
            { showLast &&
              <Pagination.Item onClick={() => this.setPage(totalPages)}> Last » </Pagination.Item>
            }
          </Pagination>
        </div>
      )
    }
    else {
      return null
    }
  }
}

