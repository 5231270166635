import React from 'react'
import PropTypes from 'prop-types'
import { toJS } from 'mobx'
import { observer, inject } from 'mobx-react'
import Currency from '../../../../helpers/currency.js'
import { ResponsiveContainer, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line } from 'recharts'
import TooltipDisplay from './TooltipDisplay'
import Statistics from './Statistics'

@inject("store")
@inject("currency")
@observer
export default class PaymentChart extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  loadReport = (e) => {
    const path = e.payload.reportPath
    window.location = path
  }

  render() {
    const { store, currency } = this.props
    const { data, loading } = store

    return (
      <div>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data} margin={{top: 20, right: 30, left: 20, bottom: 5}}>
            <XAxis dataKey="name"/>
            <YAxis tickFormatter={amountCents => Currency.formatted(amountCents, currency) }/>
            <CartesianGrid strokeDasharray="3 3"/>
            <Tooltip content={<TooltipDisplay currency={currency} />} />
            <Legend />
            <Line
              isAnimationActive={false}
              className="dashboard-chart-data-line"
              dataKey="previousTotalCents"
              name="Previous Period"
              strokeWidth={3}
              stroke="#c1c8d1"
              onClick={this.loadReport}  />
            <Line
              type="monotone"
              isAnimationActive={false}
              className="dashboard-chart-data-line"
              dataKey="currentTotalCents"
              name="Current Period"
              strokeWidth={4}
              // stroke="#4DA143"
              stroke="#333"
              onClick={this.loadReport}
            />
          </LineChart>
        </ResponsiveContainer>
        <Statistics />
      </div>
    )
  }
}
