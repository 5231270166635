import searchSpotifyPlaylists from './searchSpotifyPlaylists'
import searchAppleMusicPlaylists from './searchAppleMusicPlaylists'

async function performRefreshPlaylist(playlist) {
  switch(playlist.playlistProvider) {
    case "spotify":
      return await searchSpotifyPlaylists(playlist.playlistUrl)
    case "apple":
      return await searchAppleMusicPlaylists(playlist.playlistUrl)
    default:
      throw new Error(`Unknown playlistProvider: ${playlist.playlistProvider}`)
  }
}

export default performRefreshPlaylist
