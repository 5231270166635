import React from 'react'
import PropTypes from 'prop-types'
import SelectOption from '../SelectOption'

export default class OptionGroup extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired
  }

  render() {
    const { label, options } = this.props

    return (
      <optgroup label={label}>
        { options.slice().map((option, index) => 
          <SelectOption key={index} option={option} />
        )}
      </optgroup>
    )
  }
}
