function extractAttributes(event) {
  const { type } = event
  const { attributes } = event

  if(type === "grouped-event-booking") {
    return attributes[0]
  }

  return attributes
}

export default extractAttributes
