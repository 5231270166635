import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import {observer} from "mobx-react"
import store from '../stores/store.js'
import FaIcon from '../../../helpers/fa_icon.js'

@observer
export default class SaveButton extends React.Component {
  static propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func
  }

  static defaultProps = {
    text: "Save Changes"
  }

  render() {
    const { text, onClick } = this.props
    const { loading, allowSave } = store
    const icon = loading ? "spinner" : null

    return (
      <Button
        disabled={!allowSave}
        onClick={onClick}
        className="btn-lg btn-call-to-action w-100"
        >
        <FaIcon spin={loading} icon={icon} text={text} />
      </Button>
    )
  }
}
