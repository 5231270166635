import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { addDays } from 'date-fns'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';

@inject("store")
@inject("dateFormat")
@observer
export default class DateSelector extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    dateFormat: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  }

  handleChange = (date) => {
    const { store } = this.props
    store.setDate(date)
  }

  render() {
    const { store, dateFormat } = this.props
    const { selectedDate, endDate } = store

    return (
      <div>
        <DatePicker
          inline
          selectsRange
          name="availability-checker-datepicker"
          onChange={this.handleChange}
          selected={selectedDate}
          dateFormat={dateFormat}
          minDate={new Date()}
          startDate={selectedDate}
          endDate={endDate}
        />
      </div>
    )
  }
}
