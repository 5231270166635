import React from 'react'
import PropTypes from 'prop-types'
import { Button, Nav, NavItem, Tab, Row, Col, Card } from 'react-bootstrap'
import {observer} from "mobx-react"
import FormContainer from './components/FormContainer.js'
import FormBody from './components/FormBody.js'
import FormSidebar from './components/FormSidebar.js'
import FaIcon from '../../helpers/fa_icon.js'
import FieldGroup from './components/FieldGroup.js'
import EventTypeSelector from '../EventTypeSelector'
import SaveButton from './components/SaveButton'
import EditableTitle from './components/EditableTitle.js'
import Visibility from './components/Visibility'
import SendForm from './components/SendForm.js'
import Notification from './components/Notification.js'
import PrintForm from './components/PrintForm.js'
import MoreOptions from './components/MoreOptions.js'
import ErrorBadge from './components/ErrorBadge.js'
import AddTabButton from './components/AddTabButton'
import store from './stores/store.js'
import './style.scss'

const autosaveIntervalSeconds = 30

@observer
export default class FormBuilder extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    formTemplateId: PropTypes.number,
    formId: PropTypes.number,
    eventId: PropTypes.number,
    mode: PropTypes.string,
    disableEdit: PropTypes.bool,
    unlockable: PropTypes.bool,
    complete: PropTypes.bool,
    printVersion: PropTypes.bool,
    values: PropTypes.object,
    allCategories: PropTypes.bool,
    eventCategories: PropTypes.array,
    allAddOnCategories: PropTypes.bool,
    addOnCategories: PropTypes.array,
    autosave: PropTypes.bool,
    apiKey: PropTypes.string.isRequired,
    dateFormat: PropTypes.string.isRequired,
    timeFormat: PropTypes.string.isRequired,
    suggestionLists: PropTypes.array,
    selectedEventCategoryIds: PropTypes.array,
    selectedPackageGroupIds: PropTypes.array,
    selectedPackageIds: PropTypes.array,
    selectedAddOnCategoryIds: PropTypes.array,
    selectedAddOnIds: PropTypes.array,
    selectedBackdropIds: PropTypes.array,
    managePaths: PropTypes.array,
    supportsSpotify: PropTypes.bool.isRequired,
    supportsAppleMusic: PropTypes.bool.isRequired,
    supportsTidal: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    autosave: false,
    isHistoricalVersion: false
  }

  constructor(props) {
    super(props)

    let data = this.props.data || {}
    store.apiKey = this.props.apiKey
    store.formTemplateId = this.props.formTemplateId
    store.formId = this.props.formId
    store.eventId = this.props.eventId
    store.mode = this.props.mode
    store.unlockable = this.props.unlockable
    store.disableEdit = this.props.disableEdit
    store.title = data.title || "New Questionnaire"
    store.fieldGroups = data.fieldGroups || []
    store.complete = this.props.complete
    store.printVersion = this.props.printVersion
    store.dateFormat = this.props.dateFormat
    store.timeFormat = this.props.timeFormat
    store.suggestionLists = this.props.suggestionLists
    store.selectedEventCategoryIds = this.props.selectedEventCategoryIds
    store.selectedPackageGroupIds = this.props.selectedPackageGroupIds
    store.selectedPackageIds = this.props.selectedPackageIds
    store.selectedAddOnCategoryIds = this.props.selectedAddOnCategoryIds
    store.selectedAddOnIds = this.props.selectedAddOnIds
    store.selectedBackdropIds = this.props.selectedBackdropIds
    store.supportsAdvancedMusicFeatures = this.props.supportsAdvancedMusicFeatures
    store.supportsSpotify = this.props.supportsSpotify
    store.supportsAppleMusic = this.props.supportsAppleMusic
    store.supportsTidal = this.props.supportsTidal
    if(this.props.values) {
      Object.entries(this.props.values).map((entry) => {
        let key = entry[0]
        // Build an array of Maps
        let value = entry[1].map((obj) => new Map(Object.entries(obj)))
        store.values.set(key, value)
      })
    }
    store._previousStringifiedData = store._stringifiedData
    store._previousStringifiedTemplate = store._stringifiedTemplate

    store.eventTypeSelectorSettings = {
      eventCategories: props.eventCategories,
      addOnCategories: props.addOnCategories,
    }
  }

  handleEventTypeSelectorChange = (change) => {
    store.allCategories = change.allCategories
    store.eventCategoryIds = change.eventCategoryIds
    store.packageGroupIds = change.packageGroupIds
    store.eventTypeIds = change.eventTypeIds
    store.allMiniSessions = change.allMiniSessions
    store.miniSessionIds = change.miniSessionIds
    store.allAddOnCategories = change.allAddOnCategories
    store.addOnCategoryIds = change.addOnCategoryIds
    store.addOnIds = change.addOnIds
  }

  componentDidMount() {
    this.autosaveTimer = setInterval(() => this.autosave(), autosaveIntervalSeconds * 1000)
  }

  componentWillUnmount() {
    if(this.autosaveTimer) {
      clearTimeout(this.autosaveTimer)
    }
  }

  autosave = () => {
    const { autosave } = this.props

    if(autosave && store.editable && !store.previewable && store.allowSave) {
      store.persistFormContent(null, true)
    }
  }

  handleTabSelected = (key) => {
    store.activeFieldGroup = key
    this.autosave()
  }

  render() {
    let fieldGroups = store.fieldGroups
    let showSidebar = !store.previewable && (store.buildable || store.editable || !store.printVersion)

    return (
      <div className={`form-builder form-builder-${store.mode}-mode`}>
        <Notification />
        <EditableTitle />
        <FormContainer showSidebar={showSidebar}>
          <FormBody showSidebar={showSidebar}>
            { store.buildable &&
              <div className="text-end">
                <AddTabButton />
              </div>
            }
            <Tab.Container
              id="form-builder-tab-container"
              activeKey={store.activeFieldGroup}
              onSelect={this.handleTabSelected}
            >
              <div style={styles.content}>
                { fieldGroups.length > 1 &&
                  <Visibility hideOnPrint={true}>
                    <Row className="clearfix">
                      <Col xs={12}>
                        <Nav variant="tabs">
                          {fieldGroups.map((fieldGroup, fieldGroupIndex) =>
                          <NavItem key={fieldGroupIndex}>
                            <Nav.Link eventKey={`tab-${fieldGroupIndex}`}>
                              { fieldGroup.name }
                              <ErrorBadge errorCount={store.fieldGroupErrorCounts.get(fieldGroupIndex)} />
                            </Nav.Link>
                          </NavItem>
                          )}

                        </Nav>
                      </Col>
                    </Row>
                  </Visibility>
                }
                <Visibility hideOnPrint={true}>
                  <Tab.Content>
                    {fieldGroups.map((fieldGroup, fieldGroupIndex) =>
                    <Tab.Pane key={fieldGroupIndex} eventKey={`tab-${fieldGroupIndex}`}>
                      <FieldGroup key={fieldGroupIndex} fieldGroup={fieldGroup} fieldGroupIndex={fieldGroupIndex} />
                    </Tab.Pane>
                    )}
                  </Tab.Content>
                </Visibility>
                <Visibility showOnPrint={true}>
                  {fieldGroups.map((fieldGroup, fieldGroupIndex) =>
                  <div className="tab-section" key={fieldGroupIndex}>
                    <h3>{ fieldGroup.name }</h3>
                    <FieldGroup fieldGroup={fieldGroup} fieldGroupIndex={fieldGroupIndex} />
                    <div className="page-break" />
                  </div>
                  )}
                </Visibility>

                <div style={styles.footer}>
                  { store.buildable && 
                  <div style={styles.buttonContainer}>
                    <SaveButton text="Save Template" onClick={() => store.persistFormTemplate()} />
                  </div>
                  }
                  { store.editable && !store.previewable &&
                    <div style={styles.buttonContainer}>
                      <SaveButton onClick={() => store.persistFormContent()} />
                    </div>
                  }
                  { (store.previewable && !store.disableEdit) &&
                    <div style={styles.buttonContainer}>
                      <Button className="btn-lg btn-call-to-action w-100" onClick={() => store.exitPreviewMode()}>Back to Editor</Button>
                    </div>
                  }
                </div>
              </div>
            </Tab.Container>
          </FormBody>
          <FormSidebar showSidebar={showSidebar}>
            { store.buildable &&
              <Card className="mb-2">
                <Card.Header>
                  <Card.Title>Edit Mode</Card.Title>
                </Card.Header>
                <Card.Body>
                  { this.props.eventId ?
                    <p>Any changes made to this questionnaire will only apply to this booking's copy of the questionnaire.</p>
                    :
                    <>
                      <p>Any changes made to this template will apply to all future bookings.</p>
                      <Button variant="success" className="w-100 spacer-bottom" onClick={() => store.enterPreviewMode()}>Live Preview</Button>
                    </>
                  }
                </Card.Body>
              </Card>
            }

            { (store.buildable && this.props.eventCategories && !this.props.eventId) &&
              <Card>
                <Card.Header>
                  <Card.Title>Show for the following:</Card.Title>
                </Card.Header>
                <Card.Body>
                  <EventTypeSelector
                    allCategories={this.props.allCategories}
                    eventCategories={this.props.eventCategories}
                    supportsPackages={true}
                    supportsMiniSessions={this.props.supportsMiniSessions}
                    supportsAddOns={true}
                    allMiniSessions={this.props.allMiniSessions}
                    miniSessions={this.props.miniSessions}
                    allAddOnCategories={this.props.allAddOnCategories}
                    addOnCategories={this.props.addOnCategories}
                    onChange={this.handleEventTypeSelectorChange}
                  />
                </Card.Body>
              </Card>
            }

            { store.editable && !store.previewable &&
              <div style={styles.buttonContainer} className="d-none d-sm-block">
                <SaveButton onClick={() => store.persistFormContent()} />
              </div>
            }
            { !store.printVersion && 
              <div style={styles.buttonContainer}>
                <PrintForm />
              </div>
            }

            <MoreOptions managePaths={this.props.managePaths} />

            <div style={styles.buttonContainer}>
              <SendForm />
            </div>
          </FormSidebar>
        </FormContainer>
      </div>
    )
  }
}

const styles = {
  buttonContainer: {
    marginTop: 20
  },
  content: {
    position: "relative"
  },
  footer: {
    position: "sticky",
    bottom: 0,
    marginBottom: 20
  }
}
