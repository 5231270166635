import React from 'react'

function parseDimensions(value) {
  if(!value) {
    return null
  }
  else if(value.match(/[0-9+]/)) {
    return `${value}px`
  }
  else {
    return value
  }
}

export default class ImagePreview extends React.Component {
  renderImage() {
    const { url, width, height } = this.props

    return (
      <img
        width={parseDimensions(width)}
        height={parseDimensions(height)}
        src={url}
        style={{maxWidth: "100%", maxHeight: "auto"}}
      />
    )
  }

  render() {
    const { url, imageLink } = this.props

    if(url && imageLink) {
      return (
        <div>
          <a href={imageLink} target="_blank" rel="noopener">
            { this.renderImage() }
          </a>
        </div>
      )
    }
    else if(url) {
      return (
        <div>
          { this.renderImage() }
        </div>
      )
    }
    else {
      return null
    }
  }
}

