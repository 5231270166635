import React from 'react'
import {observer} from "mobx-react"
import store from '../stores/store.js'

@observer
export default class Notification extends React.Component {
  render() {
    if(store.notification) {
      return (
        <div className="text-center alert alert-success fade show">{ store.notification }</div>
      )
    }
    else {
      return null
    }
  }
}
