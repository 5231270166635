import React from 'react'
import PropTypes from 'prop-types'

export default class StaffDayUnavailable extends React.Component {
  static propTypes = {
    event: PropTypes.shape({
      title: PropTypes.string.isRequired,
      attributes: PropTypes.shape({
        id: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  }

  render() {
    const { event } = this.props

    return (
      <a
        href={`/admin/users/${event.attributes.id}/availability`}
        className="calendar-event">
        { event.title }
      </a>
    )
  }
}

