import React from 'react'
import DebouncedInput from '../../ui/DebouncedInput'

const SearchBar = ({ value, onChange }) => (
  <div className="form-group string optional">
    <DebouncedInput
      type="search"
      placeholder="Search Backdrops"
      className="form-control input-sm string optional"
      value={value}
      onChange={onChange}
    />
  </div>
)

export default SearchBar
