import React from 'react'
import PropTypes from 'prop-types'
import {observer} from "mobx-react"
import OutsideClicker from '../../OutsideClicker'
import FaIcon from '../../../helpers/fa_icon.js'
import store from '../stores/store.js'

@observer
export default class EditableTitle extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editingTitle: false
    }

  }

  render() {
    if(store.buildable) {
      return (
        <h1>
          { this.state.editingTitle ? 
            <OutsideClicker onClick={() => this.setState({editingTitle: false})}>
              <input type="text" autoFocus value={store.title} onChange={(e) => store.title = e.target.value } />
            </OutsideClicker>
            : 
            <span
              style={{cursor: "pointer"}}
              onClick={() => this.setState({editingTitle: true})}>
              { store.title }

              <FaIcon icon="pencil-square-o" style={{paddingLeft: 10, fontSize: 20, color: "#dddddd"}}/>
            </span>
          }
        </h1>
      )
    }
    else {
      return (<h1>{ store.title}</h1>)
    }
  }
}
