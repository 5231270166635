import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { addDays, subDays } from 'date-fns'

const SimulatorForm = ({
  eventTypeId,
  setEventTypeId,
  total,
  setTotal,
  bookDate,
  setBookDate,
  scheduledDate,
  setScheduledDate,
  groupedEventTypes,
  currency,
  dateFormat,
  dueNumberOfDaysBeforeOrAfterEvent,
  dueDateIsBeforeOrAfter,
}) => {
  
  const finalDueDate = dueDateIsBeforeOrAfter === 'before' ?
    subDays(scheduledDate, dueNumberOfDaysBeforeOrAfterEvent) :
    addDays(scheduledDate, dueNumberOfDaysBeforeOrAfterEvent)


  return (
    <Form>
      <div className="mb-3">
        <Form.Group>
          <Form.Label>Package</Form.Label>
          <Form.Select
            name="eventTypeId"
            value={eventTypeId}
            onChange={e => setEventTypeId(e.target.value)}
          >
            <option value="">Select a package</option>
            { groupedEventTypes.map(([groupName, options]) => (
              <optgroup key={groupName} label={groupName}>
                { options.map(option => (
                  <option key={option.id} value={option.id}>{ option.attributes.name }</option>
                ))}
              </optgroup>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
      <div className="mb-3">
        <Form.Group>
          <Form.Label>Total Due Due</Form.Label>
          <InputGroup>
            { currency.prefix && <InputGroup.Text id="basic-addon1">{ currency.prefix }</InputGroup.Text> }
            <Form.Control
              name="total"
              value={total}
              onChange={e => setTotal(e.target.value)}
            />
            { currency.suffix && <InputGroup.Text id="basic-addon1">{ currency.suffix }</InputGroup.Text> }
          </InputGroup>
        </Form.Group>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <Form.Group>
            <Form.Label>Book Date</Form.Label>
            <div className="w-100">
              <DatePicker
                name={"bookDate"}
                onChange={setBookDate}
                className="form-control"
                selected={bookDate}
                dateFormat={dateFormat}
                minDate={new Date()}
              />
            </div>
          </Form.Group>
        </div>
        <div className="col-sm-4">
          <Form.Group>
            <Form.Label>Scheduled Event Date</Form.Label>
            <div className="w-100">
              <DatePicker
                name={"scheduledDate"}
                onChange={setScheduledDate}
                className="form-control"
                selected={scheduledDate}
                dateFormat={dateFormat}
                minDate={new Date()}
              />
            </div>
          </Form.Group>
        </div>
        <div className="col-sm-4">
          <Form.Group>
            <Form.Label>Final Due Date</Form.Label>
            <div className="w-100">
              <DatePicker
                name={"scheduledDate"}
                disabled={true}
                className="form-control"
                selected={finalDueDate}
                dateFormat={dateFormat}
              />
            </div>
            <Form.Text className="text-muted">Automatically calculated based on your due date settings</Form.Text>
          </Form.Group>
        </div>
      </div>
    </Form>
  )
}


export default SimulatorForm
