import React, { useState } from 'react'
import { Form, Modal, Button, FormGroup, FormControl } from 'react-bootstrap'
import SimpleFormat from '@16g/react-simple-format'
import { extractSlidesFromBackdrop } from '../util'

const Backdrop = ({ backdrop, category, allCategories, count, csrfToken }) => {
  const [showModal, setShowModal ] = useState(false)

  const editUrl = `/admin/photobooth_backgrounds/${backdrop.id}/edit`
  const duplicateUrl = `/admin/photobooth_backgrounds/${backdrop.id}/duplicate`

  function handleShowModal(e) {
    e.preventDefault()
    setShowModal(true)
  }

  function handleHideModal() {
    setShowModal(false)
  }

  return (
    <div className="categorized-item">
      <div className={`photobooth-background photobooth-background-${backdrop.id}`}>
        <div className="name">
          <a href={editUrl}>
            { backdrop.name }
          </a>
        </div>
        <div className="name">
          { backdrop.priceFormatted }
        </div>
        <div className="image-wrapper">
          <a href={editUrl}>
            <img src={backdrop.primaryImage.videoVariant} className="image" style={{minHeight: 120, minWidth: 120}} />
          </a>
        </div>
        <div className="controls">
          { backdrop.position > 1 &&
          <a href={`/admin/photobooth_backgrounds/${backdrop.id}/move_to?position=${backdrop.position - 1}`} className="control" title="Move Up" data-method="put">
            <i className="fa fa-arrow-up" />
          </a>
          }
          { backdrop.position < count &&
            <a href={`/admin/photobooth_backgrounds/${backdrop.id}/move_to?position=${backdrop.position + 1}`} className="control" title="Move Down" data-method="put">
              <i className="fa fa-arrow-down" />
            </a>
          }
          <a href="#" className="control" title="Move to Position" onClick={handleShowModal}>
            <i className="fa fa-arrows" />
          </a>
          <a href={editUrl} className="control cog" title="Settings">
            <i className="fa fa-cog" />
          </a>
          <a href={duplicateUrl} className="control clone" title="Make a copy" data-confirm="Do you wish to make a copy of this backdrop?" data-method="post" id={`photobooth-background-clone-${backdrop.id}`}>
            <i className="fa fa-clone" />
          </a>
        </div>
      </div>
      <Modal show={showModal} onHide={handleHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Quick move backdrop to new category or position</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action={`/admin/photobooth_backgrounds/${backdrop.id}/move_to`} method="post">
            <input type="hidden" name="_method" value="put" />
            <input type="hidden" value={csrfToken} name="authenticity_token" />
            <div className="row">
              <div className="col-sm-6">
                <FormGroup>
                  <Form.Label>Category</Form.Label>
                  <Form.Select
                    name="photobooth_background[backdrop_category_id]"
                    defaultValue={category.id}
                  >
                    {allCategories.map(option => 
                    <option key={option.id} value={option.id}>{option.name}</option>
                    )}
                  </Form.Select>
                </FormGroup>
              </div>
              <div className="col-sm-6">
                <FormGroup>
                  <Form.Label>Position</Form.Label>
                  <FormControl
                    name="photobooth_background[position]"
                    type="text"
                    defaultValue={backdrop.position}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="text-end">
              <Button onClick={handleHideModal}>Close</Button>
              { " " }
              <input type="submit" className="btn btn-primary" value="Save Changes" />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Backdrop
