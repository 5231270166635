import React from 'react'

const MandatoryAutoPayControl = ({ enableAutoPay }) => {
  return (
    <div className="alert alert-warning mt-3" role="alert">
      <label className="form-check-label ms-2">
        <span className="fw-bold pe-1">Auto Pay:</span> By choosing this payment method you agree to have your payment method charged automatically on each future payment date.
      </label>
    </div>
  )
}

export default MandatoryAutoPayControl
