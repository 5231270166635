import React from 'react';
import PropTypes from 'prop-types'

export default class VenuePreview extends React.Component {
  render() {
    const { data } = this.props
    const { name, formattedAddress, previewImage } = data.attributes

    return (
      <div
        className="venue-preview"
        style={styles.containerStyle}
      >
        <div className="venue-status no-print">
          <div className="img-wrapper">
            <img src={previewImage.videoVariant} alt={name} />
          </div>
        </div>
        <div className="venue-details">
          <div className="name">{ name }</div>
          <div className="datetime">{ formattedAddress }</div>
        </div>
      </div>
    )
  }
}

const styles = {
  containerStyle: {
  }
}
