import React from 'react'
import { observer } from "mobx-react"
import ConditionalWrapper from './ConditionalWrapper'
import TimelineWrapper from './TimelineWrapper'
import FieldInstance from './FieldInstance'
import FieldShadowEffect from './FieldShadowEffect'
import RemoveFieldInstance from './RemoveFieldInstance'
import AddFieldInstance from './AddFieldInstance'
import SideControls from './SideControls'
import TopControls from './TopControls'
import FaIcon from '../../../helpers/fa_icon'
import store from '../stores/store'
import mergeField from '../util/mergeField'
import FieldInstanceCollectionHeader from './FieldInstanceCollectionHeader'
import FieldInstanceCollectionFooter from './FieldInstanceCollectionFooter'
import FieldInstanceLoadHandler from './FieldInstanceLoadHandler'


const FieldInstanceCollection = observer(({ field: unmergedField, index, fieldGroupIndex }) => {
  const field = mergeField(unmergedField)
  const { buildable } = store
  const { fields, allowSort } = store.fieldGroups[fieldGroupIndex]

  const fieldEditingIsLocked = store.fieldEditingIsLocked(field)
  const removable = store.editable && field.allowMultipleEntries && store.instancesFor(field.uuid) > 1 && !fieldEditingIsLocked

  return (
    <ConditionalWrapper field={field}>
      <div className={`field-group-wrapper field-group-type-${field.type} ${allowSort ? 'field-group-sortable' : 'field-group-not-sortable'}`}>
        <TopControls buildable={buildable} field={field} fieldIndex={index} fieldGroupIndex={fieldGroupIndex} />
        <div className="field-group">
          <SideControls buildable={buildable} allowSort={allowSort} />
          <TimelineWrapper field={field}>
            <div className="field-group-content">
              <FieldInstanceLoadHandler field={field} />
              <FieldInstanceCollectionHeader field={field} />
              { [...Array(store.instancesFor(field.uuid)).keys()].map((instance) => {
                return (
                  <div className="field-instance-wrapper" key={instance}>
                    <FieldShadowEffect field={field}>
                      <FieldInstance field={field} instance={instance} />
                    </FieldShadowEffect>
                    { removable && <div className="field-instance-control"><RemoveFieldInstance field={field} instance={instance} /></div> }
                  </div>
                )
              }
              )}
            </div>
          </TimelineWrapper>
        </div>
        <div className="field-group-tools">
          <div>
            <FieldInstanceCollectionFooter field={field} />
          </div>
          <div>
            <AddFieldInstance field={field} />
          </div>
        </div>
      </div>
    </ConditionalWrapper>
  )
})

export default FieldInstanceCollection
