import { autorun, observable, action, computed, toJS } from 'mobx'
import client from '../../../helpers/client'
import { asNormalizedMaps, asNormalizedArrays } from '../../../helpers/json-normalizer'

class Store {
  @observable ready = false
  @observable franchise
  @observable eventCategories
  @observable packageGroups
  @observable eventTypes
  @observable activeEventCategoryId = null
  @observable activePackageGroupId = null

  constructor({ host, apiKey, franchise, eventCategories, packageGroups, eventTypes }) {
    this.host = host
    this.apiKey = apiKey
    if(franchise) {
      this.franchise = franchise
    }
    console.log({ eventCategories, packageGroups, eventTypes })
    if(eventCategories) {
      this.eventCategories = eventCategories.map(eventCategory => eventCategory.attributes)
    }
    if(packageGroups) {
      this.packageGroups = packageGroups.map(packageGroup => packageGroup.attributes)
    }
    if(eventTypes) {
      this.eventTypes = eventTypes.map(eventType => eventType.attributes)
    }
  }

  async setup() {
    if(!this.franchise) {
      await this.loadFranchise()
    }

    this.ready = true
  }

  @computed get visibleEventCategories() {
    return this.eventCategories
  }

  @computed get visiblePackageGroups() {
    return this.packageGroups.filter(packageGroup => packageGroup.eventCategoryId == this.activeEventCategoryId)
  }

  @computed get visibleEventTypes() {
    return this.eventTypes.filter(eventType => eventType.packageGroupId == this.activePackageGroupId)
  }

  @computed get showServiceSelector() {
    return !this.activeEventCategoryId && !this.activePackageGroupId
  }

  @computed get showPackageGroupSelector() {
    return this.activeEventCategoryId && !this.activePackageGroupId
  }

  @computed get showEventTypeSelector() {
    return this.activeEventCategoryId && this.activePackageGroupId
  }

  loadFranchise() {
    return client.get(`${this.host}/api/v1/franchise`)
      .then(response => {
        const data = asNormalizedArrays(response.data)
        this.franchise = Object.values(data.franchise)[0]
      })
      .catch(error => (this.unrecoverableError = error))
  }
}

export default Store
