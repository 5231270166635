import React from 'react';

function iconForPaymentMethodOption(paymentMethodOption) {
  switch (paymentMethodOption.kind) {
    case 'card':
      return 'fa fa-credit-card';
    case 'us_bank_account':
      return 'fa fa-bank';
    case 'apple-pay':
      return 'fa fa-apple';
    case 'google-pay':
      return 'fa fa-google';
    case 'paypal':
      return 'fa fa-paypal';
    default:
      return 'fa fa-credit-card';
  }
}

const CardButton = ({ onClick, id, paymentMethodOption, label }) => (
  <button className="credit-card-button" onClick={onClick} id={id} >
    <i className={iconForPaymentMethodOption(paymentMethodOption)}></i>
    { " " }
    { label || "Debit or Credit Card" }
  </button>
)


export default CardButton
