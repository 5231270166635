import React, { useState, useEffect } from 'react'
import { Form, FormGroup, FormControl, InputGroup } from 'react-bootstrap'
import ErrorBlock from './ErrorBlock'

const DaySelector = ({ label, value, onChange, error, hint }) => {
  return (
    <FormGroup size="lg" className="hour_selector">
      { label && <Form.Label> { label } </Form.Label> }
      <InputGroup>
        <FormControl
          type="number"
          value={value}
          onChange={e => onChange(e.target.value)}
          isInvalid={!!error}
        />
        <InputGroup.Text>Day</InputGroup.Text>
      </InputGroup>
      { hint && <Form.Text muted>{ hint }</Form.Text> }
      <ErrorBlock error={error} />
    </FormGroup>
  )
}

export default DaySelector
