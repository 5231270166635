import React from 'react'
import SongPlaylistImporter from './SongPlaylistImporter'
import SongPlaylistClearButton from './SongPlaylistClearButton'

const FieldInstanceCollectionFooter= ({ field }) => {
  switch(field.type) {
    case "song_list":
      return (
        <>
          <SongPlaylistImporter field={field} />
          <SongPlaylistClearButton field={field} />
        </>
      )
    default:
      return null
  }
}

export default FieldInstanceCollectionFooter
