import React from 'react'
import loadingGraphic from '../images/loading.png'

export default class Loader extends React.Component {
  render() {
    return (
      <div className="availability-checker-loader">
        <img src={loadingGraphic} alt="Loading..." />
      </div>
    )
  }
}
