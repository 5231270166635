import React, { useEffect } from 'react'
import { observer } from "mobx-react"
import store from '../stores/store.js'
import SongProviderLabel from '../../ui/SongProviderLabel'
import FaIcon from '../../../helpers/fa_icon'
import SongPlaylistClearButton from './SongPlaylistClearButton'
import SongPlaylistImporter from './SongPlaylistImporter'

const SongPlaylistHeader = observer(({ field }) => {
  const metaPlaylistMode = store.valueFor(field.uuid, "metaPlaylistMode", 0)
  const metaPlaylistUrl = store.valueFor(field.uuid, "metaPlaylistUrl", 0)
  const metaPlaylistName = store.valueFor(field.uuid, "metaPlaylistName", 0)
  const metaPlaylistDescription = store.valueFor(field.uuid, "metaPlaylistDescription", 0)
  const metaPlaylistArtworkUrl = store.valueFor(field.uuid, "metaPlaylistArtworkUrl", 0)
  const metaPlaylistProvider = store.valueFor(field.uuid, "metaPlaylistProvider", 0)

  return (
    <div className="song-playlist-header shadow-sm" style={metaPlaylistArtworkUrl ? styles.visible : styles.hidden}>
      { metaPlaylistArtworkUrl &&
      <div className="song-playlist-header-artwork">
        <img src={metaPlaylistArtworkUrl} style={styles.artwork} />
      </div>
      }
      <div className="song-playlist-header-content">
        <div className="song-playlist-header-title">
          Playlist Details
          <div className="badge badge-primary">{ metaPlaylistMode }</div>
        </div>
        { metaPlaylistName && <div style={styles.playlistName}>{ metaPlaylistName }</div> }
        { metaPlaylistUrl &&
          <div>
            <a href={metaPlaylistUrl} target="_blank">{ metaPlaylistUrl }
              {" "}
              <FaIcon icon="external-link" />
            </a>
          </div> 
        }
        { metaPlaylistProvider && <SongProviderLabel provider={metaPlaylistProvider} /> }
        <div>
          <SongPlaylistImporter field={field} />
          <SongPlaylistClearButton field={field} />
        </div>
      </div>
    </div>
  )
})

const styles = {
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'block'
  },
  playlistName: {
    marginBottom: 4
  },
}

export default SongPlaylistHeader
