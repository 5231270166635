import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'

const QuantityToken = (props) => {
  const { data, innerProps, selectProps } = props

  const [ showModal, setShowModal ] = useState(false)
  const [ quantity, setQuantity ] = useState(data.quantity)


  function handleShowModal(e) {
    e.preventDefault()
    setShowModal(true)
  }

  function handleCloseModal() {
    setShowModal(false)
  }

  function handleSaveAndClose(e) {
    e.preventDefault()

    const currentValue = selectProps.value
    let newQuantity = parseInt(quantity)

    if(newQuantity <= 0) {
      newQuantity = 1
      setQuantity(1)
    }

    const newMergedValue = currentValue.map(currentvalue => {
      if(currentvalue.value === data.value) {
        return { ...currentvalue, quantity: newQuantity}
      }
      else {
        return currentvalue
      }
    })
    selectProps.onChange(newMergedValue)

    handleCloseModal()
  }

  return (
    <div className="token" {...innerProps}>
      <Modal show={showModal} onHide={handleCloseModal} size="sm">
        <Modal.Header closeButton />
        <Modal.Body>
          <Form onSubmit={handleSaveAndClose}>
            <Form.Group className="mb-3" controlId="formQuantity">
              <Form.Label>Adjust Quantity</Form.Label>
              <Form.Control
                type="number"
                value={quantity}
                onChange={e => setQuantity(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* Save and close buttons */}
          <Button variant="primary" onClick={handleSaveAndClose}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <a href="#" role="button" onClick={handleShowModal} className="text-dark">
        { quantity }
        { " x " }
        { data.label }
      </a>
    </div>
  )
}



export default QuantityToken
