import intersection from 'lodash/intersection'
import store from '../stores/store.js'
import elementsForField from './elementsForField.js'

function evaluateAllConditionals({ field, selectedEventCategoryIds, selectedPackageGroupIds, selectedPackageIds, selectedAddOnCategoryIds, selectedAddOnIds, selectedBackdropIds }) {
  return field.conditions.every(condition => {
    const { conditionalFieldUuid, conditionalMatcher, conditionalText, conditionalPackageSelectorSettings } = condition

    if(conditionalFieldUuid === "_package_matcher") {
      return evaluatePackageMatcherConditional({
        conditionalPackageSelectorSettings,
        conditionalMatcher,
        selectedEventCategoryIds,
        selectedPackageGroupIds,
        selectedPackageIds,
        selectedAddOnCategoryIds,
        selectedAddOnIds,
        selectedBackdropIds
      })
    }
    else {
      const relatedField = store.fieldGroupFor(conditionalFieldUuid)
      if(relatedField) {
        return evaluateRelatedFieldConditional({ conditionalText, conditionalMatcher, relatedField })
      }
      else {
        return false
      }
    }
  })
}

function evaluatePackageMatcherConditional({ conditionalPackageSelectorSettings, conditionalMatcher, selectedEventCategoryIds, selectedPackageGroupIds, selectedPackageIds, selectedAddOnCategoryIds, selectedAddOnIds, selectedBackdropIds }) {
  const { allCategories, allAddOnCategories, eventCategoryIds, packageGroupIds, eventTypeIds, addOnCategoryIds, addOnIds } = conditionalPackageSelectorSettings

  if(allCategories) {
    return true
  }
  else if(intersection(selectedEventCategoryIds, eventCategoryIds).length) {
    return true
  }
  else if(intersection(selectedPackageGroupIds, packageGroupIds).length) {
    return true
  }
  else if(intersection(selectedPackageIds, eventTypeIds).length) {
    return true
  }
  else if(allAddOnCategories && selectedAddOnIds.length) {
    return true
  }
  else if(intersection(selectedAddOnCategoryIds, addOnCategoryIds).length) {
    return true
  }
  else if(intersection(selectedAddOnIds, addOnIds).length) {
    return true
  }
  else {
    return false
  }
}

function evaluateRelatedFieldConditional({ conditionalMatcher, conditionalText, relatedField }) {
  const elements = elementsForField(relatedField)
  const value = store.valueFor(relatedField.uuid, elements[0], 0)

  switch(conditionalMatcher) {
    case 'equals':
      return value == conditionalText
    case 'does_not_equal':
      return value != conditionalText
    case 'contains':
      if(!value) return false
      return value.toLowerCase().includes(conditionalText.toLowerCase())
    case 'does_not_contain':
      if(!value) return true
      return !value.toLowerCase().includes(conditionalText.toLowerCase())
    case 'has_selected':
      return value.includes(conditionalText)
    case 'does_not_have_selected':
      return !value.includes(conditionalText)
    case 'has_any_selected':
      return value.length > 0
    case 'does_not_have_any_selected':
      return value.length == 0
    case 'greater_than':
      return parseFloat(value) > parseFloat(conditionalText)
    case 'greater_than_or_equal':
      return parseFloat(value) >= parseFloat(conditionalText)
    case 'less_than':
      return parseFloat(value) < parseFloat(conditionalText)
    case 'less_than_or_equal':
      return parseFloat(value) <= parseFloat(conditionalText)
    case 'is_empty':
      return !value
    case 'is_not_empty':
      return !!value
    case 'is_checked':
      return !!value
    case 'is_attached':
      return !!value
    case 'is_not_attached':
      return !value
    default:
      throw new Error(`Unhandled matcher ${conditionalMatcher}`)
  }

  return true
}

export default evaluateAllConditionals
