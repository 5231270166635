import React, { useState } from 'react'
import SavedPaymentMethodPreview from './SavedPaymentMethodPreview'
import SavedPaymentMethodButton from './SavedPaymentMethodButton'
import ShowAllPaymentMethods from './ShowAllPaymentMethods'
import client from '../../../helpers/client'
import extractErrorMessage from '../util/extractErrorMessage'

const SavedPaymentMethodList = ({ setLoading, onGoBack, userId, paymentIntentId, paymentMethodOption, matchingSavedPaymentMethods }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeSavedPaymentMethodId, setActiveSavedCardId] = useState(null)

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true)

    try {
      const activeSavedPaymentMethod = matchingSavedPaymentMethods.find(savedPaymentMethod => savedPaymentMethod.id === activeSavedPaymentMethodId)
      switch(activeSavedPaymentMethod.processor) {
        case "stripe":
          await client.post(`/payment_intents/${paymentIntentId}/submit_stripe_saved_payment_method`, {
            paymentMethodOptionId: paymentMethodOption.id,
            savedPaymentMethodId: activeSavedPaymentMethod.id,
            customerId: activeSavedPaymentMethod.customerId
          }, { withCredentials: true })
          window.location.reload()
          break;
        case "stripe_express":
          await client.post(`/payment_intents/${paymentIntentId}/submit_stripe_express_saved_payment_method`, {
            savedPaymentMethodId: activeSavedPaymentMethod.id,
            paymentMethodOptionId: paymentMethodOption.id,
            customerId: activeSavedPaymentMethod.customerId
          }, { withCredentials: true })
          window.location.reload()
          break;
        case "square":
          await client.post(`/payment_intents/${paymentIntentId}/submit_square_saved_payment_method`, {
            paymentMethodOptionId: paymentMethodOption.id,
            savedPaymentMethodId: activeSavedPaymentMethod.id,
            customerId: activeSavedPaymentMethod.customerId
          }, { withCredentials: true })
          window.location.reload()
          break;
        default:
          throw new Error(`Unknown processor: ${activeSavedPaymentMethod.processor}`)
      }
    }
    catch(e) {
      setErrorMessage(extractErrorMessage(e))
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      { errorMessage && <div className="card-errors" role="alert">{errorMessage}</div> }

      <SavedPaymentMethodButton onClick={onGoBack} label={paymentMethodOption.name} />

      <div className="saved-card-collection">
        { matchingSavedPaymentMethods.map((savedPaymentMethod) =>
          <SavedPaymentMethodPreview
            key={savedPaymentMethod.id}
            active={activeSavedPaymentMethodId === savedPaymentMethod.id}
            savedPaymentMethod={savedPaymentMethod}
            onClick={() => setActiveSavedCardId(savedPaymentMethod.id)}
          />
        )}
      </div>

      { activeSavedPaymentMethodId &&
        <button type="submit" className="submit-payment-btn">Submit Payment</button>
      }

      <ShowAllPaymentMethods onClick={onGoBack} showBackToAllPaymentMethodOptions={true} />
    </form>
  )
}

export default SavedPaymentMethodList
