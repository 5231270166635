import React from 'react';
import { ListGroup } from 'react-bootstrap';
import EventBadge from './EventBadge';
import BrandBadge from './BrandBadge';

const SearchResultEvent= ({ result, ...props }) => {
  const { brandId, title, venueName, venueAddress, datetimeDescriptions, previewImage, url } = result.attributes

  return (
    <ListGroup.Item action {...props} href={url} className="global-search-result-item" active={false}>
      <div className="global-search-preview-image-container text-dark">
        <img src={previewImage} alt={title} className="img-fluid" />
      </div>
      <div className="global-search-preview-details-container">
        <div>
          <span className="text-dark fw-medium"> { title } </span>
          <EventBadge {...result.attributes} />
          <BrandBadge brandId={brandId} />
        </div>
        { datetimeDescriptions && 
        <>
          { datetimeDescriptions.map((datetimeDescription, index) => (
            <div key={index} className="text-muted">{ datetimeDescription }</div>
          )) }
        </>
        }
        <div className="text-muted">{ venueName }</div>
        <div className="text-muted">{ venueAddress }</div>
        {/* <div className={`text-dark status ${status}`}>{ formattedStatus }</div> */}
      </div>
    </ListGroup.Item>
  )
}

export default SearchResultEvent
