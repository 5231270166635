import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form, FormGroup } from 'react-bootstrap'

@inject("checklistStore")
@observer
export default class SortControl extends React.Component {
  static propTypes = {
    checklistStore: PropTypes.object.isRequired
  }

  render() {
    const { checklistStore } = this.props
    const { sort, availableSortOptions } = checklistStore

    return (
      <div className="checklist-sort">
        <FormGroup>
          <Form.Select
            name="form-select"
            value={sort}
            onChange={e => (checklistStore.sort = e.target.value)}
            aria-label="Sort by"
          >
            { availableSortOptions.map(sortBy =>
              <option key={sortBy} value={sortBy}>
                { sortBy }
              </option>
            )}
          </Form.Select>
        </FormGroup>
      </div>
    )
  }
}

