import React from 'react'
import PropTypes from 'prop-types'
import {observer} from "mobx-react"
import { Button } from 'react-bootstrap'
import store from '../stores/store.js'
import FaIcon from '../../../helpers/fa_icon.js'

@observer
export default class PrintForm extends React.Component {
  handlePrintForm() {
    store.persistFormContent(() => window.location= "?format=pdf&autoprint=true&printable=true")
  }

  render() {
    if(store.printable || store.mode === "preview" || store.mode === "build") {
      return null
    } else if(store.editable) {
      return (
        <Button
          className="w-100"
          size="lg"
          variant="outline-secondary"
          onClick={this.handlePrintForm.bind(this)}
        >
          <FaIcon icon="print" text="Print" />
        </Button>
      )
    }
    else {
      return null
    }
  }
}
