import React from 'react';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';

const splitPattern = /([^\n]\n)(?=[^\n])/g;
const linkPattern = /(^|[\s\n]|<[A-Za-z]*\/?>)((?:https?|ftp):\/\/[\-A-Z0-9+\u0026\u2019@#\/%?=()~_|!:,.;]*[\-A-Z0-9+\u0026@#\/%=~()_|])/gi;
const keyPrefix = 'paragraph';

function splitParagraphs(text) {
  return text.replace(/\r\n?/g, '\n').split(/\n\n+/).map(t => (
    splitPattern.test(t) ? t.replace(splitPattern, '$1<br/>') : t
  ));
}

function autolinkText(text) {
  return text.replace(linkPattern, "$1<a href='$2'>$2</a>");
}

export default function SimpleFormat({ text, wrapperTag, prefix, postfix, autolink, ...props }) {
  const sanitizedText = text ? sanitizeHtml(text) : '';
  const linkedText = autolink ? autolinkText(sanitizedText) : sanitizedText;
  const paragraphs = splitParagraphs(autolinkText(sanitizedText));
  const length = paragraphs.length;

  const children = paragraphs.map((p, i) => {
    let element = parser(p);

    if (!Array.isArray(element)) element = [element];

    if (i === 0 && prefix) element = [prefix, ...element];
    if (i === length - 1 && postfix) element = [...element, postfix];

    return React.createElement(wrapperTag, { key: `${keyPrefix}-${i}`, ...props }, element);
  });
  return React.createElement('div', {}, children);
}

SimpleFormat.propTypes = {
  text: PropTypes.string,
  wrapperTag: PropTypes.string,
  prefix: PropTypes.node,
  postfix: PropTypes.node,
};

SimpleFormat.defaultProps = {
  wrapperTag: 'p',
  autolink: true
};
