import React from 'react';

const EventBadge = ({ status, postponed, canceled }) => {
  if(canceled && status === 'proposal_awaiting_review') {
    return <div className="badge text-bg-danger">Canceled Request for Proposal</div>
  }
  else if(canceled && status === 'proposal_date_open') {
    return <div className="badge text-bg-danger">Canceled Proposal</div>
  }
  else if(canceled) {
    return <div className="badge text-bg-danger">Canceled Booking</div>
  }
  else if(postponed && status === 'proposal_awaiting_review') {
    return <div className="badge text-bg-warning">Postponed Request for Proposal</div>
  }
  else if(postponed && status === 'proposal_date_open') {
    return <div className="badge text-bg-warning">Postponed Proposal</div>
  }
  else if(postponed) {
    return <div className="badge text-bg-warning">Postponed Booking</div>
  }
  else if(status === 'expired_proposal') {
    return <div className="badge text-bg-purple-expired">Expired proposal</div>
  }
  else if(status === 'awaiting_signature' || status === 'confirmed') {
    return <div className="badge text-bg-success">Confirmed</div>
  }
  else if(status === 'proposal_date_open' || status === 'proposal_date_reserved') {
    return <div className="badge text-bg-purple">Proposal</div>
  }
  else if(status === 'proposal_awaiting_review') {
    return <div className="badge text-bg-purple">Proposal Awaiting Review</div>
  }
  else {
    return null
  }
}

export default EventBadge;
