import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, FormControl } from 'react-bootstrap'
import { inject } from 'mobx-react'
import { camelCase } from 'change-case'
import HintField from './HintField'


export default class TextField extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    style: PropTypes.object,
    hint: PropTypes.string,
    errors: PropTypes.object,
    required: PropTypes.bool,
    labelsAsPlaceholders: PropTypes.bool,
    autoFocus: PropTypes.bool
  }

  static defaultProps = {
    labelsAsPlaceholders: false,
    labelStyle: {},
    inputStyle: {}
  }

  get errorMessage() {
    const { errors, name } = this.props

    if(errors && errors[camelCase(name)]) {
      return errors[camelCase(name)][0]
    }
    return null
  }

  get helpMessage() {
    const { hint } = this.props
    return this.errorMessage || hint || null
  }

  render() {
    const { name, label, value, onChange, hint, style, errors, required, labelsAsPlaceholders, labelStyle, inputStyle, autoFocus } = this.props
    const { helpMessage } = this

    return (
      <FormGroup className={name} style={{...styles.wrapper, ...style}}>
        { (!labelsAsPlaceholders) &&
          <Form.Label style={{...styles.label, ...labelStyle}}>
            { label }
            { required && <span style={styles.required}>*</span> }
          </Form.Label>
        }
        <FormControl
          style={{...styles.input, ...inputStyle}}
          name={name}
          value={value}
          onChange={e => onChange(e.target.value)}
          placeholder={labelsAsPlaceholders ? label : null}
          autoFocus={autoFocus}
          isInvalid={!!this.errorMessage}
        />
        <HintField
          hint={hint}
          errorMessage={this.errorMessage}
        />
      </FormGroup>
    )
  }
}

const styles = {
  label: {
    fontSize: 18,
  },
  input: {
    fontSize: 18
  },
  required: {
    color: "#cc0000",
    fontWeight: "normal",
    paddingLeft: 2
  }
}
