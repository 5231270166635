import React from 'react'
import PropTypes from 'prop-types'
import { autorun } from 'mobx'
import { observer, inject } from 'mobx-react'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'

// Note that we batch our typing to make things more responsive and to avoid
// excessive API calls
const SEARCH_DELAY = 500

@inject("checklistStore")
@observer
export default class SearchBox extends React.Component {
  static propTypes = {
    checklistStore: PropTypes.object.isRequired
  }

  state = {
    searchText: ""
  }

  componentDidMount() {
    autorun(this.handleStoreUpdated)
  }

  handleStoreUpdated = () => {
    // const { checklistStore } = this.props
    // const { searchText } = checklistStore
    // this.setState({ searchText })
  }

  handleChange = (e) => {
    const { checklistStore } = this.props
    const searchText = e.target.value
    clearTimeout(this.timeout)

    this.setState({ searchText }, () => {
      this.timeout = setTimeout(() => {
        checklistStore.searchText = searchText
      }, SEARCH_DELAY)
    })
  }

  render() {
    const { checklistStore } = this.props
    const { searchText } = this.state
    
    return (
      <div className="checklist-filter">
        <FormGroup className="input-group">
          <FormControl
            name="search"
            placeholder="Search"
            value={searchText}
            onChange={this.handleChange}
            aria-label="Search"
          />
          <span className="input-group-text">
            <i className="fa fa-search" />
          </span>
        </FormGroup>
      </div>
    )
  }
}

