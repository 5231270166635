import queryString from 'query-string'
import { serializeType, deserializeType } from './serialize.js'

function extractExistingParams() {
  if(location.hash) {
    const parsed = queryString.parse(location.hash)
    return parsed
  }
  else {
    return {}
  }
}

function persistToUrl({ param, type, value }) {
  const existingParams = extractExistingParams()
  const newParams = {
    ...existingParams,
    [param]: serializeType(type, value)
  }

  history.replaceState(undefined, undefined, `#${queryString.stringify(newParams)}`)
}

function restoreFromUrl({ param, type }) {
  const existingParams = extractExistingParams()
  if(existingParams[param]) {
    return deserializeType(type, existingParams[param])
  }

  return null
}

export { persistToUrl, restoreFromUrl }
