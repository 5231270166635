import React from 'react'

const DepositPayment = ({ franchisePaymentPlan }) => (
  <li>
    { (franchisePaymentPlan.attributes.remainingDepositAmountCents < franchisePaymentPlan.attributes.depositAmountCents) ?
      <>
        Today ({ franchisePaymentPlan.attributes.depositTerm }): { franchisePaymentPlan.attributes.remainingDepositAmountFormatted } ({ franchisePaymentPlan.attributes.appliedDepositAmountFormatted } already applied)
      </>
      :
      <>
        Today: { franchisePaymentPlan.attributes.depositAmountFormatted } 
      </>
    }
  </li>
)

export default DepositPayment

