import React from 'react'
import { Form, FormGroup } from 'react-bootstrap'
import { showBackdropsOptions, showCategoriesOptions } from '../options'


const Toolbar = ({ showCategories, showBackdrops, onSetShowCategories, onSetShowBackdrops }) => (
  <div className="row">
    <div className="col-sm-6">
      <FormGroup>
        <Form.Label>Show Categories</Form.Label>
        <Form.Select
          value={showCategories}
          onChange={e => onSetShowCategories(e.target.value) }
          className="input-sm"
        >
          {showCategoriesOptions.map(option => 
          <option key={option} value={option}>{option}</option>
          )}
        </Form.Select>
      </FormGroup>
    </div>
    <div className="col-sm-6">
      <FormGroup>
        <Form.Label>Show Backdrops</Form.Label>
        <Form.Select
          value={showBackdrops}
          onChange={e => onSetShowBackdrops(e.target.value) }
          className="input-sm"
        >
          {showBackdropsOptions.map(option => 
          <option key={option} value={option}>{option}</option>
          )}
        </Form.Select>
      </FormGroup>
    </div>
  </div>
)

export default Toolbar
