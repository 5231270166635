import React from 'react'
import { Col } from 'react-bootstrap'

class FormBody extends React.Component {
  render() {
    const { showSidebar, children } = this.props

    if(showSidebar) {
      return (
        <Col sm={8} className="form-body">
          { children }
        </Col>
      )
    }
    else {
      return <div className="form-body">{ children }</div>
    }
  }
}

export default FormBody
