import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import store from '../stores/store.js'
import FaIcon from '../../../helpers/fa_icon'

@inject("store")
@observer
export default class EventType extends React.Component {
  static propTypes = {
    eventType: PropTypes.object.isRequired,
    onClickPackage: PropTypes.func,
    showCheckboxes: PropTypes.bool
  }

  handleSelection = (id) => {
    const { store } = this.props
    store.toggleEventTypeSelection(id)
  }

  handleStarSelected = (event, id) => {
    const { store } = this.props
    store.toggleEventTypeStar(id)
    event.preventDefault()
  }

  get active() {
    const { eventType } = this.props
    return eventType.status === 'public'
  }

  renderStar() {
    const { store } = this.props
    const { id, selected, recommended } = this.props.eventType

    if(!store.showRecommendedEventTypes) {
      return null
    }

    if(!selected) {
      return null
    }

    return (
      <a
        style={styles.starLink}
        href="#"
        onClick={(event) => this.handleStarSelected(event, id) }
        >
        { recommended ? 
          <FaIcon icon="star" style={styles.starActive} title="Click the star to set the event type as 'Recommended'" />
          :
          <FaIcon icon="star-o" style={styles.starInactive} title="Click the star to set the event type as 'Recommended'" />
        }
      </a>
    )
  }

  renderName() {
    const { eventType, onClickPackage } = this.props
    const activeStyle = this.active ? styles.packageActive : styles.packageInactive

    if(onClickPackage) {
      return (
        <span
          onClick={() => onClickPackage({eventType})}
          style={{...activeStyle, ...styles.clickableEventType}}
        >{ eventType.name }</span>
      )
    }
    else {
      return <span style={activeStyle}>{ eventType.name }</span>
    }
  }
  
  render() {
    const { eventType, showCheckboxes } = this.props
    const { id, selected, name } = eventType

    if(showCheckboxes) {
      return (
        <Form.Check
          type="checkbox"
          style={styles.checkbox}
          id={`event-type-selector-${id}`}
          >
          <Form.Check.Input
            type="checkbox"
            onChange={() => this.handleSelection(id)}
            checked={selected}
          />
          <Form.Check.Label>
            { this.renderName() }
            { this.renderStar() }
          </Form.Check.Label>
        </Form.Check>
      )
    }
    else {
      return (
        <div>
          { this.renderName() }
          { this.renderStar() }
        </div>
      )
    }
  }
}

const styles = {
  checkbox: {
    marginTop: 0,
    marginBottom: 5
  },
  starLink: {
    marginLeft: 10
  },
  starInactive: {
    color: "#bbb"
  },
  starActive: {
    color: "#dad038"
  },
  clickableEventType: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  packageActive: {},
  packageInactive: {
    color: "#aaa"
  }
}
