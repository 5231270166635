import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import FaIcon from '../../../helpers/fa_icon'

@inject("store")
@observer
export default class AddOn extends React.Component {
  static propTypes = {
    addOn: PropTypes.object.isRequired,
    onClickAddOn: PropTypes.func,
    showCheckboxes: PropTypes.bool
  }

  handleSelection = (id) => {
    const { store } = this.props
    store.toggleAddOnSelection(id)
  }

  renderName() {
    const { addOn, onClickAddOn } = this.props

    if(onClickAddOn) {
      return (
        <span
          onClick={() => onClickAddOn({addOn})}
          style={{...styles.active, ...styles.clickableAddOn}}
        >{ addOn.name }</span>
      )
    }
    else {
      return <span style={styles.active}>{ addOn.name }</span>
    }
  }
  
  render() {
    const { addOn, showCheckboxes } = this.props
    const { id, selected, name } = addOn

    if(showCheckboxes) {
      return (
        <Form.Check
          type="checkbox"
          style={styles.checkbox}
          id={`addOn-${id}`}
          >
          <Form.Check.Input
            onChange={() => this.handleSelection(id)}
            checked={selected}
          />
          <Form.Check.Label>
            { this.renderName() }
          </Form.Check.Label>
        </Form.Check>
      )
    }
    else {
      return (
        <div>
          { this.renderName() }
        </div>
      )
    }
  }
}

const styles = {
  checkbox: {
    marginTop: 0,
    marginBottom: 5
  },
  clickableAddOn: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  active: {},
}
