import React, { useState } from 'react'
import SearchBar from './SearchBar'
import SongList from './SongList'
import { Form, Button, Row, Col, FormGroup, FormControl } from 'react-bootstrap'
import TextField from '../../ui/TextField'

const CustomSong = ({ currentSong, onSelect }) => {
  const [ song, setSong ] = useState({
    id: new Date().getTime(),
    name: '',
    artistName: '',
    albumName: '',
    songNotes: '',
    provider: 'custom',
    ...currentSong
  })

  function handleAddSong(e) {
    e.preventDefault()
    onSelect(song)
  }

  return (
    <>
      <Row className="mb-2">
        <Col sm={4}>
          <FormGroup>
            <FormControl
              name="name"
              placeholder="Song Name"
              value={song.name}
              onChange={e => setSong({ ...song, name: e.target.value })}
              autoFocus
            />
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <FormControl
              name="artist"
              placeholder="Artist"
              value={song.artistName}
              onChange={e => setSong({ ...song, artistName: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup>
            <FormControl
              name="album"
              placeholder="Album"
              value={song.albumName}
              onChange={e => setSong({ ...song, albumName: e.target.value })}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup className="mb-2">
        <Form.Label>Additional Notes</Form.Label>
        <FormControl
          as="textarea"
          className="song-notes-textarea"
          type="text"
          value={song.songNotes}
          onChange={e => setSong({ ...song, songNotes: e.target.value })}
        />
      </FormGroup>
      <div className="text-center">
        <Button
          variant="primary"
          type="submit"
          onClick={handleAddSong}
        >
          Save
        </Button>
      </div>
    </>
  )
}

export default CustomSong
