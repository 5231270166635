import React from 'react'
import NewChecklistItem from './NewChecklistItem'
import SearchBox from './SearchBox'
import AssignedFilter from './AssignedFilter'
import FilterControl from './FilterControl'
import SortControl from './SortControl'
import ProposalToggle from './ProposalToggle'

const ToolbarExpanded = ({ showProposalToggle }) => (
  <div>
    <div className="row">
      <div className="col-4">
        <NewChecklistItem />
      </div>

      <div className="control-toolbar col-8">
        <div className="control-item"><SearchBox /></div>
        <div className="control-item"><AssignedFilter /></div>
        <div className="control-item"><FilterControl /></div>
        <div className="control-item"><SortControl /></div>
      </div>
    </div>
    { showProposalToggle &&
      <div className="text-end">
        <ProposalToggle />
      </div>
    }
  </div>
)

export default ToolbarExpanded
