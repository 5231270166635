import React, { useState } from 'react'
import client from '../../helpers/client'
import { Form } from 'react-bootstrap'
import FlashMessages from './components/FlashMessages'
import SchedulingModePanel from './components/SchedulingModePanel'
import PricingPanel from './components/PricingPanel'
import AvailabilityPanel from './components/AvailabilityPanel'
import './style.scss'

const PackageEditor = (props) => {
  const [success, setSuccess] = useState("")
  const [errors, setErrors] = useState({})
  const [formValues, _setFormValues] = useState(props.eventType)
  const [loading, setLoading] = useState(false)

  const handleSave = () => {
    setLoading(true)
    const response = client.put(`/api/v1/event_types/${props.eventType.id}`, {
      apiKey: props.apiKey, ...formValues
    })
      .then(response => {
        setSuccess("Your package was updated")
        setErrors({})
        window.scrollTo(0, 0)
        setLoading(false)
      })
      .catch(e => {
        if(e.response && e.response.data && e.response.data.errors) {
          setErrors(e.response.data.errors)
        }
        else {
          setErrors({ base: e.message })
        }
        window.scrollTo(0, 0)
        setLoading(false)
      })
  }

  const setFormValues = (formValues) => {
    _setFormValues(prevState => ({ ...prevState, ...formValues }))
  }

  const formState = {
    formValues, setFormValues, errors, loading, onSave: handleSave
  }

  return (
    <div className="react-package-editor">
      <Form noValidate>
        <FlashMessages
          {...props}
          {...formState}
          success={success}
          setSuccess={setSuccess}
        />
        <SchedulingModePanel
          {...props}
          {...formState}
        />
        <PricingPanel
          {...props}
          {...formState}
        />
        <AvailabilityPanel
          {...props}
          {...formState}
        />
      </Form>
    </div>
  )
}

export default PackageEditor
