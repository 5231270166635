import {
  CATEGORIES_ALL,
  CATEGORIES_ACTIVE,
  BACKDROPS_ACTIVE,
  BACKDROPS_ARCHIVED,
  BACKDROPS_ALL
} from './options'

function activeBackdropsForCategory(backdrops, category, showBackdrops) {
  const backdropsForCategory = backdrops.filter(backdrop => backdrop.backdropCategoryId === category.id)

  switch(showBackdrops) {
    case BACKDROPS_ACTIVE:
      return backdropsForCategory.filter(backdrop => !backdrop.archived)
    case BACKDROPS_ARCHIVED:
      return backdropsForCategory.filter(backdrop => backdrop.archived)
    case BACKDROPS_ALL:
      return backdropsForCategory
  }
}

function extractSlidesFromBackdrop(backdrop) {
  let slides = []

  if(backdrop.thirdPartyVideoId) {
    slides.push({ type: "video", videoId: backdrop.thirdPartyVideoId })
  }

  if(backdrop.primaryImage && backdrop.primaryImage.videoVariant) {
    slides.push({type: "image", url: backdrop.primaryImage.videoVariant})
  }

  backdrop.additionalImages.forEach(image => {
    if(image && image.videoVariant) {
      slides.push({type: "image", url: image.videoVariant})
    }
  })

  return slides
}

function getCsrfToken() {
  const elem = document.getElementsByName("csrf-token")[0]

  if(elem) {
    return elem.content
  }
  else {
    return null
  }
}

function paginatedResults(results, currentPage, perPage) {
  return results.slice((currentPage - 1) * perPage, currentPage * perPage)
}

function sortByPosition(collection) {
  return collection.sort((a, b) => a.position - b.position)
}

function backdropContains(backdrop, search) {
  const normalizedSearch = search.toLowerCase()
  if(backdrop.name.toLowerCase().includes(normalizedSearch)) {
    return true
  }
  if(backdrop.description.toLowerCase().includes(normalizedSearch)) {
    return true
  }

  return false
}

function resultsForSearch(backdrops, search) {
  if(!search) {
    return backdrops
  }

  return backdrops.filter(backdrop => backdropContains(backdrop, search))
}

export {
  activeBackdropsForCategory,
  extractSlidesFromBackdrop,
  getCsrfToken,
  paginatedResults,
  sortByPosition,
  resultsForSearch
}
