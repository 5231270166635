import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form } from 'react-bootstrap'
import store from '../stores/store.js'
import FaIcon from '../../../helpers/fa_icon'

@inject("store")
@observer
export default class Backdrop extends React.Component {
  static propTypes = {
    backdrop: PropTypes.object.isRequired,
    onClickBackdrop: PropTypes.func,
    showCheckboxes: PropTypes.bool
  }

  handleSelection = (id) => {
    const { store } = this.props
    store.toggleBackdropSelection(id)
  }

  renderName() {
    const { backdrop, onClickBackdrop } = this.props

    if(onClickBackdrop) {
      return (
        <span
          onClick={() => onClickBackdrop({backdrop})}
          style={{...styles.active, ...styles.clickableBackdrop}}
        >{ backdrop.name }</span>
      )
    }
    else {
      return <span style={styles.active}>{ backdrop.name }</span>
    }
  }
  
  render() {
    const { backdrop, showCheckboxes } = this.props
    const { id, selected, name } = backdrop

    if(showCheckboxes) {
      return (
        <Form.Check
          type="checkbox"
          style={styles.checkbox}
          id={`backdrop-${id}`}
          >
          <Form.Check.Input
            onChange={() => this.handleSelection(id)}
            checked={selected}
            type="checkbox"
          />
          <Form.Check.Label>
            { this.renderName() }
          </Form.Check.Label>
        </Form.Check>
      )
    }
    else {
      return (
        <div>
          { this.renderName() }
        </div>
      )
    }
  }
}

const styles = {
  checkbox: {
    marginTop: 0,
    marginBottom: 5
  },
  clickableBackdrop: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  active: {},
}
