import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import ChartTypeSelector from './ChartTypeSelector'
import BrandSelector from './BrandSelector'
import DateControl from './DateControl'
import IntervalControl from './IntervalControl'

const ToolbarCompact = () => {
  const [showFilterModal, setShowFilterModal] = React.useState(false)

  return (
    <>
      <div>
        <div className="d-flex w-100 align-items-center mb-3">
          <div className="flex-grow-1">
            <h5 className="p-0 m-0">Revenue Growth</h5>
          </div>
          <div className="ms-1">
            <Button
              variant="light"
              onClick={() => setShowFilterModal(true)}
            >
              Filter
              { " " }
              <i className="fa fa-filter" />
            </Button>
          </div>
        </div>
      </div>
      <div className="text-center mt-1 mb-1">
        <ChartTypeSelector />
      </div>
      <Modal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <BrandSelector />
          </div>
          <div className="mb-3">
            <DateControl />
          </div>
          <div className="mb-3">
            <IntervalControl />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowFilterModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ToolbarCompact
