import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { Form, FormGroup } from 'react-bootstrap'

@inject("checklistStore")
@observer
export default class ProposalToggle extends React.Component {
  static propTypes = {
    checklistStore: PropTypes.object.isRequired
  }

  render() {
    const { checklistStore } = this.props
    const { showProposals } = checklistStore

    return (
      <div className="proposal-toggle">
        <FormGroup>
          <Form.Check
            type="checkbox"
            inline
            checked={showProposals}
            name={"proposal-toggle"}
            onChange={e => (checklistStore.showProposals = e.target.checked)}
            label="Show Proposals"
            id="proposal-toggle-show-proposals"
          />
        </FormGroup>
      </div>
    )
  }
}

