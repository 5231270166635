import React from 'react';

const MiniSessionBadge = ({ status }) => {
  return (
    <>
      <div className="badge text-bg-info text-white me-1">Session</div>
      { status == "private" && <div className="badge text-bg-secondary">Private</div> }
      { status == "disabled" && <div className="badge text-bg-secondary">Disabled</div> }
    </>
  )
}

export default MiniSessionBadge
