import React from 'react'
import { Form, FormGroup } from 'react-bootstrap'
import { observer } from 'mobx-react'
import store from '../stores/store.js'

const timelineOptions = [
  { value: 'length', label: 'Length Only' },
  { value: 'start_time', label: 'Start Time' },
  { value: 'start_and_end_time', label: 'Start & End Time' },
]

const TimelineFieldEditor = observer(({ field, fieldGroupIndex, fieldIndex }) => {
  const timelineSettings = field.timelineSettings || [{
    timelineMode: ''
  }]

  function setTimelineSettings(properties) {
    const timelineSettings = {
      ...field.timelineSettings,
      ...properties
    }
    store.setFieldProperties(fieldGroupIndex, fieldIndex, { timelineSettings })
  }

  return (
    <FormGroup>
      <Form.Label>Show:</Form.Label>
      <Form.Select
        value={timelineSettings.timelineMode || ""}
        onChange={e => setTimelineSettings({ timelineMode: e.target.value })}
        >
        <option value=""></option>
        { timelineOptions.map(option =>
          <option key={option.value} value={option.value}>
            { option.label }
          </option>
        )}
      </Form.Select>
    </FormGroup>
  )
})

export default TimelineFieldEditor
