import React from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Modal, Row, Col, FormGroup, FormControl } from 'react-bootstrap'
import client from '../../helpers/client'
import SimulationResults from './SimulationResults'

const initialState = {
    show: false,
    loading: false,
    street: "",
    city: "",
    stateCode: "",
    postalCode: "",
    showResults: false,
    name: "",
    taxRate: "",
    errorMessage: ""
  }

export default class TaxSimulator extends React.Component {
  static propTypes = {
    stateName: PropTypes.string.isRequired,
    zipName: PropTypes.string.isRequired,
    stateCodes: PropTypes.array
  }

  state = initialState

  handleClose = () => {
    this.setState(initialState)
  }

  handleSubmit = (e) => {
    const { street, city, stateCode, postalCode } = this.state

    e.preventDefault()
    this.setState({loading: true})

    client.put(`/admin/tax_zones/simulate.json`, {taxZone: { street, city, stateCode, postalCode }})
      .then(response => {
        const { name, taxRate } = response.data
        this.setState({loading: false, showResults: true, errorMessage: "", name, taxRate })
      })
      .catch(error => {
        const { data, status } = error.response
        const { errors } = data
        const errorMessage = status === 422 ? errors.join(", ") : "Error contacting server"
        this.setState({loading: false, showResults: true, errorMessage, name: "", taxRate: "" })
      })
  }

  render() {
    const { stateName, zipName, stateCodes } = this.props
    const { show, street, city, stateCode, postalCode, showResults, loading,
      name, taxRate, errorMessage } = this.state

    return (
      <div style={styles.wrapper}>
        <Button onClick={() => this.setState({show: true})}>
          <i className="fa fa-map-marker" />
          { " Try it out" }
        </Button>
        <Modal show={show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Tax Rate Simulator</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit}>
              <h4>Enter an address to preview tax rates</h4>
                
              <FormGroup>
                <Form.Label>Address</Form.Label>
                <FormControl
                  type="text"
                  placeholder="123 Main St"
                  value={street}
                  onChange={e => this.setState({street: e.target.value})}
                />
              </FormGroup>
              <Row>
                <Col sm={6}>
                  <FormGroup>
                    <Form.Label>City</Form.Label>
                    <FormControl
                      type="text"
                      placeholder="Los Angeles"
                      value={city}
                      onChange={e => this.setState({city: e.target.value})}
                    />
                  </FormGroup>
                </Col>
                { (stateCodes && stateCodes.length > 0) &&
                  <Col sm={3}>
                    <FormGroup>
                      <Form.Label>{ stateName }</Form.Label>
                      <Form.Select
                        value={stateCode}
                        onChange={e => this.setState({stateCode: e.target.value})}
                      >
                        { stateCodes.map(state =>
                          <option key={state} value={state}>{ state }</option>
                        )}
                      </Form.Select>
                    </FormGroup>
                  </Col>
                }
                <Col sm={3}>
                  <FormGroup>
                    <Form.Label>{ zipName }</Form.Label>
                    <FormControl
                      type="text"
                      placeholder="90210"
                      value={postalCode}
                      onChange={e => this.setState({postalCode: e.target.value})}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="mt-2">
                <Button type="submit" size="lg" variant="primary" disabled={loading} className="w-100">
                  { loading ? "Calculating..." : "Calculate Tax Rate" }
                </Button>
              </div>
            </form>
            { showResults &&
              <SimulationResults
                name={name}
                taxRate={taxRate}
                errorMessage={errorMessage}
              />
            }
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const styles = {
  wrapper: {
    display: "inline-block"
  }
}
