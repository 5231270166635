import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'
import uniqueId from 'lodash/uniqueId'

export default class ColorPicker extends Component {
  state = {
    color: this.props.value
  }

  static propTypes = {
    value: PropTypes.string.isRequired,
    id: PropTypes.string
  }

  componentWillMount() {
    if(this.props.id) {
      this.id = this.props.id
    }
    else {
      this.id = uniqueId()
    }
  }

  componentDidMount() {
    $(`#${this.id}`).minicolors({theme: 'bootstrap', change: (color, opacity) => {
      this.handleChange(color)
    }})
  }

  componentDidUpdate(prevProps) {
    if(this.props.color && this.props.color !== this.state.color) {
      this.setState({ color: this.props.color })
    }
  }

  handleChange = (color) => {
    const { onChange } = this.props
    this.setState({color}, () => {
      onChange && onChange(color)
    })
  }

  render() {
    const { color } = this.state

    return (
      <FormControl
        id={this.id}
        type="text"
        value={color || ""}
        onChange={(e) => this.handleChange(e.target.value)}
      />
    )
  }
}
