import React from 'react'

const ErrorBadge = ({ errorCount }) => {
  if(!errorCount) {
    return null
  }

  return (
    <span style={styles.errorBadge}>
      { errorCount }
    </span>
  )
}

const styles = {
  errorBadge: {
    display: "inline-block",
    minWidth: 10,
    padding: "3px 7px",
    marginLeft: 5,
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: 1,
    color: "#fff",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    backgroundColor: "#a00",
    borderRadius: 10
  }
}

export default ErrorBadge
