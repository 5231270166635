import React from 'react'
import { observer, inject } from 'mobx-react'
import { inGroupsOf } from '../util'

@inject("store")
@observer
class EventTypeSelector extends React.Component {
  render() {
    const { store } = this.props
    const { visibleEventTypes } = store
    const perRow = 3

    return (
      <div className="service-selector">
          { inGroupsOf(visibleEventTypes, perRow).map((row, index) =>
            <div key={index} className="service-selector-row">
              { row.map(eventType =>
                <div key={eventType.id} className="service-selector-item">
                  <div className="service-selector-service-image" style={{backgroundImage: `url(${eventType.primaryImage.panelVariant})`}} />
                  <div className="service-selector-service-title">{ eventType.name } </div>
                </div>
              )}
            </div>
          )}
      </div>
    )
  }
}

export default EventTypeSelector
