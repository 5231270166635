import React, { useState } from 'react'

import { Form, FormGroup, Radio } from 'react-bootstrap'
import ErrorBlock from './ErrorBlock'


const DateModeSelector = ({ formValues, setFormValues, errors }) => (
  <FormGroup>
    <Form.Check
      type="radio"
      value="date_time"
      onChange={e => setFormValues({ dateMode: e.target.value}) }
      checked={formValues.dateMode === "date_time"}
      label="Date & Time"
      id="date_time"
      isInvalid={!!errors.dateMode}
    />
    <Form.Check
      type="radio"
      value="date_only"
      onChange={e => setFormValues({ dateMode: e.target.value, calendarMode: "automatic_slots" })}
      checked={formValues.dateMode === "date_only"}
      label="Date Only"
      id="date_only"
      isInvalid={!!errors.dateMode}
    />
    <ErrorBlock error={errors.dateMode} />
  </FormGroup>
)

export default DateModeSelector
