import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'

@inject("store")
@observer
class CartPreview extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  render() {
    const { store } = this.props
    const { cartHtml } = store

    return (
      <div className="cart-preview" dangerouslySetInnerHTML={{__html: cartHtml }} />
    )
  }
}

export default CartPreview
