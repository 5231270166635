import React from 'react'
import ScheduledPayment from './ScheduledPayment'
import DepositPayment from './DepositPayment'
import FinalPayment from './FinalPayment'


const FranchisePaymentPlan = ({ franchisePaymentPlan, dateFormat, activeFranchisePaymentPlanId, setActiveFranchisePaymentPlanId }) => (
  <li className={`list-group-item d-flex justify-content-between align-items-start list-group-item-action ${activeFranchisePaymentPlanId == franchisePaymentPlan.id ? 'payment-plan-active' : ''} ${franchisePaymentPlan.attributes.available ? 'enabled' : 'disabled'}`} onClick={() => setActiveFranchisePaymentPlanId(franchisePaymentPlan.id)}>
    <div className="mt-1">
      <input
        type="radio"
        name="paymentPlan"
        value={franchisePaymentPlan.id}
        checked={activeFranchisePaymentPlanId == franchisePaymentPlan.id}
        onChange={() => setActiveFranchisePaymentPlanId(franchisePaymentPlan.id)}
        className="form-check-input"
      />
    </div>
    <div className="ms-2 me-auto">
      <h5>{ franchisePaymentPlan.attributes.customerTitle }</h5>
      { franchisePaymentPlan.attributes.available ?
        <>
          <div>{ franchisePaymentPlan.attributes.customerSubtitle }</div>
          { franchisePaymentPlan.attributes.showsPaymentBreakdown && activeFranchisePaymentPlanId == franchisePaymentPlan.id && (
            <ul>
              <DepositPayment franchisePaymentPlan={franchisePaymentPlan} dateFormat={dateFormat} />
              { franchisePaymentPlan.attributes.scheduledPayments.map((scheduledPayment, index) => (
                <ScheduledPayment key={index} scheduledPayment={scheduledPayment} dateFormat={dateFormat} />
              ))}
              <FinalPayment franchisePaymentPlan={franchisePaymentPlan} dateFormat={dateFormat} />
            </ul>
          )}
        </>
        :
        <>
          <div className="text-danger">Plan unavailable: { franchisePaymentPlan.attributes.availabilityMessage }</div>
        </>
      }
    </div>
  </li>
)

export default FranchisePaymentPlan
