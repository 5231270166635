import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

function validationStateFor(fieldName, errors) {
  if(errors[fieldName]) {
    return "error"
  }
  else {
    return null
  }
}

function hasValidationError(fieldName, errors) {
  return errors[fieldName] !== undefined
}

const ErrorBlock = ({ error }) => {
  if(!error) {
    return null
  }

  const errorMessage = Array.isArray(error) ? error.join(", ") : error

  return (
    <Form.Control.Feedback type="invalid" style={{display: "block"}}>{ errorMessage }</Form.Control.Feedback>
  )
}

export { hasValidationError, validationStateFor }
export default ErrorBlock
