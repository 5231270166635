import React from 'react'
import PropTypes from 'prop-types'
import ToolbarButtonGroup from './ToolbarButtonGroup'
import ToolbarButton from './ToolbarButton'
import { observer, inject } from 'mobx-react'

const ChartTypeSelector = ({ store }) => {
  const { chartType } = store

  return (
    <ToolbarButtonGroup>
      <ToolbarButton
        active={chartType === "bookings"}
        onClick={() => (store.chartType = "bookings")}
      >
        Scheduled
      </ToolbarButton>
      <ToolbarButton
        active={chartType === "payments"}
        onClick={() => (store.chartType = "payments")}
      >
        Payment
      </ToolbarButton>
      <ToolbarButton
        active={chartType === "bookedOn"}
        onClick={() => (store.chartType = "bookedOn")}
      >
        Booked
      </ToolbarButton>
    </ToolbarButtonGroup>
  )
}

export default inject("store")(observer(ChartTypeSelector))
