import React from 'react'
import PropTypes from 'prop-types'
import ImagePreview from './ImagePreview'
import FileUploader from './FileUploader'

export default class ImageUploaderPreview extends React.Component {
  render() {
    const { apiKey, onChange, value, width, height, imageLink, disabled } = this.props

    return (
      <div>
        <div style={{marginBottom: 10}}>
          <ImagePreview
            imageLink={imageLink}
            width={width}
            height={height}
            url={value}
          />

          { value &&
            <div style={{marginBottom: 10, marginTop: 10 }}>
              <a href={value} className="btn btn-secondary" download={true}>
                <i className="fa fa-download" />
                { " " }
                Download Image
              </a>
            </div>
          }
        </div>
        <div style={{marginBottom: 10}}>
          <FileUploader
            apiKey={apiKey}
            onChange={onChange}
            disabled={disabled}
            acceptedFormats=".png, .jpg, .jpeg, .gif"
          />
        </div>
      </div>
    )
  }
}
