import React from 'react'
import PropTypes from 'prop-types'
import {observer} from "mobx-react"

@observer
export default class Separator extends React.Component {
  render() {
    return (
      <hr />
    )
  }
}
