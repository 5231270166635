import React from 'react'
import {observer} from "mobx-react"
import Header from './Header.js'
import SubHeader from './SubHeader.js'
import Separator from './Separator.js'
import TextField from './TextField.js'
import SongField from './SongField.js'
import DateField from './DateField.js'
import TimeField from './TimeField.js'
import ESignatureField from './ESignatureField.js'
import DoubleTextField from './DoubleTextField.js'
import TripleTextField from './TripleTextField.js'
import QuadrupleTextField from './QuadrupleTextField.js'
import TextBox from './TextBox.js'
import SpotifyPlaylist from './SpotifyPlaylist.js'
import Dropdown from './Dropdown.js'
import RadioGroup from './RadioGroup.js'
import CheckboxGroup from './CheckboxGroup.js'
import Checkbox from './Checkbox.js'
import PlainText from './PlainText.js'
import StaticImage from './StaticImage.js'
import FileUploadField from './FileUploadField.js'
import ImageUploadField from './ImageUploadField.js'

const FieldInstance = observer(({ field, instance }) => {
  switch(field.type) {
    case 'header':
      return (<Header instance={instance} field={field} />)
    case 'subheader':
      return (<SubHeader instance={instance} field={field} />)
    case 'separator':
      return (<Separator instance={instance} field={field} />)
    case 'dropdown':
      return (<Dropdown instance={instance} field={field} />)
    case 'radio_group':
      return (<RadioGroup instance={instance} field={field} />)
    case 'checkbox':
      return (<Checkbox instance={instance} field={field} />)
    case 'checkbox_group':
      return (<CheckboxGroup instance={instance} field={field} />)
    case 'text_field':
      return (<TextField instance={instance} field={field} />)
    case 'double_text_field':
      return (<DoubleTextField instance={instance} field={field} />)
    case 'triple_text_field':
      return (<TripleTextField instance={instance} field={field} />)
    case 'quadruple_text_field':
      return (<QuadrupleTextField instance={instance} field={field} />)
    case 'text_box':
      return (<TextBox instance={instance} field={field} />)
    case 'plain_text':
      return (<PlainText instance={instance} field={field} />)
    case 'static_image':
      return (<StaticImage instance={instance} field={field} />)
    case 'file_uploader_field':
      return (<FileUploadField instance={instance} field={field} />)
    case 'image_uploader_field':
      return (<ImageUploadField instance={instance} field={field} />)
    case 'e_sign_field':
      return (<ESignatureField instance={instance} field={field} />)
    case 'date_field':
      return (<DateField instance={instance} field={field} />)
    case 'time_field':
      return (<TimeField instance={instance} field={field} />)
    case 'spotify_playlist':
      return (<SpotifyPlaylist instance={instance} field={field} />)
    case 'song':
      return (<SongField instance={instance} field={field} />)
    case 'song_list':
      return (<SongField instance={instance} field={field} />)
    default:
      return (<div>Unknown element { field.type }</div>)
  }
})

export default FieldInstance
