import React from 'react'
import PropTypes from 'prop-types'
import {observer} from "mobx-react"

import store from '../stores/store.js'

@observer
export default class Header extends React.Component {
  render() {
    return (
      <h2>{this.props.field.name}</h2>
    )
  }
}
