import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Modal, Row, Col, FormGroup, Radio, FormControl } from 'react-bootstrap'
import ColorPicker from '../ColorPicker'
import EventTypeSelector from '../EventTypeSelector'
import CopyableTextBlock from '../CopyableTextBlock'
import stringifyProps from './stringifyProps'

export default class DesignTemplateWidgetButton extends Component {
  state = {
    showModal: false,
    style: "embed",
    showBookNowButton: true,
    bookNowButtonText: "Book Now",
    categoryId: null,
    showTagViewer: true,
    showAdvancedStylingOptions: false,
    buttonBackgroundColor: "",
    buttonForegroundColor: "",
    textColor: "",
  }

  static propTypes = {
    type: PropTypes.string.isRequired,
    host: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired,
    categories: PropTypes.array.isRequired
  }

  showModal = () => {
    this.setState({showModal: true})
  }

  hideModal = () => {
    this.setState({showModal: false})
  }

  showAdvancedStylingOptions = (e) => {
    e.preventDefault()
    this.setState({ showAdvancedStylingOptions: true })
  }

  hideAdvancedStylingOptions = (e) => {
    e.preventDefault()
    this.setState({ showAdvancedStylingOptions: false })
  }

  get html() {
    const { style, showBookNowButton, bookNowButtonText, showTagViewer, categoryId, showAdvancedStylingOptions, buttonBackgroundColor, buttonForegroundColor, textColor } = this.state
    const { host, apiKey } = this.props

    const baseProps = { apiKey, host: `https://${host}` }
    const dataProps = { showBookNowButton, bookNowButtonText, categoryId, showTagViewer }
    if(showAdvancedStylingOptions) {
      if(buttonBackgroundColor) {
        dataProps.buttonBackgroundColor = buttonBackgroundColor
      }
      if(buttonForegroundColor) {
        dataProps.buttonForegroundColor = buttonForegroundColor
      }
      if(textColor) {
        dataProps.textColor = textColor
      }
    }
    const combinedProps = {...baseProps, ...dataProps}

    switch(style) {
      case "basicLink":
        let url = `https://${host}/design_templates`
        if(Object.keys(dataProps).length > 0) {
          url = `${url}?props=${encodeURIComponent(stringifyProps(dataProps))}`
        }
        return url
      case "embed":
        return `<script src="https://${host}/api/checkcherry_widgets" type="text/javascript" charset="utf-8"></script><div class="checkcherry__widget__design-template-gallery" data-props='${stringifyProps(combinedProps)}'></div>`
      default:
        return null;
    }
  }

  render() {
    const { type, categories } = this.props
    const { showModal, style, showBookNowButton, bookNowButtonText, showTagViewer, categoryId, showAdvancedStylingOptions, textColor, buttonBackgroundColor, buttonForegroundColor } = this.state

    return (
      <div>
        <div className="btn-group">
          <Button onClick={this.showModal} variant="primary">
            <i className="fa fa-code" />
            { " " }
            Get Embed Code
          </Button>
        </div>
        <Modal show={showModal} onHide={this.hideModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Design Template Gallery Widget</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={5}>
                <h3>Customize</h3>
                <FormGroup className="mb-2">
                  <Form.Label>Choose Style</Form.Label>
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "basicLink"}
                    onChange={() => this.setState({style: "basicLink"})}
                    label="Link Only"
                    id="design-template-widget-button-style-basic-link"
                  />
                  <Form.Check
                    type="radio"
                    name="style"
                    checked={style === "embed"}
                    onChange={() => this.setState({style: "embed"})}
                    label="Embed on my site"
                    id="design-template-widget-button-style-embed"
                  />
                </FormGroup>
                <FormGroup className="mb-2">
                  <Form.Label>Collection</Form.Label>
                  <Form.Check
                    type="radio"
                    name="category"
                    checked={categoryId === null}
                    onChange={() => this.setState({categoryId: null})}
                    label="All Collections"
                    id="design-template-widget-button-category-all"
                  />
                  { categories.map(category =>
                    <Form.Check
                      type="radio"
                      key={category.id}
                      name="category"
                      checked={categoryId === category.id}
                      onChange={() => this.setState({categoryId: category.id})}
                      label={category.name}
                      id={`design-template-widget-button-category-${category.id}`}
                    />
                  )}
                </FormGroup>
                <FormGroup className="mb-2">
                  <Form.Label>Show Book Now Button?</Form.Label>
                  <Form.Check
                    type="radio"
                    name="showBookNowButton"
                    checked={showBookNowButton === true}
                    onChange={() => this.setState({showBookNowButton: true})}
                    label="Yes"
                    id="design-template-widget-button-show-book-now-button-yes"
                  />
                  <Form.Check
                    type="radio"
                    name="showBookNowButton"
                    checked={showBookNowButton === false}
                    onChange={() => this.setState({showBookNowButton: false})}
                    label="No"
                    id="design-template-widget-button-show-book-now-button-no"
                  />
                </FormGroup>
                { showBookNowButton &&
                  <FormGroup className="mb-2">
                    <Form.Label>Button Text</Form.Label>
                    <FormControl
                      type="bookNowButtonText"
                      value={bookNowButtonText}
                      onChange={(e) => this.setState({bookNowButtonText: e.target.value})}
                    />
                  </FormGroup>
                }
                <FormGroup className="mb-2">
                  <Form.Label>Show Tag Viewer?</Form.Label>
                  <Form.Check
                    type="radio"
                    name="showTagViewer"
                    checked={showTagViewer === true}
                    onChange={() => this.setState({showTagViewer: true})}
                    label="Yes"
                    id="design-template-widget-button-show-tag-viewer-yes"
                  />
                  <Form.Check
                    type="radio"
                    name="showTagViewer"
                    checked={showTagViewer === false}
                    onChange={() => this.setState({showTagViewer: false})}
                    label="No"
                    id="design-template-widget-button-show-tag-viewer-no"
                  />
                </FormGroup>
                { !showAdvancedStylingOptions &&
                  <div>
                    <a href="#" onClick={this.showAdvancedStylingOptions}>
                      Show Advanced Options
                    </a>
                  </div>
                }
                { showAdvancedStylingOptions &&
                  <div>
                    <h3>Advanced</h3>
                    <Row>
                      <Col sm={12}>
                        <Form.Label>Override Text Color</Form.Label>
                        <ColorPicker
                          value={textColor}
                          onChange={value => this.setState({ textColor: value })}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Form.Label>Override Button Background Color</Form.Label>
                        <ColorPicker
                          value={buttonBackgroundColor}
                          onChange={value => this.setState({ buttonBackgroundColor: value })}
                        />
                      </Col>
                      <Col sm={12}>
                        <label>Override Button Text Color</label>
                        <ColorPicker
                          value={buttonForegroundColor}
                          onChange={value => this.setState({ buttonForegroundColor: value })}
                        />
                      </Col>
                    </Row>
                  </div>
                }
              </Col>
              <Col sm={{ span: 5, offset: 2 }}>
                <h3>HTML</h3>

                <p>Copy and paste the HTML below into your website to add your contact form</p>

                <FormGroup className="mb-2">
                  <CopyableTextBlock value={this.html} />
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

const styles = {
  previewStyle: {
    wordWrap: 'break-word'
  },
  embedStyle: {
    height: 300
  }
}
