import React from 'react'
import SongPlaylistHeader from './SongPlaylistHeader'

const FieldInstanceCollectionHeader = ({ field }) => {

  switch(field.type) {
    case "song_list":
      return (
        <SongPlaylistHeader field={field} />
      )
    default:
      return null
  }
}

export default FieldInstanceCollectionHeader
